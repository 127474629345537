import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCoupons } from '../../modules/actions';
import './coupon.scss'

function CouponPage(props) {

    useEffect(() => {
        props.getCoupons()
    }, [])

    document.title = "Coupons"

    return (
        <div className="container couponMain">
            <h1>Shop Today's Trending Deals and Save Big</h1>
            <div className="couponsDiv">
                {props.coupons && props.coupons.map((a, i) => {
                    return (
                        <div onClick={()=> window.open(a.link)} key={i} className="coupon" style={{ backgroundImage: `url(${a.image})` }}>
                            <div className="bottomPortion">
                                <img src={a.vendor_image} />
                                <label>{a.title}</label>
                                <p>{a.sub_heading}</p>
                            </div>
                        </div>
                    )
                })}
                {/* <div className="coupon" style={{ backgroundImage: `url(${require("./one.jpg")})` }}>
                    <div className="bottomPortion">
                        <img src={require("./oneLogo.jpg")} />
                        <label>Vistaprint Code</label>
                        <p>40% Off Business Cards</p>
                    </div>
                </div>
                <div className="coupon" style={{ backgroundImage: `url(${require("./two.jpg")})` }}>
                    <div className="bottomPortion">
                        <img src={require("./twoLogo.jpg")} />
                        <label>Staples Sale</label>
                        <p>Up to 25% Off Office</p>
                    </div>
                </div>
                <div className="coupon" style={{ backgroundImage: `url(${require("./three.jpg")})` }}>
                    <div className="bottomPortion">
                        <img src={require("./threeLogo.jpg")} />
                        <label>Travelocity Online Cash Back</label>
                        <p>6% Cash Back on Hotels</p>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        coupons: store.store.coupons
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCoupons
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CouponPage)