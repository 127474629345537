import React from 'react'
import blog1 from '../../../Images/blog1.jpg'
import blog2 from '../../../Images/blog2.jpg'
import blog3 from '../../../Images/blog3.jpg'
import { NavLink, withRouter } from 'react-router-dom';
import { getTopBlogs } from '../../../modules/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class TenthSetion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (document.getElementById("carousel-example5Next")) {
                document.getElementById("carousel-example5Next").click()
            }
        }, 2000)

        this.props.getTopBlogs()
    }

    render() {
        return (
            <>
                <div className="blog container webBlog">
                    <h2>Latest Blog</h2>
                    <div className="blog-div">
                        {this.props.topBlogs && this.props.topBlogs.length ?
                            this.props.topBlogs.map((a, i) => {
                                return (
                                    i < 3 &&
                                    <div key={i} className="blogCard">
                                        <div className="blogImg">
                                            {window.screen.width < 600 ?
                                                <a a={`/blogs/${a.slug}`}>
                                                    <div className="blogImgBg" style={{ backgroundImage: `url('${a.image}')` }}></div>
                                                    <a a={`/blogs/${a.slug}`}>{a.title}</a>
                                                </a>
                                                :
                                                <NavLink to={`/blogs/${a.slug}`}>
                                                    <div className="blogImgBg" style={{ backgroundImage: `url('${a.image}')` }}></div>
                                                    <NavLink to={`/blogs/${a.slug}`}>{a.title}</NavLink>
                                                </NavLink>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            : null}
                        <div className="seeMore" onClick={() => this.props.history.push("/blogs")} >See more</div>
                    </div>
                </div>

                <div className="blog container mobileBlog">
                    <h2>Latest Blog</h2>
                    <div className="blog-div">

                        <div id="carousel-example5" className="carousel slide hidden-xs" data-ride="carousel" data-type="multi"
                            style={{ position: 'inherit' }}>
                            <div className="carousel-inner">

                                <ol className="carousel-indicators">
                                    <li data-target="#carousel-example5" data-slide-to="0" className="active"></li>
                                    <li id="carousel-example5Next" data-target="#carousel-example5" data-slide-to="1"></li>
                                    <li data-target="#carousel-example5" data-slide-to="2"></li>
                                </ol>

                                {this.props.topBlogs && this.props.topBlogs.length ?
                                    this.props.topBlogs.map((a, i) => {
                                        return (
                                            i < 3 &&
                                            <div className={`carousel-item ${i === 0 && 'active'}`} >
                                                <div className="blogCard">
                                                    <div className="blogImg">
                                                        {window.screen.width < 600 ?
                                                            <a href={`/blogs/${a.slug}`}>
                                                                <div className="blogImgBg" style={{ backgroundImage: `url('${a.image}')` }}></div>
                                                                <a href={`/blogs/${a.slug}`}>{a.title}</a>
                                                            </a>
                                                            :
                                                            <NavLink to={`/blogs/${a.slug}`}>
                                                                <div className="blogImgBg" style={{ backgroundImage: `url('${a.image}')` }}></div>
                                                                <NavLink to={`/blogs/${a.slug}`}>{a.title}</NavLink>
                                                            </NavLink>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null}
                            </div>
                        </div>
                        <div className="seeMore2" onClick={() => this.props.history.push("/blogs")} >See more</div>

                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    topBlogs: store.topBlogs
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTopBlogs
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TenthSetion))