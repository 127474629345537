import React from 'react'
import ProfileDiv from './Components/Profile';
import MyAccount from './Components/MyAccount';
import MyOrder from './Components/MyOrder';
import Wishlist from './Components/Wishlist';
import Wallet from './Components/Wallet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import QuickView from '../quickViewModel/quickView';
import { NavLink } from 'react-router-dom';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    render() {
        document.title = `${this.props.user.firstname}'s Profile`
        return (
            <div className="skinCaree profilee">
                {this.props.isLoading &&
                    <div className="loaderr loaderSkin" id="loader">
                        <div className="loading">
                            <div className="finger finger-1" id="loaderIn">
                                <div className="finger-item">
                                    <span></span><i></i>
                                </div>
                            </div>
                            <div className="finger finger-2">
                                <div className="finger-item">
                                    <span></span><i></i>
                                </div>
                            </div>
                            <div className="finger finger-3">
                                <div className="finger-item">
                                    <span></span><i></i>
                                </div>
                            </div>
                            <div className="finger finger-4">
                                <div className="finger-item">
                                    <span></span><i></i>
                                </div>
                            </div>
                            <div className="last-finger">
                                <div className="last-finger-item"><i></i></div>
                            </div>
                        </div>
                    </div>}
                {this.props.quickView &&
                    <QuickView />
                }
                <div className="page container">
                    <div className="allProducts">

                        <div className="row mb-5">
                            <div className="col-md-12">
                                <ul className="nav nav-tabs profile-navs" id="myTab" role="tablist">
                                    <li className="nav-item pad-0">
                                        {window.screen.width < 600 ?
                                            <a
                                                style={{ height: '100%' }}
                                                className="nav-link"
                                                href="/profile/summary"
                                            >
                                                <i className="fa fa-user"></i>My Profile</a>
                                            :
                                            <NavLink
                                                style={{ height: '100%' }}
                                                className="nav-link"
                                                to="/profile/summary"
                                            >
                                                <i className="fa fa-user"></i>My Profile</NavLink>
                                        }
                                    </li>
                                    <li className="nav-item pad-0">
                                        {window.screen.width < 600 ?
                                            <a
                                                style={{ height: '100%' }}
                                                className="nav-link"
                                                href="/profile/my-account"
                                            >
                                                <i className="fa fa-lock"></i>My Account</a>
                                            :
                                            <NavLink
                                                style={{ height: '100%' }}
                                                className="nav-link"
                                                to="/profile/my-account"
                                            >
                                                <i className="fa fa-lock"></i>My Account</NavLink>
                                        }
                                    </li>
                                    {/* <li className="nav-item pad-0">
                                        <a style={{ height: '100%' }} className="nav-link" id="review" data-toggle="tab" href="#contact"
                                            role="tab" aria-controls="contact" aria-selected="false"><i
                                                className="fa fa-shopping-cart"></i> My Orders</a>
                                    </li> */}
                                    {/* <li className="nav-item pad-0">
                                        <a style={{ height: '100%' }} className="nav-link" id="description" data-toggle="tab"
                                            href="#wallet" role="tab" aria-controls="home" aria-selected="true"><i
                                                class="fas fa-wallet"></i> My Wallet</a>
                                    </li> */}
                                    <li className="nav-item pad-0">
                                        {window.screen.width < 600 ?
                                            <a
                                                style={{ height: '100%' }}
                                                className="nav-link"
                                                href="/profile/whishlist"
                                            >
                                                <i className="fa fa-heart"></i>Wishlist</a>
                                            :
                                            <NavLink
                                                style={{ height: '100%' }}
                                                className="nav-link"
                                                to="/profile/whishlist"
                                            >
                                                <i className="fa fa-heart"></i>Wishlist</NavLink>
                                        }
                                    </li>
                                </ul>
                            </div>
                            {/* {this.props.children} */}
                        </div>



                        {/* <div className="row marginTopDiv">
                            <div className="col-md-12">
                                <div className="tab-content" id="myTabContent">

                                    <ProfileDiv />

                                    <MyAccount />

                                    <MyOrder />

                                    <Wishlist />

                                    <Wallet />

                                    {this.props.children}

                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    user: store.user,
    quickView: store.quickView,
    isLoading: store.isLoading
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile)