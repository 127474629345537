import React from 'react'
import p4 from '../../../Images/p4.jpg'
import Slider from "react-slick";
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Axios from 'axios'
import { fetchArrivedProduct, getCartOrders, getCookie, getWishlist, quickViewModel } from '../../../modules/actions';
import NOAvaliableImage from '../../../Images/NoAvaliable.jpeg'
import QuickView from '../../quickViewModel/quickView';

class SixthSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    // hoverImage = (id) => {
    //     document.getElementById(`hoverDiv${id}`).style.opacity = "1"
    // }

    // hoverOutImage = (id) => {
    //     document.getElementById(`hoverDiv${id}`).style.opacity = "0"
    // }

    renderArrows = () => {
        return (
            <div style={{ zIndex: 1 }} className="slider-arrow">
                <i onClick={() => this.slider.slickPrev()} className="fas fa-chevron-left" aria-hidden="true"></i>
                <i onClick={() => this.slider.slickNext()} className="fas fa-chevron-right" aria-hidden="true"></i>
            </div>
        );
    };

    addToCart = (id, out) => {
        if (!out) {
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("id", id);
            data.append("action", "add");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (response.data.code === "token_not_valid") {
                        let msg = "You have login first"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        let data = response.data.products
                        // this.props.getCartOrders(data);
                        let msg = response.data.message
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        return data
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        } else {
            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>Out of stock</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
            }, 2000)
        }
    }

    addToWhishlist = (id) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("product_id", id);
        // data.append("action", "add");
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/wishlist/update/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.code === "token_not_valid") {
                    // let msg = "You have login first"
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    window.location.href = '/login'
                } else {
                    let data = response.data.products
                    // let msg = response.data.message
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    this.props.getWishlist()
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    return data
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    quickView = (obj) => {
        this.props.quickViewModel(obj)
    }

    checkWhishlist = (a, abc) => {
        let whishlistProducts = this.props.whishlistProducts
        if (whishlistProducts) {
            let check = whishlistProducts.filter(b => b.id === a.id)
            if (check.length) {
                return (
                    <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-heart-fill" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                    </svg>
                )
            } else {
                return (
                    <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                    </svg>
                )
            }
        } else {
            return (
                <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                </svg>
            )
        }
    }

    discoutGet = (a, b) => {
        let price = a
        let discounted_price = b
        let discounted_given = Number(price) - Number(discounted_price)
        return `${Number(Math.ceil((discounted_given / price) * 100).toFixed(1)).toFixed(0)}% Off`
    }

    changeRoute = (a, ev) => {
        if (ev.target.id !== "noOpen" && ev.target.nodeName !== "path") {
            if (window.screen.availWidth < 768) {
                window.location.href = `/skin-care/${a.slug}`
            } else {
                window.open(`/skin-care/${a.slug}`, '_blank');
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (document.getElementById("carousel-example22Next")) {
                document.getElementById("carousel-example22Next").click()
                document.getElementById("carousel-example7Next").click()
            }
        }, 2000)
    }

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false
        };
        return (
            <>
                <div className="just-Ariv">
                    {this.props.quickView &&
                        <QuickView />
                    }
                    <h2>Best Sellers</h2>
                    <div className="products-carts webProView">
                        <div style={{ position: "relative", marginTop: "2rem" }}>
                            {this.renderArrows()}
                            <Slider style={{ zIndex: 2 }} ref={c => (this.slider = c)} {...settings}>
                                {this.props.arrivedProducts && this.props.arrivedProducts.all && this.props.arrivedProducts.all.map((a, i) => {
                                    return (
                                        <div key={i} className="product">
                                            <div onClick={(ev) => this.changeRoute(a, ev)} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                                <div id="hoverDiv1" className="hoverDiv" style={{ opacity: 0 }}>
                                                    {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id,"out")} className="hoverI update-cart" data-action="add" data-productid="66">
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z">
                                                            </path>
                                                        </svg>
                                                    </div> */}
                                                    <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid="66">
                                                        {this.checkWhishlist(a)}
                                                    </div>
                                                </div>
                                                {a.stock_quantity === 0 &&
                                                    <div className="outOfStockDiv">Out of stock</div>
                                                }
                                            </div>
                                            <div onClick={(ev) => this.changeRoute(a, ev)} className="product-txt">
                                                <h6><i>{a.brand} - </i> <NavLink style={{ color: 'black', textDecoration: "none" }} target={window.screen.availWidth < 768 ? false : false} >{a.name.length > 42 ? `${a.name.slice(0, 42)}...` : a.name}</NavLink></h6>
                                                {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                                <p className="price web">
                                                    {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                        <>
                                                            <span className="lightGray">
                                                                MRP:&nbsp;&nbsp;
                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                            <span style={{ float: 'right' }}>
                                                                <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                            </span>
                                                            <span className=""> |</span>
                                                            <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                        </>
                                                        :
                                                        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {a.price}</span>
                                                    }
                                                </p>
                                                <div className="mob-btn">
                                                    {/* <div className="mob-cart"> */}
                                                    {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                                </svg>
                                                                <div>
                                                                    <p> ADD TO CART </p>
                                                                </div>
                                                            </button> */}
                                                    {/* </div> */}
                                                    <p className="price">
                                                        {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                            <>
                                                                <span className="lightGray">

                                                                    <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                <span style={{ float: 'right' }}>
                                                                    <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                    {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                </span>
                                                                <span className=""> |</span>
                                                                <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                            </>
                                                            :
                                                            <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i> {a.price}</span>
                                                        }
                                                    </p>
                                                    {/* <div className="mob-wish">
                                                        <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                            {this.checkWhishlist(a, "a")}
                                                            <div id="noOpen">
                                                                <p id="noOpen"> WISHLIST </p>
                                                            </div>
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {/* <hr /> */}
                                            <div className="button-div">
                                                <button onClick={() => this.quickView(a)}>
                                                    Quick View
                                                </button>
                                            </div>
                                        </div>

                                    )
                                })}
                            </Slider >
                        </div >
                    </div >
                </div >

                <div id="carousel-example22" className="carousel slide hidden-xs div2 tabProView" data-ride="carousel" data-type="multi"
                    style={{ position: 'inherit' }}>

                    <div className="carousel-inner">
                        <div className="controlProduct">
                            <a className=" btn " href="#carousel-example22" data-slide="prev"><i className="fas fa-chevron-left"></i></a>
                            <a id="carousel-example22Next" className=" btn" href="#carousel-example22" data-slide="next"><i className="fas fa-chevron-right"></i></a>
                        </div>
                        {this.props.arrivedProducts && this.props.arrivedProducts.all && this.props.arrivedProducts.all.map((a, i) => {
                            return (
                                <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                                    <div className="product-div-2">
                                        <div className="product">
                                            <div onClick={(ev) => this.changeRoute(a, ev)} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                                <div id="hoverDiv1" className="hoverDiv" style={{ opacity: 0 }}>
                                                    {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id,"out")} className="hoverI update-cart" data-action="add" data-productid="66">
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z">
                                                            </path>
                                                        </svg>
                                                    </div> */}
                                                    <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid="66">
                                                        {this.checkWhishlist(a)}
                                                    </div>
                                                </div>
                                                {a.stock_quantity === 0 &&
                                                    <div className="outOfStockDiv">Out of stock</div>
                                                }
                                            </div>
                                            <div onClick={(ev) => this.changeRoute(a, ev)} className="product-txt">
                                                <h6><i>{a.brand} - </i>
                                                    {a.name.length > 42 ? `${a.name.slice(0, 42)}...` : a.name}
                                                    {/* <NavLink style={{ color: 'black', textDecoration: "none" }} target={window.screen.availWidth < 768 ? false : false} to="#!">{a.name.length > 42 ? `${a.name.slice(0, 42)}...` : a.name}</NavLink> */}
                                                </h6>
                                                {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                                <p className="price web">
                                                    {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                        <>
                                                            <span className="lightGray">
                                                                MRP:&nbsp;&nbsp;
                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                            <span style={{ float: 'right' }}>
                                                                <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                            </span>
                                                            <span className=""> |</span>
                                                            <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                        </>
                                                        :
                                                        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {a.price}</span>
                                                    }
                                                </p>
                                                <div className="mob-btn">
                                                    {/* <div className="mob-cart"> */}
                                                    {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                                </svg>
                                                                <div>
                                                                    <p> ADD TO CART </p>
                                                                </div>
                                                            </button> */}
                                                    {/* </div> */}
                                                    <p className="price">
                                                        {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                            <>
                                                                <span className="lightGray">

                                                                    <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                <span style={{ float: 'right' }}>
                                                                    <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                    {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                </span>
                                                                <span className=""> |</span>
                                                                <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                            </>
                                                            :
                                                            <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i> {a.price}</span>
                                                        }
                                                    </p>
                                                    {/* <div className="mob-wish">
                                                        <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                            {this.checkWhishlist(a, "a")}
                                                            <div id="noOpen">
                                                                <p id="noOpen"> WISHLIST </p>
                                                            </div>
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {/* <hr /> */}
                                            <div className="button-div">
                                                <button onClick={() => this.quickView(a)}>
                                                    Quick View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div id="carousel-example7" className="carousel slide hidden-xs div2 mobProView" data-ride="carousel" data-type="multi"
                    style={{ position: 'inherit' }}>

                    <div className="carousel-inner">
                        <div className="controlProduct">
                            <a className=" btn " href="#carousel-example7" data-slide="prev"><i className="fas fa-chevron-left"></i></a>
                            <a id="carousel-example7Next" className=" btn" href="#carousel-example7" data-slide="next"><i className="fas fa-chevron-right"></i></a>
                        </div>

                        {this.props.arrivedProducts && this.props.arrivedProducts.all && this.props.arrivedProducts.all.map((a, i) => {
                            return (
                                <div key={i} className={`carousel-item ${i === 0 ? "active" : ""}`}>
                                    <div className="product-div-2">
                                        <div className="product">
                                            <div onClick={(ev) => this.changeRoute(a, ev)} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                                <div id="hoverDiv1" className="hoverDiv" style={{ opacity: 0 }}>
                                                    {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id,"out")} className="hoverI update-cart" data-action="add" data-productid="66">
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z">
                                                            </path>
                                                        </svg>
                                                    </div> */}
                                                    <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid="66">
                                                        {this.checkWhishlist(a)}
                                                    </div>
                                                </div>
                                                {a.stock_quantity === 0 &&
                                                    <div className="outOfStockDiv">Out of stock</div>
                                                }
                                            </div>
                                            <div onClick={(ev) => this.changeRoute(a, ev)} className="product-txt">
                                                <h6><i>{a.brand} - </i>
                                                    {a.name.length > 42 ? `${a.name.slice(0, 42)}...` : a.name}
                                                    {/* <NavLink style={{ color: 'black', textDecoration: "none" }} target={window.screen.availWidth < 768 ? false : false} to="#!">{a.name.length > 42 ? `${a.name.slice(0, 42)}...` : a.name}</NavLink> */}
                                                </h6>
                                                {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                                <p className="price web">
                                                    {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                        <>
                                                            <span className="lightGray">
                                                                MRP:&nbsp;&nbsp;
                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                            <span style={{ float: 'right' }}>
                                                                <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                            </span>
                                                            <span className=""> |</span>
                                                            <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                        </>
                                                        :
                                                        <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {a.price}</span>
                                                    }
                                                </p>
                                                <div className="mob-btn">
                                                    {/* <div className="mob-cart"> */}
                                                    {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                                </svg>
                                                                <div>
                                                                    <p> ADD TO CART </p>
                                                                </div>
                                                            </button> */}
                                                    {/* </div> */}
                                                    <p className="price">
                                                        {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                            <>
                                                                <span className="lightGray">

                                                                    <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                <span style={{ float: 'right' }}>
                                                                    <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                    {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                </span>
                                                                <span className=""> |</span>
                                                                <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                            </>
                                                            :
                                                            <span style={{ float: 'right' }}><i className="fas fa-rupee-sign" aria-hidden="true"></i> {a.price}</span>
                                                        }
                                                    </p>
                                                    {/* <div className="mob-wish">
                                                        <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                            {this.checkWhishlist(a, "a")}
                                                            <div id="noOpen">
                                                                <p id="noOpen"> WISHLIST </p>
                                                            </div>
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            {/* <hr /> */}
                                            <div className="button-div">
                                                <button onClick={() => this.quickView(a)}>
                                                    Quick View
                                                        </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
                {/* <div className="jusrAriveButtton">
                    <button className="quiz">
                        <NavLink to="/skin-care">
                            Show More
            </NavLink>

                    </button>
                </div> */}
            </>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    skinCareProducts: store.skinCareProducts,
    whishlistProducts: store.whishlistProducts,
    arrivedProducts: store.arrivedProducts,
    quickView: store.quickView
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCartOrders,
            quickViewModel,
            getWishlist,
            fetchArrivedProduct
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SixthSection))