import React from 'react'
import { NavLink } from 'react-router-dom'
import ft1 from '../../Images/ft1.jpg'
import ft2 from '../../Images/ft2.jpg'
import ft3 from '../../Images/ft3.jpg'
import ft4 from '../../Images/ft4.jpg'
import ftlogo from '../../Images/Logo 2.png'
import ftlogo2 from '../../Images/Logo 3.png'

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <footer>
                <div className="footer-con container-fluid">

                    <div className="footer">
                        {/* <div className="innerFooter  ">
                            <div className="det-con">
                                <div className="details ">
                                    <img className="footerlogo" width="120px" src={ftlogo} alt="" />
                                    <img className="footerlogo" width="120px" src={ftlogo2} alt="" />
                                    <p className="comapany-detail">Wequity aims responsible investing more accessible and
                                    impactful.
                                </p>
                                    <ul className="social-links">
                                        <li>
                                            <a className="facebook" href="#">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="twitter" href="#">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="google+" href="#">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="insta" href="#">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>

                                </div>
                                <div className="contact ">
                                    <h6 className="cont-heading">CONTACT US</h6>
                                    <ul>
                                        <li className="cont-link"> 002-6666-8888 </li>
                                        <li className="cont-link"> info.abc@gmail.com </li>
                                        <li className="cont-link"> 40 Abc Street 133/2 </li>
                                        <li className="cont-link"> NewYork City </li>

                                    </ul>

                                </div>
                            </div>
                            <div className="news-pic">
                                <div className="newsletter ">
                                    <h6 className="news-heading"> OUR NEWSLETTER </h6>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Email"
                                            aria-label="Recipient's username" aria-describedby="button-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="button"
                                                id="button-addon2">SUBSCRIBE</button>
                                        </div>
                                    </div>
                                    <p>Subscribe to our mailing list to get the updates to your email inbox.</p>

                                </div>
                                <div className="pictures ">
                                    <div>
                                        <img src={ft1} alt="" />
                                        <img src={ft2} alt="" />
                                        <img src={ft3} alt="" />
                                        <img src={ft4} alt="" />
                                        <img src={ft3} alt="" />
                                        <img src={ft4} alt="" />
                                    </div>
                                </div>

                            </div>
                        </div> */}


                        <div className="cossmetifyFooter container">
                            <ul className="col-sm-4">
                                <li className="cossmetifyFooterUlHead">COMPANY</li>
                                <li>
                                    {window.screen.width < 600 ?
                                        <a href="/about">About Us</a>
                                        :
                                        <NavLink to="/about">About Us</NavLink>
                                    }
                                </li>
                                <li>
                                    {window.screen.width < 600 ?
                                        <a href="/contact-us">Contact Us</a>
                                        :
                                        <NavLink to="/contact-us">Contact Us</NavLink>
                                    }
                                </li>
                                {/* <li>Contact Us</li>
                                <li>FAQ</li> */}
                            </ul>

                            {/* <ul className="col-sm-4">
                                <li className="cossmetifyFooterUlHead">WORK WITH US</li>
                                <li>Partners</li>
                                <li>Press</li>
                                <li>Influencers</li>
                            </ul> */}

                            <ul className="col-sm-4">
                                <li className="cossmetifyFooterUlHead">USEFUL INFO</li>
                                <li>
                                    {window.screen.width < 600 ?
                                        <a href="/privacy">Privacy Policy</a>
                                        :
                                        <NavLink to="/privacy">Privacy Policy</NavLink>
                                    }
                                </li>
                                <li>
                                    {window.screen.width < 600 ?
                                        <a href="/terms-and-conditions">Terms & Conditions</a>
                                        :
                                        <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
                                    }
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>
                <div className="copyright container-fluid">
                    <div className="copyright-text ">
                        <p>Copyright ©2021 Glamate.</p>
                        <p>csg@glamate.in</p>
                        <ul>
                            <li><a href="https://www.facebook.com/glamatebeauty" target="_blank">Facebook</a></li>
                            <li><a href="https://www.linkedin.com/company/glamate/" target="_blank">LinkedIn</a></li>
                            <li><a style={{ border: 'none !important' }} href="https://www.instagram.com/glamatebeauty/" target="_blank">Instagram</a></li>
                        </ul>
                    </div>
                </div>
            </footer >
        )
    }
}

export default Footer