import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './quiz.scss'

import maleGif from '../../Images/Gif/male.gif'
import femaleGif from '../../Images/Gif/female.gif'
import robo from '../../Images/robo.png'
import { NavLink } from 'react-router-dom';
import { getBrandsTypeUser, getCookie } from '../../modules/actions';
import Axios from 'axios'
import one from '../../Images/1.jpg'
import two from '../../Images/2.jpg'
import three from '../../Images/3.jpg'
import four from '../../Images/4.jpg'
import five from '../../Images/5.jpg'
import six from '../../Images/6.jpg'
import seven from '../../Images/7.jpg'
import eight from '../../Images/8.jpg'
import nine from '../../Images/9.jpg'
import ten from '../../Images/10.jpg'
import eleven from '../../Images/11.jpg'

import normal from '../../Images/normal.png'
import oily from '../../Images/oily.png'
import combination from '../../Images/combination.png'
import dry from '../../Images/dry.png'
import sensitive from '../../Images/sensitive.png'

import normalMale from '../../Images/normal-male.png'
import oilyMale from '../../Images/oily-male.png'
import combinationMale from '../../Images/combination-male.png'
import dryMale from '../../Images/dry-male.png'
import sensitiveMale from '../../Images/sensitive-male.png'

import oneMale from '../../Images/1-male.png'
import twoMale from '../../Images/2-male.png'
import threeMale from '../../Images/3-male.png'
import fourMale from '../../Images/4-male.png'
import fiveMale from '../../Images/5-male.png'
import sixMale from '../../Images/6-male.png'
import sevenMale from '../../Images/7-male.png'
import eightMale from '../../Images/8-male.png'
import nineMale from '../../Images/9-male.png'
import tenMale from '../../Images/10-male.png'
import elevenMale from '../../Images/11-male.png'




import skin1 from '../../Images/19.jpg'
import skin2 from '../../Images/20.jpg'
import skin3 from '../../Images/21.jpg'
import skin4 from '../../Images/22.jpg'
import skin5 from '../../Images/23.jpg'
import skin6 from '../../Images/24.jpg'
import skin7 from '../../Images/25.jpg'

import sleep1 from '../../Images/13.jpg'
import sleep2 from '../../Images/14.jpg'
import sleep3 from '../../Images/15.jpg'

import water1 from '../../Images/16.jpg'
import water2 from '../../Images/17.jpg'
import water3 from '../../Images/18.jpg'

import gif from '../../Images/data2.gif'

import k_beauty1 from '../../Brand Images/K-Beauty/59DC8522-9A2D-4859-8354-58DFF3EBE6BD_LaneigeLogo.png'
import k_beauty2 from '../../Brand Images/K-Beauty/8058aa9916e1a392c6902c8e4b685cc18ceaa139r1-450-218v2_00.jpg'
import k_beauty3 from '../../Brand Images/K-Beauty/cosrx_bg1_1.png'
import k_beauty4 from '../../Brand Images/K-Beauty/innisfree-logo-2.jpg'
import k_beauty5 from '../../Brand Images/K-Beauty/the-face-shop-logo.png'
import k_beauty6 from '../../Brand Images/K-Beauty/Tony_Moly_logo.png'

import clinical1 from '../../Brand Images/Clinical/_qOt4ius.png'
import clinical2 from '../../Brand Images/Clinical/4e03eca221e55215a37808be1886f9f3.png'
import clinical3 from '../../Brand Images/Clinical/37f9f3e8f9d4002c68ba496da97fa2fb.w750.h750.jpg'
import clinical4 from '../../Brand Images/Clinical/1280px-Neutrogena_logo.svg.png'
import clinical5 from '../../Brand Images/Clinical/dermaco.png'
import clinical6 from '../../Brand Images/Clinical/logo.png'

import luxury1 from '../../Brand Images/Luxury/5a1ac58cf65d84088faf133d.png'
import luxury2 from '../../Brand Images/Luxury/brand.gif'
import luxury3 from '../../Brand Images/Luxury/dermalogica-logo.png'
import luxury4 from '../../Brand Images/Luxury/download.png'
import luxury5 from '../../Brand Images/Luxury/estee-lauder-2-logo-png-transparent.png'
import luxury6 from '../../Brand Images/Luxury/Mac_logo_logotype.png'

import MassBeauty1 from '../../Brand Images/MassBeauty/768px-Nivea_logo.svg.png'
import MassBeauty2 from '../../Brand Images/MassBeauty/logo-buywow-2020_native_shopify.png'
import MassBeauty3 from '../../Brand Images/MassBeauty/Olay_logo.png'
import MassBeauty4 from '../../Brand Images/MassBeauty/p_USER629678875145298237155139972686664398.jpg'
import MassBeauty5 from '../../Brand Images/MassBeauty/Plum_logo_300_x200_PNG.png'
import MassBeauty6 from '../../Brand Images/MassBeauty/pufqbr2gqxm85ob6bmos.png'

import NaturalHerbal1 from '../../Brand Images/Natural&Herbal/696f077e-8c8d-48ec-9ef9-14356ff1743b.__CR0,0,600,180_PT0_SX600_V1___.jpeg'
import NaturalHerbal2 from '../../Brand Images/Natural&Herbal/10660287_708424022584953_5134500964517843633_n.jpg'
import NaturalHerbal3 from '../../Brand Images/Natural&Herbal/earth-rhythm-500_f876b35c-f4b7-46e4-9334-5bc9eba4fb73.png'
import NaturalHerbal4 from '../../Brand Images/Natural&Herbal/IMG_8440_1.jpg'
import NaturalHerbal5 from '../../Brand Images/Natural&Herbal/logo_e3a67a8c-6a4d-48a0-909e-4c06938a9c8b.png'
import NaturalHerbal6 from '../../Brand Images/Natural&Herbal/logo-aboutus.png'

import Logo2 from '../../Images/Logo 2.png'
import Logo3 from '../../Images/Logo 3.png'

import { Button, Spin, Tooltip } from 'antd';
import Modal from 'antd/lib/modal/Modal';


import ReactGA from 'react-ga'

var isSensitive, pollution, isSunExposed, sleepHrs;

function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

class Quiz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            skinConcernsArr: [],
            hairConcernsArr: [],
            skinCustom: [],
            gifDiv: false,
            name: "",
            brandss: [],
            brandsss: [],
            toolss: [],
        }
    }

    componentDidMount() {
        this.props.getBrandsTypeUser()
        if (localStorage.getItem('retake')) {
            document.getElementById('waiting').style.display = "flex";
            setTimeout(() => {
                console.log(this.props.user)

                let gender = this.props.user.gender
                this.setState({
                    gender,
                })
                if (gender === 'male') {
                    // document.getElementById('user-image').src = maleGif;
                    document.getElementById('user-image-bg').style.backgroundImage = `url('${maleGif}')`;
                    document.getElementById('progress').style.marginLeft = "150px";
                    document.getElementById('concern-one').src = oneMale;
                    document.getElementById('concern-two').src = twoMale;
                    document.getElementById('concern-three').src = threeMale;
                    document.getElementById('concern-four').src = fourMale;
                    document.getElementById('concern-five').src = fiveMale;
                    document.getElementById('concern-six').src = sixMale;
                    document.getElementById('concern-seven').src = sevenMale;
                    document.getElementById('concern-eight').src = eightMale;
                    document.getElementById('concern-nine').src = nineMale;
                    document.getElementById('concern-ten').src = tenMale;
                    document.getElementById('concern-eleven').src = elevenMale;
                    document.getElementById('skin-type-oily').getElementsByTagName('img')[0].src = oilyMale;
                    document.getElementById('skin-type-dry').getElementsByTagName('img')[0].src = dryMale;
                    document.getElementById('skin-type-combination').getElementsByTagName('img')[0].src = combinationMale;
                    document.getElementById('skin-type-normal').getElementsByTagName('img')[0].src = normalMale;
                    document.getElementById('skin-type-sensitive').getElementsByTagName('img')[0].src = sensitiveMale;
                }
                else {
                    // document.getElementById('user-image').src = femaleGif;
                    document.getElementById('user-image-bg').style.backgroundImage = `url('${femaleGif}')`;
                    document.getElementById("progress").style.marginLeft = "150px";
                    document.getElementById('concern-one').src = one;
                    document.getElementById('concern-two').src = two;
                    document.getElementById('concern-three').src = three;
                    document.getElementById('concern-four').src = four;
                    document.getElementById('concern-five').src = five;
                    document.getElementById('concern-six').src = six;
                    document.getElementById('concern-seven').src = seven;
                    document.getElementById('concern-eight').src = eight;
                    document.getElementById('concern-nine').src = nine;
                    document.getElementById('concern-ten').src = ten;
                    document.getElementById('concern-eleven').src = eleven;
                    document.getElementById('skin-type-oily').getElementsByTagName('img')[0].src = oily;
                    document.getElementById('skin-type-dry').getElementsByTagName('img')[0].src = dry;
                    document.getElementById('skin-type-combination').getElementsByTagName('img')[0].src = combination;
                    document.getElementById('skin-type-normal').getElementsByTagName('img')[0].src = normal;
                    document.getElementById('skin-type-sensitive').getElementsByTagName('img')[0].src = sensitive;
                }

                // if (gender == 'male') {
                // document.getElementById('user-image').src = maleGif;
                // document.getElementById('progress').style.marginLeft = "150px";
                // this.setState({
                //     gender: "male"
                // })
                // }
                // else {
                // document.getElementById('user-image').src = femaleGif;
                // document.getElementById('user-image-bg').style.backgroundImage = `url('${femaleGif}')`;
                document.getElementById("progress").style.marginLeft = "150px";
                //     this.setState({
                //         gender: "female"
                //     })
                // }
                document.getElementById('waiting').remove();
                document.getElementById('retakingQuiz').style.display = "flex";
                document.getElementById('in-col-2Retake').style.display = "flex";
            }, 2000);
        } else {
            document.getElementById('waiting').style.display = "flex";
            setTimeout(function () {
                document.getElementById('waiting').remove();
                document.getElementById('intro').style.display = "flex";
                document.getElementById('waiting').style.display = "flex";

                setTimeout(function () {
                    document.getElementById('waiting').remove();
                    document.getElementById('q1').style.display = "flex";
                    document.getElementById('in-col-1').style.display = "flex";
                }, 2000);
            }, 2000);
        }
    }


    gettingValues = (ev, name) => {
        if (name === "name") {
            if (ev.target.value.length) {
                var numbers = /^[0-9]+$/;
                if (!ev.target.value[ev.target.value.length - 1].match(numbers)) {
                    this.setState({
                        [name]: ev.target.value
                    })
                }
            } else {
                var numbers = /^[0-9]+$/;
                if (!ev.target.value.match(numbers)) {
                    this.setState({
                        [name]: ev.target.value
                    })
                } else {
                    this.setState({
                        [name]: ev.target.value
                    })
                }
            }
        }
        else if (name === "age") {
            if (ev.target.value.toString().length < 3) {
                this.setState({
                    [name]: ev.target.value
                })
            }
        }
        else {
            this.setState({
                [name]: ev.target.value
            })
        }
    }

    skinConcerns = (name) => {
        let skinConcernsArr = this.state.skinConcernsArr
        if (skinConcernsArr.includes(name)) {
            skinConcernsArr.splice(skinConcernsArr.indexOf(name), 1)
        } else {
            skinConcernsArr.push(name)
        }
        this.setState({
            skinConcerns: skinConcernsArr.join(",")
        })
    }

    hairConcerns = (name) => {
        let hairConcernsArr = this.state.hairConcernsArr
        if (hairConcernsArr.includes(name)) {
            hairConcernsArr.splice(hairConcernsArr.indexOf(name), 1)
        } else {
            hairConcernsArr.push(name)
        }
        this.setState({
            hairConcerns: hairConcernsArr.join(",")
        })
    }

    submitQuiz = (ev) => {
        ReactGA.event({category: "Button Click", action: "Submitted Quiz"})
        ev.preventDefault()

        this.setState({
            gifDiv: true,
            gifBottom: `Analyzing ${this.props.is_authenticated ? this.props.user.firstname : this.state.username}'s glam profile`
        })

        setTimeout(() => {
            this.setState({
                gifBottom: `Matching products for ${this.props.is_authenticated ? this.props.user.firstname : this.state.username}'s glam profile`
            })
        }, 5000)
        setTimeout(() => {
            this.setState({
                gifBottom: `Sorting recommendations for ${this.props.is_authenticated ? this.props.user.firstname : this.state.username}`
            })
        }, 10000)
        let skinRoutines = ''
        if (this.state.skinRoutine) {
            skinRoutines = this.state.skinRoutine
        } else {
            skinRoutines = this.state.skinCustom.join(",")
        }
        this.setCookie("sleepHrs", this.state.sleep, 1)
        this.setCookie("name", this.props.user && this.props.user.firstname ? this.props.user.firstname : this.state.username, 1)
        this.setCookie("pollution", this.state.pollution, 1)
        this.setCookie("isSunExposed", this.state.isSunExposed, 1)
        this.setCookie("isSensitive", this.state.isSensitive, 1)
        this.setCookie('skinType', this.state.skinType, 1);
        this.setCookie("skinConcerns", this.state.skinConcerns && this.state.skinConcerns.toLowerCase(), 1)
        // this.setCookie("skinRoutines", skinRoutines, 1)
        this.setCookie('brand_type', this.state.brands, 1);
        this.setCookie('skin_tone', this.state.selectedSkinTone, 1);
        this.setCookie('morning_skin_feels', this.state.skinFeel, 1);
        this.setCookie('moisturized_skin_feels', this.state.AfterMoist, 1);
        this.setCookie('have_acne', this.state.haveAcne, 1);
        this.setCookie('water_options', this.state.drinkDaily, 1);
        this.setCookie('hair_volume', this.state.hairVolume, 1);
        this.setCookie('hair_types', this.state.hairType, 1);
        this.setCookie('hair_looks', this.state.naturalHair, 1);
        this.setCookie('hair_length', this.state.hairLength, 1);
        this.setCookie('hair_oily_time', this.state.getOily, 1);
        this.setCookie('hair_tools', this.state.tools, 1);
        this.setCookie('wears_makeup', this.state.wearMakeup, 1);
        this.setCookie('HairConcerns', this.state.hairConcerns && this.state.hairConcerns.toLowerCase(), 1);
        this.setCookie('gender', this.state.gender);
        if (localStorage.getItem("retake")) {
            this.setCookie("age", this.props.user.age, 1)
        } else {
            this.setCookie("age", this.state.age, 1)
        }
        if (!this.props.is_authenticated) {
            this.setCookie("quiz", true, 1)
        }

        setTimeout(() => {
            let retake = localStorage.getItem('retake')
            if (this.props.is_authenticated) {
                console.log(this.props.is_authenticated)
                const data = new FormData();
                const token = getCookie('authToken');

                let retake = localStorage.getItem('retake')
                data.append('age', getCookie('age'));
                data.append('pollution', getCookie('pollution'));
                data.append('isSunExposed', "");
                data.append('sleepHrs', getCookie('sleepHrs'));
                data.append('skinType', getCookie('skinType'));
                data.append('isSensitive', getCookie('isSensitive'));
                data.append('skinConcerns', getCookie('skinConcerns'));
                // data.append('skinRoutines', getCookie('skinRoutines'));
                data.append('brand_type', getCookie("brand_type"));
                data.append('skin_tone', getCookie("skin_tone"));
                data.append('morning_skin_feels', getCookie("morning_skin_feels"));
                data.append('moisturized_skin_feels', getCookie("moisturized_skin_feels"));
                data.append('have_acne', getCookie("have_acne"));
                data.append('water_options', getCookie("water_options"));
                data.append('hair_volume', getCookie("hair_volume"));
                data.append('hair_types', getCookie("hair_types"));
                data.append('hair_looks', getCookie("hair_looks"));
                data.append('hair_length', getCookie("hair_length"));
                data.append('hair_oily_time', getCookie("hair_oily_time"));
                data.append('hair_tools', getCookie("hair_tools"));
                data.append('wears_makeup', getCookie("wears_makeup"));
                data.append('hairConcerns', getCookie("HairConcerns"));
                data.append('gender', getCookie("gender"));
                Axios({
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_URL + '/api/quiz/update/',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `Bearer ${token}`,
                    },
                    data: data,
                    validateStatus: (status) => {
                        return true;
                    },
                })
                    .then(response => {
                        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                        this.setCookie('authToken', token, 2)
                        window.location.href = "/summary-page"
                    })
                    .catch(error => {
                        // console.log(error)
                    })
            } else {
                window.location.href = "/sign-up"
            }
            // }
        }, 15000)
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


    next1 = () => {
        let name = document.getElementById("in1").value;
        let scroll = document.getElementById('right');
        this.setState({
            username: name
        })
        if (name.length != 0) {

            document.getElementById('dnameM').style.display = "flex";
            document.getElementById('dname').style.display = "flex";
            document.getElementById("dname").innerHTML = name;
            document.getElementById('in-col-1').remove();
            document.getElementById('waiting').style.display = "flex";
            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

            setTimeout(function () {
                document.getElementById('waiting').remove();
                document.getElementById('quesname').style.display = "flex";
                document.getElementById("hdname").innerHTML = "Hello " + name + ", Welcome to GlaMate";
                document.getElementById('q2').style.display = "flex";
                document.getElementById('in-col-2').style.display = "flex";
                document.getElementById('progress-bar').style.width = "4.437%";
                document.getElementById('user-data').innerHTML = "Building Glam Profile for " + name;
                scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
            }, 1000);

        }
    }


    nextRetake(name) {
        let scroll = document.getElementById('right');
        document.getElementById('in-col-2Retake').remove();
        document.getElementById('dretakeM').style.display = "flex";
        document.getElementById('dretake').style.display = "flex";
        document.getElementById("dretake").innerHTML = name;
        document.getElementById('waiting').style.display = "flex";

        setTimeout(function () {
            document.getElementById('waiting').remove();
            document.getElementById('q4').style.display = "flex";
            document.getElementById('in-col-4').style.display = "flex";
            document.getElementById('progress-bar').style.width = "15%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);

    }

    next2(gender) {
        let scroll = document.getElementById('right');
        if (window.screen.width > 768) {

        }
        if (gender === 'male') {
            // document.getElementById('user-image').src = maleGif;
            document.getElementById('user-image-bg').style.backgroundImage = `url('${maleGif}')`;
            document.getElementById('progress').style.marginLeft = "150px";
            document.getElementById('concern-one').src = oneMale;
            document.getElementById('concern-two').src = twoMale;
            document.getElementById('concern-three').src = threeMale;
            document.getElementById('concern-four').src = fourMale;
            document.getElementById('concern-five').src = fiveMale;
            document.getElementById('concern-six').src = sixMale;
            document.getElementById('concern-seven').src = sevenMale;
            document.getElementById('concern-eight').src = eightMale;
            document.getElementById('concern-nine').src = nineMale;
            document.getElementById('concern-ten').src = tenMale;
            document.getElementById('concern-eleven').src = elevenMale;
            document.getElementById('skin-type-oily').getElementsByTagName('img')[0].src = oilyMale;
            document.getElementById('skin-type-dry').getElementsByTagName('img')[0].src = dryMale;
            document.getElementById('skin-type-combination').getElementsByTagName('img')[0].src = combinationMale;
            document.getElementById('skin-type-normal').getElementsByTagName('img')[0].src = normalMale;
            document.getElementById('skin-type-sensitive').getElementsByTagName('img')[0].src = sensitiveMale;

            this.setState({
                gender: "male"
            })
        }
        else {
            // document.getElementById('user-image').src = femaleGif;
            document.getElementById('user-image-bg').style.backgroundImage = `url('${femaleGif}')`;
            document.getElementById("progress").style.marginLeft = "150px";
            document.getElementById('concern-one').src = one;
            document.getElementById('concern-two').src = two;
            document.getElementById('concern-three').src = three;
            document.getElementById('concern-four').src = four;
            document.getElementById('concern-five').src = five;
            document.getElementById('concern-six').src = six;
            document.getElementById('concern-seven').src = seven;
            document.getElementById('concern-eight').src = eight;
            document.getElementById('concern-nine').src = nine;
            document.getElementById('concern-ten').src = ten;
            document.getElementById('concern-eleven').src = eleven;
            document.getElementById('skin-type-oily').getElementsByTagName('img')[0].src = oily;
            document.getElementById('skin-type-dry').getElementsByTagName('img')[0].src = dry;
            document.getElementById('skin-type-combination').getElementsByTagName('img')[0].src = combination;
            document.getElementById('skin-type-normal').getElementsByTagName('img')[0].src = normal;
            document.getElementById('skin-type-sensitive').getElementsByTagName('img')[0].src = sensitive;
            this.setState({
                gender: "female"
            })
        }


        document.getElementById('in-col-2').remove();
        document.getElementById('dgenderM').style.display = "flex";
        document.getElementById('dgender').style.display = "flex";
        document.getElementById("dgender").innerHTML = gender;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            document.getElementById('q3').style.display = "flex";
            document.getElementById('in-col-3').style.display = "flex";
            document.getElementById('progress-bar').style.width = "8.694%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

        }, 1000);
    }

    next3 = () => {
        if (this.state.age > 13) {
            if (Number(document.getElementById("in3").value) > 100) {
                alert("Age must be less than 100")
            } else {
                let scroll = document.getElementById('right');


                let age = document.getElementById("in3").value;

                if (age.length != 0) {
                    document.getElementById('in-col-3').remove();
                    document.getElementById('dageM').style.display = "flex";
                    document.getElementById('dage').style.display = "flex";
                    document.getElementById("dage").innerHTML = age;
                    document.getElementById('waiting').style.display = "flex";
                    scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

                    setTimeout(function () {
                        document.getElementById('waiting').remove();
                        document.querySelector('body').style.zoom = '99%'
                        document.getElementById('q4').style.display = "flex";
                        document.getElementById('in-col-4').style.display = "flex";
                        document.getElementById('progress-bar').style.width = "13.131%";
                        var elmnt = document.getElementById("q4");
                        elmnt.scrollIntoView();
                        // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

                    }, 1000);
                }
            }
        } else {
            this.setState({
                ageRestriction: true
            })
            // alert("We do not support for the ages of 13 or less")
        }
    }

    next4(type) {
        let scroll = document.getElementById('right');
        setTimeout(() => {

        }, 1000)
        setTimeout(() => {
            // alert("abc")

        }, 1100)

        this.setState({
            skinType: type
        })
        if (document.getElementById('in-col-4')) {
            document.getElementById('in-col-4').remove();
        }
        document.getElementById('dtypeM').style.display = "flex";
        document.getElementById('dtype').style.display = "flex";
        document.getElementById("dtype").innerHTML = type;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        let skinType = type;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('q5').style.display = "flex";
            document.getElementById('in-col-5').style.display = "flex";
            document.getElementById('btn-concern').style.display = "flex";
            document.getElementById('progress-bar').style.width = "22.005%";
            var elmnt = document.getElementById("q5");
            elmnt.scrollIntoView();
            // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);

    }

    next5 = () => {
        let scroll = document.getElementById('right');
        setTimeout(() => {
            // alert("abc")


        }, 1100)
        document.getElementById('in-col-5').remove();

        document.getElementById('dconcernM').style.display = "flex";
        document.getElementById('dconcern').style.display = "flex";
        document.getElementById('dconcern').innerHTML = this.state.skinConcerns.split(",").join(', ');

        // document.getElementById('btn-concern').remove();
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            // document.getElementById('waiting').remove();
            // scroll = document.getElementById('right');

            // document.getElementById('q6').style.display = "flex";
            // document.getElementById('in-col-6').style.display = "flex";
            // document.getElementById('btn-custom').style.display = "flex";
            // document.getElementById('progress-bar').style.width = "26.442%";

            // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

            document.getElementById('waiting').remove();
            document.getElementById('q8').style.display = "flex";
            document.getElementById('in-col-img').style.display = "flex";
            // document.getElementById('btn-concern3').style.display = "flex";
            document.getElementById('progress-bar').style.width = "30.879%";
            var elmnt = document.getElementById("q8");
            elmnt.scrollIntoView();
            // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    next6() {
        let scroll = document.getElementById('right');

        if (document.getElementById('btn-check1').classList.contains('selected')) {
            let interest = document.getElementById('btn-check1').value;
            document.getElementById('in-col-6').remove();
            document.getElementById('btn-custom').remove();
            document.getElementById('dinterestM').style.display = "flex";
            document.getElementById('dinterest').style.display = "flex";
            document.getElementById("dinterest").innerHTML = interest;
            document.getElementById('waiting').style.display = "flex";
            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
            setTimeout(function () {
                document.getElementById('waiting').remove();
                document.getElementById('q7').style.display = "flex";
                document.getElementById('in-col-7').style.display = "flex";
                document.getElementById('btn-concern3').style.display = "flex";
                document.getElementById('progress-bar').style.width = "30.879%";
                let scroll = document.getElementById('right');
                scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
            }, 1000);
        }
        else if (document.getElementById('btn-check2').classList.contains('selected')) {
            let interest = document.getElementById('btn-check2').value;
            document.getElementById('in-col-6').remove();
            document.getElementById('btn-custom').remove();
            document.getElementById('dinterestM').style.display = "flex";
            document.getElementById('dinterest').style.display = "flex";
            document.getElementById("dinterest").innerHTML = interest;
            document.getElementById('waiting').style.display = "flex";
            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
            setTimeout(function () {
                document.getElementById('waiting').remove();
                document.getElementById('q7').style.display = "flex";
                document.getElementById('in-col-7').style.display = "flex";
                document.getElementById('btn-concern3').style.display = "flex";
                document.getElementById('progress-bar').style.width = "30.879%";
                let scroll = document.getElementById('right');
                scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
            }, 1000);
        }
        else if (document.getElementById('btn-check3').classList.contains('selected')) {
            let interest = document.getElementById('btn-check3').value;
            document.getElementById('in-col-6').remove();
            document.getElementById('in-custom').remove();
            document.getElementById('btn-custom').remove();
            document.getElementById('dinterestM').style.display = "flex";
            document.getElementById('dinterest').style.display = "flex";
            document.getElementById('dinterest').innerHTML = interest;
            document.getElementById('waiting').style.display = "flex";
            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
            setTimeout(function () {
                document.getElementById('waiting').remove();
                document.getElementById('q7').style.display = "flex";
                document.getElementById('in-col-7').style.display = "flex";
                document.getElementById('btn-concern3').style.display = "flex";
                document.getElementById('progress-bar').style.width = "30.879%";
                let scroll = document.getElementById('right');
                scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
            }, 1000);
        }
    }

    next7 = (brand) => {
        let scroll = document.getElementById('right');

        this.setState({
            preference: brand === "No preference" ? brand : "Brand preference selected"
        })
        document.getElementById('in-col-7').remove();
        document.getElementById('dbrandM').style.display = "flex";
        document.getElementById('dbrand').style.display = "flex";
        console.log(this.state.brandsName)
        document.getElementById("dbrand").innerHTML = brand === "No preference" ? brand : this.state.brandsName.split(",").join(", ");
        document.getElementById('btn-concern3').remove();
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

        setTimeout(function () {
            let scroll = document.getElementById('right');

            document.getElementById('waiting').remove();
            document.getElementById('q8').style.display = "flex";
            document.getElementById('in-col-img').style.display = "flex";
            // document.getElementById('in-col-8').style.display = "flex";
            document.getElementById('progress-bar').style.width = "35.316%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    next7Push = (brand, id) => {
        let brandss = this.state.brandss
        if (brandss.includes(id)) {
            brandss.splice(brandss.indexOf(id), 1)
        }
        else {
            brandss.push(id)
        }

        let brandsss = this.state.brandsss
        if (brandsss.includes(brand)) {
            brandsss.splice(brandsss.indexOf(brand), 1)
        }
        else {
            brandsss.push(brand)
        }

        this.setState({
            brands: brandss.join(','),
            brandsName: brandsss.join(',')
        })
    }

    next7two = (name, ev, index) => {
        let buttons = document.getElementsByClassName("skinToneButton")
        let scroll = document.getElementById('right');
        this.setState({
            selectedSkinTone: name
        })
        for (let i = 0; i < buttons.length; i++) {
            if (i !== index) {
                buttons[i].disabled = true
                buttons[i].style.borderColor = "lightgray"
            } else {
                buttons[i].disabled = false
                buttons[i].style.borderColor = "#ff9676"
            }
        }
        document.getElementById('in-col-img').remove();
        document.getElementById('dSkinToneM').style.display = "flex";
        document.getElementById('dSkinTone').style.display = "flex";
        document.getElementById("dSkinTone").innerHTML = name;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            let scroll = document.getElementById('right');

            document.getElementById('waiting').remove();
            document.getElementById('q8two').style.display = "flex";
            document.getElementById('in-col-imgtwo').style.display = "flex";
            document.getElementById('in-col-8two').style.display = "flex";
            document.getElementById('progress-bar').style.width = "39.753%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

        }, 1000);
    }

    next8(skin) {
        let scroll = document.getElementById('right');
        this.setState({
            isSensitive: skin === "Yes" ? true : false
        })
        setTimeout(() => {
            // alert("abc")


        }, 1100)
        if (skin === 'Yes')
            isSensitive = true;
        else if (skin === 'No')
            isSensitive = false;

        document.getElementById('in-col-8two').remove();
        document.getElementById('in-col-imgtwo').remove();

        document.getElementById('dskinM').style.display = "flex";
        document.getElementById('dskin').style.display = "flex";
        document.getElementById("dskin").innerHTML = skin;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('q9').style.display = "flex";
            document.getElementById('in-col-9').style.display = "flex";
            document.getElementById('progress-bar').style.width = "72%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }


    next8two(skin) {
        let scroll = document.getElementById('right');
        this.setState({
            isSensitive: skin === "Yes" ? true : false
        })
        document.getElementById('in-col-imgtwo').remove();
        document.getElementById('in-col-8two').remove();
        document.getElementById('dskinM').style.display = "flex";
        document.getElementById('dskin').style.display = "flex";
        document.getElementById('dskin').innerHTML = skin;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qHaveAcne').style.display = "flex";
            document.getElementById('in-col-7four').style.display = "flex";
            document.getElementById('progress-bar').style.width = "44.190%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }


    next9(poll) {
        this.setState({
            pollution: poll
        })
        let scroll = document.getElementById('right');
        setTimeout(() => {
            // alert("abc")


        }, 1100)
        pollution = poll;

        document.getElementById('in-col-9').remove();

        document.getElementById('dpolM').style.display = "flex";
        document.getElementById('dpol').style.display = "flex";
        document.getElementById("dpol").innerHTML = poll;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('q10').style.display = "flex";
            document.getElementById('in-col-10').style.display = "flex";
            document.getElementById('progress-bar').style.width = "81%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);

    }

    next10(pol) {
        let scroll = document.getElementById('right');

        this.setState({
            pollution: pol
        })
        document.getElementById('in-col-9').remove();

        document.getElementById('dpolM').style.display = "flex";
        document.getElementById('dpol').style.display = "flex";
        document.getElementById("dpol").innerHTML = pol;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            let scroll = document.getElementById('right');

            document.getElementById('q11').style.display = "flex";
            document.getElementById('in-col-11').style.display = "flex";
            document.getElementById('progress-bar').style.width = "66.375%";
            var elmnt = document.getElementById("q11");
            elmnt.scrollIntoView();
            // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);

    }

    next11 = () => {
        let scroll = document.getElementById('right');

        document.getElementById('in-col-7eleven').remove();

        document.getElementById('dtoolsUsedM').style.display = "flex";
        document.getElementById('dtoolsUsed').style.display = "flex";
        document.getElementById("dtoolsUsed").innerHTML = this.state.tools.split(",").join(", ");
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            document.getElementById('submit').style.display = "inline";
            document.getElementById('restart').style.display = "inline";
            document.getElementById("submit").disabled = false;
            document.getElementById("restart").disabled = false;
            document.getElementById('progress-bar').style.width = "100%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

        }, 1000);
    }

    next11push = (brand) => {
        let toolss = this.state.toolss
        if (toolss.includes(brand)) {
            toolss.splice(toolss.indexOf(brand), 1)
        }
        else {
            toolss.push(brand)
        }
        this.setState({
            tools: toolss.join(',')
        })
    }

    selectall(a) {
        let arr = a.split(',')
        // console.log(arr)
        this.setState({
            skinCustom: arr
        })
        if (document.getElementById('button-1').classList.contains('selected')) {

        }
        else {
            document.getElementById('button-1').classList.toggle('selected');
        }


        if (document.getElementById('button-2').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-2').classList.toggle('selected');
        }


        if (document.getElementById('button-3').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-3').classList.toggle('selected');
        }


        if (document.getElementById('button-4').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-4').classList.toggle('selected');
        }


        if (document.getElementById('button-5').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-5').classList.toggle('selected');
        }


        if (document.getElementById('button-6').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-6').classList.toggle('selected');
        }


        if (document.getElementById('button-7').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-7').classList.toggle('selected');
        }


        if (document.getElementById('button-8').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-8').classList.toggle('selected');
        }


        if (document.getElementById('button-9').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-9').classList.toggle('selected');
        }


        if (document.getElementById('button-10').classList.contains('selected')) {


        }
        else {
            document.getElementById('button-10').classList.toggle('selected');
        }

    }

    skinRoutine = (a, i) => {
        if (a) {
            this.setState({
                skinRoutine: a.toLowerCase()
            }, () => {
                // console.log(this.state.skinRoutine)
            })
        } else {
            this.setState({
                skinRoutine: ''
            })
        }
    }

    selectAll = (a) => {

    }

    custom = (a, i) => {
        let skinCustom = this.state.skinCustom
        if (skinCustom.includes(a.toLowerCase())) {
            skinCustom.splice(i, 1)
            this.setState({
                skinCustom
            })
        } else {
            skinCustom.push(a.toLowerCase())
            this.setState({
                skinCustom
            })
        }
        // console.log(this.state.skinCustom)
    }

    setSkinTone = (name, ev, index) => {
        let buttons = document.getElementsByClassName("skinToneButton")
        this.setState({
            selectedSkinTone: name
        })
        for (let i = 0; i < buttons.length; i++) {
            if (i !== index) {
                buttons[i].disabled = true
                buttons[i].style.borderColor = "lightgray"
            } else {
                buttons[i].disabled = false
                buttons[i].style.borderColor = "#ff9676"
            }
        }
    }

    nextSkinFeel = (skinFeel) => {
        // let scroll = document.getElementById('right');
        // this.setState({
        //     skinFeel,
        // })
        // document.getElementById('in-col-7two').remove();
        // document.getElementById('dSkinFeelM').style.display = "flex";
        // document.getElementById('dSkinFeel').style.display = "flex";
        // document.getElementById('dSkinFeel').innerHTML = skinFeel;
        // document.getElementById('waiting').style.display = "flex";
        // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        // setTimeout(function () {
        //     document.getElementById('waiting').remove();
        //     scroll = document.getElementById('right');

        //     document.getElementById('qAfterMoist').style.display = "flex";
        //     document.getElementById('in-col-7three').style.display = "flex";
        //     document.getElementById('progress-bar').style.width = "48.627%";

        //     scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        // }, 1000);

        let scroll = document.getElementById('right');
        this.setState({
            skinFeel,
        })
        document.getElementById('in-col-7two').remove();
        document.getElementById('dSkinFeelM').style.display = "flex";
        document.getElementById('dSkinFeel').style.display = "flex";
        document.getElementById('dSkinFeel').innerHTML = skinFeel;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qHaveAcne').style.display = "flex";
            document.getElementById('in-col-7four').style.display = "flex";
            document.getElementById('progress-bar').style.width = "53.064%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextAfterMoist = (AfterMoist) => {
        let scroll = document.getElementById('right');
        this.setState({
            AfterMoist,
        })
        document.getElementById('in-col-7three').remove();
        document.getElementById('dAfterMoistM').style.display = "flex";
        document.getElementById('dAfterMoist').style.display = "flex";
        document.getElementById('dAfterMoist').innerHTML = AfterMoist;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qHaveAcne').style.display = "flex";
            document.getElementById('in-col-7four').style.display = "flex";
            document.getElementById('progress-bar').style.width = "53.064%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextHaveAcne = (haveAcne) => {
        let scroll = document.getElementById('right');
        this.setState({
            haveAcne,
        })
        document.getElementById('in-col-7four').remove();
        document.getElementById('dHaveAcneM').style.display = "flex";
        document.getElementById('dHaveAcne').style.display = "flex";
        document.getElementById('dHaveAcne').innerHTML = haveAcne;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qWearMakeup').style.display = "flex";
            document.getElementById('in-col-7five').style.display = "flex";
            document.getElementById('progress-bar').style.width = "57.501%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextWearMakeup = (wearMakeup) => {
        let scroll = document.getElementById('right');
        this.setState({
            wearMakeup,
        })
        document.getElementById('in-col-7five').remove();
        document.getElementById('dWearMakeupM').style.display = "flex";
        document.getElementById('dWearMakeup').style.display = "flex";
        document.getElementById('dWearMakeup').innerHTML = wearMakeup;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('q11').style.display = "flex";
            document.getElementById('in-col-11').style.display = "flex";
            document.getElementById('progress-bar').style.width = "61.938%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextSleep = (sleep) => {
        let scroll = document.getElementById('right');
        this.setState({
            sleep,
        })
        document.getElementById('in-col-11').remove();
        document.getElementById('dsleepM').style.display = "flex";
        document.getElementById('dsleep').style.display = "flex";
        document.getElementById('dsleep').innerHTML = sleep;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qDrinkDaily').style.display = "flex";
            document.getElementById('in-col-11six').style.display = "flex";
            document.getElementById('progress-bar').style.width = "70.812%";
            var elmnt = document.getElementById("qDrinkDaily");
            elmnt.scrollIntoView();
            // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextDrinkDaily = (drinkDaily) => {
        let scroll = document.getElementById('right');
        this.setState({
            drinkDaily,
        })
        document.getElementById('in-col-11six').remove();
        document.getElementById('dDrinkDailyM').style.display = "flex";
        document.getElementById('dDrinkDaily').style.display = "flex";
        document.getElementById('dDrinkDaily').innerHTML = drinkDaily;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qHairVolume').style.display = "flex";
            document.getElementById('in-col-7six').style.display = "flex";
            document.getElementById('progress-bar').style.width = "75.249%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }


    nextHairVolume = (hairVolume) => {
        let scroll = document.getElementById('right');
        this.setState({
            hairVolume,
        })
        document.getElementById('in-col-7six').remove();
        document.getElementById('dhairVolumeM').style.display = "flex";
        document.getElementById('dhairVolume').style.display = "flex";
        document.getElementById('dhairVolume').innerHTML = hairVolume;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qHairType').style.display = "flex";
            document.getElementById('in-col-7seven').style.display = "flex";
            document.getElementById('progress-bar').style.width = "79.686%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextHairType = (hairType) => {
        let scroll = document.getElementById('right');
        this.setState({
            hairType,
        })
        document.getElementById('in-col-7seven').remove();
        document.getElementById('dhairTypeM').style.display = "flex";
        document.getElementById('dhairType').style.display = "flex";
        document.getElementById('dhairType').innerHTML = hairType;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qNaturalHair').style.display = "flex";
            document.getElementById('in-col-7eight').style.display = "flex";
            document.getElementById('progress-bar').style.width = "84.123%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextNaturalHair = (naturalHair) => {
        let scroll = document.getElementById('right');
        this.setState({
            naturalHair,
        })
        document.getElementById('in-col-7eight').remove();
        document.getElementById('dnaturalHairM').style.display = "flex";
        document.getElementById('dnaturalHair').style.display = "flex";
        document.getElementById('dnaturalHair').innerHTML = naturalHair;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qHairLength').style.display = "flex";
            document.getElementById('in-col-7nine').style.display = "flex";
            document.getElementById('progress-bar').style.width = "88.560%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextHairLength = (hairLength) => {
        let scroll = document.getElementById('right');
        this.setState({
            hairLength,
        })
        document.getElementById('in-col-7nine').remove();
        document.getElementById('dhairLengthM').style.display = "flex";
        document.getElementById('dhairLength').style.display = "flex";
        document.getElementById('dhairLength').innerHTML = hairLength;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            scroll = document.getElementById('right');

            document.getElementById('qHairConcern').style.display = "flex";
            document.getElementById('btn-concern2').style.display = "flex";
            document.getElementById('in-col-5two').style.display = "flex";
            document.getElementById('progress-bar').style.width = "90.997%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }

    nextHairConcerns = () => {
        let scroll = document.getElementById('right');

        document.getElementById('in-col-5two').remove();
        document.getElementById('dhairConcernM').style.display = "flex";
        document.getElementById('dhairConcern').style.display = "flex";
        document.getElementById('dhairConcern').innerHTML = this.state.hairConcerns.split(",").join(", ");
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            // document.getElementById('waiting').remove();
            // scroll = document.getElementById('right');

            // document.getElementById('qGetOily').style.display = "flex";
            // document.getElementById('in-col-7ten').style.display = "flex";
            // document.getElementById('progress-bar').style.width = "93.434%";

            // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

            document.getElementById('waiting').remove();
            document.getElementById('submit').style.display = "inline";
            document.getElementById('restart').style.display = "inline";
            document.getElementById("submit").disabled = false;
            document.getElementById("restart").disabled = false;
            document.getElementById('progress-bar').style.width = "100%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        }, 1000);
    }


    nextGetOily = (getOily) => {
        // let scroll = document.getElementById('right');
        // this.setState({
        //     getOily,
        // })
        // document.getElementById('in-col-7ten').remove();
        // document.getElementById('dgetOilyM').style.display = "flex";
        // document.getElementById('dgetOily').style.display = "flex";
        // document.getElementById('dgetOily').innerHTML = getOily;
        // document.getElementById('waiting').style.display = "flex";
        // scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        // setTimeout(function () {
        //     document.getElementById('waiting').remove();
        //     scroll = document.getElementById('right');
        //     document.getElementById('btn-Tools').style.display = "flex";
        //     document.getElementById('qToolsUsed').style.display = "flex";
        //     document.getElementById('in-col-7eleven').style.display = "flex";
        //     document.getElementById('progress-bar').style.width = "96%";

        //     scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        // }, 1000);

        let scroll = document.getElementById('right');
        this.setState({
            getOily,
        })
        document.getElementById('in-col-7ten').remove();

        document.getElementById('dgetOilyM').style.display = "flex";
        document.getElementById('dgetOily').style.display = "flex";
        document.getElementById("dgetOily").innerHTML = getOily;
        document.getElementById('waiting').style.display = "flex";
        scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;
        setTimeout(function () {
            document.getElementById('waiting').remove();
            document.getElementById('submit').style.display = "inline";
            document.getElementById('restart').style.display = "inline";
            document.getElementById("submit").disabled = false;
            document.getElementById("restart").disabled = false;
            document.getElementById('progress-bar').style.width = "100%";

            scroll.scrollTop = scroll.scrollHeight - scroll.clientHeight;

        }, 1000);
    }

    nameKeyUp = (ev) => {
        if (ev.keyCode === 13) {
            if (isEmptyOrSpaces(this.state.name)) {

            } else {
                this.next1()
            }
        }
    }

    ageKeyUp = (ev) => {
        if (ev.keyCode === 13) {
            if (Number(this.state.age) > 0) {
                this.next3()
            } else {

            }
        }
    }

    render() {
        return (
            <div className="quizzMain">

                <div className="split left" id="left">
                    <a className="quizBackMain" href="/home">
                        <img className="quizBack" src={Logo2} />
                        <img className="quizBack2" src={Logo3} />
                    </a>
                    <div className="centered" id="user-image-bg">
                        {/* <img id="user-image" src="" /> */}
                        <p className="h6" id="user-data">Building Glam Profile for ....</p>
                        <div className="progress" id="progress">
                            <div className="progress-bar" id="progress-bar" role="progressbar" style={{ width: '1%' }}></div>
                        </div>
                    </div>
                </div>
                {this.state.gifDiv &&
                    <div className="gifDiv">
                        <img src={gif} />
                        <p>{this.state.gifBottom} <Spin /></p>
                    </div>
                }
                {/* <form> */}
                <div className="split right" id="right">
                    <center>
                        <p className="h1" style={{ fontSize: '48px', color: 'black', fontFamily: 'jenna', fontWeight: 'bolder' }}>Build your Glam profile !</p>
                    </center>
                    <div className="right-quiz">
                        <div className="container" style={{ padding: '20px', paddingLeft: '20px', textAlign: 'center' }}>
                            {localStorage.getItem('retake') ?
                                <>
                                    <div className="row justify-content-start">
                                        <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques">.........</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-start">
                                        <div className="col" id="retakingQuiz" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques">Retaking the quiz will update your existing profile and products recommendations. Does this sound good to you?</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-14" id="in-col-2Retake" style={{ display: 'inline-flex', display: 'none' }}>
                                            <button type="button" id="btn-2" onClick={() => this.nextRetake('Yes')}>Yes</button>
                                            {/* <button type="button" id="btn-2" onClick={() => this.nextRetake('No')}>No</button> */}
                                            <a href='/home'><button type="button" id="btn-2" style={{ color: "black" }} onClick={() => this.nextRetake('No')}>No</button></a>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-14" id="dretakeM" style={{ display: 'inline-flex', display: 'none' }}>
                                            <p className="bubble-text-ans" id="dretake"></p>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="row justify-content-start">
                                        <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques">.........</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-start">
                                        <div className="col" id="intro" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques" style={{ textAlign: 'left' }}>Hello, this is your glam assistant..... Welcome to the future!<br />Let me help you find the right beauty products in minutes.</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-start">
                                        <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques">.........</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-start">
                                        <div className="col" id="q1" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques"> First things first, whose profile are we talking about?</p>
                                        </div>
                                    </div>


                                    <div className="row justify-content-end">
                                        <div className="col-14" id="in-col-1" style={{ display: 'none' }}>
                                            <input onKeyUp={(ev) => this.nameKeyUp(ev)} className="name" id="in1" type="text" name="" placeholder="Enter your name" onChange={(ev) => this.gettingValues(ev, "name")} autocomplete="off" value={this.state.name} />
                                            <button type="button" id="btn-1" onClick={isEmptyOrSpaces(this.state.name) ? null : this.next1}><i className="fas fa-check-circle"></i></button>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-14" id="dnameM" style={{ display: 'inline-flex', display: 'none' }}>
                                            <p className="bubble-text-ans" id="dname"></p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-start">
                                        <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques">.........</p>
                                        </div>
                                    </div>


                                    <div className="row justify-content-start">
                                        <div className="col" id="quesname" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques" id="hdname"></p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-start">
                                        <div className="col" id="q2" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques"> Gender?</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-14" id="in-col-2">
                                            <button type="button" id="btn-2" onClick={() => this.next2('male')}>Male</button>
                                            <button type="button" id="btn-2" onClick={() => this.next2('female')}>Female</button>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-14" id="dgenderM" style={{ display: 'inline-flex', display: 'none' }}>
                                            <p className="bubble-text-ans" id="dgender"></p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-start">
                                        <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques">.........</p>
                                        </div>
                                    </div>




                                    <div className="row justify-content-start">
                                        <div className="col" id="q3" style={{ display: 'inline-flex', display: 'none' }}>
                                            <img src={robo} className="robo" />
                                            <p className="bubble-text-ques">How old are you?</p>
                                        </div>
                                    </div>

                                    <div className="row justify-content-end">
                                        <div className="col-14" id="in-col-3">
                                            <input value={this.state.age} onKeyUp={(ev) => this.ageKeyUp(ev)} onChange={(ev) => this.gettingValues(ev, "age")} className="name" id="in3" type="number" name="" placeholder="Enter your age" autocomplete="off" />
                                            <button type="button" id="btn-3" onClick={Number(this.state.age) > 0 ? this.next3 : null}><i className="fas fa-check-circle"></i></button>
                                        </div>
                                    </div>


                                    <div className="row justify-content-end">
                                        <div className="col-14" id="dageM" style={{ display: 'inline-flex', display: 'none' }}>
                                            <p className="bubble-text-ans" id="dage"></p>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>


                            <div className="row justify-content-start">
                                <div className="col" id="q4" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques"> What's your skin type?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-4">
                                    <div className="row">
                                        <div className="tooltipMain first" id="skin-type-normal">
                                            <button onClick={() => this.next4('normal')} type="button" id="btn-4" >
                                                <img id="skin-image" src="" />
                                            </button>
                                            {/* <Tooltip trigger="click" visible={this.state.visible1} onVisibleChange={(visible) => this.setState({ visible1: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain first" id="skin-type-oily">
                                            <button onClick={() => this.next4('oily')} type="button" id="btn-4" >
                                                <img id="skin-image" src="" />
                                            </button>
                                            {/* <Tooltip trigger="click" visible={this.state.visible2} onVisibleChange={(visible) => this.setState({ visible2: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain first" id="skin-type-dry">
                                            <button onClick={() => this.next4('dry')} type="button" id="btn-4" >
                                                <img id="skin-image" src="" />
                                            </button>
                                            {/* <Tooltip trigger="click" visible={this.state.visible3} onVisibleChange={(visible) => this.setState({ visible3: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain first" id="skin-type-combination">
                                            <button onClick={() => this.next4('combination')} type="button" id="btn-4" >
                                                <img id="skin-image" src="" />
                                            </button>
                                            {/* <Tooltip trigger="click" visible={this.state.visible4} onVisibleChange={(visible) => this.setState({ visible4: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dtypeM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dtype"></p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="q5" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques"> Your skin Concern(s)? <br /> select maximum 3 </p>
                                </div>
                            </div>

                            <div className="row justify-content-center" id="in-col-5" style={{ display: 'none' }}>


                                <div className="skinConcernimages">

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("acne")} id="check1" type="button" className="btn btn-primary"
                                            value="acne">
                                            <img id="concern-one" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible5} onVisibleChange={(visible) => this.setState({ visible5: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Acne</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Wrinkles")} id="check2" name="overwrite" type="button" className="btn btn-primary"
                                            value="Wrinkles">
                                            <img id="concern-two" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible6} onVisibleChange={(visible) => this.setState({ visible6: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Wrinkles/Age Spots</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Dark Circles")} id="check3" name="overwrite" type="button" className="btn btn-primary"
                                            value="Dark Circles">
                                            <img id="concern-three" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible7} onVisibleChange={(visible) => this.setState({ visible7: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Dark Circles</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Pores")} id="check4" name="overwrite" type="button" className="btn btn-primary"
                                            value="Pores">
                                            <img id="concern-four" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible8} onVisibleChange={(visible) => this.setState({ visible8: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Pores</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Oiliness")} id="check5" name="overwrite" type="button" className="btn btn-primary"
                                            value="Oiliness">
                                            <img id="concern-five" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible9} onVisibleChange={(visible) => this.setState({ visible9: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Oiliness</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Hyperpigmentation")} id="check6" name="overwrite" type="button" className="btn btn-primary"
                                            value="Hyperpigmentation">
                                            <img id="concern-six" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible10} onVisibleChange={(visible) => this.setState({ visible10: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Hyperpigmentation</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Dryness")} id="check7" name="overwrite" type="button" className="btn btn-primary"
                                            value="Dryness">
                                            <img id="concern-seven" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible11} onVisibleChange={(visible) => this.setState({ visible11: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Dryness</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Redness")} id="check8" name="overwrite" type="button" className="btn btn-primary"
                                            value="Redness">
                                            <img id="concern-eight" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible12} onVisibleChange={(visible) => this.setState({ visible12: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Redness</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Sun Damage")} id="check9" name="overwrite" type="button" className="btn btn-primary"
                                            value="Sun Damage">
                                            <img id="concern-nine" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible13} onVisibleChange={(visible) => this.setState({ visible13: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Tanninc/Sun Damaged</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Dark spots")} id="check10" name="overwrite" type="button" className="btn btn-primary"
                                            value="Dark spots">
                                            <img id="concern-ten" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible14} onVisibleChange={(visible) => this.setState({ visible14: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Dark spots</button>
                                        {/* </Tooltip> */}
                                    </div>

                                    <div className="tooltipMain">
                                        <button onClick={() => this.skinConcerns("Uneven skin")} id="check11" name="overwrite" type="button" className="btn btn-primary"
                                            value="Uneven skin">
                                            <img id="concern-eleven" src="" />
                                        </button>
                                        {/* <Tooltip visible={this.state.visible15} onVisibleChange={(visible) => this.setState({ visible15: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."> */}
                                        <button className="button">Uneven skintone</button>
                                        {/* </Tooltip> */}
                                    </div>


                                </div>
                                <input disabled={!this.state.skinConcerns} type="button" className="btn-concern" id="btn-concern" value="NEXT" onClick={this.next5} />
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dconcernM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dconcern"></p>
                                </div>
                            </div>

                            {/* <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div> */}

                            <div className="row justify-content-start">
                                <div className="col" id="q6" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">What products are you interested in exploring today? </p>
                                </div>
                            </div>

                            <div className="row justify-content-end" id="in-col-6" style={{ display: 'none' }}>

                                <div className="col"><button id="btn-check1" onClick={() => this.skinRoutine('cleanser, moisturizer, sunscreen', 1)} type="button" className="btn btn-primary"
                                    value="3-stem (cleanser, moisturizer, sunscreen)">3-stem<br />(cleanser, moisturizer, sunscreen) </button>
                                </div>

                                <div className="col"><button id="btn-check2" onClick={() => this.skinRoutine('cleanser, toner, serum, moisturizer, SPF', 2)} type="button" className="btn btn-primary"
                                    value="5-stem (cleanser, toner, serum, moisturizer, SPF)">5-stem<br />(cleanser, toner, serum, moisturizer, SPF)</button></div>

                                <div className="col"><button onClick={() => this.skinRoutine()} type="button" className="btn btn-primary" id="btn-check3"
                                    value="custom">Create custom routine()</button></div>
                            </div>
                            <br />


                            <div className="row justify-content-center" id="in-custom">
                                <button onClick={() => this.custom('Cleansers', 0)} id="button-1" type="button" className="mbtn" value="Cleansers">Cleansers</button>
                                <button onClick={() => this.custom('Mask & peal', 1)} id="button-2" type="button" className="mbtn" value="Mask & peal">Mask & peal</button>
                                <button onClick={() => this.custom('Exfoliators', 2)} id="button-3" type="button" className="mbtn" value="Exfoliators">Exfoliators</button>
                                <button onClick={() => this.custom('Eye Treatment', 3)} id="button-4" type="button" className="mbtn" value="Eye Treatment">Eye Treatment</button>
                                <button onClick={() => this.custom('Face Oils', 4)} id="button-5" type="button" className="mbtn" value="Face Oils">Face Oils</button>
                                <button onClick={() => this.custom('Serum', 5)} id="button-6" type="button" className="mbtn" value="Serum">Serum</button>
                                <button onClick={() => this.custom('Lip Treatment', 6)} id="button-7" type="button" className="mbtn" value="Lip Treatment">Lip Treatment</button>
                                <button onClick={() => this.custom('Moisturizer', 7)} id="button-8" type="button" className="mbtn" value="Moisturizer">Moisturizer</button>
                                <button onClick={() => this.custom('Toner', 8)} id="button-9" type="button" className="mbtn" value="Toner">Toner</button>
                                <button onClick={() => this.custom('Spot Treatment', 9)} id="button-10" type="button" className="mbtn" value="Spot Treatment">Spot Treatment</button>
                                <input
                                    type="button" className="small" id="select-all" onClick={() => this.selectall('cleansers,mask & peal,exfoliators,eye treatment,face oils,serum,lip treatment,moisturizer,toner,spot treatment')} value="select all" />
                            </div>

                            <input type="button" className="btn-custom" id="btn-custom" value="NEXT" onClick={this.next6}
                                style={{ display: 'none' }} />



                            <div className="row justify-content-end">
                                <div className="col-14" id="dinterestM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dinterest"></p>
                                </div>
                            </div>

                            {/* <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div> */}

                            <div className="row justify-content-start">
                                <div className="col" id="q7" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques"> What are your brand preferences?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7">
                                    <div className="row brandsSelectDiv">
                                        {this.props.brandsTypeUser && this.props.brandsTypeUser.map((a, i) => (
                                            <button type="button" a={i} className={this.state.brandss.includes(a.id) ? "selectedd" : ""} id="btn-7" onClick={() => this.next7Push(a.name, a.id)}>
                                                <h5>{a.name}</h5>
                                                <div className="brandImages">
                                                    {a.images && a.images.map((b, i2) => (
                                                        <img key={b} src={b.image} />
                                                    ))}
                                                </div>
                                            </button>
                                        ))}
                                        <button type="button" className="noPrefer" id="btn-7" onClick={() => this.next7('No preference')}>No preference</button>
                                    </div>
                                </div>
                                <input disabled={!this.state.brands} type="button" className="btn-concern" id="btn-concern3" value="NEXT" onClick={this.next7} />
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dbrandM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dbrand"></p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="q8" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques"> What is your skin tone?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14 skinTone" id="in-col-img">
                                    <div class="row justify-content-center">

                                        <div className="tooltipMain">
                                            <button className="skinToneButton" onClick={(ev) => this.next7two("Toffee", ev, 0)} id="skinTonebut0">
                                                <img src={skin1} />
                                            </button>
                                            {/* <Tooltip visible={this.state.visible16} onVisibleChange={(visible) => this.setState({ visible16: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain">
                                            <button className="skinToneButton" onClick={(ev) => this.next7two("Dark Brown", ev, 1)} id="skinTonebut1">
                                                <img src={skin2} />
                                            </button>
                                            {/* <Tooltip visible={this.state.visible17} onVisibleChange={(visible) => this.setState({ visible17: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain">
                                            <button className="skinToneButton" onClick={(ev) => this.next7two("Medium", ev, 2)} id="skinTonebut2">
                                                <img src={skin3} />
                                            </button>
                                            {/* <Tooltip visible={this.state.visible18} onVisibleChange={(visible) => this.setState({ visible18: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain">
                                            <button className="skinToneButton" onClick={(ev) => this.next7two("Exotic", ev, 3)} id="skinTonebut3">
                                                <img src={skin4} />
                                            </button>
                                            {/* <Tooltip visible={this.state.visible19} onVisibleChange={(visible) => this.setState({ visible19: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain">
                                            <button className="skinToneButton" onClick={(ev) => this.next7two("Wheatish", ev, 4)} id="skinTonebut4">
                                                <img src={skin5} />
                                            </button>
                                            {/* <Tooltip visible={this.state.visible20} onVisibleChange={(visible) => this.setState({ visible20: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain">
                                            <button className="skinToneButton" onClick={(ev) => this.next7two("Light Neutral", ev, 5)} id="skinTonebut5">
                                                <img src={skin6} />
                                            </button>
                                            {/* <Tooltip visible={this.state.visible22} onVisibleChange={(visible) => this.setState({ visible22: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        <div className="tooltipMain">
                                            <button className="skinToneButton" onClick={(ev) => this.next7two("Fair", ev, 6)} id="skinTonebut6">
                                                <img src={skin7} />
                                            </button>
                                            {/* <Tooltip visible={this.state.visible23} onVisibleChange={(visible) => this.setState({ visible23: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.">
                                                <button className="button">Tip</button>
                                            </Tooltip> */}
                                        </div>

                                        {/* <div className="btn-skin-tone-done">
                                            <input type="button" className="btn-skin-tone" id="btn-skin-tone" value="NEXT" onClick={() => this.next7two()} />
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dSkinToneM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dSkinTone"></p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="q8two" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">Do you have a sensitive skin?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-imgtwo">
                                    <div className="tooltipMain" id="skin-type-sensitive">
                                        <div style={{ height: 250 }} className="row">
                                            <img id="skin-image" src="" />
                                        </div>
                                        {/* <Tooltip visible={this.state.visible24} onVisibleChange={(visible) => this.setState({ visible24: visible })} trigger="click" placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.">
                                            <button className="button">Tip</button>
                                        </Tooltip> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-8two">
                                    <div className="row">
                                        <button type="button" id="btn-8" onClick={() => this.next8two('Yes')}>Yes</button>
                                        <button type="button" id="btn-8" onClick={() => this.next8two('No')}>No</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dskinM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dskin"></p>
                                </div>
                            </div>

                            {/* <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div> */}

                            <div className="row justify-content-start">
                                <div className="col" id="q7two" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques"> When you wake up in the morning how does your skin feel?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7two">
                                    <div className="row justify-content-end">
                                        <button type="button" id="btn-7" onClick={() => this.nextSkinFeel('Dry')}>Dry</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextSkinFeel('Comfortable')}>Comfortable</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextSkinFeel('Oily in T zone')}>Oily in T zone</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextSkinFeel('Oily')}>Oily</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextSkinFeel('Not sure')}>Not sure</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dSkinFeelM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dSkinFeel"></p>
                                </div>
                            </div>

                            {/* <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qAfterMoist" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">How does your skin feel after several hours of applying moisturizer?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7three">
                                    <div className="row justify-content-end">
                                        <button type="button" id="btn-7" onClick={() => this.nextAfterMoist('Dry')}>Dry</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextAfterMoist('Smooth')}>Smooth</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextAfterMoist('Slightly shiny')}>Slightly shiny</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextAfterMoist('I don’t us moisturizer')}>I don’t us moisturizer</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dAfterMoistM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dAfterMoist"></p>
                                </div>
                            </div> */}

                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qHaveAcne" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">I have acne.</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7four">
                                    <div className="row">
                                        <button type="button" id="btn-7" onClick={() => this.nextHaveAcne('Never')}>Never</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextHaveAcne('Sometimes')}>Sometimes</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextHaveAcne('Often')}>Often</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dHaveAcneM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dHaveAcne"></p>
                                </div>
                            </div>


                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qWearMakeup" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">Do you wear makeup?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7five">
                                    <div className="row">
                                        <button type="button" id="btn-7" onClick={() => this.nextWearMakeup('Yes')}>Yes</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextWearMakeup('No')}>No</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dWearMakeupM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dWearMakeup"></p>
                                </div>
                            </div>


                            {/* <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div> */}

                            <div className="row justify-content-start">
                                <div className="col" id="q9" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">Pollution level in the place you live?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-9">
                                    <div className="row pollutionDiv">

                                        <button type="button" id="btn-9" onClick={() => this.next10('High')}>
                                            <img id="level-image" src="./Images/high.png" />
                                        </button>

                                        <button type="button" id="btn-9" onClick={() => this.next10('Moderate')}>
                                            <img id="level-image" src="./Images/moderate.png" />
                                        </button>

                                        <button type="button" id="btn-9" onClick={() => this.next10('Low')}>
                                            <img id="level-image" src="./Images/low.png" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dpolM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dpol"></p>
                                </div>
                            </div>

                            {/* <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="q10" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">Does your skin become red after exposing to sun or after a workout? </p>
                                </div>
                            </div>


                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-10">
                                    <div className="row">
                                        <button type="button" id="btn-10" onClick={() => this.next10('Yes')}>Yes</button>
                                        <button type="button" id="btn-10" onClick={() => this.next10('No')}>No</button>
                                    </div>
                                </div>
                            </div>


                            <div className="row justify-content-end">
                                <div className="col-14" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dexpo"></p>
                                </div>
                            </div> */}

                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="q11" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">How many hours do you get sleep?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-11">
                                    <div className="row sleepDiv">
                                        <button type="button" id="btn-11" onClick={() => this.nextSleep('4 to 6')}>
                                            <img src={sleep1} />
                                        </button>
                                        <button type="button" id="btn-11" onClick={() => this.nextSleep('6 to 8')}>
                                            <img src={sleep2} />
                                        </button>
                                        <button type="button" id="btn-11" onClick={() => this.nextSleep('8+')}>
                                            <img src={sleep3} />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dsleepM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dsleep"></p>
                                </div>
                            </div>


                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qDrinkDaily" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">How much water do you drink daily?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-11six">
                                    <div className="row waterDiv">
                                        <button type="button" id="btn-11" onClick={() => this.nextDrinkDaily('Less than 2 liters')}>
                                            <img src={water1} />
                                        </button>
                                        <button type="button" id="btn-11" onClick={() => this.nextDrinkDaily('2 to 4 liters')}>
                                            <img src={water2} />
                                        </button>
                                        <button type="button" id="btn-11" onClick={() => this.nextDrinkDaily('More than 4 liters')}>
                                            <img src={water3} />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dDrinkDailyM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dDrinkDaily"></p>
                                </div>
                            </div>


                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qHairVolume" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">What’s your hair volume?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7six">
                                    <div className="row">
                                        <button type="button" id="btn-7" onClick={() => this.nextHairVolume('Thin')}>Thin</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextHairVolume('Normal')}>Normal</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextHairVolume('Thick')}>Thick</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dhairVolumeM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dhairVolume"></p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qHairType" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">What’s your hair type?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7seven">
                                    <div className="row">
                                        <button type="button" id="btn-7" onClick={() => this.nextHairType('Dry')}>Dry</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextHairType('Oily')}>Oily</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextHairType('Normal')}>Normal</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dhairTypeM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dhairType"></p>
                                </div>
                            </div>


                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qNaturalHair" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">How does your natural hair look like?</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7eight">
                                    <div className="row">
                                        <button type="button" id="btn-7" onClick={() => this.nextNaturalHair('Straight')}>Straight</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextNaturalHair('Wavy')}>Wavy</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextNaturalHair('Curly')}>Curly</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dnaturalHairM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dnaturalHair"></p>
                                </div>
                            </div>


                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qHairLength" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">I color my hair.</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7nine">
                                    <div className="row">
                                        <button type="button" id="btn-7" onClick={() => this.nextHairLength('Never')}>Never</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextHairLength('Sometimes')}>Sometimes</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextHairLength('Often')}>Often</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dhairLengthM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dhairLength"></p>
                                </div>
                            </div>



                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qHairConcern" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">What are your hair concerns?<br /> Select maximum 3</p>
                                </div>
                            </div>

                            <div className="row justify-content-center" id="in-col-5two" style={{ display: 'none' }}>


                                <div className="">

                                    <button onClick={() => this.hairConcerns("Frizz")} id="checktwo1" type="button" className="btn btn-primary"
                                        value="Frizz">
                                        Frizz
                                    </button>

                                    <button onClick={() => this.hairConcerns("Damaged hair")} id="checktwo2" name="overwrite" type="button" className="btn btn-primary"
                                        value="Damaged hair">
                                        Damaged hair
                                    </button>

                                    <button onClick={() => this.hairConcerns("Dandruff")} id="checktwo3" name="overwrite" type="button" className="btn btn-primary"
                                        value="Dandruff">
                                        Dandruff
                                    </button>

                                    <button onClick={() => this.hairConcerns("Color Protection")} id="checktwo4" name="overwrite" type="button" className="btn btn-primary"
                                        value="Color Protection">
                                        Color Protection
                                    </button>

                                    <button onClick={() => this.hairConcerns("Lack of Shine")} id="checktwo5" name="overwrite" type="button" className="btn btn-primary"
                                        value="Lack of Shine">
                                        Lack of Shine
                                    </button>

                                    <button onClick={() => this.hairConcerns("Lack of Volume")} id="checktwo6" name="overwrite" type="button" className="btn btn-primary"
                                        value="Lack of Volume">
                                        Lack of Volume
                                    </button>

                                    <button onClick={() => this.hairConcerns("Hair loss")} id="checktwo7" name="overwrite" type="button" className="btn btn-primary"
                                        value="Hair loss">
                                        Hair loss
                                    </button>

                                    <button onClick={() => this.hairConcerns("Ageing hair")} id="checktwo8" name="overwrite" type="button" className="btn btn-primary"
                                        value="Ageing hair">
                                        Ageing hair
                                    </button>

                                    <button onClick={() => this.hairConcerns("Split ends")} id="checktwo9" name="overwrite" type="button" className="btn btn-primary"
                                        value="Split ends">
                                        Split ends
                                    </button>

                                </div>
                                <input disabled={!this.state.hairConcerns} type="button" className="btn-concern" id="btn-concern2" value="NEXT" onClick={this.nextHairConcerns} />
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dhairConcernM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dhairConcern"></p>
                                </div>
                            </div>


                            {/* <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div> */}

                            <div className="row justify-content-start">
                                <div className="col" id="qGetOily" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">After washing your hair how soon does it get oily</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="in-col-7ten">
                                    <div className="row justify-content-end">
                                        <button type="button" id="btn-7" onClick={() => this.nextGetOily('Before dark')}>Before dark</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextGetOily('1 day')}>1 day</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextGetOily('2-3 days')}>2-3 days</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextGetOily('4-5days')}>4-5days</button>
                                        <button type="button" id="btn-7" onClick={() => this.nextGetOily('6+ days')}>6+ days</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dgetOilyM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dgetOily"></p>
                                </div>
                            </div>



                            {/* <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <div className="row justify-content-start">
                                <div className="col" id="qToolsUsed" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">Tools do you use</p>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div style={{ flexDirection: "column" }} className="col-14" id="in-col-7eleven">
                                    <div className="row justify-content-end">
                                        <button type="button" className={this.state.toolss.includes("Hair Dryer") ? "selectedd" : ""} id="btn-7" onClick={() => this.next11push('Hair Dryer')}>Hair Dryer</button>
                                        <button type="button" className={this.state.toolss.includes("Hair Straightener or Curler") ? "selectedd" : ""} id="btn-7" onClick={() => this.next11push('Hair Straightener or Curler')}>Hair Straightener or Curler</button>
                                        <button type="button" className={this.state.toolss.includes("Brushes") ? "selectedd" : ""} id="btn-7" onClick={() => this.next11push('Brushes')}>Brushes</button>
                                        <button type="button" className={this.state.toolss.includes("Other") ? "selectedd" : ""} id="btn-7" onClick={() => this.next11push('Other')}>Other</button>
                                    </div>
                                    <div style={{ width: "100%" }} className="row justify-content-end">
                                        <input disabled={!this.state.tools} type="button" className="btn-concern" id="btn-Tools" value="NEXT" onClick={() => this.next11()} />
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-end">
                                <div className="col-14" id="dtoolsUsedM" style={{ display: 'inline-flex', display: 'none' }}>
                                    <p className="bubble-text-ans" id="dtoolsUsed"></p>
                                </div>
                            </div> */}


                            <div className="row justify-content-start">
                                <div className="col" id="waiting" style={{ display: 'inline-flex', display: 'none' }}>
                                    <img src={robo} className="robo" />
                                    <p className="bubble-text-ques">.........</p>
                                </div>
                            </div>

                            <button onClick={(ev) => this.submitQuiz(ev)} id="submit" className="submit"> Submit </button>
                            <button type="button" id="restart" className="restart" onClick={() => window.location.reload()}> Restart </button>

                        </div>
                    </div>
                </div>

                <Modal footer={false} visible={this.state.ageRestriction} onCancel={() => this.setState({ ageRestriction: false })} centered closeIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>}>
                    <h5
                        style={{ textAlign: "center", fontWeight: "bold", marginTop: 30, marginBottom: 30, }}
                    >
                        We do not support for the ages of 13 or less.
                        <br />
                        <a href="/">
                            <button style={{ marginTop: 20, background: "#ff9676", border: "none", color: "white", borderRadius: 5, padding: "10px 20px" }}>Go to home</button>
                        </a>
                    </h5>
                </Modal>

                {/* </form> */}

            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    user: store.user,
    is_authenticated: store.is_authenticated,
    brandsTypeUser: store.brandsTypeUser
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBrandsTypeUser
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Quiz)