import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendMails } from '../../modules/actions';
import './contactUs.scss'

const { Panel } = Collapse;

function ContactUs(props) {

    let [typeOne, setTypeOne] = useState({
        type: "General Enquiries & Feedback"
    })
    let [typeTwo, setTypeTwo] = useState({
        type: "Partners"
    })
    let [typeThree, setTypeThree] = useState({
        type: "Press"
    })
    let [typeFour, setTypeFour] = useState({
        type: "Influencers"
    })

    let [message, setMessage] = useState("")
    let [messageColor, setMessageColor] = useState("")

    function ValidateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return (true)
        }
        setMessage("You have entered an invalid email address!")
        setMessageColor("red")
        return (false)
    }

    const sendMail = (name, ev) => {

        ev.preventDefault()
        if (name === "one") {
            if (typeOne.name && typeOne.email && typeOne.message) {
                if (ValidateEmail(typeOne.email)) {
                    props.sendMails(typeOne)
                    setTypeOne({})
                    setTypeOne({
                        type: "General Enquiries & Feedback"
                    })
                    setMessage("Successfully sent")
                    setMessageColor("green")
                }
            } else {
                setMessage("All Feilds Are Required")
                setMessageColor("red")
            }
        }
        else if (name === "two") {
            if (typeTwo.name && typeTwo.email && typeTwo.message) {
                if (ValidateEmail(typeOne.email)) {
                    props.sendMails(typeTwo)
                    setTypeTwo({})
                    setTypeTwo({
                        type: "Partners"
                    })
                    setMessage("Successfully sent")
                    setMessageColor("green")
                }
            } else {
                setMessage("All Feilds Are Required")
                setMessageColor("red")
            }
        }
        else if (name === "three") {
            if (typeThree.name && typeThree.email && typeThree.message) {
                if (ValidateEmail(typeOne.email)) {
                    props.sendMails(typeThree)
                    setTypeThree({})
                    setTypeThree({
                        type: "Press"
                    })
                    setMessage("Successfully sent")
                    setMessageColor("green")
                }
            } else {
                setMessage("All Feilds Are Required")
                setMessageColor("red")
            }
        }
        else if (name === "four") {
            if (typeFour.name && typeFour.email && typeFour.message) {
                if (ValidateEmail(typeOne.email)) {
                    props.sendMails(typeFour)
                    setTypeFour({})
                    setTypeFour({
                        type: "Influencers"
                    })
                    setMessage("Successfully sent")
                    setMessageColor("green")
                }
            } else {
                setMessage("All Feilds Are Required")
                setMessageColor("red")
            }
        }
    }

    useEffect(() => {
        console.log(typeOne)
    }, [typeOne])

    return (
        <div className="container contactUsMain">
            <img className="mainImg" src={require('./mainImage.jpg')} />
            <h6>Contact Us</h6>

            <Collapse>
                <Panel header="General Enquiries & Feedback" key="1">
                    <p className="content_p">For feedback and general inquiries, fill the form below and expect a response within 1-2 working days. However, under certain circumstances we might take longer.</p>
                    <p className="content_p">Messages or queries regarding product pricing, delivery or availability will not be responded to. For the queries regarding this we recommend contacting the retailer directly. </p>
                    <div className="content_form">
                        <form>
                            <div className="inputDiv">
                                <label>NAME:</label>
                                <input
                                    type="text"
                                    onChange={(ev) => {
                                        let obj = { ...typeOne }
                                        obj.name = ev.target.value
                                        setTypeOne(obj)
                                    }}
                                    value={typeOne.name ? typeOne.name : ""}
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Phone Number:</label>
                                <input
                                    type="text"
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Email Address:</label>
                                <input
                                    type="text"
                                    onChange={(ev) => {
                                        let obj = { ...typeOne }
                                        obj.email = ev.target.value
                                        setTypeOne(obj)
                                    }}
                                    value={typeOne.email ? typeOne.email : ""}
                                />
                            </div>
                            <div className="inputDiv">
                                <label>MESSAGE:</label>
                                <textarea
                                    rows="5"
                                    onChange={(ev) => {
                                        let obj = { ...typeOne }
                                        obj.message = ev.target.value
                                        setTypeOne(obj)
                                    }}
                                    value={typeOne.message ? typeOne.message : ""}
                                />
                            </div>

                            <div className="buttonDiv">
                                {message &&
                                    <p style={{ color: messageColor }}>{message}</p>
                                }
                                <button onClick={(ev) => sendMail("one", ev)}>SEND</button>
                            </div>
                        </form>
                    </div>
                </Panel>
                <Panel header="Partners" key="2">
                    <p className="content_p">Are you a Brand or a Retailer with beauty or cosmetic products? Would you like to be on our platform and showcase your products to potential customers? Please fill the form below and we will get back to you.</p>
                    <div className="content_form">
                        <form>
                            <div className="inputDiv">
                                <label>Brand/Retailer NAME:</label>
                                <input
                                    type="text"
                                    onChange={(ev) => {
                                        let obj = { ...typeTwo }
                                        obj.name = ev.target.value
                                        setTypeTwo(obj)
                                    }}
                                    value={typeTwo.name ? typeTwo.name : ""}
                                />
                            </div>
                            <div className="inputDiv">
                                <label>URL:</label>
                                <input
                                    type="text"
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Phone Number:</label>
                                <input
                                    type="text"
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Email Address:</label>
                                <input
                                    type="text"
                                    onChange={(ev) => {
                                        let obj = { ...typeTwo }
                                        obj.email = ev.target.value
                                        setTypeTwo(obj)
                                    }}
                                    value={typeTwo.email ? typeTwo.email : ""}
                                />
                            </div>
                            <div className="inputDiv">
                                <label>MESSAGE:</label>
                                <textarea
                                    rows="5"
                                    onChange={(ev) => {
                                        let obj = { ...typeTwo }
                                        obj.message = ev.target.value
                                        setTypeTwo(obj)
                                    }}
                                    value={typeTwo.message ? typeTwo.message : ""}
                                />
                            </div>

                            <div className="buttonDiv">
                                {message &&
                                    <p style={{ color: messageColor }}>{message}</p>
                                }
                                <button onClick={(ev) => sendMail("two", ev)}>SEND</button>
                            </div>
                        </form>
                    </div>
                </Panel>
                <Panel header="Press" key="3">
                    <p className="content_p">For any press or media related enquiries please fill out the details and we will get in touch with you soon. </p>
                    <div className="content_form">
                        <form>
                            <div className="inputDiv">
                                <label>NAME:</label>
                                <input
                                    type="text"
                                    onChange={(ev) => {
                                        let obj = { ...typeThree }
                                        obj.name = ev.target.value
                                        setTypeThree(obj)
                                    }}
                                    value={typeThree.name ? typeThree.name : ""}
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Publication:</label>
                                <input
                                    type="text"
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Phone Number:</label>
                                <input
                                    type="text"
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Email Address:</label>
                                <input
                                    type="text"
                                    onChange={(ev) => {
                                        let obj = { ...typeThree }
                                        obj.email = ev.target.value
                                        setTypeThree(obj)
                                    }}
                                    value={typeThree.email ? typeThree.email : ""}
                                />
                            </div>
                            <div className="inputDiv">
                                <label>MESSAGE:</label>
                                <textarea
                                    rows="5"
                                    onChange={(ev) => {
                                        let obj = { ...typeThree }
                                        obj.message = ev.target.value
                                        setTypeThree(obj)
                                    }}
                                    value={typeThree.message ? typeThree.message : ""}
                                />
                            </div>

                            <div className="buttonDiv">
                                {message &&
                                    <p style={{ color: messageColor }}>{message}</p>
                                }
                                <button onClick={(ev) => sendMail("three", ev)}>SEND</button>
                            </div>
                        </form>
                    </div>
                </Panel>
                <Panel header="Influencers" key="4">
                    <p className="content_p">Do you have a Social Media account or a blog or a website? If you are an Infleuncer with the Passion towards beauty and lifestyle related things we would love to work with you.</p>
                    <p className="content_p">If you are willing to be a part of our journey and become a part of the GlaMate influencer network, please fill the form below and we will get back to you promptly.</p>
                    <div className="content_form">
                        <form>
                            <div className="inputDiv">
                                <label>INFLUENCER NAME:</label>
                                <input
                                    type="text"
                                    onChange={(ev) => {
                                        let obj = { ...typeFour }
                                        obj.name = ev.target.value
                                        setTypeFour(obj)
                                    }}
                                    value={typeFour.name ? typeFour.name : ""}
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Social Media Handles:</label>
                                <input
                                    type="text"
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Website/Blog Link:</label>
                                <input
                                    type="text"
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Phone Number:</label>
                                <input
                                    type="text"
                                />
                            </div>
                            <div className="inputDiv">
                                <label>Email Address:</label>
                                <input
                                    type="text"
                                    onChange={(ev) => {
                                        let obj = { ...typeFour }
                                        obj.email = ev.target.value
                                        setTypeFour(obj)
                                    }}
                                    value={typeFour.email ? typeFour.email : ""}
                                />
                            </div>
                            <div className="inputDiv">
                                <label>MESSAGE:</label>
                                <textarea
                                    rows="5"
                                    onChange={(ev) => {
                                        let obj = { ...typeFour }
                                        obj.message = ev.target.value
                                        setTypeFour(obj)
                                    }}
                                    value={typeFour.message ? typeFour.message : ""}
                                />
                            </div>

                            <div className="buttonDiv">
                                {message &&
                                    <p style={{ color: messageColor }}>{message}</p>
                                }
                                <button onClick={(ev) => sendMail("four", ev)}>SEND</button>
                            </div>
                        </form>
                    </div>
                </Panel>
            </Collapse>
        </div>
    )
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            sendMails
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactUs)