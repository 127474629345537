import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import Axios from 'axios'
import { fetchSkincareProductsRecommended, getCartOrders, getCookie, getProfileData, getWishlist, quickViewModel } from '../../modules/actions';
import NOAvaliableImage from '../../Images/NoAvaliable.jpeg'
import QuickView from '../quickViewModel/quickView';
import Skeleton from '@material-ui/lab/Skeleton';

class Recommendation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: []
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        let text = this.props.location.search.match("%20")
        let RecommendNameArr = text ? this.props.location.search.slice(1).split("%20") : this.props.location.search.slice(1).split(" ")
        this.props.fetchSkincareProductsRecommended(RecommendNameArr[0] ? RecommendNameArr[0].toLowerCase() : "skin")
        this.setState({
            currentPage: RecommendNameArr.join("-").toLowerCase()
        })
        this.props.getProfileData()
        window.scrollTo({ top: 0, behavior: 'auto' });
    }
    addToCart = (id, out) => {
        if (!out) {
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("id", id);
            data.append("action", "add");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (response.data.code === "token_not_valid") {
                        let msg = "You have login first"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        let data = response.data.products
                        // this.props.getCartOrders(data);
                        let msg = response.data.message
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        return data
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        } else {
            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>Out of stock</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
            }, 2000)
        }
    }

    addToWhishlist = (id) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("product_id", id);
        // data.append("action", "add");
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/wishlist/update/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.code === "token_not_valid") {
                    // let msg = "You have login first"
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    window.location.href = '/login'
                } else {
                    let data = response.data.products
                    // this.props.getCartOrders(data);
                    // let msg = response.data.message
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    this.props.getWishlist()
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    return data
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    quickView = (obj) => {
        this.props.quickViewModel(obj)
    }

    checkWhishlist = (a, abc) => {
        let whishlistProducts = this.props.whishlistProducts
        if (whishlistProducts) {
            let check = whishlistProducts.filter(b => b.id === a.id)
            if (check.length) {
                return (
                    <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-heart-fill" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                    </svg>
                )
            } else {
                return (
                    <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                    </svg>
                )
            }
        } else {
            return (
                <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                </svg>
            )
        }
    }

    discoutGet = (a, b) => {
        let price = a
        let discounted_price = b
        let discounted_given = Number(price) - Number(discounted_price)
        return `${Number(Math.ceil((discounted_given / price) * 100).toFixed(1)).toFixed(0)}% Off`
    }

    changeRoute = (a, ev) => {
        if (ev.target.id !== "noOpen" && ev.target.nodeName !== "path") {
            if (window.screen.availWidth < 768) {
                window.location.href = `/${a.type}/${a.slug}?score=${a.score}`
            } else {
                window.open(`/${a.type}/${a.slug}?score=${a.score}`, '_blank');
            }
        }
    }

    render() {
        // console.log()
        let RecommendNameArr = this.props.location.search.slice(1).split("%20")
        let categories = []
        if (this.props.recommendedProducts) {

            for (let i = 0; i < this.props.recommendedProducts.length; i++) {
                if (!categories.join('').includes(this.props.recommendedProducts[i].category)) {
                    categories.push(this.props.recommendedProducts[i].category)
                }
            }
        }
        document.title = `${this.props.user.firstname}'s Recommended Products`
        return (
            // this.props.user.SkinRoutines ?
            <div className="skinCaree">
                {this.props.quickView &&
                    <QuickView />
                }
                <div className="page container recommendation">
                    <div className="alert alert-success alert-dismissable" style={{ display: 'none' }}>
                        <strong className="alert-text"></strong>
                    </div>


                    <div className="allProducts">
                        {this.props.user.firstname &&
                            <h2 style={{ textTransform: 'capitalize' }}>{this.props.user.firstname ? this.props.user.firstname : "Loading.."}'s Recommended {RecommendNameArr[0] && RecommendNameArr[1] ? RecommendNameArr[0] + " " + RecommendNameArr[1] : RecommendNameArr[0] ? RecommendNameArr[0] : ""} Products</h2>
                        }
                        <div className="cat-pro">
                            <div className="shopProducts" style={{ width: '100%' }}>
                                <div className="webpro" id="desktop-view-products">
                                    {this.props.isLoading &&
                                        [1, 2, 3].map((a, i) => {
                                            return (
                                                <div className="recommendationDivInside" key={i}>
                                                    <div class='col-md-2'>
                                                        <Skeleton animation="wave" variant="rect" height={30} className="catNameButton" />
                                                    </div>
                                                    <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', padding: '0px', flexWrap: 'wrap' }} class={`col-md-9 ${a}Products`}>
                                                        {[1, 2, 1].map((a, i) => {
                                                            return (
                                                                <div key={i} className="product">
                                                                    <div style={{ width: "100%", height: "100%", alignItems: "center", display: 'flex', justifyContent: "flex-start", flexDirection: "column" }}>
                                                                        <Skeleton animation="wave" variant="rect" width={window.innerWidth > 600 ? 230 : "100%"} height="60%" />
                                                                        <Skeleton animation="wave" variant="text" width="90%" style={{ marginTop: 10 }} height={25} />
                                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                                        <Skeleton animation="wave" variant="rect" width="50%" height={35} style={{ marginTop: 10, borderRadius: 50 }} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div><NavLink to="#!" className="btn btn-seemore">See more</NavLink></div>
                                                </div>
                                            )
                                        })
                                    }
                                    {categories.map((a, i) => {
                                        return (
                                            // this.props.user.SkinRoutines.match(a.toLowerCase()) &&
                                            <div key={i} className="recommendationDivInside" id={`${a}`}>
                                                <div class='col-md-2'>
                                                    {window.screen.width < 600 ?
                                                        <a target={window.screen.availWidth < 768 ? false : '_blank'} href={`/${this.state.currentPage}?category=${a.match("&") ? a.split("&").join("-") : a}`}>
                                                            <button className="catNameButton">{a}</button>
                                                        </a>
                                                        :
                                                        <NavLink target={window.screen.availWidth < 768 ? false : '_blank'} to={`/${this.state.currentPage}?category=${a.match("&") ? a.split("&").join("-") : a}`}>
                                                            <button className="catNameButton">{a}</button>
                                                        </NavLink>
                                                    }
                                                </div>
                                                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', padding: '0px', flexWrap: 'wrap' }} class={`col-md-9 ${a}Products`}>

                                                    {this.props.recommendedProducts.map((b, i) => {
                                                        return (
                                                            b.category === a &&
                                                            <div key={i} className="product">
                                                                <div onClick={(ev) => this.changeRoute(b, ev)} className="product-img" style={b.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${b.image})` }}>
                                                                    <div className="row">
                                                                        <div className="col-md-12 text-right">
                                                                            <button className="btn btn-match"> {b.score}% Match</button>
                                                                        </div>
                                                                    </div>
                                                                    <div id={`hoverDiv${b.id}`} className="hoverDiv" style={{ opacity: 0 }}>
                                                                        {/* <div onClick={() => b.stock_quantity !== 0 ? this.addToCart(b.id) : this.addToCart(b.id,"out")} className="hoverI update-cart" data-action="add" data-productid={`${b.id}`}>
                                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z">
                                                                                    </path>
                                                                                </svg>
                                                                            </div> */}
                                                                        <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(b.id)} data-productid={`${b.id}`}>
                                                                            {this.checkWhishlist(b)}
                                                                        </div>
                                                                    </div>
                                                                    {b.stock_quantity === 0 &&
                                                                        <div className="outOfStockDiv">Out of stock</div>
                                                                    }
                                                                </div>
                                                                <div onClick={(ev) => this.changeRoute(b, ev)} className="product-txt" id="recommendation-product-txt">
                                                                    <h6><i>{b.brand} - </i> 
                                                                    {b.name.length + b.brand.length > 42 ? `${b.name.slice(0, (42 - b.brand.length))}...` : b.name}
                                                                    {/* <NavLink target={window.screen.availWidth < 768 ? false : false} to="#!">{b.name.length + b.brand.length > 42 ? `${b.name.slice(0, (42 - b.brand.length))}...` : b.name}</NavLink> */}
                                                                    </h6>
                                                                    {/* <p>
                                                                            <i>{b.category} - {b.brand}</i></p> */}
                                                                    <p className="price web">
                                                                        {b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < b.price ?
                                                                            <>
                                                                                <span className="lightGray">
                                                                                    MRP:&nbsp;&nbsp;
                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{b.price}</span></span>
                                                                                <span style={{ float: 'right' }}>
                                                                                    <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                                    {b.product_affiliates && b.product_affiliates.length && Number(b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                                </span>
                                                                                <span className=""> |</span>
                                                                                <span className="pinkk">{this.discoutGet(b.price, b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                            </>
                                                                            :
                                                                            <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {b.price}</span>
                                                                        }
                                                                    </p>
                                                                    <div className="mob-btn">
                                                                        {/* <div className="mob-cart"> */}
                                                                        {/* <button onClick={() => b.stock_quantity !== 0 ? this.addToCart(b.id) : this.addToCart(b.id,"out")} data-action="add" data-productid={`${b.id}`}>
                                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                                                </svg>
                                                                                <div>
                                                                                    <p> ADD TO CART </p>
                                                                                </div>
                                                                            </button> */}
                                                                        {/* </div> */}
                                                                        <p className="price">
                                                                            {b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < b.price ?
                                                                                <>
                                                                                    <span className="lightGray">

                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{b.price}</span></span>
                                                                                    <span style={{ float: 'right' }}>
                                                                                        <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                                        {b.product_affiliates && b.product_affiliates.length && Number(b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                                    </span>
                                                                                    <span className=""> |</span>
                                                                                    <span className="pinkk">{this.discoutGet(b.price, b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                                </>
                                                                                :
                                                                                <span style={{ float: 'right' }}><i className="fas fa-rupee-sign" aria-hidden="true"></i> {b.price}</span>
                                                                            }
                                                                        </p>
                                                                        {/* <div className="mob-wish">
                                                                            <button id="noOpen" onClick={() => this.addToWhishlist(b.id)} data-productid={`${b.id}`}>
                                                                                {this.checkWhishlist(b, "a")}
                                                                                <div id="noOpen">
                                                                                    <p id="noOpen"> WISHLIST </p>
                                                                                </div>
                                                                            </button>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                {/* <hr /> */}
                                                                <div className="button-div">
                                                                    <button onClick={() => this.quickView(b)}>
                                                                        Quick View
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <div>
                                                    {window.screen.width < 600 ?
                                                        <a target={window.screen.availWidth < 768 ? false : '_blank'} href={`/${this.state.currentPage}?category=${a.match("&") ? a.split("&").join("-") : a}`} className="btn btn-seemore">See more</a>
                                                        :
                                                        <NavLink target={window.screen.availWidth < 768 ? false : '_blank'} to={`/${this.state.currentPage}?category=${a.match("&") ? a.split("&").join("-") : a}`} className="btn btn-seemore">See more</NavLink>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                                <div className="mobviewpro" id="mobile-view-products">

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="allProducts">
                            {this.props.user.SkinRoutines && this.props.user.SkinRoutines.split(',').length !== categories.length &&
                                <h2 style={{ textTransform: 'capitalize' }}>More Recommended Products</h2>
                            }
                            <div className="cat-pro">
                                <div className="shopProducts" style={{ width: '100%' }}>
                                    <div className="webpro" id="desktop-view-products">

                                        {categories.map((a, i) => {
                                            return (
                                                !this.props.user.SkinRoutines.match(a.toLowerCase()) &&
                                                <div key={i} className="recommendationDivInside" id={`${a}`}>
                                                    <div class='col-md-2'><button className="catNameButton">{a}</button></div>
                                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', padding: '0px', flexWrap: 'wrap' }} class={`col-md-9 ${a}Products`}>

                                                        {this.props.recommendedProducts.map((b, i) => {
                                                            return (
                                                                b.category === a &&
                                                                <div key={i} className="product">
                                                                    <div onClick={(ev) => this.changeRoute(b, ev)} className="product-img" style={{ backgroundImage: `url(${b.image})` }}>
                                                                        <div className="row">
                                                                            <div className="col-md-12 text-right">
                                                                                <button className="btn btn-match"> {b.score}% Match</button>
                                                                            </div>
                                                                        </div>
                                                                        <div id={`hoverDiv${b.id}`} className="hoverDiv" style={{ opacity: 0 }}>
                                                                            <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(b.id)} data-productid={`${b.id}`}>
                                                                                {this.checkWhishlist(b)}
                                                                            </div>
                                                                        </div>
                                                                        {b.stock_quantity === 0 &&
                                                                            <div className="outOfStockDiv">Out of stock</div>
                                                                        }
                                                                    </div>
                                                                    <div onClick={(ev) => this.changeRoute(b, ev)} className="product-txt" id="recommendation-product-txt">
                                                                        <h6><i>{b.brand} - </i> <NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/${b.type}/${b.slug}?score=${b.score}`}>{b.name.length > 42 ? `${b.name.slice(0, 42)}...` : b.name}</NavLink></h6>
                                                                        <p className="price web">
                                                                            {b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < b.price ?
                                                                                <>
                                                                                    <span className="lightGray">
                                                                                        MRP:&nbsp;&nbsp;
                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{b.price}</span></span>
                                                                                    <span style={{ float: 'right' }}>
                                                                                        <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                                        {b.product_affiliates && b.product_affiliates.length && Number(b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                                        <span className="lightGray two"> |
                                                                                        </span>
                                                                                    </span>
                                                                                    <span className="pinkk">{this.discoutGet(b.price, b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                                </>
                                                                                :
                                                                                <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {b.price}</span>
                                                                            }
                                                                        </p>
                                                                        <div className="mob-btn">
                                                                            <p className="price">
                                                                                {b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < b.price ?
                                                                                    <>
                                                                                        <span className="lightGray">
                                                                                            MRP:&nbsp;&nbsp;
                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{b.price}</span></span>
                                                                                        <span style={{ float: 'right' }}>
                                                                                            <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                                            {b.product_affiliates && b.product_affiliates.length && Number(b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                                            <span className="lightGray two"> |
                                                                                        </span>
                                                                                        </span>
                                                                                        <span className="pinkk">{this.discoutGet(b.price, b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                                    </>
                                                                                    :
                                                                                    <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {b.price}</span>
                                                                                }
                                                                            </p>
                                                                            <div className="mob-wish">
                                                                                <button id="noOpen" onClick={() => this.addToWhishlist(b.id)} data-productid={`${b.id}`}>
                                                                                    {this.checkWhishlist(b, "a")}
                                                                                    <div id="noOpen">
                                                                                        <p id="noOpen"> WISHLIST </p>
                                                                                    </div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="button-div">
                                                                        <button onClick={() => this.quickView(b)}>
                                                                            Quick View
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                    </div>
                                                    <div><NavLink target='_blank' to={`/skin-care?${a}`} className="btn btn-seemore">See more</NavLink></div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div className="mobviewpro" id="mobile-view-products">

                                    </div>
                                </div>
                            </div>
                        </div>
 */}
                </div>
            </div>
            // : <></>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    is_authenticated: store.is_authenticated,
    user: store.user,
    recommendedProducts: store.recommendedProducts,
    whishlistProducts: store.whishlistProducts,
    quickView: store.quickView,
    isLoading: store.isLoading

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchSkincareProductsRecommended,
            getProfileData,
            getCartOrders,
            quickViewModel,
            getWishlist
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Recommendation))