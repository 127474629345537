import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Navbar from '../Navbar';

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <>
                <div className="termsDiv">
                    <h1>Privacy Policy</h1>
                    <p>
                    Our Privacy Policy forms part of and must be read in conjunction with, website Terms and Conditions. We reserve the right to change this Privacy Policy at any time.

                    </p>
                    <p>
                    We respect the privacy of our users and every person who visits our sites www.glamate.in. Here, ‘The Glamate eRetail Pvt Ltd’ referred to as (“we”, “us”, or “our” or “Glamate”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us on our website email.

                    </p>
                    <p>
                    When you visit our website www.glamate.in (“Site”) and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have concerning it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue the use of our site and our services.
                    </p>

                    <br></br>
                    <h2>About Us:</h2>
                    <p>
                    Here at, www.glamate.in, we provide a meticulously designed beauty product recommendations and price comparison website. 
                    </p>
                    <p>
                    Users can freely browse through products and compare product prices and when users click on buy now they will be directed to third party websites. In addition they can also take a beauty quiz and see recommended products. Users will need to create an account after the quiz to see the recommendations. 
                    </p>
                    <p>
                    The purpose of making this website is to bring the best deal for you and make you aware of various offers related to fashion and beauty.
                    </p>
                    <p>
                        <b>
                        Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.  
                        </b>
                    </p>

                    <br></br>

                    <h2>What Information Do We Collect?</h2>
                    <p>
                    We collect personal information that you voluntarily provide to us when  registering with us, expressing an interest in obtaining information about us or our services, when participating in activities on the Site (such as using our policy builder), or otherwise contacting us.
                    </p>
                    <p>
                    The personal information that we collect depends on the context of your interactions with us and the Site, the choices you make and the features you use. The personal information we collect can include the following:
                    </p>
                    <p>
                    Personal Information refers to data such as a name, e-mail address or phone number that can be linked to a particular individual or organization without the use of additional data. We might not be able to provide any or all of the functionality and functionalities found on the Services if you refuse to share such details with us.
                    </p>

                    <br></br>

                    <h2>Information automatically collected:</h2>
                    <p>
                    We automatically collect certain information when you visit, use or navigate the Site. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser, and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Site and other technical information.  If you access our site with your mobile device, we may automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and IP address. This information is primarily needed to maintain the security and operation of our Site, and for our internal analytics and reporting purposes.
                    </p>
                    <p>
                    Like many businesses, we also collect information through cookies and similar technologies.
                    </p>
                    <p>
                    If you have chosen to subscribe to our newsletter, your first name, last name and email address will be shared with our newsletter provider. This is to keep you updated with information and offers for marketing purposes.
                    </p>

                    <br></br>

                    <h2>Cookie Policy:</h2>
                    <p>
                    By continuing to use our site and services, you are agreeing to the use of cookies and similar technologies for the purposes we describe in this cookie policy. If you do not agree to any part of this agreement, then don't press "accept," within which case you will not be able to use the service.
                    </p>
                    <p>
                    <b>Cookies</b><br></br>
                    A cookie is a small text file that a website saves on your computer or mobile device when you visit the site. In general, cookies have two main purposes: to improve your browsing experience by remembering your actions and preferences and to help us analyze our website traffic.  
                    </p>
                    <p>
                    <b>What to do with Cookies?</b><br></br>
                    We use cookies to help us analyze traffic to the Website, to help us improve website performance and usability and to make the Website more secure. Third-party cookies help us use Google Analytics to count, track and analyze visits to the Website. This helps us understand how people are using our websites and where we need to make improvements. These third-party cookies do not specifically identify you.
                    </p>
                    <p>
                    <b>Types & Category of Cookies used</b><br></br>
                    List the category of cookies used in https://www.glamate.in site. For example:<br></br>
                    a)    Security<br></br>
                    <li>We use cookies to enable and support our security features, and to help us detect malicious activity violations of our Terms and Conditions.</li><br></br>
                    b)    Performance, Analytics, Research & Advertising<br></br>
                    <li>Cookies help us learn how well our site and web products perform in different locations. We also use these to understand, improve and research products, features and services, including when you access this site https://www.glamate.in from other websites, applications or devices such as your work computer or your mobile device. We also use third-party cookies to improve and personalize our marketing messages/communications with you.</li>

                    </p>
                    <p>
                    <b>Control cookies</b><br></br>
                    Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Site
                    </p>

                    <br></br>

                    <h2>How Do We Use Your Information:</h2>
                    <p>
                    We use your personal information for these purposes in reliance on our legitimate business interests (“Business Purposes”), to enter into or perform a contract with you (“Contractual”), with your consent (“Consent”), and/or for compliance with our legal obligations (“Legal Reasons”). We indicate the specific processing grounds we rely on next to each purpose listed below.  
                    </p>
                    <p>
                    We use the information we collect or receive:   
                    </p>
                    <ul>
                        <li><b>Request Feedback </b>for our Business Purposes and/or with your Consent. We may use your information to request feedback and to contact you about your use of our Site.</li>
                        <li><b>To protect our Site</b> for Business Purposes and/or Legal Reasons.  We may use your information as part of our efforts to keep our Site safe and secure (for example, for fraud monitoring and prevention).</li>
                        <li><b>To enable user-to-user communications</b> with your consent. We may use your information to enable user-to-user communications with each user’s consent.</li>
                        <li><b>To enforce our terms, conditions, and policies</b> for our business purposes and as legally required.</li>
                        <li><b>To respond to legal requests and prevent harm</b> as legally required. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
                        <li><b>For other Business Purposes.</b> We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Site, products, services, marketing, and your experience.</li>
                    </ul>

                    <br></br>

                    <h2>Will Your Information Be Shared With Anyone:</h2>
                    <p>
                    We only share and disclose your information in the following situations:
                    </p>
                    <ul>
                        <li><b>Compliance with Laws.</b>We may disclose your information where we are legally required to do so to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal processes, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
                        <li><b>Vital Interests and Legal Rights.</b>We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                        <li><b>Vendors, Consultants, and Other Third-Party Service Providers.</b>We may share your data with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work.</li>
                        <li><b>Business Transfers.</b>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                        <li><b>Third-Party Advertisers.</b>We may use third-party advertising companies to serve ads when you visit the Site. These companies may use information about your visits to our Site and other websites that are contained in web cookies and other tracking technologies to provide advertisements about goods and services of interest to you.</li>
                        <li><b>Affiliates.</b>We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
                        <li><b>Business Partners.</b>We may share your information with our business partners to offer you certain products, services, or promotions.</li>
                        <li><b>With your Consent.</b>We may disclose your personal information for any other purpose with your consent.</li>
                        <li><b>Other Users.</b>When you share personal information (for example, by posting comments, contributions, or other content to the Site) or otherwise interact with public areas of the Site, such personal information may be viewed by all users and may be publicly distributed outside the Site in perpetuity.</li>
                    </ul>

                    <br></br>

                    <h2>Analytics and advertising:</h2>
                    <p>
                        <b>Google Analytics</b><br></br>
                        This website uses Google Analytics, a web analytics service. It is operated by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.<br></br>
                        Google Analytics uses so-called "cookies". These are text files that are stored on your computer and that allow an analysis of the use of the website by you. The information generated by the cookie about your use of this website is usually transmitted to a Google server in the USA and stored there.<br></br>
                        Google Analytics cookies are stored based on Art. 6 (1) (f) DSGVO. The website operator has a legitimate interest in analyzing user behavior to optimize both its website and its advertising.

                    </p>
                    <p>
                    <b>Google Adsense</b><br></br>
                    This website uses Google Adsense, a Web-advertising-service of Google Inc., United States ("Google"). Google Adsense uses "cookies" (text files) that are stored on your computer/Mobile Device and allows an analysis of the use of the Website by you. Google Adsense also uses so-called "web beacons" (small invisible images) to gather information. Through the use of Web beacons simple actions such as the visitor traffic to the Website can be recorded and collected. The information generated by the cookie and / or Web Beacon information about your use of this Website, including your IP address will be transferred to a Google server in the U.S. and stored there. Google will use this information to evaluate your use of the site in order to show, compiling reports on Website activity and ads for Website operators and providing other use of this Website and internet related services. Even Google is able to transmit this information to third parties unless required by law or if third parties process this data on Google's behalf. Google will not associate your IP address with any other data held by Google. 
                    </p>

                    <br></br>

                    <h2>Is Your Information Transferred Internationally:</h2>
                    <p>
                    Your data will be stored and processed in India or other countries where the Company has operations. Using the Services, you agree to the information being transferred outside of your country, even though your country has stricter data privacy laws.
                    </p>

                    <br></br>

                    <h2>What Is Our Stance On Third-Party Websites:</h2>
                    <p>
                    The Site may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services, or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy policy. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Site. You should review the policies of such third parties and contact them directly to respond to your questions.
                    </p>

                    <br></br>

                    <h2>How Long Do We Keep Your Information:</h2>
                    <p>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). 
                    </p>
                    <p>
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                    </p>

                    <br></br>

                    <h2>How Do We Keep Your Information Safe:</h2>
                    <p>
                    We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, the transmission of personal information to and from our Site is at your own risk. You should only access the services within a secure environment. To ensure security norms, we use HTTPS Security encryption and valid SSL certification.
                    </p>

                    <br></br>

                    <h2>Do We Collect Information From Minor:</h2>
                    <p>
                    We do not knowingly solicit data from or market to children under 13 years of age and the Services are not directed at children under 16.  By using the Site, you represent that you are at least 16 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Site.  If we learn that personal information from users less than 16 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.  If you become aware of any data we have collected from children under age 16, please contact us at our email: <b>csg@glamate.in</b>
                    </p>

                    <br></br>

                    <h2>What Are Your Privacy Rights:</h2>
                    <p>
                    Personal Information 
                    </p>
                    <p>
                    You may at any time review or change the information by:
                    <li>Contacting us using the contact information provided below</li>
                    </p>
                    <p>
                    We may change or delete your information, upon your request to change or delete your information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use, and/or comply with legal requirements. 
                    </p>

                    <br></br>

                    <h2>Do We Make Updates To This Policy:</h2>
                    <p>
                    We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible.You'll be able to review the original current version of our terms and conditions at any time by clicking on the "Privacy Policy" link on our website. The earliest current version displayed there on a page can succeed all previous versions.
                    </p>
                    <p>
                    We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
                    </p>

                    <br></br>
                    <h2>Consent:</h2>
                    <p>
                    By using the Website and/or providing your information, you agree to the collection and use of the information you provide on the Website in compliance with this Privacy Policy, including, but not limited to, your consent to share your information as described in this Privacy Policy.
                    </p>

                    <br></br>
                    <h2>How Can You Contact Us About This Policy:</h2>
                    <p>
                    If you have questions or comments about this policy, you can write to our website’s email - <b>csg@glamate.in</b>
                    </p>

                    <br></br>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Privacy)