import Skeleton from '@material-ui/lab/Skeleton';
import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { getBlogs, getBlogSingle, getBlogTags } from '../../modules/actions';
import './blogsPage.scss'

function BlogsPage(props) {

    let [current, setCurrent] = useState(1)
    let [tag, setTag] = useState("all")

    const paginate = (ev) => {
        setCurrent(ev)
        props.history.push(`/blogs?page=${ev}`)
        props.getBlogs()
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    const setCurrentt = () => {
        let params = new URLSearchParams(window.location.search)
        let page = params.get("page") ? params.get("page") : 1
        let tag = params.get("tag")
        if (tag !== null && tag !== false) {
            setCurrent(Number(page))
            setTag(tag)
        }
        else {
            setCurrent(Number(page))
            setTag("all")
        }
    }

    useEffect(() => {
        window.scrollTo(500, 0);
        props.getBlogTags()
        setCurrentt()
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, [])

    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let tag = params.get("tag")

        if (props.blogTags) {
            if (tag) {
                if (props.blogTags.filter((a) => a.name.toLowerCase() === tag.toLowerCase())) {
                    props.getBlogs(props.blogTags.filter((a) => a.name.toLowerCase() === tag.toLowerCase())[0].id)
                }
            } else {
                props.getBlogs()
            }
        }

    }, [props.blogTags])

    return (
        <div className="blogsPage">
            {/* <div className="blogFirstSession">
                    <div className="latestMain col-md-8 col-sm-12">
                        <div className="latest col-sm-12">
                            <div className="badge">LATEST</div>
                            <div className="butt">Best Collagen Skin Products</div>
                        </div>
                    </div>
                    <div className="trendingAndEditor col-md-4 col-sm-12">
                        <div className="trending">
                            <div className="badge">TRENDING</div>
                            <div className="butt">Best Products for Rosacea</div>
                        </div>
                        <div className="editor">
                            <div className="badge">EDITORS PICK</div>
                            <div className="butt">Best Supplements for Skins</div>
                        </div>
                    </div>
                </div> */}

            <div className="topicsSession container">
                <h2>Topics</h2>
                <div className="topicBadges">
                    <button className={tag === "all" ? "active tagButt" : "tagButt"} onClick={() => {
                        props.history.push(`/blogs`)
                        props.getBlogs()
                        setTag("all")
                    }}>All</button>
                    {props.blogTags ?
                        props.blogTags.map((a, i) => {
                            return (
                                <button className={tag === a.name ? "active" : "tagButt"} onClick={() => {
                                    props.history.push(`/blogs?tag=${a.name}`)
                                    props.getBlogs(a.id)
                                    setTag(a.name)
                                }} key={i}>{a.name}</button>
                            )
                        })
                        :
                        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((a, i) => {
                            return (
                                <Skeleton className="tagButt" animation="wave" variant="rect" width={70} height={50} />
                            )
                        })
                    }
                </div>
            </div>

            <div className="blogPosting container">
                <div className="blogs col-md-12 col-sm-12">
                    {/* <h6>Beauty Hub</h6> */}
                    <div className="blogsPosts">
                        {props.blogs ?
                            props.blogs.map((a, i) => {
                                return (
                                    <div className="blog col-md-4 col-sm-12" key={i}>
                                        <div onClick={() => {
                                            props.history.push(`/blogs/${a.slug}`)
                                            props.getBlogSingle(false)
                                        }} className="blogImage" style={{ backgroundImage: `url(${a.image})` }}>
                                            <div className="tag">{a.blog_tag_detail && a.blog_tag_detail.name}</div>
                                        </div>
                                        <div className="blogContent">
                                            <p className="title" onClick={() => {
                                                props.history.push(`/blogs/${a.slug}`)
                                                props.getBlogSingle(false)
                                            }}>{a.title.length > 47 ? `${a.title.slice(0, 47)}...` : a.title}</p>
                                            <p className="by">{a.author}</p>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            [1, 2, 3, 4, 5, 6].map((a, i) => {
                                return (
                                    <div className="blog col-md-4 col-sm-12" key={i} style={{ height: 297 }}>
                                        <Skeleton animation="wave" variant="rect" width={"100%"} height={220} />
                                        <Skeleton style={{ marginTop: 10 }} animation="wave" variant="rect" width={"100%"} height={20} />
                                        <Skeleton style={{ margin: "auto", marginTop: 10 }} animation="wave" variant="rect" width={"30%"} height={20} />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <Pagination
                        style={{ float: "right" }}
                        showQuickJumper={false}
                        href="#pagi"
                        responsive
                        onChange={(ev) => paginate(ev)}
                        showQuickJumper
                        pageSize={14}
                        current={current}
                        total={props.count}
                    />
                </div>
                {/* <div className="blogsRelated col-md-4 col-sm-12">
                        <h6>Top Posts</h6>
                        <div className="relatedMain">
                            {relatedPosts.map((a, i) => {
                                return (
                                    <div key={i} className="relatedBlog">
                                        <div className="relatedImage col-md-4" style={{ backgroundImage: `url(${a.img})` }}></div>
                                        <div className="relatedContent col-md-8"><p>{a.title}</p></div>
                                    </div>
                                )
                            })}
                        </div>
                        <h6 className="beside">Keep In Touch</h6>
                        <div className="socioIcons">
                            <i class="far fa-envelope"></i>
                            <i class="fab fa-instagram"></i>
                            <i class="fab fa-facebook-f"></i>
                            <i class="fab fa-youtube"></i>
                            <i class="fab fa-pinterest-p"></i>
                            <i class="fab fa-tiktok"></i>
                            <i class="fab fa-twitter"></i>
                        </div>
                    </div> */}
            </div>
        </div>
    )
}

const mapStateToProps = ({ store }) => {
    return {
        blogTags: store.blogTags,
        blogs: store.blogs,
        count: store.count
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBlogs,
            getBlogTags,
            getBlogSingle: (id) => getBlogSingle(id)
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BlogsPage))