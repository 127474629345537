import React from 'react'
import oily from '../../../Images/oily.png'
import dry from '../../../Images/dry.png'
import combination from '../../../Images/combination.png'
import normal from '../../../Images/normal.png'
import low from '../../../Images/low.png'
import high from '../../../Images/high.png'
import moderate from '../../../Images/moderate.png'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { getProfileData } from '../../../modules/actions';
import SummaryPage from '../../SummaryPage/SummaryPage'

class ProfileDiv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getProfileData()
    }

    render() {
        return (
            <div className="skinCaree tab-pane fade show active container" id="home" role="tabpanel" aria-labelledby="description">
                <div className="row mb-mobile">
                    <div className="col-md-12 text-center">
                        <center> <button className="btn btn-theme mt-2 mb-2"><a href="/quiz"
                            style={{ color: 'white', textDecoration: 'none' }}>Retake Quiz</a></button>
                        </center>
                    </div>
                </div>

                <div className="row allProducts">
                    <div className="col-md-12 text-center">
                        <h2 style={{ fontSize: '50px !important', textTransform: 'capitalize' }}>{this.props.user.firstname}  {this.props.user.lastname}'s Beauty profile</h2>
                    </div>
                </div>
                <SummaryPage />


                {/* <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="card card-profile">
                            <div className="card-body">
                                <h3 className="profile-title">Your Skin Type</h3> <br />
                                {this.props.user.skin_type == 'dry' &&
                                    <img src={dry} className="img img-skin" />
                                }
                                {this.props.user.skin_type == 'oily' &&
                                    <img src={oily} className="img img-skin" />
                                }
                                {this.props.user.skin_type == 'combination' &&
                                    <img src={combination} className="img img-skin" />
                                }
                                {this.props.user.skin_type == 'normal' &&
                                    <img src={normal} className="img img-skin" />
                                }
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="card card-profile">
                            <div className="card-body">
                                <h3 className="profile-title">Your Age</h3> <br />
                                <b className="age">{this.props.user.age}</b>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="card card-profile">
                            <div className="card-body">
                                <h3 className="profile-title">Do you have sensitive skin</h3> <br />
                                <div className="row">
                                    <div className="col-md-6 col-12 text-right" id="col-special">
                                        <div>
                                            {this.props.user.isSensitive ?
                                                <input checked disabled id="radio-1" className="radio-custom" name="radio-group"
                                                    type="radio" />
                                                :
                                                <input disabled id="radio-1" className="radio-custom" name="radio-group"
                                                    type="radio" />
                                            }
                                            <label for="radio-1" className="radio-custom-label">Yes</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-12 col-special text-left" id="col-special">
                                        <div>
                                            {!this.props.user.isSensitive ?
                                                <input checked disabled id="radio-1" className="radio-custom" name="radio-group"
                                                    type="radio" />
                                                :
                                                <input disabled id="radio-1" className="radio-custom" name="radio-group"
                                                    type="radio" />
                                            }
                                            <label for="radio-1" className="radio-custom-label">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-profile">
                            <div className="card-body">
                                <h3 className="profile-title text-center">Your Skin Concerns</h3> <br />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-check-inline">
                                            <label className="customradio"><span
                                                className="radiotextsty">acne</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('acne')} value="acne" type="radio" name="radio1" disabled
                                                    id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span
                                                className="radiotextsty">wrinkels</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('wrinkles')} value="wrinkles" type="radio" name="radio2" disabled
                                                    id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span className="radiotextsty">dark
                                                circles</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('dark circles')} value="dark circles" type="radio" name="radio3"
                                                    disabled id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span
                                                className="radiotextsty">Breakouts</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('breakouts')} value="Breakouts" type="radio" name="radio4" disabled
                                                    id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check-inline">
                                            <label className="customradio"><span
                                                className="radiotextsty">pores</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('pores')} value="pores" type="radio" name="radio5" disabled
                                                    id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span
                                                className="radiotextsty">oilness</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('oilness')} value="oilness" type="radio" name="radio6" disabled
                                                    id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span
                                                className="radiotextsty">hyperpigmentation</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('hyperpigmentation')} value="HYPERPIGMENTATION" type="radio" name="radio7"
                                                    disabled id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span className="radiotextsty">dark
                                                spots</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('dark spots')} value="dark spots" type="radio" name="radio8"
                                                    disabled id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check-inline">
                                            <label className="customradio"><span
                                                className="radiotextsty">dehydrate</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('dehydrate')} value="dehydrate" type="radio" name="radio9" disabled
                                                    id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span
                                                className="radiotextsty">pollution</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('pollution')} value="pollution" type="radio" name="radio10"
                                                    disabled id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span className="radiotextsty">sun
                                                damaged</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('sun damage')} value="sun damage" type="radio" name="radio11"
                                                    disabled id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>

                                        <br />

                                        <div className="form-check-inline">
                                            <label className="customradio"><span className="radiotextsty">uneven
                                                skintone</span>
                                                <input checked={this.props.user.SkinConcerns && this.props.user.SkinConcerns.toLowerCase().match('uneven skintone')} value="uneven skintone" type="radio" name="radio12"
                                                    disabled id="skinConcerns" />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="card card-profile">
                            <div className="card-body">
                                <h3 className="profile-title">Polution Range</h3> <br />
                                {this.props.user.pollution === 'Moderate' &&
                                    <img src={high} className="img img-skin" />
                                }
                                {this.props.user.pollution === 'High' &&
                                    <img src={moderate} className="img img-skin" />
                                }
                                {this.props.user.pollution === 'Low' &&
                                    <img src={low} className="img img-skin" />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="card card-profile">
                            <div className="card-body">
                                <h3 className="profile-title">Direct sun Exposure</h3> <br />
                                <div className="row">
                                    <div className="col-md-6 col-12 text-right" id="col-special">
                                        <div>
                                            {this.props.user.isSunExposed ?
                                                <input checked disabled id="radio-3" className="radio-custom" name="radio-group2"
                                                    type="radio" />
                                                :
                                                <input disabled id="radio-3" className="radio-custom" name="radio-group2"
                                                    type="radio" />
                                            }
                                            <label for="radio-3" className="radio-custom-label">Yes</label>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-12 col-special text-left" id="col-special">
                                        <div>
                                            {!this.props.user.isSunExposed ?
                                                <input checked disabled id="radio-4" className="radio-custom" name="radio-group2"
                                                    type="radio" />
                                                :
                                                <input disabled id="radio-4" className="radio-custom" name="radio-group2"
                                                    type="radio" />
                                            }
                                            <label for="radio-4" className="radio-custom-label">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className="card card-profile">
                            <div className="card-body">
                                <h3 className="profile-title">No. of hours you sleep</h3> <br />
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <b className="sleep-hours">{this.props.user.sleepHrs}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-12">
                        <center> <button className="quiz"><NavLink to="/suggestions"
                            style={{ color: 'white', textDecoration: 'none' }}>Recommendations</NavLink></button>
                        </center>
                    </div>
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    user: store.user,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfileData
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileDiv)