import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCookie } from '../../modules/actions';
import './checkout.scss'
import Axios from 'axios'
import { loadStripe } from '@stripe/stripe-js';

class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            address: "",
            city: "",
            state: "",
            zipcode: "",
            phone: "",
            discount: 0
        }
    }

    gettingValues = (ev, name) => {
        this.setState({
            [name]: ev.target.value
        })
    }

    validateAddress = () => {
        var value = this.state.address;

        if (value.length > 1000) {
            document.getElementById('checkoutError').innerText = "Address should be less than 1000 characters";
            return false;
        }
        if (value.length < 10) {
            document.getElementById('checkoutError').innerText = "Address should be greater than 10 characters";
            return false;
        }
        return true;
    }
    validateCity = () => {
        var value = this.state.city;

        if (value.length > 50) {
            document.getElementById('checkoutError').innerText = "City should be less than 50 characters";
            return false;
        }
        return true;
    }
    validateState = () => {
        var value = this.state.state;

        if (value.length > 50) {
            document.getElementById('checkoutError').innerText = "State should be less than 50 characters";
            return false;
        }
        return true;
    }
    validateZip = () => {
        var value = this.state.zipcode;

        if (value.length > 50) {
            document.getElementById('checkoutError').innerText = "Zipcode should be less than 50 characters";
            return false;
        }
        return true;
    }

    validateCheckout = () => {
        if (this.validateAddress() &&
            this.validateCity() &&
            this.validateState() &&
            this.validateZip()) {
            document.getElementById('checkoutError').innerText = "";
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("name", this.state.name);
            data.append("address", this.state.address);
            data.append("state", this.state.state);
            data.append("city", this.state.city);
            data.append("zipcode", this.state.zipcode);
            data.append("phone", this.state.phone);
            data.append("coupon", this.state.promoSubscribed ? this.state.coupon : "");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/stripe/checkout/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    let key = response.data.publish_key
                    let sessionId = response.data.sessionid
                    console.log(sessionId)
                    const stripePromise = loadStripe("pk_test_51HMu91LdkhCU3QNbUyj9J76dOmcOlB73XG43CUcrN93y4jAo28ihayQTAVsQHrSdVHpyRkfLysJwnpuR5lEO2QYp009wRsXo4o");
                    stripePromise.then((res) => {
                        res.redirectToCheckout({
                            sessionId: sessionId,
                        });
                    })
                })
                .catch(error => {
                    console.log(error)
                });
        }
        return false;
    }

    applyCoupen = (price) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("coupon", this.state.coupon);
        data.append("price", price);
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/check_coupon/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.discounted_price) {
                    this.setState({
                        discount: price - response.data.discounted_price,
                        promoSubscribed: true
                    })
                } else {
                    this.setState({
                        discount: 0,
                        promoSubscribed: true
                    })
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    componentDidMount() {
    }


    render() {
        let total = 0
        console.log(this.props)
        document.title = "Checkout"
        return (
            <div className="checkout-page container">

                <div className="checkout-con container">
                    <h2>Checkout</h2>
                    <div className="check-div">
                        <div className="bill-address">
                            <h6>BILLING ADDRESS</h6>

                            <div>
                                <label for="">
                                    <p> Name *</p>
                                </label> <input onChange={(ev) => this.gettingValues(ev, "name")} className="form-control" name="name" placeholder="Name" type="text"
                                    required />

                            </div>

                            <div>
                                <label for="">
                                    <p> Address *</p>
                                </label> <input onChange={(ev) => this.gettingValues(ev, "address")} className="form-control" name="address" placeholder="Address" type="text"
                                    required />

                            </div>
                            <div>
                                <label for="">
                                    <p> City *</p>
                                </label><input onChange={(ev) => this.gettingValues(ev, "city")} className="form-control" name="city" placeholder="City" type="text" required />

                            </div>
                            <div>
                                <label for="">
                                    <p> State *</p>
                                </label><input onChange={(ev) => this.gettingValues(ev, "state")} className="form-control" name="state" placeholder="State" type="text" required />

                            </div>
                            <div>
                                <label for="">
                                    <p> Zip Code *</p>
                                </label> <input onChange={(ev) => this.gettingValues(ev, "zipcode")} className="form-control" name="zipcode" placeholder="Zipcode" type="text"
                                    required />

                            </div>


                            <div>
                                <label for="">
                                    <p> Phone *</p>
                                </label><input onChange={(ev) => this.gettingValues(ev, "phone")} className="form-control" name="phone" placeholder="Phone" type="number" required />

                            </div>
                            <p className="error text-center" id="checkoutError"></p>
                        </div>
                        <div className="pro-order">
                            <div class="code">
                                <h6>PROMOTIONAL CODE</h6>
                                <div class="couponCodeMain">
                                    <input type="text" placeholder="Coupon code" onChange={(ev) => this.gettingValues(ev, "coupon")} />
                                    <div class="couponCodeButton">
                                        <button onClick={() => this.applyCoupen(total)}>Subscirbe</button>
                                    </div>
                                </div>
                            </div>
                            <div className="yourOrder">
                                <h6>YOUR ORDER</h6>
                                <div className="total">
                                    <h6>PRODUCT <span>TOTAL</span></h6>
                                    {this.props.cartOrders.map((a, i) => {
                                        if (a.product_details[0] && a.product_details[0].stock_quantity !== 0) {
                                            total += Number(a.total_price)
                                        }
                                        return (
                                            a.product_details[0] && a.product_details[0].stock_quantity !== 0 &&
                                            <p key={i}>
                                                <span className="checkoutOrderP">
                                                    {a.product_details[0] && a.product_details[0].name} x{a.quantity}
                                                </span>
                                                <span>
                                                    <i className="fas fa-rupee-sign"></i>{a.total_price}
                                                </span>
                                            </p>
                                        )
                                    })}
                                    <p>Shipping Price <span><i className="fas fa-rupee-sign"></i>{this.props.shippingPrice}.00</span></p>
                                    <p>ORDER TOTAL <span><i className="fas fa-rupee-sign"></i>{total + this.props.shippingPrice}.00</span></p>
                                    {this.state.promoSubscribed &&
                                        <p>Discount <span style={{ color: "red" }}><i className="fas fa-rupee-sign two"></i>{this.state.discount}.00</span></p>
                                    }
                                    <p>Grand Total <span><i className="fas fa-rupee-sign"></i>{(total + this.props.shippingPrice) - this.state.discount}.00</span></p>
                                    <div className="button">
                                        <button id="checkoutt" className="placeorder-btn" onClick={this.validateCheckout}>PLACE ORDER</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div >
        )
    }
}

const mapStateToProps = ({ store }) => ({
    cartOrders: store.cartOrders,
    shippingPrice: store.shippingPrice,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Checkout)