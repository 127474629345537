import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { getBrandProducts, getBrands } from '../../modules/actions'
import './brands.scss'
import ReactGA from 'react-ga'


function Brands(props) {
    let arr = ["0-9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", 'N', "O", 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    let [Active, setActive] = useState("0-9")
    let [show, setshowBtn] = useState(false)
    let [inputVal, setInputVal] = useState("")
    let [filteredBrands, setFilteredBrands] = useState([])

    const gotoTop = () => {
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', (ev) => {
            if (window.pageYOffset > 100) {
                setshowBtn(true)
            } else {
                setshowBtn(false)
            }
        })
        props.getBrands()
    }, [])

    useEffect(() => {
        if (props.brands2) {
            let arrr = inputVal ? props.brands2.filter((a) => a.name.toLowerCase().match(inputVal.toLowerCase())) : []
            setFilteredBrands(arrr)
        }
    }, [inputVal])

    return (
        <div className="col-11 mt-5 mr-auto ml-auto brands brandsPage">
            { show &&
                <button onClick={gotoTop} className="gotoTopBtn">
                    <i class="fas fa-arrow-up"></i>
                </button>}
            <h2 >All Brands</h2>
            <div className="mt-5 mb-5">
                <div className="row col-12 p-0 brt1s bg-white m-0">
                    <div className="col-12 col-md-3 brr1s">
                        <input value={inputVal} onChange={(e) => setInputVal(e.target.value)} className="searchbrandsinput" placeholder="Search Brands" />
                        {filteredBrands.length && inputVal ?
                            <div className="brandsSearchDropDown">
                                {filteredBrands.map((a, i) => {
                                    return (
                                        window.screen.width < 600 ?
                                            <a key={i} href={`/brands/${a.name}-${a.id}`}>{a.name}</a>
                                            :
                                            <NavLink key={i} to={`/brands/${a.name}-${a.id}`}>{a.name}</NavLink>
                                    )
                                })}
                            </div>
                            :
                            !filteredBrands.length && inputVal ?
                                <div className="brandsSearchDropDown">
                                    <p>No matching results</p>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="col-12 col-md-9">
                        {arr.map((value, i) => (
                            <a key={i} onClick={() => {
                                setActive(value)
                            }} className={Active === value ? "itemBtn itemBtnActive" : "itemBtn"} key={i} onClick={() => {
                                var ele = document.getElementById(value);
                                window.scrollTo(ele.offsetTop, ele.offsetTop + 250)
                            }}>{value}</a>
                        ))}
                    </div>
                </div>
            </div>
            <div className="col-12 p-0 mt-5 mb-5">
                {arr.map((value, i) => (
                    <div id={value} key={i} className="brt1s bg-white all-brnads-pg">
                        <div className="alphaCar">{value}</div>
                        <ul className="row col-12 brandscategory">
                            {props.brands2 &&
                                props.brands2.filter(obj => obj.name[0].toLocaleUpperCase() === value.toLocaleUpperCase()) &&
                                props.brands2.filter(obj => obj.name[0].toLocaleUpperCase() === value.toLocaleUpperCase()).map((a) => (
                                    <li onClick={() => {
                                        props.history.push(`/brands/${a.name}-${a.id}`)
                                    }}>{a.name}</li>
                                ))}
                        </ul>
                    </div>
                ))}
            </div>

        </div>
    )
}
const mapStateToProps = ({ store }) => ({
    brands2: store.brands2,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBrands,
            getBrandProducts
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Brands))