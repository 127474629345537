import React from 'react'

import part1 from '../../../Images/about/part1.png'
import part2 from '../../../Images/about/part2.png'
import part3 from '../../../Images/about/part3.png'
import arrowab from '../../../Images/about/arrowab.png'

class SecondSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="container curate-con mt-5">
                <div className="row justify-content-center">
                    <div className="col-sm-auto">
                        <div className="card text-center" id="abcard">
                            <img className="card-img-top" src={part1} />
                            <div className="card-body padd-0"><br />
                                <h3 id="about-us" className="process-text">Personalize</h3><br />
                                <p className="text-inf">Take our Glam quiz and create a beauty profile.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-auto arrow-about">
                        <img className="card-img-top" id="card-img-arrow" src={arrowab} />
                    </div>


                    <div className="col-sm-auto">
                        <div className="card text-center" id="abcard">
                            <img className="card-img-top" src={part2} style={{paddingTop: 50}} />
                            <div className="card-body padd-0"><br /><br />
                                <h3 className="process-text">Analyze and Recommend Product</h3>
                                <p className="text-inf">Our AI-powered algorithm will analyze your profile and recommend beauty products.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-auto arrow-about">
                        <img className="card-img-top" id="card-img-arrow" src={arrowab} />
                    </div>

                    <div className="col-sm-auto">
                        <div className="card text-center" id="abcard">
                            <img className="card-img-top" src={part3} style={{paddingTop: 50, width: '100%'}} />
                            <div className="card-body padd-0"><br />
                                <h3 className="process-text">Compare and Buy</h3><br />
                                <p className="text-inf">Compare the prices from different retailers and buy at the best price.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SecondSection