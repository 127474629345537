import React from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga'

class SeventhSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownToggle: false
        }
    }
    render() {
        document.addEventListener('click', (e) => {
            if (document.getElementsByClassName('newDropDownListTwo')[0] && document.getElementsByClassName('newDropDownListTwo')[0].style.zIndex !== '-1') {
                if (e.target.id !== "newDropDownList-menu") {
                    this.setState({
                        dropdownToggle: false
                    })
                }
            }
        })
        return (
            <div>
                <div className="banner1 one container-fluid">
                </div>
                <div className="banner1 two container-fluid">
                    <div className="banner1-text">
                        <h5>Your endless search for the right beauty products is now a thing of the past!!</h5>
                        <p>Join thousands of happy woman who found right products at best possible price.</p>
                        <div className="skinQuizBut">
                            <div>

                                {this.props.is_authenticated ?
                                    window.screen.width < 600 ?
                                        <a className="startQuiz" id="newDropDownList-menu" onClick={() => {
                                            this.setState({
                                                dropdownToggle: !this.state.dropdownToggle
                                            })
                                        }}>
                                            <button className="quiz" id="newDropDownList-menu">
                                                Recommendations
                                            </button>
                                        </a>
                                        :
                                        <NavLink className="startQuiz" id="newDropDownList-menu" onClick={() => {
                                            this.setState({
                                                dropdownToggle: !this.state.dropdownToggle
                                            })
                                        }}>
                                            <button className="quiz" id="newDropDownList-menu">
                                                Recommendations
                                        </button>
                                        </NavLink>
                                    :
                                    <a className="startQuiz" href="/quiz">
                                        <button onClick={() => ReactGA.event({ category: "Button Click", action: "Started Quiz" })} className="quiz">
                                            Start Glam Quiz
                                     </button>
                                    </a>
                                }
                                <div id="newDropDownList-menu" style={{
                                    opacity: this.state.dropdownToggle ? 1 : 0,
                                    zIndex: this.state.dropdownToggle ? 999 : -1
                                }} class="newDropDownList newDropDownListTwo">
                                    <div id="newDropDownList-menu">
                                        {window.screen.width < 600 ?
                                            <a id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} href={`/suggestions?Skin care`}>Skin</a>
                                            :
                                            <NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Skin care`}>Skin</NavLink>
                                        }
                                    </div>
                                    <div id="newDropDownList-menu">
                                        {window.screen.width < 600 ?
                                            <a id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} href={`/suggestions?Body care`}>Bath & Body</a>
                                            :
                                            <NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Body care`}>Bath & Body</NavLink>
                                        }
                                    </div>
                                    <div id="newDropDownList-menu">
                                        {window.screen.width < 600 ?
                                            <a id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} href={`/suggestions?Hair care`}>Hair</a>
                                            :
                                            <NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Hair care`}>Hair</NavLink>
                                        }
                                    </div>
                                </div>
                            </div>
                            {window.screen.width < 600 ?
                                <a className="startQuiz"href="/skin-care">
                                    <button className="quiz"
                                        style={{ backgroundColor: '#19354b', width: '148px', height: '34px', display: 'flex', justifyContent: 'center' }}>Shop
                Now</button>
                                </a>
                                :
                                <NavLink className="startQuiz" to="/skin-care">
                                    <button className="quiz"
                                        style={{ backgroundColor: '#19354b', width: '148px', height: '34px', display: 'flex', justifyContent: 'center' }}>Shop
                        Now</button>
                                </NavLink>
                            }
                        </div>
                    </div>
                </div>
                {/* <div className="banner1 two container-fluid">
                    <div className="banner1-text">
                        <h5>Your endless search for the right beauty products is now a thing of the past!!</h5>
                        <p>Join thousands of happy woman who found right products at best possible price.</p>
                        <div className="skinQuizBut">
                            <div>

                                {this.props.is_authenticated ?
                                    <NavLink className="startQuiz" id="newDropDownList-menu" onClick={() => {
                                        this.setState({
                                            dropdownToggle: !this.state.dropdownToggle
                                        })
                                    }}>
                                        <button className="quiz" id="newDropDownList-menu">
                                            Recommendations
                                        </button>
                                    </NavLink>
                                    :
                                    <a className="startQuiz" href="/quiz">
                                        <button className="quiz">
                                            Start Glam Quiz
                                     </button>
                                    </a>
                                }
                                <div id="newDropDownList-menu" style={{
                                    opacity: this.state.dropdownToggle ? 1 : 0,
                                    zIndex: this.state.dropdownToggle ? 999 : -1
                                }} class="newDropDownList newDropDownListTwo">
                                    <div id="newDropDownList-menu"><NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Skin care`}>Skin</NavLink></div>
                                    <div id="newDropDownList-menu"><NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Body care`}>Bath & Body</NavLink></div>
                                    <div id="newDropDownList-menu"><NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Hair care`}>Hair</NavLink></div>
                                </div>
                            </div>
                            <NavLink className="startQuiz" to="/skin-care">
                                <button className="quiz"
                                    style={{ backgroundColor: '#19354b', width: '148px', height: '34px', display: 'flex', justifyContent: 'center' }}>Shop
                        Now</button>
                            </NavLink>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    is_authenticated: store.is_authenticated
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SeventhSection)