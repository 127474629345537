import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Waypoint } from 'react-waypoint';
import CircularProgress from '../home/Components/ProgressBar'
import { getCartOrders, getCookie, getWishlist } from '../../modules/actions';
import Axios from 'axios'
import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser'
import RedirectPage from './RedirectPage';
import NoAvaliableImage from '../../Images/NoAvaliable.jpeg'
import one from '../../Images/1.jpg'
import two from '../../Images/2.jpg'
import three from '../../Images/3.jpg'
import ReactImageMagnify from 'react-image-magnify';
const parseHtml = htmlParser({
    isValidNode: node => node.type !== 'script',
    processingInstructions: [/* ... */]
})

let percentage = 0

function percentageCalculate(percentagee) {
    setInterval(() => {
        if (percentage < percentagee) {
            // console.log(percentage)
            percentage = percentage + 1
            document.getElementById('percentText').innerHTML = `${percentage}%`
        }
    }, 10);
}

class ProductDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            percentage: 0,
            product: false,
            quantity: 1
        }
    }

    animateProgress(scores) {
        // console.log(scores)
        let scoresArr = []
        for (let key in scores) {
            let obj = {
                lable: key,
                score: scores[key]
            }
            scoresArr.push(obj)
        }
        console.log(scoresArr, scores)
        this.setState({
            scores: scoresArr
        }, () => {
            setTimeout(() => {
                this.state.scores.map((a, i) => {
                    document.getElementById(`progress${i}`).style.width = `${a.score}%`
                })
            }, 500)
        })
    }

    componentDidMount() {
        this.props.getWishlist()
        setTimeout(() => {
            if (document.getElementById("carouselExampleSlidesOnlypNext")) {
                document.getElementById("carouselExampleSlidesOnlypNext").click()
            }
        }, 2000)

        let slug = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
        let score = Number(window.location.search.split('=')[1])
        const data = new FormData();
        data.append("slug", slug);
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/details/',
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                let data = response.data.product
                this.checkWhishlist(data, "a")
                this.setState({
                    product: data
                }, () => {
                    document.title = this.state.product.name
                    if (score) {
                        setTimeout(() => {
                            this.animateProgress(this.state.product.scores)
                            this.setMatch(score)
                        }, 1000)
                    }
                })
                return data
            })
            .catch(error => {
                console.log(error)
            });
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    setMatch = (a) => {
        let percentagee = a

        setTimeout(() => {
            percentageCalculate(percentagee)
            this.setState({
                percentage: percentagee
            })
        });
    }

    addToCart = (id, out) => {
        if (!out) {
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("id", id);
            data.append("action", "add");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (response.data.code === "token_not_valid") {
                        let msg = "You have login first"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        let data = response.data.products
                        // this.props.getCartOrders(data);
                        let msg = response.data.message
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        return data
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        } else {
            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>Out of stock</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
            }, 2000)
        }
    }

    addToWhishlist = (id) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("product_id", id);
        // data.append("action", "add");
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/wishlist/update/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.code === "token_not_valid") {
                    // let msg = "You have login first"
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    window.location.href = '/login'
                } else {
                    let data = response.data.products
                    // let msg = response.data.message
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    this.props.getWishlist()
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    return data
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    quantityMinus = () => {
        if (this.state.quantity > 1) {
            let quantity = this.state.quantity - 1
            this.setState({
                quantity,
            })
        }
    }

    quantityPlus = () => {
        let quantity = this.state.quantity + 1
        this.setState({
            quantity,
        })
    }

    checkWhishlist = (a, abc) => {
        let whishlistProducts = this.props.whishlistProducts
        if (whishlistProducts) {
            let check = whishlistProducts.filter(b => b.id === a.id)
            if (check.length) {
                return (
                    <svg onClick={() => this.addToWhishlist(this.state.product.id)} className="bi bi-heart-fill detailWhishlistIcon" width="1em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                    </svg>
                )
            } else {
                return (
                    <svg onClick={() => this.addToWhishlist(this.state.product.id)} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart detailWhishlistIcon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                    </svg>
                )
            }
        } else {
            return (
                <svg onClick={() => this.addToWhishlist(this.state.product.id)} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart detailWhishlistIcon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                </svg>
            )
        }
    }

    discoutGet = (a, b) => {
        let price = a
        let discounted_price = b
        let discounted_given = Number(price) - Number(discounted_price)
        return `${Number(Math.ceil((discounted_given / price) * 100))}% Off`
    }


    redirecTo = (link, obj) => {
        localStorage.setItem('redirect', true)
        // this.setState({
        //     redirectPage: true
        // })
        let data = new FormData()
        data.append('link_id', obj.id)
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/link/click/',
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                // console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            });

        // window.open("http://192.168.1.104:3000" + `/redirect?${link}`)
        window.open(process.env.REACT_APP_FRONTEND_URL + `/redirect?${link}`)
        // window.open(`http://192.168.1.110:3000/redirect?${link}`)
        // window.open(`http://localhost:3000/redirect?${link}`)
    }

    render() {
        return (

            this.state.product &&
            <div className="profilee">
                {this.state.redirectPage && <RedirectPage />}
                <div className="checkout-page container">
                    <div className="alert alert-success alert-dismissable" style={{ display: 'none' }}>
                        <strong className="alert-text"></strong>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="preview col-lg-4 productDetailWeb">
                                            <div className="preview-pic tab-content">
                                                <div className="tab-pane active" id="pic-00">
                                                    <ReactImageMagnify {...{
                                                        smallImage: {
                                                            alt: 'Wristwatch by Ted Baker London',
                                                            isFluidWidth: true,
                                                            src: this.state.product.image !== null ? this.state.product.image : NoAvaliableImage,
                                                        },
                                                        largeImage: {
                                                            src: this.state.product.image !== null ? this.state.product.image : NoAvaliableImage,
                                                            width: 1200,
                                                            height: 1800
                                                        },
                                                        enlargedImagePortalId: "magic-zoom",
                                                        enlargedImageContainerDimensions: {
                                                            width: '240%',
                                                            height: '140%'
                                                        }
                                                    }} />
                                                    <div id="magic-zoom-text">Roll over image to zoom in</div>
                                                </div>
                                                {this.state.product && this.state.product.secondary_images && this.state.product.secondary_images.map((a, i) => {
                                                    return (
                                                        <div key={i} className="tab-pane" id={`pic-0${i + 1}`}><img src={a} /></div>
                                                    )
                                                })}
                                                <ul className="preview-thumbnail nav nav-tabs">
                                                    <li className="active">
                                                        <a data-target="#pic-00" data-toggle="tab"><img src={this.state.product.image !== null ? this.state.product.image : NoAvaliableImage} /></a>
                                                    </li>
                                                    {this.state.product && this.state.product.secondary_images.map((a, i) => {
                                                        return (
                                                            <li key={i}>
                                                                <a data-target={`#pic-0${i + 1}`} data-toggle="tab"><img src={a} /></a>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>

                                        {this.state.product &&
                                            <div className="preview col-lg-4 productDetailMob">
                                                <div id="carouselExampleSlidesOnlyp" className="carousel slide" data-ride="carousel" data-type="multi">
                                                    <ol class="carousel-indicators">
                                                        {this.state.product && [this.state.product.image, ...this.state.product.secondary_images].length > 1 && [this.state.product.image, ...this.state.product.secondary_images].map((a, i) => {
                                                            return (
                                                                <li id={i === 1 && "carouselExampleSlidesOnlypNext"} key={i} data-target="#carouselExampleSlidesOnlyp" data-slide-to={i} class={i === 0 && "active"}></li>
                                                            )
                                                        })}
                                                    </ol>
                                                    <div className="carousel-inner">
                                                        {this.state.product && [this.state.product.image, ...this.state.product.secondary_images].map((a, i) => {
                                                            return (
                                                                <div className={`carousel-item${i === 0 ? " active" : ""}`}>
                                                                    <img className="" src={a} />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            // <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                                            //     <div className="carousel-inner">
                                            //         <div className="carousel-item active">
                                            //             <img className="d-block w-100" src={one} alt="First slide" />
                                            //         </div>
                                            //         <div className="carousel-item">
                                            //             <img className="d-block w-100" src={two} alt="Second slide" />
                                            //         </div>
                                            //         <div className="carousel-item">
                                            //             <img className="d-block w-100" src={three} alt="Third slide" />
                                            //         </div>
                                            //     </div>
                                            // </div>
                                        }

                                        <div className="col-lg-8 seconddd" id="topp">
                                            <div id="magic-zoom" />
                                            <div className="row b-top">
                                                <div className="col-lg-9 max-width sm10">
                                                    <h4 className="product-title">{this.state.product.name}</h4>
                                                    <p style={{ margin: 0 }}>{this.state.product.category} by {this.state.product.brand}</p>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <h5 className="fs mt-2 price">
                                                                {/* <span className="colord"><i className="fas fa-rupee-sign"></i> {this.state.product.price}.00</span> */}
                                                                {/* {this.state.product.discounted_price && this.state.product.discounted_price !== this.state.product.price ?
                                                                    <>
                                                                        {this.state.product.discounted_price && <span className="lightGray"><span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign three" aria-hidden="true"></i>{this.state.product.price}.00 </span></span>}
                                                                        <span className="colord">&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{this.state.product.discounted_price}.00 {this.state.product.off && <span className="lightGray two">|</span>}</span>
                                                                        {this.state.product.discounted_price && <span className="pinkk">{this.discoutGet(this.state.product.price, this.state.product.discounted_price)}</span>}
                                                                    </>
                                                                    :
                                                                    <span className="colord"><i className="fas fa-rupee-sign" aria-hidden="true"></i>{this.state.product.price}.00</span>
                                                                } */}
                                                                {/* <span className="colord"><i className="fas fa-rupee-sign" aria-hidden="true"></i>{this.state.product.product_affiliates.length && this.state.product.product_affiliates.sort((a, b) => Number(a.calculated_price) - Number(b.calculated_price))[0].calculated_price}.00</span> */}
                                                                {this.state.product.product_affiliates && this.state.product.product_affiliates.length && this.state.product.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < this.state.product.price ?
                                                                    <>
                                                                        <span className="lightGray">
                                                                            MRP:&nbsp;&nbsp;
                                                                            <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{this.state.product.price}</span></span>
                                                                        <span>
                                                                            <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                            {this.state.product.product_affiliates && this.state.product.product_affiliates.length && Number(this.state.product.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                        </span>
                                                                        <span className="lightGray seperatorrr"> |
                                                                        </span>
                                                                        <span className="pinkk">{this.discoutGet(this.state.product.price, this.state.product.product_affiliates && this.state.product.product_affiliates.length && this.state.product.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                    </>
                                                                    :
                                                                    <span>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {this.state.product.price}</span>
                                                                }
                                                            </h5>
                                                            {/* <b>inclusive of all taxes</b> */}
                                                            {/* <div>
                                                                Qty
                                                                <span>
                                                                    <button onClick={this.quantityMinus}>
                                                                        -
                                                                    </button>
                                                                    <input disabled value={this.state.quantity} />
                                                                    <button onClick={this.quantityPlus}>
                                                                        +
                                                                    </button>
                                                                </span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 max-width text-right mb-mt-3 ico">

                                                    {this.checkWhishlist(this.state.product, "a")}
                                                    {/* <button onClick={() => this.state.product.stock_quantity !== 0 ? this.addToCart(this.state.product.id) : this.addToCart(this.state.product.id, 'out')} data-productid="{{product.id}}"
                                                        data-action="add" className="btn btn-cart update-cart">Add to cart</button> */}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="scoreDiv col-lg-6">
                                                    {window.location.search &&
                                                        <>
                                                            {/* <div className="col-lg-6 widht40 mt-5"> */}
                                                            <div className="match col-lg-12">
                                                                <div className="upperProgress">
                                                                    <CircularProgress percentage={this.state.percentage} />
                                                                    <div className="progressText">
                                                                        <b id="percentText">{percentage}%</b>
                                                                        <small>matching score</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* </div> */}
                                                            <div id="progressDivv" className="col-lg-12 widht60 row">
                                                                {this.state.scores && this.state.scores.length ?
                                                                    <h3 className="text-center">Why this product works for me?</h3>
                                                                    : null}
                                                                <div className="progres-div" style={{ width: '100%' }}>
                                                                    {this.state.scores && this.state.scores.map((a, i) => {
                                                                        return (
                                                                            <div key={i}>
                                                                                <div className="d-flex progres-div-div">
                                                                                    <label>{a.lable}</label>
                                                                                    <p>{a.score}%</p>
                                                                                </div>
                                                                                <div className="progress">
                                                                                    <div id={`progress${i}`} class={`progress-bar wow p${i + 1} ${a.score}`} role="progressbar"
                                                                                        aria-valuenow={`${a.score}`} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {!this.props.is_authenticated &&
                                                        <div style={{marginTop: 20}}>
                                                            <p className="QuizBtnText">Take our quiz to find your product match</p>
                                                            <button onClick={() => window.location.href = '/quiz'} className="quickViewQuizButton">Start Glam Quiz </button>
                                                        </div>}
                                                </div>
                                                <div className="afiliateMarketList col-lg-6">
                                                    {/* <table>
                                                        <tbody>
                                                            {this.state.product.product_affiliates.length ?
                                                                this.state.product.product_affiliates.sort((a, b) => Number(a.calculated_price) - Number(b.calculated_price)).map((a, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <th style={a.offer_code ? { paddingBottom: 20 } : {}}>{a.vendor_name}</th>
                                                                            <td style={a.offer_code ? { paddingBottom: 20 } : {}} className="pricee"><i className="fas fa-rupee-sign" aria-hidden="true"></i> {a.calculated_price}</td>
                                                                            <td style={a.offer_code ? { paddingBottom: 20 } : {}}>
                                                                                <button onClick={() => this.redirecTo(a.affiliate_link, a)}>BUY NOW</button>
                                                                            </td>
                                                                            {a.offer_code &&
                                                                                <div className="offerCode">{a.offer_code}</div>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                                : null}
                                                        </tbody>
                                                    </table> */}
                                                    {this.state.product.product_affiliates.length ?
                                                        this.state.product.product_affiliates.sort((a, b) => Number(a.calculated_price) - Number(b.calculated_price)).map((a, i) => {
                                                            return (
                                                                <div key={i} className="offerLinks">
                                                                    <b>{a.vendor_name}</b>
                                                                    <p className="pricee"><i className="fas fa-rupee-sign" aria-hidden="true"></i> {Number(a.calculated_price).toFixed(0)}</p>
                                                                    <button onClick={() => this.redirecTo(a.affiliate_link, a)}> <a href>BUY NOW</a></button>
                                                                    {a.offer_code &&
                                                                        <div className="offerCode">Use <span>{a.offer_code}</span> to get this price</div>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        : null}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item col-lg-12 pad-0">
                                            <a className="nav-link active" id="home-tabb" data-toggle="tab" href="#home" role="tab"
                                                aria-controls="home" aria-selected="true">Product description</a>
                                        </li>
                                        {/* <li className="nav-item col-lg-6 pad-0">
                                            <a className="nav-link" id="profile-tabb" data-toggle="tab" href="#profilee" role="tab"
                                                aria-controls="profile" aria-selected="false">Ingredients</a>
                                        </li> */}
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel"
                                            aria-labelledby="description">
                                            <p>
                                                <ReactMarkdown
                                                    source={`${this.state.product.details}`}
                                                    escapeHtml={false}
                                                />
                                            </p>
                                        </div>
                                        {/* <div className="tab-pane fade" id="profilee" role="tabpanel" aria-labelledby="profile-tab">
                                            <b>Top ingredients</b>
                                            <p>
                                                {this.state.product.ingredients}
                                            </p>

                                        </div> */}
                                        {/* {% comment %}
                                        <div className="tab-pane fade" id="contactt" role="tabpanel" aria-labelledby="contact-tab">
                                            <div className="row mb-3">
                                                <div className="col-lg-2 col-4">
                                                    <div className="rating-img">
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <img src="https://image.ibb.co/jw55Ex/def_face.jpg" className="user-img img img-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <i className="fa fa-star rating-stars"></i>
                                                                <i className="fa fa-star rating-stars"></i>
                                                                <i className="fa fa-star rating-stars"></i>
                                                                <i className="fa fa-star-o rating-stars"></i>
                                                                <i className="fa fa-star-o rating-stars"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-8">
                                                    <span className="date">20 Aug 2020</span> <br>
                                                        <b className="user-name">Brandon Kelly</b> <br />
                                                        <p>
                                                            leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                                    </p>
                                </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <div className="col-lg-2 col-4">
                                                        <div className="rating-img">
                                                            <div className="row">
                                                                <div className="col-lg-12 text-center">
                                                                    <img src="https://image.ibb.co/jw55Ex/def_face.jpg" className="user-img img img-circle" />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12 text-center">
                                                                    <i className="fa fa-star rating-stars"></i>
                                                                    <i className="fa fa-star rating-stars"></i>
                                                                    <i className="fa fa-star rating-stars"></i>
                                                                    <i className="fa fa-star-o rating-stars"></i>
                                                                    <i className="fa fa-star-o rating-stars"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-8 col-8">
                                                        <span className="date">20 Aug 2020</span> <br>
                                                            <b className="user-name">Brandon Kelly</b> <br />
                                                            <p>
                                                                leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                                    </p>
                                </div>
                                                    </div>

                                                    <div className="row mt-5">
                                                        <div className="col-lg-8">
                                                            <h5 className="review-title">Add Review</h5><br>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <input type="text" className="form-control input-rating" placeholder="Name*" name="">
                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <input type="text" className="form-control input-rating" placeholder="Email*" name="">
                                        </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-lg-6"> <br>
                                                                                <span className="review-title">Your Rating:  </span>

                                                                                <fieldset className="rating">
                                                                                    <input type="radio" id="star5" name="rating" value="5" />
                                                                                    <label className="full" for="star5" ></label>
                                                                                    <input type="radio" id="star4half" name="rating" value="4 and a half" />
                                                                                    <label className="half" for="star4half" ></label>
                                                                                    <input type="radio" id="star4" name="rating" value="4" />
                                                                                    <label className="full" for="star4" title="Pretty good - 4 stars"></label>
                                                                                    <input type="radio" id="star3half" name="rating" value="3 and a half" />
                                                                                    <label className="half" for="star3half" ></label>
                                                                                    <input type="radio" id="star3" name="rating" value="3" />
                                                                                    <label className="full" for="star3"></label>
                                                                                    <input type="radio" id="star2half" name="rating" value="2 and a half" />
                                                                                    <label className="half" for="star2half"></label>
                                                                                    <input type="radio" id="star2" name="rating" value="2" />
                                                                                    <label className="full" for="star2" ></label>
                                                                                    <input type="radio" id="star1half" name="rating" value="1 and a half" />
                                                                                    <label className="half" for="star1half"></label>
                                                                                    <input type="radio" id="star1" name="rating" value="1" />
                                                                                    <label className="full" for="star1" ></label>
                                                                                    <input type="radio" id="starhalf" name="rating" value="half" />
                                                                                    <label className="half" for="starhalf" ></label>
                                                                                </fieldset>
                                        </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-lg-12">

                                                                                    <textarea placeholder="Your Review" className="form-control review-text" rows="4"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {% endcomment %} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div >
        )
    }
}

const mapStateToProps = ({ store }) => ({
    product: store.product,
    whishlistProducts: store.whishlistProducts,
    is_authenticated: store.is_authenticated
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCartOrders,
            getWishlist
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails)