import React from 'react'
// import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import { WOW } from 'wowjs'

import FirstSection from './Components/FirstSection'
import SeconSection from './Components/SecondSection'
// import ThirdSection from './Components/ThirdSection'
import FourthSection from './Components/FourthSection'
import FifthSection from './Components/FifthSection'
import SixthSection from './Components/SixthSection'
import SeventhSection from './Components/SeventhSection'
// import EightSection from './Components/EightSection'
import NinthSection from './Components/NinthSection'
import TenthSetion from './Components/TenthSection'

// import Axios from 'axios'
// import { getSkinCareProducts } from '../../modules/store'
import { fetchArrivedProduct } from '../../modules/actions'

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
      // loader: true
    }
  }


  componentDidMount() {
    // const wow = new WOW({
    //   offset: 100,
    //   mobile: false,
    //   live: true
    // })

    this.props.fetchArrivedProduct()

    // wow.init();

    // this.setState({
    //   percentage: 66
    // })
    // window.scrollTo({ top: 0, behavior: 'auto' });

    // setTimeout(() => {
    //   this.setState({
    //     loader: false
    //   })
    // }, 2000)

  }

  render() {
    return (
      <>
        <div className="homeee">

          <FirstSection />

          <SeconSection />

          {/* <ThirdSection /> */}
          <FifthSection />

          <FourthSection />

          {!this.props.isLoading &&
            <SixthSection />
          }

          <SeventhSection />

          {/* <EightSection /> */}

          <NinthSection />

          <TenthSetion />

        </div >

        {this.props.isLoading &&
          <div className="loaderr" id="loader">
            <div className="loading">
              <div className="finger finger-1" id="loaderIn">
                <div className="finger-item">
                  <span></span><i></i>
                </div>
              </div>
              <div className="finger finger-2">
                <div className="finger-item">
                  <span></span><i></i>
                </div>
              </div>
              <div className="finger finger-3">
                <div className="finger-item">
                  <span></span><i></i>
                </div>
              </div>
              <div className="finger finger-4">
                <div className="finger-item">
                  <span></span><i></i>
                </div>
              </div>
              <div className="last-finger">
                <div className="last-finger-item"><i></i></div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = ({ store }) => ({
  skinCareProducts: store.skinCareProducts,
  user: store.user,
  isLoading: store.isLoading
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchArrivedProduct
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
