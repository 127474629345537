import React from 'react'
import slide1 from '../../../Images/slide1.jpg'
// import { Waypoint } from 'react-waypoint'
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga'

class FirstSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownToggle: false
        }
    }

    render() {
        document.addEventListener('click', (e) => {
            if (document.getElementsByClassName('newDropDownListOne')[0] && document.getElementsByClassName('newDropDownListOne')[0].style.zIndex !== '-1') {
                if (e.target.id !== "newDropDownList-menu") {
                    this.setState({
                        dropdownToggle: false
                    })
                }
            }
        })
        return (
            <div>
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner" style={{ paddingBottom: 60 }}>
                        <div id="firstSession" className="carousel-item active animation__style01 firstDiv">
                            <img src={slide1} className="d-block w-100" alt="..." />
                            <div className="text" id="section1text">
                                <h2>One Stop Beauty Shopping Solution</h2>
                                <p>Compare Prices & Get Beauty Recommendations</p>
                                {this.props.is_authenticated ?
                                    window.screen.width < 600 ?
                                        <a className="startQuiz" id="newDropDownList-menu" onClick={() => {
                                            this.setState({
                                                dropdownToggle: !this.state.dropdownToggle
                                            })
                                        }}>
                                            <button className="quiz" id="newDropDownList-menu">
                                                Recommendations
                                        </button>
                                        </a>
                                        :
                                        <NavLink className="startQuiz" id="newDropDownList-menu" onClick={() => {
                                            this.setState({
                                                dropdownToggle: !this.state.dropdownToggle
                                            })
                                        }}>
                                            <button className="quiz" id="newDropDownList-menu">
                                                Recommendations
                                        </button>
                                        </NavLink>
                                    :
                                    <a className="startQuiz" href="/quiz">
                                        <button onClick={() => ReactGA.event({ category: "Button Click", action: "Started Quiz" })} className="quiz">
                                            Start Glam Quiz
                                     </button>
                                    </a>
                                }
                                <div id="newDropDownList-menu" style={{
                                    opacity: this.state.dropdownToggle ? 1 : 0,
                                    zIndex: this.state.dropdownToggle ? 999 : -1
                                }} class="newDropDownList newDropDownListOne">
                                    <div id="newDropDownList-menu">
                                        {window.screen.width < 600 ?
                                            <a id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} href={`/suggestions?Skin care`}>Skin</a>
                                            :
                                            <NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Skin care`}>Skin</NavLink>
                                        }
                                    </div>
                                    <div id="newDropDownList-menu">
                                        {window.screen.width < 600 ?
                                            <a id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} href={`/suggestions?Body care`}>Bath & Body</a>
                                            :
                                            <NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Body care`}>Bath & Body</NavLink>
                                        }
                                    </div>
                                    <div id="newDropDownList-menu">
                                        {window.screen.width < 600 ?
                                            <a id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} href={`/suggestions?Hair care`}>Hair</a>
                                            :
                                            <NavLink id="newDropDownList-menu" target={window.screen.availWidth < 768 ? false : "_blank"} to={`/suggestions?Hair care`}>Hair</NavLink>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    is_authenticated: store.is_authenticated
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FirstSection)