import React from 'react'
import sideImg from '../../../Images/about/side-img.png'
import laptop from '../../../Images/about/laptop.png'

class FourthSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <>
                <div className="container-fluid mt-5 about-banner">
                    <div className="row">
                        <div className="col-md-7">
                            <center><img src={sideImg} alt="" className="img img-responsive img-about" /></center>
                        </div>
                        <div className="col-md-5">
                            <div className="text-left">
                                <ul className="about-bullets">
                                    <li><i className="fas fa-check"></i> Quick and easy quiz</li>
                                    <li><i className="fas fa-check"></i> Artificial Intelligence</li>
                                    <li><i className="fas fa-check"></i> Free Analysis</li>
                                    <li><i className="fas fa-check"></i> Unbiased recommendations</li>
                                    <li><i className="fas fa-check"></i> Price comparison</li>
                                    <li><i className="fas fa-check"></i> Minimum browsing, Maximum benefits</li>
                                    <li><i className="fas fa-check"></i> 1000s of products, 100s of brands</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container curate-con mt-5">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="mb-text">Get your personalized results today !!!</h2>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12 text-center">
                            <center>
                            <a href="/quiz">
                            <button className="btn btn-theme" >Start Glam Quiz</button>
                            </a>
                            </center>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <img src={laptop} className="img img-responsive img-laptop" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default FourthSection