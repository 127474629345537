import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Navbar from '../Navbar';

class TermsAndCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <>
                <div className="termsDiv">
                    <h1>Terms & Conditions</h1>

                    <h2>Introduction</h2>
                    <p>
                        Welcome to GlaMate ("company" or "GlaMate" or "we" or "our" or "us"). These terms & conditions, which could also be revised from time to time, commenced the terms that we offer all our services to you (whether directly or indirectly). By accessing, browsing, and exploiting any website maintained by us and our social networking pages or human action with us either by phone and/or electronically, you acknowledge and conform to be sure by these terms of service.
                    </p>
                    <p>
                        GlaMate might at any time hastily modify these terms of service. You'll be able to review the original current version of our terms and conditions at any time by clicking on the "T&C" link on our website. The earliest current version displayed there on a page can succeed all previous versions.
                    </p>
                    <p>
                        Please press or click "accept" if you agree to be legally bound by all terms and conditions herein. Your acceptance of this agreement creates a legally binding contract between you and GlaMate. If you do not agree to any part of this agreement, then don't press "accept," within which case you will not be able to use the service.    
                    </p>

                    <br></br>

                    <h2>Interpretation</h2>
                    <ul >
                        <li>All references to the singular include the plural and vice versa, and the word "includes" should be construed as "without limitation."</li>
                        <li>Words importing any gender shall include all the other genders.</li>
                        <li>Reference to any statute, ordinance or other law includes all regulations and other instruments and all consolidations, amendments, re-enactments, or replacements for the time being in force.</li>
                        <li>All headings, bold typing, and italics (if any) have been inserted for convenience of reference only. They do not define, limit or affect the meaning or interpretation of the terms of this agreement.</li>
                    </ul>

                    <br></br>

                    <h2>Commitment and Scope</h2>
                    <ul >
                        <li><b>Scope:</b> These terms govern your use of our website and the services. Except as otherwise specified, these terms do not apply to third-party products or services governed by their terms of service.</li>
                        <li><b>Eligibility:</b> Our service is not available to minors under the age of 13 or to any users suspended or removed from the system by us for any reason. </li>
                        <li><b>Electronic Communication:</b> When you use this website or send emails and other electronic communications from your desktop or mobile device to us, you are communicating with us electronically. By sending, you agree to receive a reply from us electronically in the same format and you can keep copies of these communications for your records.</li>
                    </ul>

                    <br></br>

                    <h2>Our services</h2>
                    <p>
                        The website is an independent online price comparison and skincare product recommendations platform through which you can look for, select, compare and purchase beauty products. We will introduce you to third-party vendors and/or manufacturers of beauty products (each a "partner"). from time to time.
                    </p>
                    <p>
                        If you utilize the services to buy an item from a partner, you will be coordinated to the significant partner's site to finish the exchange. For all sales made utilizing the services, you will contract via a direct, lawfully official concurrence with the partner (the "Agreement"). You don't go into any legally binding relationship with Glamate for the items or services you buy. Glamate acts exclusively as an intermediary agent by providing services.
                    </p>
                    <p>
                        We are not liable for the quality, suitability, or safety of any items or services offered by a partner. We are not responsible and obligated regarding the security and information assortment practices of any partner that gets any data from you throughout any visit to such partner's site, whether connected from the website or something else, or any buy exchange made with the partner. You should review the terms and conditions of any partner before utilizing their site or making any buy exchange using their site. You thus unavoidably discharge Glamate from any misfortune or harm you bring about and make a deal to avoid stating any cases against us emerging from your purchase of any items or services ​happening because of a reference or connection on the website.
                    </p>
                    <p>
                        Do not rely on the service as a means to guarantee changes to your appearance or skin or in connection with preventing, diagnosing, or treating any disease or condition. Utilization of the service is not a substitute for medical advice regarding any known or suspected health condition, including, without limitation, any dermatological condition. If you suspect that you have a health or skin condition, see a medical provider immediately. By no means will Glamate be held responsible for any harm or liability arising from or related to the media, your use of third-party products, or your failure to seek prompt medical attention, regardless of whether the foregoing results in bodily harm, death, or other damage or liability. Due to the way recommendations are generated, they are general and advisory. You should review the ingredients in any item suggested by us and do a patch test before using it. We will not be responsible if an item suggested by us isn't appropriate for you. If the price for any item cited on the website is diverse from that on any partner site, the cost expressed on the partner site will be the correct cost.
                    </p>

                    <br></br>

                    <h2>Content responsibility</h2>
                    <p>
                        The website permitted you to submit comments, feedback etc. But you are solely responsible for the content submitted by you. You represent that you have required permission to use the content.
                    </p>
                    <p>
                        When submitting content to the website, please do not submit content that:
                    </p>
                    <ul >
                        <li>Contains ill-mannered, profane, abusive, racist, or hateful language or expressions, text, photographs, or illustrations that are pornographic or in poor taste, inflammatory attacks of a personal, racial or religious nature.</li>
                        <li>Is defamatory, threatening, disparaging, grossly inflammatory, false, misleading, fraudulent, inaccurate, unfair, contains gross exaggeration or unsubstantiated claims.</li>
                        <li>Violates the privacy rights of any third party, is unreasonably harmful or offensive to any individual or community.</li>
                        <li>Discriminates on the grounds of race, religion, national origin, gender, age, marital status, sexual orientation, or disability, or refers to such matters in any manner prohibited by law.</li>
                        <li>Violates or inappropriately encourages the violation of any municipal, state, federal or international law, rule, regulation, or ordinance.</li>
                        <li>Uses or attempts to use another's account, password, service or system except as expressly permitted by the terms of use uploads or transmits viruses or other harmful, disruptive, or destructive files.</li>
                        <li>Information or data which are unlawfully obtained.</li>
                        
                    </ul>
                    <p>
                        Any such kind of submitted content will be refused by us. If repeated violations occur, we reserve the right to cancel user access to the website without advanced notice.   
                    </p>

                    <br></br>

                    <h2>Geographic Restriction</h2>
                    <p>
                        We reserve the right, but not the obligation, to limit the usage or supply of any service to any person, geographic region, or jurisdiction. We may use this right as per necessity. Any offer to provide any service made on our website is invalid where banned.
                    </p>

                    <br></br>

                    <h2>Your Commitment and Responsibilities:</h2>
                    <ul >
                        <li>You shall use our service for a lawful purpose and comply with all the applicable laws.</li>
                        <li>You shall not use or access the website for collecting any market research for some competing business.</li>
                        <li>You will not use any device, scraper, or any automated thing to access our website for any means without taking permission.</li>
                        <li>You will inform us about anything that is inappropriate, or you can inform us if you find something illegal.</li>
                        <li>You will not interfere with or try to interrupt the proper operation of the website through the use of any virus, device, transmission mechanism, software, or routine, or access or try to gain access to any data, files, or passwords connected to the website through hacking, password or data mining, or any other means.</li>
                        <li>You will not take any action that levies or may levy (in our sole decision) an unreasonable or unreasonably big load on our technical arrangement; and</li>
                        <li>You will let us know about the unsuitable content of which you become aware. If you discover something that infringes any law, please let us know, and we'll review it.</li>
                        
                    </ul>
                    <p>
                        In our sole and absolute discretion, we reserve the right to deny your access to the website or any service, or any portion of the website or service, without notice, and to remove any content. 
                    </p>

                    <br></br>

                    <h2>Intellectual property rights notification</h2>
                    <p>
                        We respect copyright owners' rights to control the uses of their intellectual property and require users of our websites and services to do the same. Our policy is to respond to notices of alleged infringement that comply with the applicable intellectual property laws. Responses may include removing or disabling access to the material.
                    </p>
                    <p>
                        If you have an intellectual property rights-related complaint about material posted on the website, you may contact us using the information below.
                    </p>
                    <p>
                    www.glamate.in; <br></br> <b>e-mail:</b> csg@glamate.in 
                    </p>
                    <p>
                        Any notice alleging that materials hosted by or distributed through the website infringe intellectual property rights must include the following information:
                    </p>
                    <ul>
                        <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other right being infringed;</li>
                        <li>A description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
                        <li>A description of the material that you claim is infringing and where it is located on the website;</li>
                        <li>Your address, telephone number, and e-mail address;</li>
                        <li>A statement by you that you have a good faith belief that the use of the materials on the service of which you are complaining is not authorized by the copyright owner, its agent, or the law; and</li>
                        <li>A statement by you that the above information in your notice is accurate and that, under penalty of perjury, you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</li>
                    </ul>

                    <br></br>

                    <h2>Exclusion of Liability</h2>
                    <p>
                        You understand and agree that we:
                    </p>
                    <ol type="II">
                        <li>Shall not be responsible for any profit, loss, or offer received by the information provided on this website.</li>
                        <li>Do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us or any third parties.</li>
                        <li>Shall not be responsible for any materials posted by us or any third party.</li>
                    </ol>
                    <p>
                        You shall use your judgment, caution, and common sense in evaluating any prospective methods or offers and any information provided by us or any third party.
                    </p>
                    <p>
                        Further, we shall not be liable for direct, indirect consequential, or any other form of loss or damage that a user may suffer through the use of the www.glamate.in website including loss of data or information or any kind of financial or physical loss or damage.   
                    </p>
                    <p>
                        In no event shall the Glamate eRetail Pvt ltd, nor its owners, directors, employees, partners, agents, suppliers, or affiliates, be accountable for any indirect, incidental, special, eventful, or exemplary costs, including without limitation, loss of proceeds, figures, usage, goodwill, or other intangible losses, consequential from:
                    </p>
                    <ol>
                        <li>Your use or access of or failure to access or use the service.</li>
                        <li>Any conduct or content of any third party on the service.</li>
                        <li>Unlawful access, use, or alteration of your transmissions or content, whether or not we've been aware of the possibility of such damage.</li>
                        <li>No responsibility</li>
                    </ol>
                    <p>
                        We are not responsible to you for: 
                    </p>
                    <ul>
                        <li>Any reliance that you may place on any material or commentary posted on our website. please note that nothing contained in our website or the material published on it is intended to amount to advice on which you should rely; or</li>
                        <li>Any losses you suffer because the information you put into our website is inaccurate or incomplete; or</li>
                        <li>Shipping of any product to the user/customer; or</li>
                        <li>The non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, non-provision of after sales or warranty services or fraud as regards the products and /or services listed on our website; or</li>
                        <li>Any losses you suffer because you cannot use our website at any time; or</li>
                        <li>Any errors in or omissions from our website; or</li>
                        <li>Any losses you may suffer by relying on any commentary, postings or reviews (of our services or that of our partners) on our website; or</li>
                        <li>Any unauthorized access or loss of personal information that is beyond our control.</li>
                    </ul>

                    <br></br>

                    <h2>Affiliate marketing & advertising</h2>
                    <p>
                        Through the website and services, we may engage in affiliate marketing whereby we receive a commission on or percentage of the sale of goods or services on or through the website. We may also accept advertising and sponsorships from commercial businesses or receive other forms of advertising compensation. Keep in mind that we may receive commissions when you click our links and make purchases. However, this does not impact our reviews and comparisons. We try our best to keep things fair and balanced to help you make the best choice for you.
                    </p>

                    <br></br>

                    <h2>Third-party links:</h2>
                    <p>
                        We may comprise links to external or third-party websites ("external sites"). These links are provided exclusively as ease to you and not as an authorization by us of the content on such external sites. The content of such external sites is created and used by others. You can communicate with the site administrator for those external sites. We are not accountable for the content provided in the link of any external sites. We do not provide any representations about the content or correctness of the information on such external sites. It would be best if you took safety measures when you download files from all these websites to safeguard your computer from viruses and other critical programs. If you agree to access linked external sites, you do so at your own risk.
                    </p>

                    <br></br>

                    <h2>Personal information and Privacy Policy:</h2>
                    <p>
                        By accessing or using the website, you approve us to use, store, or otherwise process your personal information as per our privacy policy.
                    </p>

                    <br></br>

                    <h2>Errors, Inaccuracies, and omissions:</h2>
                    <p>
                        Every effort has been taken to ensure that the information offered on our website is accurate and error-free. We apologize for any errors or omissions that may have occurred. We cannot give you any warranty that usage of the website will be error-free or fit for purpose, timely, that defects will be amended, or that the site or the server that makes it available are free of viruses or bugs or signifies the full functionality, accuracy, reliability of the website. We do not make any warranty whatsoever, whether express or implied, relating to fitness for purpose, or accuracy.
                    </p>

                    <br></br>

                    <h2>Disclaimer of warranties, limitation of liability:</h2>
                    <p>
                        Our website and the service are provided on an "as is" and "as available" basis without any warranties of any kind, including that the website will operate error-free or that the website, its servers or its content or service are free of computer viruses or similar contamination or destructive features.
                    </p>
                    <p>
                        We disclaim all licenses or warranties, including, but not limited to, licenses or warranties of title, merchantability, non-violation of third parties' rights, and fitness for particular purpose, and any warranties arising from a matter of dealing, course of performance, or usage of trade.In relation with any warranty, contract, or common law tort claims: we shall not be liable for any unintended, incidental, or substantial damages, lost profits, or damages resulting from lost data or business stoppage resulting from the use or inability to access and use the website or the content, even if we have been recommended of the possibility of such damages.
                    </p>
                    <p>
                        The website may comprise technical incorrectness or typographical errors or omissions. unless required by applicable laws, we are not accountable for any such typographical, technical, or pricing errors recorded on the website. The website may contain information on certain services, not all of which are available in every location. A reference to a service on the websites does not suggest that such service is or will be accessible in your location. We reserve the right to do changes, corrections, and/or improvements to the website at any time without notice. 
                    </p>

                    <br></br>

                    <h2>Copyright and Trademark:</h2>
                    <p>
                        The website contains material, such as software, text, graphics, images, designs, sound recordings, audiovisual works, and other material provided by or on behalf of us (collectively referred to as the "content") or third parties may possess the content. Unauthorized use of the content may infringe copyright, trademark, and other laws. You have no rights in or to the content, and you will not take the content except as allowed under this agreement. No other use is permitted without prior written consent from us. It would be best if you recollected all copyright and other proprietary notices in the original content on any copy you make of the content. You may not transfer, provide license or sub-license, sell, or modify the content or reproduce, display, publicly perform, make a derivative version of, distribute, or otherwise use the content in any way for any public or commercial purpose. The use or posting of the content on any other website or in a networked computer environment for any purpose is expressly prohibited.
                    </p>
                    <p>
                        Suppose you infringe any part of this agreement. In that case, your permission to access and use the content and the website automatically terminates, and you must immediately destroy any copies you have made of the content.
                    </p>
                    <p>
                        Our trademarks, service marks, and logos used and displayed on the website are registered and unregistered trademarks or service marks of us. Other company, product, and service names located on the website may be trademarks or service marks owned by others (the "third-party trademarks," and, collectively with us, the "trademarks").  
                    </p>
                    <p>
                        Nothing on the website should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the trademarks without our prior written permission specific for each such use. None of the content may be retransmitted without our express, written consent for every instance.
                    </p>
                    <br></br>

                    <h2>Indemnification:</h2>
                    <p>
                        You agree to secure, indemnify, and hold us and our officers, directors, employees, successors, licensees, and allocates harmless from and against any dues, actions, or demands, including, without restriction, reasonable legal and accounting fees, arising or consequential from your breach of this agreement or your misappropriation of the content or the website. We shall make available notice to you of any such claim, suit, or proceeding and shall assist you, at your expense, in defending any such claim, suit, or proceeding. We reserve the right, at your expense, to assume the exclusive defense and control of any matter that is subject to indemnification under this section. In such a case, you agree to cooperate with any reasonable requests assisting our defense of such matter.
                    </p>

                    <br></br>

                    <h2>Miscellaneous:</h2>
                    <p>
                        <b>Severability:</b><br></br>
                        Suppose any provision of these terms is found to be unenforceable or invalid. In that case, that provision will be limited or eliminated to the minimum extent necessary so that the words will otherwise remain in full force and effect and enforceable.
                    </p>
                    <p>
                        <b>Termination:</b><br></br>
                        The services that will be provided to you can be canceled or terminated by us. We may terminate these services at any time, with or without cause, upon written notice. We will have no liability to you or any third party because of such termination. Termination of these terms will terminate all of your services subscriptions.
                    </p>
                    <p>
                        <b>Effect of Termination:</b><br></br>
                        Upon termination of these terms for any reason, or cancellation or expiration of your services: (a) we will cease providing the services; (b) we may delete your archived data within 30 days. all sections of the terms that expressly provide for survival, or by their nature should survive, will survive termination of the terms, including, without limitation, indemnification, warranty disclaimers, and limitations of liability.
                    </p>

                    <br></br>

                    <h2>Governing law and Judicial recourse:</h2>
                    <p>
                        The terms herein will be governed by and construed under the law of India and the state of Telangana without affecting any principles of law conflicts. The courts of the state of Telangana have exclusive jurisdiction over any dispute arising from the use of the website.
                    </p>
                    <p>
                        We will have no liability to you, or any third party for any failure us to perform its obligations under these terms if such non-performance arises as a result of the occurrence of an event beyond the reasonable control of us, including, without limitation, an act of war or terrorism, natural disaster, failure of electricity supply, riot, civil disorder, or civil commotion or other force majeure event.
                    </p>
                    <br></br>

                    <h2>Assignment:</h2>
                    <p>
                        We shall have the right to assign/transfer this agreement to any third party, including its holding, subsidiaries, affiliates, associates, and group companies, without any consent of the user.
                    </p>
                    <br></br>

                    <h2>Contact Information:</h2>
                    <p>
                        If you have any questions about these terms, please contact us at our email <b>csg@glamate.in</b>
                    </p>

                </div>
            </>
        )
    }
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsAndCondition)