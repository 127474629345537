import React from 'react'
import slider1 from '../../../Images/about/slider1.png'
import slider2 from '../../../Images/about/slider2.png'
import slider3 from '../../../Images/about/slider3.png'

class CopyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div id="firstSession" className="carousel-item active">
                        <img src={slider1} className="d-block w-100" alt="..." />
                        <div id="about-us" className="text">
                            <h2>Fed up with wasting money on wrong products??</h2>

                        </div>
                    </div>

                    <div id="firstSession" className="carousel-item">
                        <img src={slider2} className="d-block w-100" alt="..." />
                        <div id="about-us" className="text">
                            <h2>Friend or Colleague recommendation not <br /> working for you skin type??</h2>

                        </div>
                    </div>

                    <div id="firstSession" className="carousel-item">
                        <img src={slider3} className="d-block w-100" alt="..." />
                        <div id="about-us" className="text">
                            <h2>Not sure how to research about ingredients <br /> that are even hard to pronounce?</h2>

                        </div>
                    </div>

                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
        )
    }
}

export default CopyComponent