import React from 'react'
import { Route, Link } from 'react-router-dom'
import Home from '../home'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Quiz from '../quiz/quiz'
import About from '../about'
import SkinCare from '../SkinCare/SkinCare'
import Recommendation from '../Recommendation/Recommendation'
import Search from '../Search/Search'
import Profile from '../Profile'
import ProfileDiv from '../Profile/Components/Profile'
import MyAccount from '../Profile/Components/MyAccount'
import Wishlist from '../Profile/Components/Wishlist'

import Login from '../Login'
import Signup from '../Signup'
import ProductDetails from '../ProductDetails/ProductDetails'
import Layout from '../SkinCare/Layout'
import Success from '../Success/Success'
import { fetchBodycareProducts, fetchBodycareProductsRecommended, fetchSkincareProducts, fetchHaircareProducts, getProfileData, getWishlist } from '../../modules/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Checkout from '../Checkout/Checkout'
import QuickView from '../quickViewModel/quickView'
import SummaryPage from '../SummaryPage/SummaryPage'
import RedirectPage from '../ProductDetails/RedirectPage'
import TermsAndConditions from './TermsAndConditions'
import Privacy from './Privacy'
import ResetPassword from '../resetpassword/ResetPassword'
import Brands from '../brands/brands'
import BlogsPage from '../Blogs/BlogsPage'
import BlogDetails from '../Blogs/BlogDetails'
import BrandWisePage from '../brands/BrandWisePage'
import ReactGA from 'react-ga'

import CouponPage from '../Coupon/Coupon.js'
import ContactUs from '../ContactUs/ContactUs'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    let category = window.location.search.slice(1).split('%20').join(" ")
    let path = window.location.pathname.slice(1)
    if (path !== "quiz") {
      localStorage.removeItem('retake')
    }
    this.props.getProfileData()
    if (!category) {
      // this.props.fetchSkincareProducts()
      // this.props.fetchBodycareProducts()
      // this.props.fetchHaircareProducts()
    }
    ReactGA.initialize('UA-197160427-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
    console.log(window.location.pathname + window.location.search)
  }

  render() {

    let path = window.location.pathname.slice(1)
    // console.log(window.location.pathname)
    // window.console.log = function () {};
    // window.console.error = function () {};
    // window.console.warn = function () {};
    return (
      <div>
        <div className="notificationAlert">

        </div>
        {path !== 'quiz' ?
          <>
            {window.location.pathname !== "/login" && window.location.pathname !== "/resetPassword" && window.location.pathname !== "/sign-up" && window.location.pathname !== "/summary-page" ?
              <Navbar />
              : null
            }
            <main>
              <Route exact path="/" component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/redirect" component={RedirectPage} />
              <Route exact path="/brands" component={Brands} />
              <Route exact path="/brands/:id" component={BrandWisePage} />
              <Route path="/brands/:id/:id" component={ProductDetails} />

              <Route path="/terms-and-conditions" component={TermsAndConditions} />
              <Route path="/privacy" component={Privacy} />

              <Route path="/summary-page" component={SummaryPage}></Route>
              <Route exact path="/skin-care" component={() => <SkinCare name="Skin Care" />} />
              <Route exact path="/body-care" component={() => <SkinCare name="Body Care" />} />
              <Route exact path="/hair-care" component={() => <SkinCare name="Hair Care" />} />
              <Route path='/skin-care/:id' component={ProductDetails} />
              <Route path='/body-care/:id' component={ProductDetails} />
              <Route path='/hair-care/:id' component={ProductDetails} />

              <Route exact path="/blogs" component={() => <BlogsPage name="Skin Care" />} />
              <Route exact path='/blogs/:id' component={BlogDetails} />
              <Route path='/blogs/:id/:id' component={ProductDetails} />

              {this.props.is_authenticated &&
                <>
                  <Route path="/suggestions" component={Recommendation} />
                  <Route path="/profile" component={Profile} />
                  <Route path="/profile/summary" component={ProfileDiv} />
                  <Route path="/profile/my-account" component={MyAccount} />
                  <Route path="/profile/whishlist" component={Wishlist} />
                </>
              }
              <Route path="/search" component={Search} />
              <Route path="/login" component={Login} />
              <Route path="/resetPassword" component={ResetPassword} />
              <Route path="/sign-up" component={Signup} />
              <Route path="/success" component={Success} />
              <Route path="/checkout" component={Checkout} />

              <Route path="/coupons" component={CouponPage} />
              <Route path="/contact-us" component={ContactUs} />
            </main>
            {window.location.pathname !== "/login" && window.location.pathname !== "/sign-up" && window.location.pathname !== "/summary-page" ?
              <Footer />
              :
              null
            }
          </>
          :
          <main>
            <Route path="/quiz" component={Quiz} />
          </main>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ store }) => ({
  is_authenticated: store.is_authenticated,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSkincareProducts,
      getProfileData,
      getWishlist,
      fetchBodycareProducts,
      fetchBodycareProductsRecommended,
      fetchHaircareProducts
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
