import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../Login/login.scss'
import addTodoWithDispatch, { loginUser, loginUserSocial } from '../../modules/store';

import Axios from 'axios'
import VerifyCode from '../Signup/verifyCode';
import { Modal } from 'antd';
import Success from '../Success/Success';
import { withRouter } from 'react-router';
class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            facebok: false,
            newPassWordType: false,
            VerifyModal: false
        }
    }

    gettingValues = (ev, name) => {
        if (ev.target.value.length <= 10) {
            this.setState({
                [name]: ev.target.value
            })
        }
    }
    validatePhoneNumber() {
        var PhoneNO = this.state.username
        if (PhoneNO.length < 10) {
            document.getElementById('loginError').innerText = !PhoneNO ? "Phone Number is required" : PhoneNO.length < 10 ? "PhoneNumber should be at-least 10 characters" : "";
            return false;
        }
        return true;
    }
    SendNumber = (ev) => {
        ev.preventDefault()
        if (
            this.validatePhoneNumber()
        ) {
            const data = new FormData();
            data.append('username', this.state.username);
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/check-user/',
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(res => {
                    console.log(res.data)
                    if (res.data.exist) {
                        document.getElementById('loginError').innerText = "";
                        // Axios.get(`http://api.msg91.com/api/sendotp.php?authkey=344496AzdzXjgqMWUf5f86d63aP1&mobile=0091${this.state.username}&otp_length=6`).then((res) => {
                        //     console.log(res.data)
                        //     this.setState({
                        //         VerifyModal: true
                        //     })
                        // }).catch((err) => {
                        //     console.log(err.message)
                        // })
                        let data = new FormData()
                        data.append('username', `91${this.state.username}`)
                        Axios({
                            method: 'post',
                            url: process.env.REACT_APP_BACKEND_URL + `/api/otp/send/`,
                            validateStatus: (status) => {
                                return true;
                            },
                            data,
                        })
                            .then(response => {
                                console.log(response.data)
                                this.setState({
                                    VerifyModal: true
                                })
                            })
                            .catch(error => {
                                // console.log(error)
                            });
                    } else {
                        document.getElementById('loginError').innerText = "Phone Number is not exist";
                    }
                })
                .catch(error => {
                    console.log(error)
                });

        }

        return false;

    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'auto' });
        // var input = document.querySelector("#phone");
        // window.intlTelInput(input, ({
        //     // options here
        //     // initialCountry: "auto",
        //     geoIpLookup: function (success, failure) {
        //         $.get("http://ipinfo.io", function () { }, "jsonp").always(function (resp) {
        //             var countryCode = (resp && resp.country) ? resp.country : "";
        //             success(countryCode);
        //             console.log(countryCode);
        //         });
        //     },
        //     utilsScript: "../../../public/inputTellBuild/js/utils.js",

        // }));
    }

    focusColor = (id) => {
        if (document.getElementById(id)) {
            document.getElementById(id).classList.add("focusColor")
        }
    }

    focusOut = (id) => {
        if (document.getElementById(id)) {
            document.getElementById(id).classList.remove("focusColor")
        }
    }

    goBack = () => {
        this.props.history.goBack()
    }
    verifyAndSendApi(code) {
        console.log(this.state)
        if (code && this.state && this.state.username) {
            // Axios.get(`http://api.msg91.com/api/verifyRequestOTP.php?authkey=344496AzdzXjgqMWUf5f86d63aP1&mobile=0091${this.state.username}&otp=${code}`)
            let data2 = new FormData()
            data2.append('username', `91${this.state.username}`)
            data2.append('code', code)
            // Axios.get(`http://api.msg91.com/api/verifyRequestOTP.php?authkey=344496AzdzXjgqMWUf5f86d63aP1&mobile=0091${this.state.username}&otp=${code}`)
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + `/api/otp/verify/`,
                validateStatus: (status) => {
                    return true;
                },
                data: data2,
            })
                .then((res) => {
                    if (res.data.type === "error" && res.data.message !== "already_verified") {
                        this.setState({
                            verifyCodeError: "Please Enter a Valid OTP",
                            // countryDialCode: iti.getSelectedCountryData().dialCode
                        })
                    } else {
                        this.setState({
                            VerifyModal: false,
                            verifyCodeError: "",
                            newPassWordType: true
                        })
                    }
                }).catch((err) => {

                    this.setState({
                        verifyCodeError: "Please Enter a Valid OTP"
                    })
                })
        }

    }

    validatePasswordLength() {
        var pass1 = this.state.new_password

        if (pass1.length < 6) {
            document.getElementById('loginError').innerText = "Password should be at-least 6 characters";
            return false;
        }
        return true
    }
    success() {
        Modal.success({
            content: 'Your Password has been Updated',
        });

    }

    SetPassWord = (ev) => {
        ev.preventDefault()
        console.log("run", this.state)
        if (
            this.validatePhoneNumber()
            &&
            this.validatePasswordLength()
        ) {
            const data = new FormData();
            data.append('username', this.state.username);
            data.append('new_password', this.state.new_password);
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/change-password/no-auth/',
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(res => {
                    console.log(res.data)
                    this.success()
                    if (window.screen.width < 600) {
                        window.location.href = '/login'
                    } else {
                        this.props.history.push('/login')
                    }
                })
                .catch(error => {
                    console.log(error)
                });

        }

        return false;

    }

    ChangePhoneNumber = () => {
        this.setState({
            VerifyModal: false
        })
    }
    render() {
        document.title = `Reset Password`
        return (
            <div className="page container login">
                <VerifyCode open={this.state.VerifyModal} close={this.ChangePhoneNumber} state={this.state} verifyAndSendApi={(code) => {
                    this.verifyAndSendApi(code)
                }} />
                {this.state.newPassWordType ? <div className="card login-cont">
                    <a className="crossIcon" href="#" onClick={this.goBack}>
                        <i class="fas fa-times"></i>
                    </a>
                    <h2>Forgot Password</h2>
                    <p style={{ textAlign: "center" }}>(Type Your New Password)</p>
                    <div className="cardbody">
                        <form className="resetPasForm">
                            <div id="username" className="inputGroup inputGroup1 col-md-10" style={{ textAlign: "center", display: "none" }}>
                                <label className="lable-control col-md-12">Phone Number</label>
                                <input onFocus={() => this.focusColor("username")} value={this.state.username} onBlur={() => this.focusOut('username')} onChange={(ev) => this.gettingValues(ev, "username")} type="tel" className="form-control input-signup col-md-12" name="username" />

                            </div>
                            <div className="inputGroup inputGroup1 col-md-10" style={{ textAlign: "center" }}>
                                <label className="lable-control">Enter new Password</label>
                                <input onFocus={() => this.focusColor("repeatPass")} onBlur={() => this.focusOut('repeatPass')} onChange={(ev) => this.gettingValues(ev, "new_password")} type="password" value={this.state.new_password} className="form-control input-signup" name="password2" />
                            </div>
                        </form>
                        <p className="error text-center" id="loginError">{this.state.err && this.state.err.toLowerCase() === "enter valid credentials" ? "Invalid email or password" : ""}</p>
                        <div className="row">
                            <div className="col-md-12 text-center marginBottom">
                                <button className="btn btn-login" onClick={(ev) => this.SetPassWord(ev)}>Set Password</button>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div className="card login-cont">
                        <h2>Forgot Password
                        <span className="crossIcon" href="#" onClick={this.goBack}>
                                <i class="fas fa-times"></i>
                            </span>
                        </h2>
                        <p style={{ textAlign: "center" }}>(Type number for verification)</p>
                        <div className="cardbody">
                            <form className="resetPasForm" onSubmit={(ev) => ev.preventDefault()}>
                                <div id="username" className="inputGroup inputGroup1 col-md-10" style={{ textAlign: "center" }}>
                                    <label className="lable-control col-md-12">Phone Number</label>
                                    <div style={{ position: "absolute", top: 50, zIndex: 9, left: 0 }}>+91</div>
                                    <input style={{ paddingLeft: 30 }} onFocus={() => this.focusColor("username")} value={this.state.username} onBlur={() => this.focusOut('username')} onChange={(ev) => this.gettingValues(ev, "username")} type="tel" className="form-control input-signup col-md-12" name="username" />

                                </div>
                            </form>
                            <p className="error text-center" id="loginError">{this.state.err && this.state.err.toLowerCase() === "enter valid credentials" ? "Invalid email or password" : ""}</p>
                            <div className="row">
                                <div className="col-md-12 text-center marginBottom">
                                    <button className="btn btn-login" onClick={(ev) => this.SendNumber(ev)}>Send OTP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addTodoWithDispatch
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ForgotPassword))