import React from 'react'

class CircularProgressBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // Size of the enclosing square
        const sqSize = this.props.sqSize;
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (this.props.sqSize - 5) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        const dashOffset = dashArray - dashArray * this.props.percentage / 100;

        let percentage = this.props.percentage
        setInterval(() => {
            if (percentage < this.props.percentage) {
                // console.log(percentage)
                percentage = percentage + 1
                if (document.getElementById('percentText')) {
                    document.getElementById('percentText').innerHTML = `${percentage}%`
                }
            }
            clearInterval()
        }, 10);

        return (
            <svg
                width={130}
                height={130}
                viewBox={viewBox}>
                <circle
                    className="circle-background"
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${5}px`} />
                <circle
                    className={this.props.name === "match2" ? "circle-progress2" : "circle-progress"}
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${5}px`}
                    // Start progress marker at 12 O'Clock
                    transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }} />
            </svg>
        );
    }
}

CircularProgressBar.defaultProps = {
    sqSize: 200,
    strokeWidth: 10
};

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            percentage: 0
        };

        this.handleChangeEvent = this.handleChangeEvent.bind(this);
    }

    handleChangeEvent(event) {
        this.setState({
            percentage: event.target.value
        });
    }

    componentDidMount() {
        this.setState({
            percentage: this.props.percentage
        })
    }

    render() {
        return (
            <div>
                <CircularProgressBar
                    strokeWidth="10"
                    sqSize="200"
                    percentage={this.state.percentage} />
            </div>
        );
    }
}

export default CircularProgressBar