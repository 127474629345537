import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import Axios from 'axios'
import { getCartOrders, getCookie, getWishlist, quickViewModel } from '../../../modules/actions';
import NOAvaliableImage from '../../../Images/NoAvaliable.jpeg'
import QuickView from '../../quickViewModel/quickView';

class Wishlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getWishlist()
    }

    addToCart = (id, out) => {
        if (!out) {
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("id", id);
            data.append("action", "add");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (response.data.code === "token_not_valid") {
                        let msg = "You have login first"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        let data = response.data.products
                        // this.props.getCartOrders(data);
                        let msg = response.data.message
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        return data
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        } else {
            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>Out of stock</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
            }, 2000)
        }
    }

    addToWhishlist = (id) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("product_id", id);
        // data.append("action", "add");
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/wishlist/update/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.code === "token_not_valid") {
                    // let msg = "You have login first"
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    window.location.href = '/login'
                } else {
                    let data = response.data.products
                    this.props.getWishlist(data);
                    // let msg = response.data.message
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    return data
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    quickView = (obj) => {
        this.props.quickViewModel(obj)
    }

    checkWhishlist = (a, abc) => {
        let whishlistProducts = this.props.whishlistProducts
        if (whishlistProducts) {
            let check = whishlistProducts.filter(b => b.id === a.id)
            if (check.length) {
                return (
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-heart-fill" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                    </svg>
                )
            } else {
                return (
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                    </svg>
                )
            }
        } else {
            return (
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                </svg>
            )
        }
    }

    discoutGet = (a, b) => {
        let price = a
        let discounted_price = b
        let discounted_given = Number(price) - Number(discounted_price)
        return `${Number(Math.ceil((discounted_given / price) * 100).toFixed(1)).toFixed(0)}% Off`
    }

    changeRoute = (a, ev) => {
        if (ev.target.id !== "noOpen" && ev.target.nodeName !== "path") {
            if (window.screen.availWidth < 768) {
                window.location.href = `/${a.type}/${a.slug}`
            } else {
                window.open(`/${a.type}/${a.slug}`, '_blank');
            }
        }
    }

    render() {
        return (
            <div className="skinCaree tab-pane fade show active container" id="wish" role="tabpanel" aria-labelledby="wishlist">
                <div className="row mb-mobile">
                    <div className="col-md-12 text-center allProducts">
                        <h2 style={{ fontSize: '50px !important' }}>Wishlist</h2>
                    </div>
                </div>
                <div className="alert alert-success alert-dismissable" style={{ display: 'none' }}>
                    <strong className="alert-text"></strong>
                </div>

                <div className="row">
                    <div className="shopProducts page">
                        <div className="webpro" id="desktop-view-products">
                            {this.props.whishlistProducts && this.props.whishlistProducts.map((b, i) => {
                                return (
                                    <div key={i} className="product">
                                        <div onClick={(ev) => this.changeRoute(b, ev, "abc")} className="product-img" style={b.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${b.image})` }}>
                                            <div id={`hoverDiv${b.id}`} className="hoverDiv" style={{ opacity: 0 }}>
                                                {/* <div onClick={() => b.stock_quantity !== 0 ? this.addToCart(b.id) : this.addToCart(b.id,"out")} className="hoverI update-cart" data-action="add" data-productid={`${b.id}`}>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z">
                                                        </path>
                                                    </svg>
                                                </div> */}
                                                <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(b.id)} data-productid={`${b.id}`}>
                                                    {this.checkWhishlist(b)}
                                                </div>
                                            </div>
                                            {b.stock_quantity === 0 &&
                                                <div className="outOfStockDiv">Out of stock</div>
                                            }
                                        </div>
                                        <div onClick={(ev) => this.changeRoute(b, ev, "abc")} className="product-txt">
                                            <h6><i>{b.brand} - </i>
                                                {b.name.length > 36 ? `${b.name.slice(0, 36)}...` : b.name}
                                                {/* <NavLink target={window.screen.availWidth < 768 ? false : false} to="#!">{b.name.length > 36 ? `${b.name.slice(0, 36)}...` : b.name}</NavLink> */}
                                            </h6>
                                            {/* <p>
                                                <i>{b.category} - {b.brand}</i></p> */}
                                            <p className="price web">
                                                {b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < b.price ?
                                                    <>
                                                        <span className="lightGray">
                                                            MRP:&nbsp;&nbsp;
                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{b.price}</span></span>
                                                        <span style={{ float: 'right' }}>
                                                            <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                            {b.product_affiliates && b.product_affiliates.length && Number(b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                        </span>
                                                        <span className=""> |</span>
                                                        <span className="pinkk">{this.discoutGet(b.price, b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                    </>
                                                    :
                                                    <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {b.price}</span>
                                                }
                                            </p>
                                            <div className="mob-btn">
                                                {/* <div className="mob-cart"> */}
                                                {/* <button onClick={() => b.stock_quantity !== 0 ? this.addToCart(b.id) : this.addToCart(b.id,"out")} data-action="add" data-productid={`${b.id}`}>
                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                    </svg>
                                                    <div>
                                                        <p> ADD TO CART </p>
                                                    </div>
                                                </button> */}
                                                {/* </div> */}
                                                <p className="price">
                                                    {b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < b.price ?
                                                        <>
                                                            <span className="lightGray">

                                                                <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{b.price}</span></span>
                                                            <span style={{ float: 'right' }}>
                                                                <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                {b.product_affiliates && b.product_affiliates.length && Number(b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                            </span>
                                                            <span className=""> |</span>
                                                            <span className="pinkk">{this.discoutGet(b.price, b.product_affiliates && b.product_affiliates.length && b.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                        </>
                                                        :
                                                        <span style={{ float: 'right' }}><i className="fas fa-rupee-sign" aria-hidden="true"></i> {b.price}</span>
                                                    }
                                                </p>
                                                {/* <div className="mob-wish">
                                                    <button id="noOpen" onClick={() => this.addToWhishlist(b.id)} data-productid={`${b.id}`}>
                                                        {this.checkWhishlist(b, "a")}
                                                        <div id="noOpen">
                                                            <p id="noOpen"> WISHLIST </p>
                                                        </div>
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* <hr /> */}
                                        <div className="button-div">
                                            <button onClick={() => this.quickView(b)}>
                                                Quick View
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="mobviewpro" id="mobile-view-products">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    whishlistProducts: store.whishlistProducts,
    quickView: store.quickView
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getWishlist,
            getCartOrders,
            quickViewModel
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Wishlist))