
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSkinCareProducts } from '../../modules/store';
import $ from 'jquery'
import Modal from 'antd/lib/modal/Modal';
import Axios from 'axios';
import { Button } from 'antd';
class VerifyCodeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            digit1: "", digit2: "", digit3: "", digit4: "", digit5: "", digit6: ""
        }
    }
    VerifyInputCode = () => {
        $('.digit-group').find('input').each(function () {
            $(this).attr('maxlength', 1);
            $(this).on('keyup', function (e) {
                var parent = $($(this).parent());

                if (e.keyCode === 8 || e.keyCode === 37) {
                    var prev = parent.find('input#' + $(this).data('previous'));

                    if (prev.length) {
                        $(prev).select();
                    }
                } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39) {
                    var next = parent.find('input#' + $(this).data('next'));

                    if (next.length) {
                        $(next).select();
                    }
                }
            });
        });
    }
    componentDidUpdate() {
        this.VerifyInputCode()
    }
    resendOTP() {
        // Axios.get(`http://api.msg91.com/api/retryotp.php?authkey=344496AzdzXjgqMWUf5f86d63aP1&mobile=0091${this.props.state.username}&retrytype=text`)
        let data = new FormData()
        data.append('username', `91${this.props.account ? this.props.state.newUsername : this.props.state.username}`)
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/otp/resend/`,
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then((res) => {
                console.log(res)
            }).catch((err) => {
                console.log(err.message)
            })
    }
    render() {
        let { digit1, digit2, digit3, digit4, digit5, digit6 } = this.state
        return (
            <Modal title="" maskClosable={false} okButtonProps={{
                style: {
                    display: "none"
                }
            }} footer={false} visible={this.props.open} onCancel={() => {
                this.props.close()
                this.setState({
                    digit1: "",
                    digit2: "",
                    digit3: "",
                    digit4: "",
                    digit5: "",
                    digit6: "",
                })
            }}>

                <div id="wrapper">
                    <div id="dialog">
                        {/* <button className="close">×</button> */}
                        <p>Please enter the 6-digit verification code we sent via SMS on this Number +91{this.props.state.countryDialCode}{this.props.account ? this.props.state.newUsername : this.props.state.username}:</p>
                        <div method="get" id="form" className="digit-group" data-group-name="digits" data-autosubmit="false" autoComplete="off">
                            <input value={digit1} onChange={(ev) => this.setState({
                                digit1: ev.target.value
                            })} autoComplete="off" autoFocus={true} type="number" id="digit-1" name="digit-1" data-next="digit-2" />
                            <input value={digit2} onChange={(ev) => this.setState({
                                digit2: ev.target.value
                            })} autoComplete="off" type="number" id="digit-2" name="digit-2" data-next="digit-3" data-previous="digit-1" />
                            <input value={digit3} onChange={(ev) => this.setState({
                                digit3: ev.target.value
                            })} autoComplete="off" type="number" id="digit-3" name="digit-3" data-next="digit-4" data-previous="digit-2" />
                            <span className="splitter">&ndash;</span>
                            <input value={digit4} onChange={(ev) => this.setState({
                                digit4: ev.target.value
                            })} autoComplete="off" type="number" id="digit-4" name="digit-4" data-next="digit-5" data-previous="digit-3" />
                            <input value={digit5} onChange={(ev) => this.setState({
                                digit5: ev.target.value
                            })} autoComplete="off" type="number" id="digit-5" name="digit-5" data-next="digit-6" data-previous="digit-4" />
                            <input value={digit6} onChange={(ev) => this.setState({
                                digit6: ev.target.value
                            })} onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    let code = digit1 + digit2 + digit3 + digit4 + digit5 + digit6
                                    this.props.verifyAndSendApi(code)
                                    this.setState({
                                        digit1: "",
                                        digit2: "",
                                        digit3: "",
                                        digit4: "",
                                        digit5: "",
                                        digit6: "",
                                    })
                                }
                            }} autoComplete="off" type="number" id="digit-6" name="digit-6" data-previous="digit-5" />
                            <p className="error text-center" id="signupError">{this.props.state.verifyCodeError}</p>
                            <Button className="btn btn-primary btn-embossed"
                                disabled={digit1 && digit2 && digit3 && digit4 && digit5 && digit6 ? false : true}
                                onClick={() => {
                                    let code = digit1 + digit2 + digit3 + digit4 + digit5 + digit6
                                    this.props.verifyAndSendApi(code)
                                    this.setState({
                                        digit1: "",
                                        digit2: "",
                                        digit3: "",
                                        digit4: "",
                                        digit5: "",
                                        digit6: "",
                                    })
                                }}
                            >Verify</Button>
                        </div>
                        <div>
                            Didn't receive the code?<br />
                            <a style={{ color: "blue" }} onClick={() => this.resendOTP()}>Send code again</a><br />
                            <a style={{ color: "blue" }} onClick={() => {
                                this.props.close()
                                this.setState({
                                    digit1: "",
                                    digit2: "",
                                    digit3: "",
                                    digit4: "",
                                    digit5: "",
                                    digit6: "",
                                })
                            }}>Change phone number</a>
                        </div>
                    </div>
                </div>
            </Modal >
        )
    }
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyCodeModal)