import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCookie, getProfileData } from '../../../modules/actions';
import Axios from 'axios'
import VerifyCode from '../../Signup/verifyCode'

class MyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            VerifyModal: false,

        }
    }

    ChangePhoneNumber = () => {
        this.setState({
            VerifyModal: false,
            verifyCodeError: "",
        })
    }

    verifyAndSendApi(code) {
        if (code && this.state && this.state.newUsername) {
            let data2 = new FormData()
            data2.append('username', `91${this.state.newUsername}`)
            data2.append('code', code)
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + `/api/otp/verify/`,
                validateStatus: (status) => {
                    return true;
                },
                data: data2,
            })
                .then((res) => {
                    if (res.data.type !== 'error') {
                        this.updateUsername()
                    } else {
                        let err = "OTP not verified"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${err === "none" ? "Username updated successfully" : err}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                    }
                })
        }
    }

    gettngValues = (ev, name) => {
        if (name === 'newUsername') {
            if (ev.target.value.length < 11) {
                this.setState({
                    [name]: ev.target.value
                })
            }
        } else {
            this.setState({
                [name]: ev.target.value
            })
        }
    }

    updateUsername = () => {
        let preUsername = this.props.user.username;
        let newUsername = this.state.newUsername
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("prev_username", preUsername);
        data.append("new_username", newUsername);
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/update-username/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                this.props.getProfileData()
                let err = response.data.error
                document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${err === "none" ? "Username updated successfully" : err}</strong>`
                document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                setTimeout(() => {
                    document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                }, 2000)
                this.setState({
                    newUsername: "",
                    VerifyModal: false
                })
            })
            .catch(error => {
                console.log(error)
            });
    }

    ValidateEmail = () => {
        if (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(this.state.newEmail)) {
            return (true)
        } else {

            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>You have entered an invalid email address!</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            document.getElementsByClassName('notificationAlert')[0].style.background = 'red'
            document.getElementsByClassName('notificationAlert')[0].style.color = 'white'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                document.getElementsByClassName('notificationAlert')[0].style.background = '#d4edda'
                document.getElementsByClassName('notificationAlert')[0].style.color = '#155724'
            }, 2000)
            return (false)
        }
    }

    updateEmail = () => {
        if (this.ValidateEmail()) {
            let preEmail = this.props.user.email;
            let newEmail = this.state.newEmail
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("prev_email", preEmail);
            data.append("new_email", newEmail);
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/update-email/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    this.props.getProfileData()
                    let err = response.data.error
                    document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${err === "none" ? "Email updated successfully" : err}</strong>`
                    document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    setTimeout(() => {
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    }, 2000)
                    this.setState({
                        newEmail: ""
                    })
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }

    updateAddress = () => {
        let address = this.state.address
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("address", address);
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/update-address/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                this.props.getProfileData()
                let err = response.data.error
                document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${!err ? "Address updated successfully" : err}</strong>`
                document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                setTimeout(() => {
                    document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                }, 2000)
                this.setState({
                    address: ""
                })
            })
            .catch(error => {
                console.log(error)
            });
    }

    updatePassword = () => {
        let oldPass = this.state.oldPass
        let newPass = this.state.newPass
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("old_password", oldPass);
        data.append("new_password", newPass);
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/update-password/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.error) {
                    this.setState({
                        err: response.data.error
                    })
                }
                let err = response.data.error
                document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${!err ? "Password updated successfully" : err}</strong>`
                document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                setTimeout(() => {
                    document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                }, 2000)
                this.props.getProfileData()
                this.setState({
                    oldPass: "",
                    newPass: "",
                    repeatPass: ""
                })
            })
            .catch(error => {
                console.log(error)
            });
    }

    render() {
        return (
            <div className="skinCaree tab-pane fade show active container" id="profile" role="tabpanel" aria-labelledby="wishlist">
                <VerifyCode account={true} open={this.state.VerifyModal} close={this.ChangePhoneNumber} state={this.state} verifyAndSendApi={(code) => {
                    this.verifyAndSendApi(code)
                }} />
                <div className="alert alert-success alert-dismissable" style={{ display: 'none' }}>
                    <strong className="alert-text"></strong>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center allProducts">
                        <h2 style={{ fontSize: '50px !important' }}>General Information</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-profile">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="name" className="lable-control">Current
                                                    Phone</label>
                                                    <input id="currentUsername" type="text" name="name" disabled
                                                        value={this.props.user.username}
                                                        className="form-control accont-fields" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="new-user" className="lable-control">New
                                                    Phone</label>
                                                    <input value={this.state.newUsername} onChange={(ev) => this.gettngValues(ev, 'newUsername')} id="newUsername" type="number" form="new-user" name=""
                                                        className="form-control accont-fields" />
                                                    <p className="ProfileNumberError error text-center" id="loginError"></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <button onClick={() => {
                                                    const data = new FormData();
                                                    data.append('username', this.state.newUsername);
                                                    Axios({
                                                        method: 'post',
                                                        url: process.env.REACT_APP_BACKEND_URL + '/api/check-user/',
                                                        validateStatus: (status) => {
                                                            return true;
                                                        },
                                                        data: data,
                                                    })
                                                        .then(res => {
                                                            console.log(res.data)
                                                            if (!res.data.exist) {
                                                                let data = new FormData()
                                                                data.append('username', `91${this.state.newUsername}`)
                                                                Axios({
                                                                    method: 'post',
                                                                    url: process.env.REACT_APP_BACKEND_URL + `/api/otp/send/`,
                                                                    validateStatus: (status) => {
                                                                        return true;
                                                                    },
                                                                    data,
                                                                })
                                                                    .then(response => {
                                                                        console.log(response.data)
                                                                        this.setState({
                                                                            VerifyModal: true
                                                                        })
                                                                    })
                                                                    .catch(error => {
                                                                        // console.log(error)
                                                                    });
                                                                document.getElementById('loginError').innerText = "";
                                                            } else {
                                                                document.getElementById('loginError').innerText = "Phone Number is already exist";
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                        });


                                                }} className="btn btn-theme">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-profile">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="email" className="lable-control">Current
                                                    Email</label>
                                                    <input disabled value="{{request.user.userprofile.email}}" id="currentEmail" type="text" form="email" name=""
                                                        value={this.props.user.email}
                                                        className="form-control accont-fields" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="email" className="lable-control">New
                                                    Email</label>
                                                    <input value={this.state.newEmail} onChange={(ev) => this.gettngValues(ev, 'newEmail')} id="newEmail" type="email" form="email" name=""
                                                        className="form-control accont-fields" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <button onClick={this.updateEmail} className="btn btn-theme">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-md-12">
                        <div className="card card-profile">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="email" className="lable-control">Address</label>
                                                    <textarea value={this.state.address} onChange={(ev) => this.gettngValues(ev, "address")} id="addressId" className="form-control accont-fields"
                                                        placeholder="Address" rows="4"></textarea>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <button onClick={this.updateAddress} className="btn btn-theme">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 style={{ fontSize: '50px !important' }}>Security Information</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-profile">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="name" className="lable-control">Old
                                                    Password</label>
                                                    <input onFocus={() => this.setState({ err: "" })} value={this.state.oldPass} onChange={(ev) => this.gettngValues(ev, "oldPass")} id="oldPassword" type="password" name="name"
                                                        className="form-control accont-fields" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="new-user" className="lable-control">New
                                                    Password</label>
                                                    <input value={this.state.newPass} onChange={(ev) => this.gettngValues(ev, "newPass")} id="newPasssword" type="password" form="new-user" name=""
                                                        className="form-control accont-fields" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="new-user" className="lable-control">Repeat
                                                    Password</label>
                                                    <input value={this.state.repeatPass} onChange={(ev) => this.gettngValues(ev, "repeatPass")} id="repeatPasssword" type="password" form="new-user" name=""
                                                        className="form-control accont-fields" />
                                                    <label className="validateLable">{this.state.newPass !== this.state.repeatPass && "Password not matched"}{this.state.err}</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <button disabled={this.state.newPass !== this.state.repeatPass} id="updatePassButton" className="btn btn-theme" onClick={this.updatePassword}>Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    user: store.user,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProfileData
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyAccount)