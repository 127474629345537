import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Axios from 'axios'
import { getAllCategories, getBrandProducts, getCookie, getWishlist, quickViewModel } from '../../modules/actions';
import Slider from 'react-slick';
import { Input, Pagination, Select } from 'antd';
import NOAvaliableImage from '../../Images/NoAvaliable.jpeg'
import QuickView from '../quickViewModel/quickView'
import Skeleton from '@material-ui/lab/Skeleton';
import Sliderr from '@material-ui/core/Slider';
import ReactGA from 'react-ga'

class BrandWisePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: 'ali',
            filterProducts: [],
            from: 0,
            to: 9,
            current: 1,
            page: "",
            pricesArr: [0, 4000],
            update: true,

            route: ""
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'auto' });
        this.props.getAllCategories()
        let path = window.location.pathname.split('/')
        let routeMain = ""
        let route = ""
        let id = ""
        let splitter = ""
        if (path.length) {
            routeMain = path[path.length - 1]
            splitter = routeMain.split('-')
            if (splitter.length) {
                if (splitter[0].match("%20")) {
                    route = `All ${splitter[0].split("%20").join(" ")} Products`
                } else {
                    route = `All ${splitter[0]} Products`
                }

                if (splitter[1]) {
                    id = splitter[1]
                }
            }
        }
        this.setState({
            route,
            id
        })

        this.props.getBrandProducts(id)

        let params = new URLSearchParams(window.location.search)
        let current = params.get('page')
        if (current) {
            this.setState({
                current,
            })
        }

        window.scrollTo({ top: 0, behavior: 'auto' });
        ReactGA.pageview(window.location.pathname + window.location.search)

    }

    addToCart = (id, out) => {
        if (!out) {
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("id", id);
            data.append("action", "add");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (response.data.code === "token_not_valid") {
                        let msg = "You have login first"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        let data = response.data.products
                        let msg = response.data.message
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        return data
                    }
                })
                .catch(error => {
                    // console.log(error)
                });
        } else {
            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>Out of stock</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
            }, 2000)
        }
    }

    addToWhishlist = (id) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("product_id", id);
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/wishlist/update/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.code === "token_not_valid") {
                    // let msg = "You have login first"
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    // setTimeout(() => {
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    window.location.href = `/login`
                } else {
                    let data = response.data.products
                    this.props.getWishlist()
                    // setTimeout(() => {
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    return data
                }
            })
            .catch(error => {
                // console.log(error)
            });
    }

    serRec = (a) => {
        if (a.score) {
            localStorage.setItem(`score`, a.score)
        }
    }

    quickView = (obj) => {
        this.props.quickViewModel(obj)
    }

    checkWhishlist = (a, abc) => {
        let whishlistProducts = this.props.whishlistProducts
        if (whishlistProducts) {
            let check = whishlistProducts.filter(b => b.id === a.id)
            if (check.length) {
                return (
                    <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-heart-fill" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                    </svg>
                )
            } else {
                return (
                    <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                    </svg>
                )
            }
        } else {
            return (
                <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                </svg>
            )
        }
    }

    discoutGet = (a, b) => {
        let price = a
        let discounted_price = b
        let discounted_given = Number(price) - Number(discounted_price)
        return `${Number(Math.ceil((discounted_given / price) * 100).toFixed(1)).toFixed(0)}% Off`

    }

    paginate = (ev) => {
        let page = ev
        this.setState({
            current: page
        })

        let params = new URLSearchParams(window.location.search)
        let price = params.get("prices")
        let sort = ""
        let categoryChecked = params.get("cat") ? params.get("cat").split(",") : []

        if (ev) {
            sort = ev
        } else {
            if (params.get("sort")) {
                sort = params.get("sort")
            }
        }
        if (window.screen.width < 600) {
            window.location.href = `${window.location.pathname}?${page ? `page=${page}&&` : ""}${price ? `prices=${price.join(",")}&&` : ""}${sort ? `sort=${sort}&&` : ""}${categoryChecked.length ? `cat=${categoryChecked.join(",")}` : ""}`
        } else {
            this.props.history.push(`${window.location.pathname}?${page ? `page=${page}&&` : ""}${price ? `prices=${price.join(",")}&&` : ""}${sort ? `sort=${sort}&&` : ""}${categoryChecked.length ? `cat=${categoryChecked.join(",")}` : ""}`)
        }
        this.props.getBrandProducts(this.state.id)
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    renderArrows = () => {
        return (
            <div style={{ zIndex: 1 }} className="slider-arrow">
                <i onClick={() => this.slider.slickPrev()} className="fas fa-chevron-left" aria-hidden="true"></i>
                <i onClick={() => this.slider.slickNext()} className="fas fa-chevron-right" aria-hidden="true"></i>
            </div>
        );
    };

    priceFilter = (ev) => {
        let price = this.state.pricesArr

        let params = new URLSearchParams(window.location.search)
        let page = params.get("page")
        let sort = ""
        let categoryChecked = params.get("cat") ? params.get("cat").split(",") : []

        if (ev) {
            sort = ev
        } else {
            if (params.get("sort")) {
                sort = params.get("sort")
            }
        }

        if (window.screen.width < 600) {
            window.location.href = `${window.location.pathname}?${page ? `page=${page}&&` : ""}${this.state.setPrice && price ? `prices=${price.join(",")}&&` : ""}${sort ? `sort=${sort}&&` : ""}${categoryChecked.length ? `cat=${categoryChecked.join(",")}` : ""}`
        } else {
            this.props.history.push(`${window.location.pathname}?${page ? `page=${page}&&` : ""}${this.state.setPrice && price ? `prices=${price.join(",")}&&` : ""}${sort ? `sort=${sort}&&` : ""}${categoryChecked.length ? `cat=${categoryChecked.join(",")}` : ""}`)
        }
        this.props.getBrandProducts(this.state.id)
        window.scrollTo({ top: 0, behavior: 'auto' });

    }

    changeRoute = (a) => {
        if (window.screen.availWidth < 768) {
            window.location.href = `${window.location.pathname}/${a.slug}`
        } else {
            window.open(`${window.location.pathname}/${a.slug}`, '_blank');
        }
    }

    byCategory = () => {
        let categoryChecked = []

        let categories = ""
        if (window.screen.width < 600) {
            categories = document.querySelectorAll('#check2Mob')
        } else {
            categories = document.querySelectorAll('#check2Web')
        }
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].checked) {
                categoryChecked.push(categories[i].value)
            }
        }

        let params = new URLSearchParams(window.location.search)
        let sort = params.get("sort")
        let prices = params.get("prices")
        let page = params.get("page")
        if (window.screen.width < 600) {
            window.location.href = `${window.location.pathname}?${`page=1&&`}${prices ? `prices=${prices}&&` : ""}${sort ? `sort=${sort}&&` : ""}${categoryChecked.length ? `cat=${categoryChecked.join(",")}` : ""}`
        } else {
            this.props.history.push(`${window.location.pathname}?${`page=1&&`}${prices ? `prices=${prices}&&` : ""}${sort ? `sort=${sort}&&` : ""}${categoryChecked.length ? `cat=${categoryChecked.join(",")}` : ""}`)
        }

        this.setState({
            current: 1
        })
        this.props.getBrandProducts(this.state.id)
        window.scrollTo({ top: 0, behavior: 'auto' });

    }


    render() {
        let products = this.props.brandProducts
        let categories = this.props.categoriesWithCount
        return (

            <div className="skinCaree">
                {this.props.quickView &&
                    <QuickView />
                }

                <div className="page container">
                    <div className="alert alert-success alert-dismissable" style={{ display: 'none' }}>
                        <strong className="alert-text"></strong>
                    </div>

                    <div className="allProducts">
                        <h2 style={{ textTransform: "capitalize" }} id='mainHead'>{this.state.route}</h2>
                        <div className="cat-pro">

                            <div className="categories ">
                                {categories && !this.state.category &&
                                    <div>
                                        <h6 className="mobileHIde">By Category<span
                                            style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}
                                            onClick={() => {
                                                let params = new URLSearchParams(window.location.search)
                                                params.delete("cat")
                                                this.props.history.push(window.location.pathname + "?" + params)
                                                let arr = document.querySelectorAll('#check2Web')
                                                for (let i = 0; i < arr.length; i++) {
                                                    arr[i].checked = false
                                                }
                                                this.byCategory()
                                            }}
                                        >Clear</span> </h6>
                                        <div className="byCategories mobileHIde">
                                            <div id="category-div">
                                                {categories &&
                                                    categories.map((a, i) => {
                                                        return (
                                                            a.count ?
                                                                <label key={i} className="check">
                                                                    {a.category}&nbsp;&nbsp;<span style={{ color: "#cfcece" }}>({a.count})</span>
                                                                    <input onChange={() => this.byCategory()} id="check2Web" type="checkbox" className="brand-filter" value={a.category} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                : null
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div>
                                    <h6 className="mobileHIde">By Price <span style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}>Clear</span> </h6>
                                    <div className="byCategories mobileHIde">
                                        <div id="category-div" className="rangeMain">
                                            <div className="filteredProducts"><span className="s">filters</span> <span>({this.props.numberOfProducts})</span></div>
                                            <Sliderr
                                                min={0}
                                                max={4000}
                                                step={100}
                                                value={this.state.pricesArr}
                                                onChange={(ev, newVal) => {
                                                    this.setState({
                                                        pricesArr: newVal,
                                                        setPrice: true
                                                    })
                                                }}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                            />
                                            <div className="rangeInputs">
                                                <Input
                                                    onChange={(ev) => {
                                                        let pricesArr = [...this.state.pricesArr]
                                                        pricesArr[0] = ev.target.value
                                                        this.setState({
                                                            pricesArr: ev.target.value <= pricesArr[1] ? pricesArr : this.state.pricesArr,
                                                        })
                                                    }}
                                                    type="number"
                                                    placeholder="min"
                                                    value={this.state.pricesArr[0]}
                                                />
                                                -
                                                <Input
                                                    onChange={(ev) => {
                                                        let pricesArr = [...this.state.pricesArr]
                                                        pricesArr[1] = ev.target.value
                                                        this.setState({
                                                            pricesArr: ev.target.value >= pricesArr[0] ? pricesArr : this.state.pricesArr,
                                                        })
                                                    }}
                                                    type="number"
                                                    placeholder="max"
                                                    value={this.state.pricesArr[1]}
                                                />
                                                <button onClick={() => this.priceFilter()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                        <path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div id="category-div" className="highToLow">
                                            <div className="filteredProducts"><span className="s">Sort by:</span></div>
                                            <Select defaultValue={"Default"} onChange={(ev) => this.priceFilter(ev)} className="sortFilter">
                                                <Select.Option value="Default">Popular</Select.Option>
                                                <Select.Option value="LTH">Lowest to Highest</Select.Option>
                                                <Select.Option value="HTL">Highest to Lowest</Select.Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="webhide btn " data-toggle="modal" data-target="#exampleModalLong">
                                    <p> FILTER</p>
                                </button>


                                <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog"
                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLongTitle">FILTER</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                {!this.state.category &&
                                                    <div className="byCategories">
                                                        <h6 className="">By Category<span
                                                            style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}
                                                            onClick={() => {
                                                                let params = new URLSearchParams(window.location.search)
                                                                params.delete("cat")
                                                                this.props.history.push(window.location.pathname + "?" + params)
                                                                let arr = document.querySelectorAll('#check2Mob')
                                                                for (let i = 0; i < arr.length; i++) {
                                                                    arr[i].checked = false
                                                                }
                                                                this.byCategory()
                                                            }}
                                                            data-dismiss="modal"
                                                        >Clear</span> </h6>
                                                        <div id="category-div-mob">
                                                            {
                                                                categories &&
                                                                categories.map((a, i) => {
                                                                    return (
                                                                        a.count ?
                                                                            <label key={i} className="check">
                                                                                {a.category}&nbsp;&nbsp;<span style={{ color: "#cfcece" }}>({a.count})</span>
                                                                                <input onChange={() => this.byCategory()} id="check2Mob" type="checkbox" className="brand-filter" value={a.category} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            : null
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className="byCategories" className="rangeMain">
                                                    <h6 className="">By Price <span
                                                        onClick={() => {
                                                            let params = new URLSearchParams(window.location.search)
                                                            params.delete("priceArr")
                                                            params.delete("sortBy")
                                                            this.props.history.push(window.location.pathname + "?" + params)
                                                            this.setState({
                                                                pricesArr: [0, 4000]
                                                            }, () => {
                                                                this.priceFilter()
                                                            })
                                                        }}
                                                        data-dismiss="modal"
                                                        style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}
                                                    >Clear</span> </h6>
                                                    <div id="category-div">
                                                        <div className="filteredProducts"><span className="s">filters</span> <span>({this.props.numberOfProducts})</span></div>
                                                        <Sliderr
                                                            min={0}
                                                            max={4000}
                                                            step={100}
                                                            value={this.state.pricesArr}
                                                            onChange={(ev, newVal) => {
                                                                this.setState({
                                                                    pricesArr: newVal,
                                                                    setPrice: true
                                                                })
                                                            }}
                                                            valueLabelDisplay="auto"
                                                            aria-labelledby="range-slider"
                                                        />
                                                        <div className="rangeInputs">
                                                            <Input
                                                                onChange={(ev) => {
                                                                    let pricesArr = [...this.state.pricesArr]
                                                                    pricesArr[0] = ev.target.value
                                                                    this.setState({
                                                                        pricesArr: ev.target.value <= pricesArr[1] ? pricesArr : this.state.pricesArr,
                                                                    })
                                                                }}
                                                                type="number"
                                                                placeholder="min"
                                                                value={this.state.pricesArr[0]}
                                                            />
                                                            -
                                                            <Input
                                                                onChange={(ev) => {
                                                                    let pricesArr = [...this.state.pricesArr]
                                                                    pricesArr[1] = ev.target.value
                                                                    this.setState({
                                                                        pricesArr: ev.target.value >= pricesArr[0] ? pricesArr : this.state.pricesArr,
                                                                    })
                                                                }}
                                                                type="number"
                                                                placeholder="max"
                                                                value={this.state.pricesArr[1]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="category-div" className="highToLow">
                                                        <div className="filteredProducts"><span className="s">Sort by:</span></div>
                                                        <Select defaultValue={"Default"} onChange={(ev) => this.priceFilter(ev)} className="sortFilter">
                                                            <Select.Option value="Default">Popular</Select.Option>
                                                            <Select.Option value="LTH">Lowest to Highest</Select.Option>
                                                            <Select.Option value="HTL">Highest to Lowest</Select.Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                <button onClick={() => this.priceFilter()} type="button" className="btn btn-primary" data-dismiss="modal">Apply Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shopProducts" id="pagi">
                                <div className="webpro" id="desktop-view-products">
                                    {this.props.productLoading &&

                                        [1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((a, i) => {
                                            return (
                                                <div key={i} className="product">
                                                    <div style={{ width: "100%", height: "100%", alignItems: "center", display: 'flex', justifyContent: "flex-start", flexDirection: "column" }}>
                                                        <Skeleton animation="wave" variant="rect" width={window.innerWidth > 600 ? 230 : "100%"} height="60%" />
                                                        <Skeleton animation="wave" variant="text" width="90%" style={{ marginTop: 10 }} height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="rect" width="50%" height={35} style={{ marginTop: 10, borderRadius: 50 }} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    {!this.props.productLoading && products && products.map((a, i) => {
                                        return (
                                            // i >= this.state.from && i < this.state.to &&
                                            <div key={i} className="product">
                                                {!this.props.productLoading ?
                                                    <>
                                                        <div onClick={(ev) => this.changeRoute(a)} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                                            <div id="hoverDivR62" className="hoverDiv" style={{ opacity: 0 }}>
                                                                {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} className="hoverI update-cart" data-action="add" data-productid={a.id}>
                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                            </svg>
                                                        </div> */}
                                                                <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                    {this.checkWhishlist(a)}
                                                                </div>
                                                            </div>
                                                            {a.stock_quantity === 0 &&
                                                                <div className="outOfStockDiv">Out of stock</div>
                                                            }
                                                        </div>
                                                        <div onClick={(ev) => this.changeRoute(a)} className="product-txt">
                                                            <h6><i>{a.brand} - </i>
                                                                {/* {window.screen.width < 600 ?
                                                                    <a target={window.screen.availWidth < 768 ? false : false} href="#!">{a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}</a>
                                                                    :
                                                                    <NavLink target={window.screen.availWidth < 768 ? false : false} to="#!">{a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}</NavLink>
                                                                } */}
                                                                {a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}
                                                            </h6>
                                                            {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                                            <p className="price web">
                                                                {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                    <>
                                                                        <span className="lightGray">
                                                                            MRP:&nbsp;&nbsp;
                                                                            <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                        <span style={{ float: 'right' }}>
                                                                            <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                            {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                        </span>
                                                                        <span className=""> |</span>
                                                                        <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                    </>
                                                                    :
                                                                    <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                                }
                                                            </p>
                                                            <div className="mob-btn">
                                                                {/* <div className="mob-cart"> */}
                                                                {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                            </svg>
                                                            <div>
                                                                <p> ADD TO CART </p>
                                                            </div>
                                                        </button> */}
                                                                {/* </div> */}
                                                                <p className="price">
                                                                    {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                        <>
                                                                            <span className="lightGray">

                                                                                <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                            <span style={{ float: 'right' }}>
                                                                                <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                                {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                            </span>
                                                                            <span className=""> |</span>
                                                                            <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                        </>
                                                                        :
                                                                        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                                    }
                                                                </p>
                                                                {/* <div className="mob-wish">
                                                                    <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                        {this.checkWhishlist(a, "a")}
                                                                        <div id="noOpen">
                                                                            <p id="noOpen"> WISHLIST </p>
                                                                        </div>
                                                                    </button>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        {/* <hr /> */}
                                                        <div className="button-div">
                                                            <button onClick={() => this.quickView(a)}>
                                                                Quick View
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <div style={{ width: "100%", height: "100%", alignItems: "center", display: 'flex', justifyContent: "flex-start", flexDirection: "column" }}>
                                                        <Skeleton animation="wave" variant="rect" width={window.innerWidth > 600 ? 230 : "100%"} height="60%" />
                                                        <Skeleton animation="wave" variant="text" width="90%" style={{ marginTop: 10 }} height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="rect" width="50%" height={35} style={{ marginTop: 10, borderRadius: 50 }} />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                        // :
                                        // this.state.filterProducts.map((a, i) => {
                                        //     // console.log(a.name.length)
                                        //     return (
                                        //         i >= this.state.from && i < this.state.to &&
                                        //         <div key={i} className="product">
                                        //             <div onClick={(ev) => this.changeRoute(a, ev)} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                        //                 <div id="hoverDivR62" className="hoverDiv" style={{ opacity: 0 }}>
                                        //                     {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} className="hoverI update-cart" data-action="add" data-productid={a.id}>
                                        //                         <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        //                             <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                        //                         </svg>
                                        //                     </div> */}
                                        //                     <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                        //                         {this.checkWhishlist(a)}
                                        //                     </div>
                                        //                 </div>
                                        //                 {a.stock_quantity === 0 &&
                                        //                     <div className="outOfStockDiv">Out of stock</div>
                                        //                 }
                                        //             </div>
                                        //             <div onClick={(ev) => this.changeRoute(a, ev)} className="product-txt">
                                        //                 <h6><i>{a.brand} - </i> <NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/${this.state.mainPage}/${a.slug}`}>{a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}</NavLink></h6>
                                        //                 {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                        //                 <p className="price web">
                                        //                     {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                        //                         <>
                                        //                             <span className="lightGray">
                                        //                                 MRP:&nbsp;&nbsp;
                                        //                                                 <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                        //                             <span style={{ float: 'right' }}>
                                        //                                 <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                        //                                 {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                        //                                 <span className="lightGray two"> |
                                        //                                                 </span>
                                        //                             </span>
                                        //                             <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                        //                         </>
                                        //                         :
                                        //                         <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                        //                     }
                                        //                 </p>
                                        //                 <div className="mob-btn">
                                        //                     {/* <div className="mob-cart"> */}
                                        //                     {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                        //                         <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        //                             <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                        //                         </svg>
                                        //                         <div>
                                        //                             <p> ADD TO CART </p>
                                        //                         </div>
                                        //                     </button> */}
                                        //                     {/* </div> */}
                                        //                     <p className="price">
                                        //                         {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                        //                             <>
                                        //                                 <span className="lightGray">
                                        //                                     MRP:&nbsp;&nbsp;
                                        //                                                 <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                        //                                 <span style={{ float: 'right' }}>
                                        //                                     <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                        //                                     {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                        //                                     <span className="lightGray two"> |
                                        //                                                 </span>
                                        //                                 </span>
                                        //                                 <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                        //                             </>
                                        //                             :
                                        //                             <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                        //                         }
                                        //                     </p>
                                        //                     <div className="mob-wish">
                                        //                         <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                        //                             {this.checkWhishlist(a, "a")}
                                        //                             <div id="noOpen">
                                        //                                 <p id="noOpen"> WISHLIST </p>
                                        //                             </div>
                                        //                         </button>
                                        //                     </div>
                                        //                 </div>
                                        //             </div>
                                        //             {/* <hr /> */}
                                        //             <div className="button-div">
                                        //                 <button onClick={() => this.quickView(a)}>
                                        //                     Quick View
                                        //                 </button>
                                        //             </div>
                                        //         </div>
                                        //     )
                                        // })
                                    }
                                    {products && !products.length &&
                                        <h3 style={{ display: "flex", alignItems: "center", height: "50%" }}>No products available for filter criteria</h3>
                                    }
                                </div>
                                {!this.state.filter &&
                                    <Pagination showQuickJumper={false} href="#pagi" responsive onChange={(ev) => this.paginate(ev)} showQuickJumper pageSize={18} current={this.state.current} defaultCurrent={1} total={this.props.numberOfProducts} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = ({ store }) => {
    return {
        is_authenticated: store.is_authenticated,
        user: store.user,
        numberOfProducts: store.numberOfProducts,
        whishlistProducts: store.whishlistProducts,
        categories: store.categories,
        quickView: store.quickView,
        isLoading: store.isLoading,
        productLoading: store.productLoading,
        brandProducts: store.brandProducts,
        allCategories: store.allCategories,
        categoriesWithCount: store.categoriesWithCount
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            quickViewModel,
            getWishlist,
            getBrandProducts,
            getAllCategories
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BrandWisePage))