import { Modal, Tooltip } from 'antd';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCookie } from '../../modules/actions';

import one from '../../Images/1.jpg'
import two from '../../Images/2.jpg'
import three from '../../Images/3.jpg'
import four from '../../Images/4.jpg'
import five from '../../Images/5.jpg'
import six from '../../Images/6.jpg'
import seven from '../../Images/7.jpg'
import eight from '../../Images/8.jpg'
import nine from '../../Images/9.jpg'
import ten from '../../Images/10.jpg'
import eleven from '../../Images/11.jpg'
import Axios from 'axios';

class SummaryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            skinConcernsss: [],
            skinConcernsArr: [],
        }
    }

    componentDidMount() {
        let skinRoutines = getCookie("skinRoutines")
        let skinConcernss = getCookie("skinConcerns")
        let products = skinRoutines && skinRoutines.split(",")
        let skinConcernsss = skinConcernss && skinConcernss.split(",")
        this.setState({ products, skinConcernsss })
        console.log("testuser",this.props.user)
    }

    openModel = (name) => {
        this.setState({
            visible: true,
            [name]: true
        })
    }

    skinConcerns = (name) => {
        let skinConcernsArr = this.state.skinConcernsArr
        if (skinConcernsArr.includes(name)) {
            skinConcernsArr.splice(skinConcernsArr.indexOf(name), 1)
        } else {
            skinConcernsArr.push(name)
        }
        this.setState({
            skinConcerns: skinConcernsArr.join(",")
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    }


    submitt = (ev, RouteName) => {
        if (this.props.user) {
            window.open(`/suggestions?${RouteName}`)
            // console.log(this.props.user)
            // const data = new FormData();
            // const token = getCookie('authToken');

            // let retake = localStorage.getItem('retake')
            // data.append('age', getCookie('age'));
            // data.append('pollution', getCookie('pollution'));
            // data.append('isSunExposed', "");
            // data.append('sleepHrs', getCookie('sleepHrs'));
            // data.append('skinType', getCookie('skinType'));
            // data.append('isSensitive', getCookie('isSensitive'));
            // data.append('skinConcerns', getCookie('skinConcerns'));
            // // data.append('skinRoutines', getCookie('skinRoutines'));
            // data.append('brand_type', getCookie("brand_type"));
            // data.append('skin_tone', getCookie("skin_tone"));
            // data.append('morning_skin_feels', getCookie("morning_skin_feels"));
            // data.append('moisturized_skin_feels', getCookie("moisturized_skin_feels"));
            // data.append('have_acne', getCookie("have_acne"));
            // data.append('water_options', getCookie("water_options"));
            // data.append('hair_volume', getCookie("hair_volume"));
            // data.append('hair_types', getCookie("hair_types"));
            // data.append('hair_looks', getCookie("hair_looks"));
            // data.append('hair_length', getCookie("hair_length"));
            // data.append('hair_oily_time', getCookie("hair_oily_time"));
            // data.append('hair_tools', getCookie("hair_tools"));
            // data.append('wears_makeup', getCookie("wears_makeup"));
            // data.append('hairConcerns', getCookie("HairConcerns"));
            // Axios({
            //     method: 'post',
            //     url: process.env.REACT_APP_BACKEND_URL + '/api/quiz/update/',
            //     headers: {
            //         'Content-Type': 'application/x-www-form-urlencoded',
            //         Authorization: `Bearer ${token}`,
            //     },
            //     data: data,
            //     validateStatus: (status) => {
            //         return true;
            //     },
            // })
            //     .then(response => {
            //         console.log(response)
            //         window.open(`/suggestions?${RouteName}`)
            //         // document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
            //         // this.setCookie('authToken', token, 2)
            //     })
            //     .catch(error => {
            //         // console.log(error)
            //     })
        } else {
            window.location.href = "/sign-up"
        }
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getSummaries(user){

        // Skin Summary

        // Skin Type
        if (user.skin_type == 'oily') {
            document.skinSummary = "Sometimes over secretion of sebum can make your face shine like a 20 watt bulb."
        } else if (user.skin_type == 'dry') {
            document.skinSummary = "Your skin is characterised by poor epidermal function, which means your skin can be sensitive to certain ingredients."
        } else if (user.skin_type == 'combination') {
            document.skinSummary = "Your skin type is a combination of oily and dry skin. A little care and a good routine can give you an even, flawless and glossy skin."
        } else {
            document.skinSummary = "You're one of those fortunate ones with skin which has a fairly balanced production of sebum and moisture."
        }

        // Have acne
        if (user.have_acne == 'Sometimes') {
            document.skinSummary = document.skinSummary + " From pollution to sun damage, your skin goes through a constant battle. Maintaining a proper skincare routine and a clean skin can reduce the chances of outbreaks to nil."
        } else if (user.have_acne == 'Often') {
            document.skinSummary = document.skinSummary + " Frequent outbreaks are a bummer, but not irremediable. The key to avoiding breakouts is to keep your skin clean and moisturized."
        } else {
            document.skinSummary = document.skinSummary + " Since you never get acne, You must be doing a good job at keeping your skin clean and hydrated."
        }

        // Is sensitive
        if (user.isSensitive == true) {
            document.skinSummary = document.skinSummary + " Your skin is highly reactive to certain environmental elements or skincare products i.e your skin exhibits rashes, flakiness or burns when it is exposed to a trigger."
        } else {
            document.skinSummary = document.skinSummary + " Even if your skin is not sensitive it's good to be careful while buying skin care. A variety of chemical ingredients goes into skincare items and some of those components do not react well with each other."
        }

        // Makeup
        if (user.wears_makeup == true) {
            document.skinSummary = document.skinSummary + " Makeup is an excellent confidence booster. When applied in a correct order, makeup can be beneficial for your skin."
        } else {
            document.skinSummary = document.skinSummary + " It is absolutely fine and kinda beneficial if you don't prefer wearing makeup."
        }

        // Skin Strength

        // Skin Type
        if (user.skin_type == 'oily') {
            document.skinStrength = "Oily skin ought to drive you crazy but we have some good news. Your skin has more natural moisture compared to other skin types and is therefore less prone to premature wrinkles or ageing."
        } else if (user.skin_type == 'dry') {
            document.skinStrength = "Dry skin is a lot less likely to attract dirt compared to other skin types. Pores are also less visible."
        } else if (user.skin_type == 'combination') {
            document.skinStrength = "Your skin's natural oils act as a barrier against air pollution, wind and sun."
        } else {
            document.skinStrength = "You are likely to have a smooth skin texture with good blood circulation, which also makes your skin less prone to blemishes."
        }

        // Have acne
        if (user.have_acne == 'Sometimes') {
            document.skinStrength = document.skinStrength + " If acnes aren't a frequent occurrence, you are likely to have a strong barrier function. Just a little caution and you're good to go."
        } else if (user.have_acne == 'Often') {
            document.skinStrength = document.skinStrength + " Acnes have no pros. But once you figure out your skin type, with appropriate products and a good diet, you can improve your skin health and keep outbreaks at bay."
        } else {
            document.skinStrength = document.skinStrength + " No acne is a sign of healthy skin. So kudos on taking good care of your skin!"
        }

        // Is sensitive
        if (user.isSensitive == true) {
            document.skinStrength = document.skinStrength + " Herbal treatment is usually the best choice for sensitive skin. A little caution and care can take all your troubles away."
        }

        // Makeup
        if (user.wears_makeup == true) {
            document.skinStrength = document.skinStrength + " A good blend enhances your appearance. Makeup with necessary and harmless ingredients also creates a barrier around your skin, thereby protecting your skin from pollution, sun damage et al."
        } else {
            document.skinStrength = document.skinStrength + " Some cosmetic products are made with harmful chemical ingredients, so if you are confident with your natural skin it's best that you avoid unnecessary layers."
        }

        // Skin Weakness

        // Skin Type
        if (user.skin_type == 'oily') {
            document.skinWeakness = "You probably already know but we'll still say it. Your skin is susceptible to frequent breakouts, large pores, blackheads and whiteheads."
        } else if (user.skin_type == 'dry') {
            document.skinWeakness = "With low skin elasticity, your skin is likely to feel rough and dull when kept unmoisturized."
        } else if (user.skin_type == 'combination') {
            document.skinWeakness = "Large and clogged pores, oily T-zone and dry patches are some of the concerns you might have to deal with if you are not careful."
        } else {
            document.skinWeakness = "Your skin is likely to grow drier with age. Hence, always keep your skin's moisture level in check."
        }

        // Have acne
        if (user.have_acne == 'Sometimes') {
            document.skinWeakness = document.skinWeakness + " Though a seldom occurrence, acnes wreck your flawless appearance. So you must take all kinds of measures to avoid outbreaks."
        } else if (user.have_acne == 'Often') {
            document.skinWeakness = document.skinWeakness + " Acnes are not a sign of a healthy skin. So you must take all possible measures to improve your skin quality."
        } else {
            document.skinWeakness = document.skinWeakness + " Even if you don't get acne, your skin is still the most exposed part of your body. So eat lots of fruits, drink sufficient water and apply suitable products in order to maintain beautiful, healthy skin."
        }

        // Is sensitive
        if (user.isSensitive == true) {
            document.skinWeakness = document.skinWeakness + " You must be very careful while choosing products because even a tiny amount of a certain component can cause your skin to react."
        } 

        // Makeup
        if (user.wears_makeup == true) {
            document.skinWeakness = document.skinWeakness + " Most of the cosmetic products in the market contain preservatives, chemicals and colours. A wrong blend of products can harm your skin. Heavy makeup during the day can lead to outbreaks or discomfort."
        } else {
            document.skinWeakness = document.skinWeakness + " Makeup can be uncomfortable for some people, but a light layer of face powder during a day can prevent excess sweating and make you feel fresh."
        }
        
        // Hair Summary

        // Hair Type
        if (user.hair_types == 'Oily') {
            document.hairSummary = "Overproduction of sebum causes your hair to look greasy and slick."
        } else if (user.hair_types == 'Dry') {
            document.hairSummary = "Your hair lacks natural lubrication, which means either your hair doesn't produce sufficient sebum or fails to retain moisture."
        } else {
            document.hairSummary = "Your hair quality is great as it's neither too oily or dry."
        }

        // Hair looks
        if (user.hair_looks == 'Wavy') {
            document.hairSummary = document.hairSummary + " Wavy hair is easy to style as it's not as stubborn as curly hair. Wavy hair gets the best of both worlds as it appears voluminous unlike straight hair and isn't unmanageably frizzy like curly hair."
        } else if (user.hair_looks == 'Curly') {
            document.hairSummary = document.hairSummary + " Taking care of curly hair can be strenuous. You probably spend a good chunk of your day detangling those unruly bends and curves."
        } else {
            document.hairSummary = document.hairSummary + " Maintaining straight hair is a lot easier compared to other hair types. When maintained and protected properly straight hair can appear lustrous, resilient and healthier than other hair types."
        }

        // Hair Strength

        // Hair Type
        if (user.hair_types == 'Oily') {
            document.hairStrength = "A balanced sebum production protects your hair naturally."
        } else if (user.hair_types == 'Dry') {
            document.hairStrength = "If you have moderately dry hair you don't need to wash regularly, as frequent washes can ruin your hair quality."
        } else {
            document.hairStrength = "Your hair is not too dry or not too oily."
        }

        // Hair looks
        if (user.hair_looks == 'Wavy') {
            document.hairStrength = document.hairStrength + " Natural oils can evenly moisturize your hair. Also wavy hair has more texture than straight hair."
        } else if (user.hair_looks == 'Curly') {
            document.hairStrength = document.hairStrength + " Maintaining good curls requires patience and good hair products. Curly hair is unique and can be styled in a thousand ways."
        } else {
            document.hairStrength = document.hairStrength + " Straight hair is usually evenly hydrated as sebum can make it to all corners of your head making it tough and nourished."
        }

        // Hair Weakness

        // Hair Type
        if (user.hair_types == 'Oily') {
            document.hairWeakness = "You might think washing your hair frequently is the solution, but it actually enhances sebum production. Don't worry, there are a myriad of hair care products dedicated to this particular cause."
        } else if (user.hair_types == 'Dry') {
            document.hairWeakness = "Extreme dryness can make your hair fragile, vulnerable and dull. A lot of factors can cause dry hair such as styling products, nutritional deficiencies and sun damage."
        } else {
            document.hairWeakness = "Avoid using products which are not suitable for your hair type."
        }

        // Hair looks
        if (user.hair_looks == 'Wavy') {
            document.hairWeakness = document.hairWeakness + " Your hair is a little less manageable than straight hair but with good care and suitable hair products, wavy hair can stand out from the crowd."
        } else if (user.hair_looks == 'Curly') {
            document.hairWeakness = document.hairWeakness + " The twists and turns make your hair more fragile and delicate. Heavy curls restrict sebum from travelling to the top,keeping most of your hair from nourishment. This is one of the reasons why curly hair is more prone to damage."
        } else {
            document.hairWeakness = document.hairWeakness + " Straight-haired people often complain about hair volume. As hair follicles are smooth, sebum is sometimes visible on the top which makes your hair look greasy or oily."
        }

        if (this.props.user && this.props.user.skin_type) {
            this.props.user.skin_type = this.props.user.skin_type.charAt(0).toUpperCase() + this.props.user.skin_type.slice(1)
        }
        
        if(this.props.user && this.props.user.SkinConcerns) {
            var value = this.props.user.SkinConcerns, parts = value.split(', ');
            for(var i in parts) {
                parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1)
                console.log(parts[i])
            }
            console.log(parts)
    
            this.props.user.SkinConcerns = parts.join(', ');
        }

        if(this.props.user && this.props.user.HairConcerns) {
            var value = this.props.user.HairConcerns, parts = value.split(', ');
            for(var i in parts) {
                parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1)
                console.log(parts[i])
                // parts[i].charAt(0).toUpperCase() + parts[i].slice(1)
            }
            console.log(parts)
    
            this.props.user.HairConcerns = parts.join(', ');
        }
    }

    render() {
        console.log(this.props.user)
        this.getSummaries(this.props.user)
        document.title = "Glam profile summary"
        return (
            <div className="summaryMain">
                <div className="summaryBorderDiv">
                    <p className="hiText">Hi {this.props.user ? <span style={{ textTransform: "capitalize" }}>{this.props.user.firstname}</span> : ""}, here is you glam profile summary</p>
                    <table className="summaryTable">
                        <tr>
                            <th>Skin Type</th>
                            <th>Skin Concern(s)</th>
                            <th>Sensitive Skin</th>
                            {/* <th>Environment</th> */}
                            <th>Hair Type</th>
                            <th>Hair Concerns</th>
                        </tr>
                        <tr>
                            <td>{this.props.user && this.props.user.skin_type}</td>
                            <td>{this.props.user && this.props.user.SkinConcerns}</td>
                            <td>{this.props.user && this.props.user.isSensitive ? "Yes" : "No"}</td>
                            {/* <td>{this.props.user && this.props.user.pollution}</td> */}
                            <td>{this.props.user && this.props.user.hair_types}</td>
                            <td>{this.props.user && this.props.user.HairConcerns}</td>
                        </tr>
                    </table>

                    <table className="summaryTable summaryTableTwo">
                        <tr>
                            <th>Skin Type</th>
                            <th>Skin Concers</th>
                        </tr>
                        <tr>
                            <td>{this.props.user && this.props.user.skin_type}</td>
                            <td>{this.props.user && this.props.user.SkinConcerns}</td>
                        </tr>
                    </table>
                    <table className="summaryTable summaryTableTwo">
                        <tr>
                            <th>Sensitive Skin</th>
                            <th>Environment</th>
                        </tr>
                        <tr>
                            <td>{this.props.user && this.props.user.isSensitive ? "Yes" : "No"}</td>
                            <td>{this.props.user && this.props.user.pollution}</td>
                        </tr>
                    </table>
                    <table className="summaryTable summaryTableTwo">
                        <tr>
                            <th>Hair Type</th>
                            <th>Hair Concerns</th>
                        </tr>
                        <tr>
                            <td>{this.props.user && this.props.user.hair_types}</td>
                            <td>{this.props.user && this.props.user.HairConcerns}</td>
                        </tr>
                    </table>

                    <div className="skinSummary">
                        <h5>SKIN SUMMARY</h5>
                            <p style={{ textAlign: "Justify" }}>{document.skinSummary}</p>
                        <br></br>
                        <h5>HAIR SUMMARY</h5>
                            <p>{document.hairSummary}</p>
                    </div>
                    <div className="strengthWeak">
                        <div className="strength">
                            <h5>SKIN STRENGTH</h5>
                                <p style={{ textAlign: "Justify" }}>{document.skinStrength}</p>
                            <br></br>
                            <h5>HAIR STRENGTH</h5>
                                <p style={{ textAlign: "Justify" }}>{document.hairStrength}</p>
                        </div>

                        <div className="weak">
                            <h5>SKIN WEAKNESS</h5>
                                <p style={{ textAlign: "Justify" }}>{document.skinWeakness}</p>
                            <br></br>
                            <h5>HAIR WEAKNESS</h5>
                                <p style={{ textAlign: "Justify" }}>{document.hairWeakness}</p>
                        </div>
                    </div>
                    {/* <div className="editableDiv">
                        <div className="first">
                            <div className="div">
                                <h5>PRODUCTS <i class="fas fa-pen"></i></h5>
                                <div className="badgessMain">
                                    {this.state.products.map((a, i) => {
                                        return (
                                            <div className="badgess" key={i}>
                                                {a}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="div">
                                <h5>ALLEGENS <i class="fas fa-pen"></i></h5>
                                <div className="badgessMain">
                                    <div className="badgess">
                                        No
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="second">
                            <div className="div">
                                <h5>SKIN CONCERNS <i onClick={() => this.openModel("concerns")} class="fas fa-pen"></i></h5>
                                <div className="badgessMain">
                                    {this.state.skinConcernsss.map((a, i) => {
                                        return (
                                            <div className="badgess" key={i}>
                                                {a}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="div">
                                <h5>ALLEGENS <i class="fas fa-pen"></i></h5>
                                <div className="badgessMain">
                                    <div className="badgess">
                                        5k
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                */}
                </div>
                <Modal
                    title="Basic Modal"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    width="80%"
                    okText="update"
                    onCancel={this.handleCancel}
                    cancelText="close"
                >
                    {this.state.concerns &&
                        <div className="skinConcernimages">

                            <button onClick={() => this.skinConcerns("acne")} id="checkkk1" type="button" className="btn btn-primary"
                                value="acne">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={one} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Wrinkles")} id="checkkk2" name="overwrite" type="button" className="btn btn-primary"
                                value="Wrinkles">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={two} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Dark Circles")} id="checkkk3" name="overwrite" type="button" className="btn btn-primary"
                                value="Dark Circles">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={three} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Pores")} id="checkkk4" name="overwrite" type="button" className="btn btn-primary"
                                value="Pores">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={four} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Oiliness")} id="checkkk5" name="overwrite" type="button" className="btn btn-primary"
                                value="Oiliness">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={five} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Hyperpigmentation")} id="checkkk6" name="overwrite" type="button" className="btn btn-primary"
                                value="Hyperpigmentation">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={six} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Dryness")} id="checkkk7" name="overwrite" type="button" className="btn btn-primary"
                                value="Dryness">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={seven} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Redness")} id="checkkk8" name="overwrite" type="button" className="btn btn-primary"
                                value="Redness">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={eight} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Sun Damage")} id="checkkk9" name="overwrite" type="button" className="btn btn-primary"
                                value="Sun Damage">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={nine} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Dark spots")} id="checkkk10" name="overwrite" type="button" className="btn btn-primary"
                                value="Dark spots">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={ten} />
                                </Tooltip>
                            </button>

                            <button onClick={() => this.skinConcerns("Uneven skin")} id="checkkk11" name="overwrite" type="button" className="btn btn-primary"
                                value="Uneven skin">
                                <Tooltip placement="left" title="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).">
                                    <img src={eleven} />
                                </Tooltip>
                            </button>


                        </div>
                    }
                </Modal>
                <div className="row mt-3">
                    <div className="col-md-12 text-center">
                        <button className="col-sm-4 btn btn-login reccom" onClick={(ev) => this.submitt(ev, "Skin Care")}>View Skin recommendation</button>
                        <button className="col-sm-4 btn btn-login reccom" onClick={(ev) => this.submitt(ev, "Hair Care")}>View Hair recommendation</button>
                        <button className="col-sm-4 btn btn-login reccom" onClick={(ev) => this.submitt(ev, "Body Care")}>View Body recommendation</button>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    user: store.user
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SummaryPage)