import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { getCookie } from '../../modules/actions'
import Axios from 'axios'
import { loginUser, loginUserSocial } from '../../modules/store';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import googleIcon from '../../Images/search.png'
import VerifyCode from './verifyCode';
import $ from 'jquery'

function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}


class CopyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            repeatPassword: "",
            username: "",
            VerifyModal: false,
            verifyCodeError: "",
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'auto' });
        // var input = document.querySelector("#phone");
        // window.intlTelInput(input, ({
        //     // options here
        //     initialCountry: "",
        //     geoIpLookup: function (success, failure) {
        //         $.get("http://ipinfo.io", function () { }, "jsonp").always(function (resp) {
        //             var countryCode = (resp && resp.country) ? resp.country : "";
        //             success(countryCode);
        //             console.log(countryCode);
        //         });
        //     },
        //     utilsScript: "../../../public/inputTellBuild/js/utils.js",

        // }));

        if (!getCookie('quiz')) {
            window.location.href = "/quiz"
        }
    }

    gettingValues = (ev, name) => {
        var input = document.querySelector("#phone");
        // var iti = window.intlTelInputGlobals.getInstance(input);
        if (name === "username") {
            if ((ev.target.value).toString().length <= 10) {
                this.setState({
                    [name]: ev.target.value,
                    // countryDialCode: iti.getSelectedCountryData().dialCode
                })
            }
        } else {
            this.setState({
                [name]: ev.target.value
            })
        }
    }

    validateFirstName() {
        var value = this.state.firstname;

        if (value.length == 0 || isEmptyOrSpaces(value)) {
            document.getElementById('signupError').innerText = "Enter First Name";
            return false;
        }
        if (value.length > 30) {
            document.getElementById('signupError').innerText = "First Name should be less than 30 characters";
            return false;
        }
        return true;
    }

    validateLastName() {
        var value = this.state.lastname;

        if (value.length == 0 || isEmptyOrSpaces(value)) {
            document.getElementById('signupError').innerText = "Enter Last Name";
            return false;
        }
        if (value.length > 150) {
            document.getElementById('signupError').innerText = "Last Name should be less than 150 characters";
            return false;
        }
        return true;

    }

    validateEmail() {
        var value = this.state.email;
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
            return (true)
        } else {
            document.getElementById('signupError').innerText = "Enter a valid email";
            return (false)
        }
    }

    validatePasswords() {
        var pass1 = this.state.password
        var pass2 = this.state.repeatPassword

        if (pass1 !== pass2) {
            document.getElementById('signupError').innerText = "Passwords do not match";
            return false;
        }
        return true;
    }

    validatePasswordLength() {
        var pass1 = this.state.password
        var pass2 = this.state.repeatPassword

        if (pass1.length < 6 || pass2.length < 6) {
            document.getElementById('signupError').innerText = "Password should be at-least 6 characters";
            return false;
        }
        return true;
    }

    validatePhoneNumber() {
        var PhoneNO = this.state.username
        if (PhoneNO.length < 10) {
            document.getElementById('signupError').innerText = !PhoneNO ? "Phone Number is required" : PhoneNO.length < 10 ? "PhoneNumber should be at-least 10 characters" : "";
            return false;
        }
        return true;
    }
    validateSignup(ev) {
        ev.preventDefault()
        if (this.validateFirstName() &&
            this.validateLastName() &&
            this.validateEmail() &&
            this.validatePasswords() &&
            this.validatePasswordLength()
            &&
            this.validatePhoneNumber()
        ) {
            var input = document.querySelector("#phone");
            // var iti = window.intlTelInputGlobals.getInstance(input);
            document.getElementById('signupError').innerText = "";
            const data = new FormData();
            data.append('username', this.state.username);
            data.append('email', this.state.email);
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/check-user-email/',
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(res => {
                    if (!res.data.email_exists && !res.data.username_exists) {
                        let data2 = new FormData()
                        data2.append("username", `91${this.state.username}`)
                        // Axios.get(`http://api.msg91.com/api/sendotp.php?authkey=344496AzdzXjgqMWUf5f86d63aP1&mobile=0091${this.state.username}&otp_length=6`)
                        // Axios({
                        //     method: 'post',
                        //     url: process.env.REACT_APP_BACKEND_URL + `/api/otp/send/`,
                        //     validateStatus: (status) => {
                        //         return true;
                        //     },
                        //     data: data2,
                        // })
                        //     .then((res) => {
                        //         this.setState({
                        //             VerifyModal: true
                        //         })
                        //     }).catch((err) => {
                        //         console.log(err.message)
                        //     })
                        this.verifyAndSendApi()
                    } else {
                        if (res.data.email_exists && res.data.username_exists) {
                            document.getElementById('signupError').innerText = "Email and Phone Number already registered. \n Try another Email and Phone Number ";
                        }
                        else if (res.data.email_exists) {
                            document.getElementById('signupError').innerText = "Email already registered. Try another Email";
                        } else if (res.data.username_exists) {
                            document.getElementById('signupError').innerText = "Phone Number already registered. Try another Phone Number";
                        }
                    }
                })
                .catch(error => {
                    // console.log(error)
                });
        }

        return false;
    }
    verifyAndSendApi(code) {
        // console.log(this.state)
        // if (code && this.state && this.state.username) {
        //     var input = document.querySelector("#phone");
        //     // var iti = window.intlTelInputGlobals.getInstance(input);
        //     // console.log(getCookie('age'), getCookie('pollution'), getCookie('isSunExposed'), "before verfication")
        //     let data2 = new FormData()
        //     data2.append('username', `91${this.state.username}`)
        //     data2.append('code', code)
        //     // Axios.get(`http://api.msg91.com/api/verifyRequestOTP.php?authkey=344496AzdzXjgqMWUf5f86d63aP1&mobile=0091${this.state.username}&otp=${code}`)
        //     Axios({
        //         method: 'post',
        //         url: process.env.REACT_APP_BACKEND_URL + `/api/otp/verify/`,
        //         validateStatus: (status) => {
        //             return true;
        //         },
        //         data: data2,
        //     })
        //         .then((res) => {
        //             if (res.data.type === "error" && res.data.message !== "already_verified") {
        //                 this.setState({
        //                     verifyCodeError: "Please Enter a Valid OTP"
        //                 })
        //             } else {
        //                 console.log(getCookie('age'), getCookie('pollution'), getCookie('isSunExposed'), "after verifcation")
        //                 const data = new FormData();
        //                 data.append('firstname', this.state.firstname);
        //                 data.append('lastname', this.state.lastname);
        //                 data.append('email', this.state.email.toLowerCase());
        //                 data.append('username', this.state.username);
        //                 data.append('password1', this.state.password);
        //                 data.append('age', getCookie('age'));
        //                 data.append('pollution', getCookie('pollution'));
        //                 data.append('isSunExposed', "");
        //                 data.append('sleepHrs', getCookie('sleepHrs'));
        //                 data.append('skinType', getCookie('skinType'));
        //                 data.append('isSensitive', getCookie('isSensitive'));
        //                 data.append('skinConcerns', getCookie('skinConcerns'));
        //                 // data.append('skinRoutines', getCookie('skinRoutines'));
        //                 data.append('brand_type', getCookie("brand_type"));
        //                 data.append('skin_tone', getCookie("skin_tone"));
        //                 data.append('morning_skin_feels', getCookie("morning_skin_feels"));
        //                 data.append('moisturized_skin_feels', getCookie("moisturized_skin_feels"));
        //                 data.append('have_acne', getCookie("have_acne"));
        //                 data.append('water_options', getCookie("water_options"));
        //                 data.append('hair_volume', getCookie("hair_volume"));
        //                 data.append('hair_types', getCookie("hair_types"));
        //                 data.append('hair_looks', getCookie("hair_looks"));
        //                 data.append('hair_length', getCookie("hair_length"));
        //                 data.append('hair_oily_time', getCookie("hair_oily_time"));
        //                 data.append('hair_tools', getCookie("hair_tools"));
        //                 data.append('wears_makeup', getCookie("wears_makeup"));
        //                 data.append('hairConcerns', getCookie("HairConcerns"));

        //                 let obj = {
        //                     firstname: this.state.firstname,
        //                     lastname: this.state.lastname,
        //                     email: this.state.email.toLowerCase(),
        //                     username: this.state.username,
        //                     password1: this.state.password,
        //                     age: getCookie('age'),
        //                     pollution: getCookie('pollution'),
        //                     sleepHrs: getCookie('sleepHrs'),
        //                     skinType: getCookie('skinType'),
        //                     isSensitive: getCookie('isSensitive'),
        //                     skinConcerns: getCookie('skinConcerns'),
        //                     brand_type: getCookie("brand_type"),
        //                     morning_skin_feels: getCookie("morning_skin_feels"),
        //                     moisturized_skin_feels: getCookie("moisturized_skin_feels"),
        //                     have_acne: getCookie("have_acne"),
        //                     water_options: getCookie("water_options"),
        //                     hair_volume: getCookie("hair_volume"),
        //                     hair_types: getCookie("hair_types"),
        //                     hair_looks: getCookie("hair_looks"),
        //                     hair_length: getCookie("hair_length"),
        //                     hair_oily_time: getCookie("hair_oily_time"),
        //                     hair_tools: getCookie("hair_tools"),
        //                     wears_makeup: getCookie("wears_makeup"),
        //                     hairConcerns: getCookie("HairConcerns")
        //                 }


        //                 Axios({
        //                     method: 'post',
        //                     url: process.env.REACT_APP_BACKEND_URL + '/api/signup/',
        //                     headers: {
        //                         'Content-Type': 'application/x-www-form-urlencoded'
        //                     },
        //                     data: data,
        //                     validateStatus: (status) => {
        //                         return true;
        //                     },
        //                 }).catch(error => {
        //                     console.log(error)
        //                 }).then(response => {
        //                     console.log(response)
        //                     if (response.data.error) {
        //                         if (response.data.error.includes("username")) {
        //                             document.getElementById('signupError').innerText = "Phone Number already registered. Try another Phone Number";
        //                             this.setState({
        //                                 VerifyModal: false,
        //                                 verifyCodeError: "",
        //                             })
        //                         }
        //                         if (response.data.error.includes("email")) {
        //                             document.getElementById('signupError').innerText = "email already taken. Try another email";
        //                             this.setState({
        //                                 VerifyModal: false,
        //                                 verifyCodeError: "",
        //                             })
        //                         }
        //                     }
        //                     else {
        //                         this.setState({
        //                             VerifyModal: false,
        //                             verifyCodeError: "",
        //                         })
        //                         let obj = {
        //                             username: this.state.username,
        //                             password: this.state.password
        //                         }
        //                         loginUser(obj)
        //                             .then((res) => {
        //                                 console.log(res)
        //                                 if (res.access) {
        //                                     // document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        //                                     document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        //                                     this.setCookie("authToken", res.access, 2)
        //                                     this.setCookie("fromSignup", res.access, 2)
        //                                     window.location.href = '/summary-page'
        //                                 }
        //                             })
        //                             .catch((err) => {
        //                                 console.log(err)
        //                             })
        //                     }
        //                 });
        //             }

        //         }).catch((err) => {

        //             this.setState({
        //                 verifyCodeError: "Please Enter a Valid OTP"
        //             })
        //         })
        // }

        const data = new FormData();
        data.append('firstname', this.state.firstname);
        data.append('lastname', this.state.lastname);
        data.append('email', this.state.email.toLowerCase());
        data.append('username', this.state.username);
        data.append('password1', this.state.password);
        data.append('age', getCookie('age'));
        // data.append('pollution', getCookie('pollution'));
        data.append('isSunExposed', "");
        data.append('sleepHrs', getCookie('sleepHrs'));
        data.append('skinType', getCookie('skinType'));
        data.append('isSensitive', getCookie('isSensitive'));
        data.append('skinConcerns', getCookie('skinConcerns'));
        // data.append('skinRoutines', getCookie('skinRoutines'));
        // data.append('brand_type', 1);
        data.append('skin_tone', getCookie("skin_tone"));
        // data.append('morning_skin_feels', getCookie("morning_skin_feels"));
        data.append('moisturized_skin_feels', getCookie("moisturized_skin_feels"));
        data.append('have_acne', getCookie("have_acne"));
        data.append('water_options', getCookie("water_options"));
        data.append('hair_volume', getCookie("hair_volume"));
        data.append('hair_types', getCookie("hair_types"));
        data.append('hair_looks', getCookie("hair_looks"));
        data.append('hair_length', getCookie("hair_length"));
        // data.append('hair_oily_time', getCookie("hair_oily_time"));
        data.append('hair_tools', getCookie("hair_tools"));
        data.append('wears_makeup', getCookie("wears_makeup"));
        data.append('hairConcerns', getCookie("HairConcerns"));
        data.append('gender', getCookie("gender"));


        let obj = {
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email.toLowerCase(),
            username: this.state.username,
            password1: this.state.password,
            age: getCookie('age'),
            // pollution: getCookie('pollution'),
            sleepHrs: getCookie('sleepHrs'),
            skinType: getCookie('skinType'),
            isSensitive: getCookie('isSensitive'),
            skinConcerns: getCookie('skinConcerns'),
            // brand_type: getCookie("brand_type"),
            // morning_skin_feels: getCookie("morning_skin_feels"),
            moisturized_skin_feels: getCookie("moisturized_skin_feels"),
            have_acne: getCookie("have_acne"),
            water_options: getCookie("water_options"),
            hair_volume: getCookie("hair_volume"),
            hair_types: getCookie("hair_types"),
            hair_looks: getCookie("hair_looks"),
            hair_length: getCookie("hair_length"),
            // hair_oily_time: getCookie("hair_oily_time"),
            hair_tools: getCookie("hair_tools"),
            wears_makeup: getCookie("wears_makeup"),
            hairConcerns: getCookie("HairConcerns")
        }


        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/signup/',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data,
            validateStatus: (status) => {
                return true;
            },
        }).catch(error => {
        }).then(response => {
            // console.log(response)
            if (response.data.error) {
                if (response.data.error.includes("username")) {
                    document.getElementById('signupError').innerText = "Phone Number already registered. Try another Phone Number";
                    this.setState({
                        VerifyModal: false,
                        verifyCodeError: "",
                    })
                }
                if (response.data.error.includes("email")) {
                    document.getElementById('signupError').innerText = "email already taken. Try another email";
                    this.setState({
                        VerifyModal: false,
                        verifyCodeError: "",
                    })
                }
            }
            else {
                this.setState({
                    VerifyModal: false,
                    verifyCodeError: "",
                })
                let obj = {
                    username: this.state.username,
                    password: this.state.password
                }
                loginUser(obj)
                    .then((res) => {
                        // console.log(res)
                        if (res.access) {
                            // document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                            document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                            this.setCookie("authToken", res.access, 2)
                            this.setCookie("fromSignup", res.access, 2)
                            window.location.href = '/summary-page'
                        }
                    })
                    .catch((err) => {
                        // console.log(err)
                    })
            }
        });

    }
    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    responseFacebook = (response) => {
        // console.log(response)
        if (response.accessToken) {
            const data = new FormData();
            data.append('firstname', this.state.firstname);
            data.append('lastname', this.state.lastname);
            data.append('email', this.state.email);
            data.append('username', this.state.username);
            data.append('password1', this.state.password);
            data.append('age', getCookie('age'));
            // data.append('pollution', getCookie('pollution'));
            data.append('isSunExposed', "");
            data.append('sleepHrs', getCookie('sleepHrs'));
            data.append('skinType', getCookie('skinType'));
            data.append('isSensitive', getCookie('isSensitive'));
            data.append('skinConcerns', getCookie('skinConcerns'));
            // data.append('skinRoutines', getCookie('skinRoutines'));
            // data.append('brand_type', 1);
            data.append('skin_tone', getCookie("skin_tone"));
            // data.append('morning_skin_feels', getCookie("morning_skin_feels"));
            data.append('moisturized_skin_feels', getCookie("moisturized_skin_feels"));
            data.append('have_acne', getCookie("have_acne"));
            data.append('water_options', getCookie("water_options"));
            data.append('hair_volume', getCookie("hair_volume"));
            data.append('hair_types', getCookie("hair_types"));
            data.append('hair_looks', getCookie("hair_looks"));
            data.append('hair_length', getCookie("hair_length"));
            // data.append('hair_oily_time', getCookie("hair_oily_time"));
            data.append('hair_tools', getCookie("hair_tools"));
            data.append('wears_makeup', getCookie("wears_makeup"));
            data.append('hairConcerns', getCookie("HairConcerns"));
            data.append("provider", 'facebook');
            data.append("access_token", response.accessToken);
            data.append("method", "signup")
            data.append('gender', getCookie("gender"));

            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/oauth/login/',
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(res => {
                    // console.log(res)
                    // if (this.state.facebok) {
                    // if (res.data.user_exists) {
                    //     loginUserSocial(res.data).then(() => {
                    //         //             document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                    //         //             this.setCookie("authToken", res.data.access, 2)
                    //         //             window.location.href = '/summary-page'
                    //         // }
                    //         Axios({
                    //             method: 'post',
                    //             url: process.env.REACT_APP_BACKEND_URL + '/api/quiz/update/',
                    //             headers: {
                    //                 'Content-Type': 'application/x-www-form-urlencoded',
                    //                 Authorization: `Bearer ${res.data.access}`,
                    //             },
                    //             data: data,
                    //             validateStatus: (status) => {
                    //                 return true;
                    //             },
                    //         }).then((ress) => {
                    //             document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                    //             this.setCookie("authToken", res.data.access, 2)
                    //             window.location.href = '/summary-page'
                    //         })
                    //     })
                    // }
                })
                .catch(error => {
                    // console.log(error)
                });
        }
    }

    responseGoogle = (response) => {
        if (response.accessToken) {
            const data = new FormData();
            data.append('firstname', this.state.firstname);
            data.append('lastname', this.state.lastname);
            data.append('email', this.state.email);
            data.append('username', this.state.username);
            data.append('password1', this.state.password);
            data.append('age', getCookie('age'));
            // data.append('pollution', getCookie('pollution'));
            data.append('isSunExposed', "");
            data.append('sleepHrs', getCookie('sleepHrs'));
            data.append('skinType', getCookie('skinType'));
            data.append('isSensitive', getCookie('isSensitive'));
            data.append('skinConcerns', getCookie('skinConcerns'));
            // data.append('skinRoutines', getCookie('skinRoutines'));
            // data.append('brand_type', 1);
            data.append('skin_tone', getCookie("skin_tone"));
            // data.append('morning_skin_feels', getCookie("morning_skin_feels"));
            data.append('moisturized_skin_feels', getCookie("moisturized_skin_feels"));
            data.append('have_acne', getCookie("have_acne"));
            data.append('water_options', getCookie("water_options"));
            data.append('hair_volume', getCookie("hair_volume"));
            data.append('hair_types', getCookie("hair_types"));
            data.append('hair_looks', getCookie("hair_looks"));
            data.append('hair_length', getCookie("hair_length"));
            // data.append('hair_oily_time', getCookie("hair_oily_time"));
            data.append('hair_tools', getCookie("hair_tools"));
            data.append('wears_makeup', getCookie("wears_makeup"));
            data.append('hairConcerns', getCookie("HairConcerns"));
            data.append("access_token", response.accessToken);
            data.append("provider", 'google-oauth2');
            data.append("method", "signup")
            data.append('gender', getCookie("gender"));

            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/oauth/login/',
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(res => {
                    if (res.data.user_exists) {
                        loginUserSocial(res.data).then(() => {
                            Axios({
                                method: 'post',
                                url: process.env.REACT_APP_BACKEND_URL + '/api/quiz/update/',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                    Authorization: `Bearer ${res.data.access}`,
                                },
                                data: data,
                                validateStatus: (status) => {
                                    return true;
                                },
                            }).then((ress) => {
                                document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                                this.setCookie("authToken", res.data.access, 2)
                                window.location.href = '/summary-page'
                            })
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                });
        }
    }

    focusColor = (id) => {
        document.getElementById(id).classList.add("focusColor")
    }

    focusOut = (id) => {
        document.getElementById(id).classList.remove("focusColor")
    }
    ChangePhoneNumber = () => {
        this.setState({
            VerifyModal: false,
            verifyCodeError: "",
        })
    }

    render() {
        return (
            <div className="page container login">
                <VerifyCode open={this.state.VerifyModal} close={this.ChangePhoneNumber} state={this.state} verifyAndSendApi={(code) => {
                    this.verifyAndSendApi(code)
                }} />
                <div className="card signup-cont">
                    <a className="crossIcon" href="/home">
                        <i class="fas fa-times"></i>
                    </a>
                    <h2>One last step! <br /> SignUp to see your recommendations</h2>
                    <div className="card-body">
                        <form onSubmit={(ev) => this.validateSignup(ev)}>
                            <div className="svgContainer">
                                <div>
                                    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg"
                                        x="0px" y="0px" viewBox="0 0 200 200"
                                        style={{ enableBackground: 'new 0 0 200 200' }} >
                                        <path className="head mauve"
                                            d="M167.3,57.6c3.9-3.9,6.3-9.3,6.3-15.2c0-11.9-9.6-21.5-21.5-21.5c-6.7,0-12.7,3.1-16.6,7.9
                                       c-10.9-5.5-23-8.4-35.5-8.4c-0.2,0-0.4,0-0.6,0c-12.3,0.1-24.2,3-34.9,8.3C60.6,24,54.6,21,47.9,21C36,21,26.4,30.6,26.4,42.5
                                       c0,5.9,2.4,11.3,6.3,15.2c-8.1,12.7-12.3,27.5-12.2,43c0.2,21.2,8.6,41.1,23.7,56c15,14.7,34.8,22.8,55.8,22.8c0.2,0,0.4,0,0.6,0
                                       c43.8-0.3,79.2-36.3,78.9-80.1C179.4,84.4,175.1,70,167.3,57.6 M100.5,167.7c-18.1,0.2-35.1-6.8-48-19.4
                                       c-12.9-12.7-20.1-29.6-20.2-47.7c-0.1-18.1,6.8-35.1,19.5-48c12.7-12.9,29.6-20.1,47.7-20.2c0.2,0,0.4,0,0.5,0
                                       c17.9,0,34.7,6.9,47.5,19.5c12.9,12.7,20.1,29.6,20.2,47.7C168,136.8,137.9,167.4,100.5,167.7" />
                                        <path className="nose mauve"
                                            d="M100,108.7c5.6,0,10.2-4.6,10.2-10.2H89.8C89.8,104.2,94.4,108.7,100,108.7" />
                                        <path className="mouth mauve"
                                            d="M99.6,126.4c-9.7,0-14.5-7.2-14.7-7.5c-0.7-1-0.4-2.4,0.7-3.1c1-0.7,2.4-0.4,3.1,0.7c0.2,0.3,3.7,5.5,10.9,5.5
                                       c7.4,0,11.7-5.6,11.8-5.6c0.7-1,2.1-1.2,3.1-0.4c1,0.7,1.2,2.1,0.4,3.1C114.7,119.3,109.2,126.4,99.6,126.4" />
                                        <path className="philtrum mauve" d="M100,125.9c-1.2,0-2.2-1-2.2-2.2v-9.1c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2v9.1
                                       C102.2,124.9,101.2,125.9,100,125.9" />
                                        <path className="LCheekbone mauve" d="M86.3,65.1c-6.4-3.2-20.7-0.4-27.9,9C52.4,82,47.9,92.7,62,101c12.5,7.4,22.8-3.4,27.8-12
                                       C94.7,80.4,93.7,68.8,86.3,65.1" />
                                        <path className="LBlankEye blank"
                                            d="M78.6,73.9c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8c3.8,0,6.8-3.1,6.8-6.8C85.4,77,82.4,73.9,78.6,73.9" />
                                        <path className="LEye mauve"
                                            d="M81.9,80.7c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3C80.4,84,81.9,82.5,81.9,80.7" />
                                        <path className="RCheekbone mauve" d="M113.7,65.1c6.4-3.2,20.7-0.4,27.9,9c6,7.9,10.5,18.6-3.5,26.9c-12.5,7.4-22.8-3.4-27.8-12
                                       C105.3,80.4,106.3,68.8,113.7,65.1" />
                                        <path className="RBlankEye blank" d="M121.4,73.9c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8c3.8,0,6.8-3.1,6.8-6.8
                                       C128.2,77,125.1,73.9,121.4,73.9" />
                                        <path className="REye mauve" d="M118.1,80.7c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3
                                       C119.6,84,118.1,82.5,118.1,80.7" />
                                        <path className="LArm mauve" d="M85.9,250c-10.1,11.9-56.4,62.7-120.1,77c-20,4.5-14.1-9.3-19.5-18.6l0,0c-5.3-9.2-2.1-21.1,7.1-26.5L69,215.3
                                    c9.2-5.3,21.1-2.1,26.5,7.1l0,0C100.8,231.6,93.4,241.2,85.9,250z" />
                                        <path className="RArm mauve" d="M114.6,250c10.1,11.9,56.4,62.7,120.1,77c20,4.5,14.1-9.3,19.5-18.6l0,0c5.3-9.2,2.1-21.1-7.1-26.5l-115.5-66.7
                            c-9.2-5.3-21.1-2.1-26.5,7.1l0,0C99.7,231.6,107.1,241.2,114.6,250z" />
                                    </svg>
                                </div>
                            </div>
                            <div className="row">
                                <div id="firstNamee" className="col-md-6">
                                    <label className="lable-control">First Name</label>
                                    <input onFocus={() => this.focusColor("firstNamee")} onBlur={() => this.focusOut('firstNamee')} onChange={(ev) => this.gettingValues(ev, "firstname")} type="text" id="firstName" className="form-control input-signup" name="firstname" />
                                </div>
                                <div id="lastNamee" className="col-md-6">
                                    <label className="lable-control">Last Name</label>
                                    <input onFocus={() => this.focusColor("lastNamee")} onBlur={() => this.focusOut('lastNamee')} onChange={(ev) => this.gettingValues(ev, "lastname")} type="text" id="lastName" className="form-control input-signup" name="lastname" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div id="emaill" className="inputGroup inputGroup1">
                                        <label className="lable-control" for="email1">Email</label>
                                        <input type="email" onFocus={() => this.focusColor("emaill")} onBlur={() => this.focusOut('emaill')} onChange={(ev) => this.gettingValues(ev, "email")} name="email" type="email" id="email" className="email" maxlength="256" />
                                        <span className="indicator"></span>
                                    </div>
                                </div>
                                <div id="username" className="col-md-6">
                                    <label className="lable-control">Phone Number</label>
                                    <div style={{ position: "absolute", top: 50 }}>+91</div>
                                    <input style={{ paddingLeft: 30 }} onFocus={() => this.focusColor("username")} value={this.state.username} onBlur={() => this.focusOut('username')} onChange={(ev) => this.gettingValues(ev, "username")} type="number" id="phone" className="form-control input-signup" name="username" />
                                    {/* <input type="tel" id="phone" placeholder="" className="form-control input-signup"/> */}
                                </div>
                            </div>



                            <div className="row">
                                <div id="pass" className="col-md-6">
                                    <label className="lable-control">Password</label>
                                    <input onFocus={() => this.focusColor("pass")} onBlur={() => this.focusOut('pass')} onChange={(ev) => this.gettingValues(ev, "password")} type="password" id="password" className="form-control input-signup" name="password1" />
                                </div>
                                <div id="repeatPass" className="col-md-6">
                                    <label className="lable-control">Repeat Password</label>
                                    <input onFocus={() => this.focusColor("repeatPass")} onBlur={() => this.focusOut('repeatPass')} onChange={(ev) => this.gettingValues(ev, "repeatPassword")} type="password" id="password2" className="form-control input-signup" name="password2" />
                                </div>
                            </div>
                            <p className="error text-center" id="signupError"></p>
                            <div className="row mt-3">
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-login" onClick={(ev) => this.validateSignup(ev)}>Signup</button>
                                </div>
                            </div>
                            {/* 
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <p className="hr-line">OR</p>
                                </div>
                            </div> */}


                        </form>
                        <div className="socialLogins">
                            <FacebookLogin
                                appId="845465089371224"
                                callback={this.responseFacebook}
                                cssClass="my-facebook-button-class"
                                icon={<i class="fab fa-facebook"></i>}
                                onClick={() => this.setState({ facebok: true })}
                                textButton=""
                            />
                            <GoogleLogin
                                className="googleButton"
                                clientId="249280993801-t44rf582uhai4kbi84u29ekog1kkkfdi.apps.googleusercontent.com"
                                buttonText={<img src={googleIcon} />}
                                icon={false}
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                            <button className="btn btn-login signupp" onClick={(ev) => window.location = "login"}>login</button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12 text-center">
                                <p className="login-link">By continuing, you agree that you have read <br /> and accept our <a className="login-link" target="_blank" href="/terms-and-conditions">T&Cs</a> and  <a className="login-link" target="_blank" href="/privacy">Privacy Policy</a></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CopyComponent)