import React from 'react'

class FifthSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    imageHover = (id) => {
        document.getElementById(`smOne${id}`).style.opacity = "1"
        setTimeout(() => {
          document.getElementById(`smOne${id}`).style.opacity = "0"
        }, 200)
        document.getElementById(`smOne${id}`).style.borderWidth = "20px"
        document.getElementById(`smOne${id}`).style.width = "100%"
        document.getElementById(`smOne${id}`).style.height = "100%"
    
        document.getElementById(`smTwo${id}`).style.opacity = "1"
        document.getElementById(`smTwo${id}`).style.borderWidth = "0px"
        document.getElementById(`smTwo${id}`).style.width = "100%"
        document.getElementById(`smTwo${id}`).style.height = "100%"
      }
    
      imageHoverOut = (id) => {
        document.getElementById(`smOne${id}`).style.opacity = "0"
        document.getElementById(`smOne${id}`).style.borderWidth = "0px"
        document.getElementById(`smOne${id}`).style.width = "0%"
        document.getElementById(`smOne${id}`).style.height = "0%"
    
        document.getElementById(`smTwo${id}`).style.opacity = "0"
        document.getElementById(`smTwo${id}`).style.borderWidth = "60px"
        document.getElementById(`smTwo${id}`).style.width = "100%"
        document.getElementById(`smTwo${id}`).style.height = "100%"
      }

    render() {
        return (
            <div className="smallbanner container-fluid">
                <div className="smallBannerImageDiv2" id="imageDiv2" onMouseOver={() => this.imageHover('2')} onMouseOut={() => this.imageHoverOut('2')}>
                    <div id="smOne2" className="smOne"></div>
                    <div id="smTwo2" className="smTwo"></div>
                </div>
                <div className="smallBannerImageDiv1" id="imageDiv1" onMouseOver={() => this.imageHover('1')} onMouseOut={() => this.imageHoverOut('1')}>
                    <div id="smOne1" className="smOne"></div>
                    <div id="smTwo1" className="smTwo"></div>
                </div>
            </div>
        )
    }
}

export default FifthSection