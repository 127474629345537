import Recommendation from "../containers/Recommendation/Recommendation"
import Axios from 'axios'
import {
  FETCH_ARRIVED_PRODUCTS,
  FETCH_BODYCARE_PRODUCTS_SUCCESS,
  FETCH_HAIRCARE_PRODUCTS_SUCCESS,
  FETCH_SKINCARE_PRODUCTS_SUCCESS,
  FETCH_SKINCARE_PRODUCTS_SUCCESS_RECOMMENDED,
  FILTER_BODYCARE_PRODUCTS,
  FILTER_HAIRCARE_PRODUCTS,
  FILTER_SKINCARE_PRODUCTS,
  FILTER_SKINCARE_PRODUCTS_RECOMMENDED,
  GET_BODYCARE_CATEGORIES,
  GET_BRANDS,
  GET_BRANDS_TYPE_USER,
  GET_CART_ORDERS,
  GET_CATEGORIES,
  GET_HAIRCARE_CATEGORIES,
  GET_MY_ORDERS,
  GET_PROFILE_DATA,
  GET_SEARCH_RESULTS,
  GET_WHISHLIST,
  IS_LOADING,
  QUICK_VIEW,
  QUICK_VIEW_FALSE,
  PRODUCT_LOADING,
  GET_BLOGS,
  GET_BLOG_TAGS,
  GET_BLOG_SINGLE,
  GET_BRAND_PRODUCTS,
  GET_ALL_CATEGORIES,
  GET_CATEGORIES_WITH_COUNT,
  GET_TOP_BLOGS,
  GET_COUPONS
} from './actions';

const initialState = {
  brands: [],

  categories: [],

  user: {},

  is_authenticated: false,

  authToken: '',

  cartOrders: [],

  recommendedProducts: [],

  skinCareProducts: [],

  filteredProduct: [],

  orders: [],

  isLoading: false,

  product: false,

  whishlistProducts: [],

  quickView: false,

  quickViewObj: false,

  productLoading: true,

  blogTags: false,

  blogs: false,

  blog: false,

  brandProducts: [],

  allCategories: [],

  categoriesWithCount: [],

  count: 0,

  topBlogs: [],

  coupons: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_LOADING:
      return {
        ...state,
        productLoading: true,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
        recommendedProducts: [],
      };
    case FETCH_SKINCARE_PRODUCTS_SUCCESS:
      return {
        ...state,
        skinCareProducts: action.payload.products,
        numberOfProducts: action.payload.numberOfProducts,
        productLoading: false,
        isLoading: !state.is_authenticated ? false : true
        // brands: action.payload.brands ? action.payload.brands : state.brands,
      };
    case FETCH_BODYCARE_PRODUCTS_SUCCESS:
      return {
        ...state,
        bodyCareProducts: action.payload.products,
        numberOfProducts: action.payload.numberOfProducts,
        productLoading: false,
        isLoading: !state.is_authenticated ? false : true
        // brands: action.payload.brands ? action.payload.brands : state.brands,
      };
    case FETCH_HAIRCARE_PRODUCTS_SUCCESS:
      return {
        ...state,
        hairCareProducts: action.payload.products,
        numberOfProducts: action.payload.numberOfProducts,
        productLoading: false,
        isLoading: !state.is_authenticated ? false : true
        // brands: action.payload.brands ? action.payload.brands : state.brands,
      };
    case FETCH_SKINCARE_PRODUCTS_SUCCESS_RECOMMENDED:
      return {
        ...state,
        recommendedProducts: action.payload.products,
        isLoading: false
      };
    case GET_BRANDS:
      return {
        ...state,
        brands2: action.payload.brands
      };
    case GET_CART_ORDERS:
      return {
        ...state,
        cartOrders: action.payload.orders.orderitems,
        shippingPrice: action.payload.shippingPrice,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.categories
      };
    case GET_BODYCARE_CATEGORIES:
      return {
        ...state,
        bodyCareCategories: action.payload.bodyCareCategories
      }
    case GET_HAIRCARE_CATEGORIES:
      return {
        ...state,
        hairCareCategories: action.payload.hairCareCategories
      }
    case FILTER_SKINCARE_PRODUCTS:
      return {
        ...state,
        skinCareProducts: action.payload.items,
        brands: action.payload.brands
      };
    case FILTER_BODYCARE_PRODUCTS:
      return {
        ...state,
        bodyCareProducts: action.payload.items,
        brands: action.payload.brands
      };
    case FILTER_HAIRCARE_PRODUCTS:
      return {
        ...state,
        hairCareProducts: action.payload.items,
        brands: action.payload.brands
      };
    case FILTER_SKINCARE_PRODUCTS_RECOMMENDED:
      return {
        ...state,
        recommendedProducts: action.payload.items,
        isLoading: false
      };
    case GET_PROFILE_DATA:
      return {
        ...state,
        user: action.payload.user,
      };
    case GET_WHISHLIST:
      return {
        ...state,
        whishlistProducts: action.payload.whishlistProducts,
      };
    case GET_MY_ORDERS:
      return {
        ...state,
        orders: action.payload.orders,
      };
    case GET_SEARCH_RESULTS:
      return {
        ...state,
        searchProducts: action.payload.searchProducts,
        query: action.payload.query,
        numberOfProducts: action.payload.numberOfProducts,
        isLoading: false,
        productLoading: false
      };
    case QUICK_VIEW:
      return {
        ...state,
        quickViewObj: action.payload.quickViewObj,
        quickView: true
      };
    case QUICK_VIEW_FALSE:
      return {
        ...state,
        quickViewObj: action.payload.quickViewObj,
        quickView: false
      };
    case FETCH_ARRIVED_PRODUCTS:
      return {
        ...state,
        arrivedProducts: action.payload.arrivedProducts,
        isLoading: false
      };
    case GET_BRANDS_TYPE_USER:
      return {
        ...state,
        brandsTypeUser: action.payload.brandsTypeUser
      }
    case GET_BLOG_TAGS:
      return {
        ...state,
        blogTags: action.payload.blogTags
      }
    case GET_BLOGS:
      return {
        ...state,
        blogs: action.payload.blogs,
        count: action.payload.count,
      }
    case GET_TOP_BLOGS:
      return {
        ...state,
        topBlogs: action.payload.topBlogs,
      }
    case GET_BLOG_SINGLE:
      return {
        ...state,
        blog: action.payload.blog
      }
    case GET_BRAND_PRODUCTS:
      return {
        ...state,
        brandProducts: action.payload.products,
        numberOfProducts: action.payload.numberOfProducts,
        productLoading: false
      }
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        allCategories: action.payload.allCategories
      }
    case GET_CATEGORIES_WITH_COUNT:
      return {
        ...state,
        categoriesWithCount: action.payload.categoriesWithCount
      }
    case GET_COUPONS:
      return {
        ...state,
        coupons: action.payload.coupons
      }
    default:
      return state
  }
}

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function checkAuth() {
  var x = getCookie('authToken');
  if (x) {
    initialState.authToken = x
    initialState.is_authenticated = true
    // getCart()
  }
  else {
    initialState.authToken = ''
    initialState.is_authenticated = false
  }
}
checkAuth()

export async function loginUser(obj) {
  const data = new FormData();
  data.append('username', obj.username);
  data.append('password', obj.password);
  const abc = Axios({
    method: 'post',
    url: process.env.REACT_APP_BACKEND_URL + '/api/token/',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data,
    validateStatus: (status) => {
      return true;
    },
  })
    // process.env.REACT_APP_BACKEND_URL
    .then(response => {
      if (response.data.access) {
        document.cookie = `authToken=${response.data.access};`;
      }
      initialState.authToken = response.data.access
      initialState.is_authenticated = true
      return response.data
    })
    .catch(error => {
      return false
    })

  return abc.then((res) => { return res })
}

export async function loginUserSocial(response) {
  document.cookie = `authToken=${response.access};`;
  initialState.authToken = response.access
  initialState.is_authenticated = true
  return true
}
