import React from 'react'
import cream from '../../../Images/cream.jpg'
import CircularProgressbar from './ProgressBar'
import ScrollAnimation from 'react-animate-on-scroll';
import { Waypoint } from 'react-waypoint';

// let percentage = 0

// function percentageCalculate(percentagee) {
//     percentage = 0
//     setInterval(() => {
//         if (percentage < percentagee) {
//             // console.log(percentage)
//             percentage = percentage + 1
//             if(document.getElementById('percentText')){
//                 document.getElementById('percentText').innerHTML = `${percentage}%`
//             }
//         }
//         clearInterval()
//     }, 10);
// }
class FourthSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            percentage: 0
        }
    }

    animateProgress() {
        if (document.getElementById('progress5').style.width) {

            document.getElementById('progress1').style.transition = "none"
            document.getElementById('progress2').style.transition = "none"
            document.getElementById('progress3').style.transition = "none"
            document.getElementById('progress4').style.transition = "none"
            document.getElementById('progress5').style.transition = "none"
            document.getElementById('progress1').style.width = "0"
            document.getElementById('progress2').style.width = "0"
            document.getElementById('progress3').style.width = "0"
            document.getElementById('progress4').style.width = "0"
            document.getElementById('progress5').style.width = "0"
            setTimeout(() => {
                document.getElementById('progress1').style.transition = "0.3s"
                document.getElementById('progress2').style.transition = "0.3s"
                document.getElementById('progress3').style.transition = "0.3s"
                document.getElementById('progress4').style.transition = "0.3s"
                document.getElementById('progress5').style.transition = "0.3s"

                document.getElementById('progress1').style.width = "89%"
                document.getElementById('progress2').style.width = "76%"
                document.getElementById('progress3').style.width = "75%"
                document.getElementById('progress4').style.width = "54%"
                document.getElementById('progress5').style.width = "67%"
            }, 500)
        } else {

            document.getElementById('progress1').style.width = "89%"
            document.getElementById('progress2').style.width = "76%"
            document.getElementById('progress3').style.width = "75%"
            document.getElementById('progress4').style.width = "54%"
            document.getElementById('progress5').style.width = "67%"
        }

    }

    animateProgress2() {
        if (document.getElementById('progress52').style.width) {

            document.getElementById('progress12').style.transition = "none"
            document.getElementById('progress22').style.transition = "none"
            document.getElementById('progress32').style.transition = "none"
            document.getElementById('progress42').style.transition = "none"
            document.getElementById('progress52').style.transition = "none"
            document.getElementById('progress12').style.width = "0"
            document.getElementById('progress22').style.width = "0"
            document.getElementById('progress32').style.width = "0"
            document.getElementById('progress42').style.width = "0"
            document.getElementById('progress52').style.width = "0"
            setTimeout(() => {
                document.getElementById('progress12').style.transition = "0.3s"
                document.getElementById('progress22').style.transition = "0.3s"
                document.getElementById('progress32').style.transition = "0.3s"
                document.getElementById('progress42').style.transition = "0.3s"
                document.getElementById('progress52').style.transition = "0.3s"

                document.getElementById('progress12').style.width = "89%"
                document.getElementById('progress22').style.width = "76%"
                document.getElementById('progress32').style.width = "75%"
                document.getElementById('progress42').style.width = "54%"
                document.getElementById('progress52').style.width = "67%"
            }, 500)
        } else {

            document.getElementById('progress12').style.width = "89%"
            document.getElementById('progress22').style.width = "76%"
            document.getElementById('progress32').style.width = "75%"
            document.getElementById('progress42').style.width = "54%"
            document.getElementById('progress52').style.width = "67%"
        }

    }

    onMatchOver = () => {
        // percentage = 0
        let percentagee = 98

        document.getElementsByClassName('circle-progress')[0].style.transition = '0s'
        this.setState({
            percentage: 0
        }, () => {
            setTimeout(() => {
                document.getElementsByClassName('circle-progress')[0].style.transition = '1s'
                // percentageCalculate(percentagee)
                this.setState({
                    percentage: percentagee
                })
            }, 500);
        })
    }

    onMatchOver2 = () => {
        // percentage = 0
        let percentagee = 98

        document.getElementsByClassName('circle-progress2')[0].style.transition = '0s'
        this.setState({
            percentage: 0
        }, () => {
            setTimeout(() => {
                document.getElementsByClassName('circle-progress2')[0].style.transition = '1s'
                // percentageCalculate(percentagee)
                this.setState({
                    percentage: percentagee
                })
            }, 500);
        })
    }

    setMatch = () => {
        let percentagee = 98

        setTimeout(() => {
            // percentageCalculate(percentagee)
            this.setState({
                percentage: percentagee
            })
        }, 1000);
    }

    setMatch2 = () => {
        let percentagee = 98

        setTimeout(() => {
            // percentageCalculate(percentagee)
            this.setState({
                percentage: percentagee
            })
        }, 1000);
    }

    setScores = () => {
        this.animateProgress()
        this.animateProgress2()
        this.setState({
            scoreSet: true
        })
    }

    
    componentDidMount(){
        setTimeout(()=>{
            if(document.getElementById("carouselExampleSlidesOnlyyNext")){
                document.getElementById("carouselExampleSlidesOnlyyNext").click()
            }
        },2000)
    }

    render() {
        return (
            <div>
                <div className="curate-con container">
                    <h2>How You See Your Recommended Products</h2>
                    <div className="curate">
                        <div className="cream">
                            <div className="roundtick" style={{ borderRadius: '50%', width: '30px' }}>
                                <div className="innercircle"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                                </svg></div>
                            </div>
                            <div className="cream-img" width="208"
                                style={{ backgroundImage: `url(${cream})` }}>
                            </div>
                            <h6>Pimple Clear Face Wash</h6>
                            <p>Refreshing and hydrating cleansing face wash that removes dirt and impurities.</p>
                            <hr />
                            <ul>
                                <li>Review <i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i
                                    className="fas fa-star"></i><i className="fas fa-star"></i></li>
                                <li><i className="far fa-heart"></i> Wishlish</li>
                            </ul>
                        </div>
                        <Waypoint onEnter={this.state.percentage > 0 ? null : this.setMatch} >
                            <div onMouseOver={this.onMatchOver} className="match" id="match">
                                <div className="upperProgress">
                                    <CircularProgressbar percentage={this.state.percentage} />
                                    <div className="progressText">
                                        <b id="percentText">{this.state.percentage}%</b>
                                        <small>matching score</small>
                                    </div>
                                </div>
                                <div className="dash">
                                    <div className="dash1"></div>
                                    <div className="dashh"></div>
                                    <div className="dashh mobileHide"></div>
                                    <div className="dashh"></div>
                                    <div className="dashh"></div>
                                    <div className="dash1"></div>
                                    <div className="dott2"></div>
                                </div>
                                <p className="p">See how well each product matches your beauty needs.</p>
                            </div>
                        </Waypoint>

                        <Waypoint onEnter={this.state.scoreSet ? null : this.setScores} >
                            <div className="progres-div">
                                <div className="transparent" onMouseOver={this.animateProgress}></div>
                                <div>
                                    <div className="d-flex progres-div-div">
                                        <label>Acne</label>
                                        <p>89%</p>
                                    </div>
                                    <div className="progress">
                                        <div id="progress1" className="progress-bar wow p1" role="progressbar" aria-valuenow="89"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex progres-div-div">

                                        <label>Dark Spots</label>
                                        <p>76%</p>
                                    </div>
                                    <div className="progress">
                                        <div id="progress2" className="progress-bar wow p2" role="progressbar" aria-valuenow="76"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex progres-div-div">

                                        <label>Hyperpigmentation</label>
                                        <p>75%</p>
                                    </div>
                                    <div className="progress">
                                        <div id="progress3" className="progress-bar p3 " role="progressbar" aria-valuenow="75"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex progres-div-div">

                                        <label>Wrinkles</label>
                                        <p>54%</p>
                                    </div>
                                    <div className="progress">
                                        <div id="progress4" className="progress-bar p4 " role="progressbar" aria-valuenow="54"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex progres-div-div">

                                        <label>Dehydrated</label>
                                        <p>67%</p>
                                    </div>
                                    <div className="progress">
                                        <div id="progress5" className="progress-bar p5 " role="progressbar" aria-valuenow="67"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className="progressDashDivMain">
                                    <div className="progressDashDiv">
                                        <div className="progressDash1"></div>
                                        <div className="progressDash"></div>
                                        <div className="progressDash"></div>
                                        <div className="progressDott"></div>
                                    </div>
                                    <p className="p">View data on how each product tackles your skin concern.</p>
                                </div>
                            </div>
                        </Waypoint>
                    </div>

                    <div className="curate curate2">
                        <div id="carouselExampleSlidesOnlyy" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <div className="cream">
                                        <div className="roundtick" style={{ borderRadius: '50%', width: '30px' }}>
                                            <div className="innercircle"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check"
                                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                                            </svg></div>
                                        </div>
                                        <div className="cream-img" width="208"
                                            style={{ backgroundImage: `url(${cream})` }}>
                                        </div>
                                        <h6>Pimple Clear Face Wash</h6>
                                        <p>Refreshing and hydrating cleansing face wash that removes dirt and impurities.</p>
                                        <hr />
                                        <ul>
                                            <li>Review <i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i
                                                className="fas fa-star"></i><i className="fas fa-star"></i></li>
                                            <li><i className="far fa-heart"></i> Wishlish</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <Waypoint onEnter={this.state.percentage > 0 ? null : this.setMatch2} >
                                        <div onMouseOver={this.onMatchOver2} className="match" id="match2">
                                            <div className="upperProgress">
                                                <CircularProgressbar name="match2" percentage={this.state.percentage} />
                                                <div className="progressText">
                                                    <b id="percentText">{this.state.percentage}%</b>
                                                    <small>matching score</small>
                                                </div>
                                            </div>
                                            <div className="dash">
                                                <div className="dash1"></div>
                                                <div className="dashh"></div>
                                                <div className="dashh mobileHide"></div>
                                                <div className="dashh"></div>
                                                <div className="dashh"></div>
                                                <div className="dash1"></div>
                                                <div className="dott2"></div>
                                            </div>
                                            <p className="p">See how well each product matches your beauty needs.</p>
                                        </div>
                                    </Waypoint>
                                </div>
                                <div class="carousel-item">
                                    <Waypoint onEnter={this.state.scoreSet ? null : this.setScores} >
                                        <div className="progres-div">
                                            <div className="transparent" onMouseOver={this.animateProgress2}></div>
                                            <div>
                                                <div className="d-flex progres-div-div">
                                                    <label>Acne</label>
                                                    <p>89%</p>
                                                </div>
                                                <div className="progress">
                                                    <div id="progress12" className="progress-bar wow p1" role="progressbar" aria-valuenow="89"
                                                        aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex progres-div-div">

                                                    <label>Dark Spots</label>
                                                    <p>76%</p>
                                                </div>
                                                <div className="progress">
                                                    <div id="progress22" className="progress-bar wow p2" role="progressbar" aria-valuenow="76"
                                                        aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex progres-div-div">

                                                    <label>Hyperpigmentation</label>
                                                    <p>75%</p>
                                                </div>
                                                <div className="progress">
                                                    <div id="progress32" className="progress-bar p3 " role="progressbar" aria-valuenow="75"
                                                        aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex progres-div-div">

                                                    <label>Wrinkles</label>
                                                    <p>54%</p>
                                                </div>
                                                <div className="progress">
                                                    <div id="progress42" className="progress-bar p4 " role="progressbar" aria-valuenow="54"
                                                        aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex progres-div-div">

                                                    <label>Dehydrated</label>
                                                    <p>67%</p>
                                                </div>
                                                <div className="progress">
                                                    <div id="progress52" className="progress-bar p5 " role="progressbar" aria-valuenow="67"
                                                        aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className="progressDashDivMain">
                                                <div className="progressDashDiv">
                                                    <div className="progressDash1"></div>
                                                    <div className="progressDash"></div>
                                                    <div className="progressDash"></div>
                                                    <div className="progressDott"></div>
                                                </div>
                                                <p className="p">View data on how each product tackles your skin concern.</p>
                                            </div>
                                        </div>
                                    </Waypoint>
                                </div>
                            </div>
                            <div className="controlProduct">
                                <a className=" btn " href="#carouselExampleSlidesOnlyy" data-slide="prev"><i className="fas fa-chevron-left"></i></a>
                                <a id="carouselExampleSlidesOnlyyNext" className=" btn" href="#carouselExampleSlidesOnlyy" data-slide="next"><i className="fas fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FourthSection