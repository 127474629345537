import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './login.scss'
import addTodoWithDispatch, { loginUser, loginUserSocial } from '../../modules/store';
import { NavLink, withRouter } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import Axios from 'axios'
import googleIcon from '../../Images/search.png'
import { getCookie } from '../../modules/actions';

class CopyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            facebok: false
        }
    }

    gettingValues = (ev, name) => {
        this.setState({
            [name]: ev.target.value
        })
    }
    validateEmail() {
        var value = this.state.username;
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
            return (true)
        } else {
            // document.getElementById('signupError').innerText = "Email is badly formatted";
            return (false)
        }
    }
    login = (ev) => {
        ev.preventDefault()
        if (this.validateEmail()) {
            let obj = {
                username: this.state.username.toLowerCase(),
                password: this.state.password
            }
            loginUser(obj).then(res => {
                console.log(res)
                if (res.access && res.quiz_submitted) {
                    window.location.pathname = '/home'
                } else {
                    if (!res.quiz_submitted && res.access) {
                        window.location.pathname = '/quiz'
                    } else {
                        this.setState({
                            err: "Enter Valid Credentials"
                        })
                    }
                }
            })
        } else {
            let obj = {
                username: this.state.username.toLowerCase(),
                password: this.state.password
            }
            loginUser({ ...obj, username: obj.username.slice(-10) }).then(res => {
                console.log(res)
                if (res.access && res.quiz_submitted) {
                    window.location.pathname = '/home'
                } else {
                    if (!res.quiz_submitted && res.access) {
                        window.location.pathname = '/quiz'
                    } else {
                        this.setState({
                            err: "Enter Valid Credentials"
                        })
                    }
                }
            })
        }

    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    responseFacebook = (response) => {
        const data = new FormData();
        data.append("provider", 'facebook');
        data.append("access_token", response.accessToken);
        if (response.status === "unknown" || response.status === "undefined" || response.status === undefined) {
        } else {
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/oauth/login/',
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(res => {
                    if (this.state.facebok) {
                        if (!res.data.user_exists) {
                            window.location.href = 'sign-up'
                        } else {
                            loginUserSocial(res.data)
                            window.location.href = 'home'
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }

    responseGoogle = (response) => {
        if (response.accessToken) {
            const data = new FormData();
            data.append("access_token", response.accessToken);
            data.append("provider", 'google-oauth2');
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/oauth/login/',
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (!response.data.user_exists) {
                        window.location.href = 'sign-up'
                    } else {
                        loginUserSocial(response.data)
                        window.location.href = 'home'
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }

    focusColor = (id) => {
        document.getElementById(id).classList.add("focusColor")
    }

    focusOut = (id) => {
        document.getElementById(id).classList.remove("focusColor")
    }

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="page container login">
                <div className="card login-cont">
                    <h2>
                        Login
                    <span className="crossIcon" href="#" onClick={this.goBack}>
                            <i class="fas fa-times"></i>
                        </span>
                    </h2>
                    <div className="card-body">

                        <form onSubmit={(ev) => this.login(ev)}>
                            <div className="svgContainer">
                                <div>
                                    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                        viewBox="0 0 200 200" style={{ enableBackground: 'new 0 0 200 200' }} >
                                        <path className="head mauve" d="M167.3,57.6c3.9-3.9,6.3-9.3,6.3-15.2c0-11.9-9.6-21.5-21.5-21.5c-6.7,0-12.7,3.1-16.6,7.9
								   c-10.9-5.5-23-8.4-35.5-8.4c-0.2,0-0.4,0-0.6,0c-12.3,0.1-24.2,3-34.9,8.3C60.6,24,54.6,21,47.9,21C36,21,26.4,30.6,26.4,42.5
								   c0,5.9,2.4,11.3,6.3,15.2c-8.1,12.7-12.3,27.5-12.2,43c0.2,21.2,8.6,41.1,23.7,56c15,14.7,34.8,22.8,55.8,22.8c0.2,0,0.4,0,0.6,0
								   c43.8-0.3,79.2-36.3,78.9-80.1C179.4,84.4,175.1,70,167.3,57.6 M100.5,167.7c-18.1,0.2-35.1-6.8-48-19.4
								   c-12.9-12.7-20.1-29.6-20.2-47.7c-0.1-18.1,6.8-35.1,19.5-48c12.7-12.9,29.6-20.1,47.7-20.2c0.2,0,0.4,0,0.5,0
								   c17.9,0,34.7,6.9,47.5,19.5c12.9,12.7,20.1,29.6,20.2,47.7C168,136.8,137.9,167.4,100.5,167.7"/>
                                        <path className="nose mauve" d="M100,108.7c5.6,0,10.2-4.6,10.2-10.2H89.8C89.8,104.2,94.4,108.7,100,108.7" />
                                        <path className="mouth mauve" d="M99.6,126.4c-9.7,0-14.5-7.2-14.7-7.5c-0.7-1-0.4-2.4,0.7-3.1c1-0.7,2.4-0.4,3.1,0.7c0.2,0.3,3.7,5.5,10.9,5.5
								   c7.4,0,11.7-5.6,11.8-5.6c0.7-1,2.1-1.2,3.1-0.4c1,0.7,1.2,2.1,0.4,3.1C114.7,119.3,109.2,126.4,99.6,126.4"/>
                                        <path className="philtrum mauve" d="M100,125.9c-1.2,0-2.2-1-2.2-2.2v-9.1c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2v9.1
								   C102.2,124.9,101.2,125.9,100,125.9"/>
                                        <path className="LCheekbone mauve" d="M86.3,65.1c-6.4-3.2-20.7-0.4-27.9,9C52.4,82,47.9,92.7,62,101c12.5,7.4,22.8-3.4,27.8-12
								   C94.7,80.4,93.7,68.8,86.3,65.1"/>
                                        <path className="LBlankEye blank" d="M78.6,73.9c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8c3.8,0,6.8-3.1,6.8-6.8C85.4,77,82.4,73.9,78.6,73.9"
                                        />
                                        <path className="LEye mauve" d="M81.9,80.7c0-1.8-1.5-3.3-3.3-3.3c-1.8,0-3.3,1.5-3.3,3.3c0,1.8,1.5,3.3,3.3,3.3C80.4,84,81.9,82.5,81.9,80.7"
                                        />
                                        <path className="RCheekbone mauve" d="M113.7,65.1c6.4-3.2,20.7-0.4,27.9,9c6,7.9,10.5,18.6-3.5,26.9c-12.5,7.4-22.8-3.4-27.8-12
								   C105.3,80.4,106.3,68.8,113.7,65.1"/>
                                        <path className="RBlankEye blank" d="M121.4,73.9c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3.1,6.8,6.8,6.8c3.8,0,6.8-3.1,6.8-6.8
								   C128.2,77,125.1,73.9,121.4,73.9"/>
                                        <path className="REye mauve" d="M118.1,80.7c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3
								   C119.6,84,118.1,82.5,118.1,80.7"/>
                                        <path className="LArm mauve" d="M85.9,250c-10.1,11.9-56.4,62.7-120.1,77c-20,4.5-14.1-9.3-19.5-18.6l0,0c-5.3-9.2-2.1-21.1,7.1-26.5L69,215.3
								c9.2-5.3,21.1-2.1,26.5,7.1l0,0C100.8,231.6,93.4,241.2,85.9,250z"/>
                                        <path className="RArm mauve" d="M114.6,250c10.1,11.9,56.4,62.7,120.1,77c20,4.5,14.1-9.3,19.5-18.6l0,0c5.3-9.2,2.1-21.1-7.1-26.5l-115.5-66.7
						c-9.2-5.3-21.1-2.1-26.5,7.1l0,0C99.7,231.6,107.1,241.2,114.6,250z"/>
                                    </svg>
                                </div>
                            </div>

                            <div id="emaill" className="inputGroup inputGroup1">
                                <label for="email1">Email / Phone Number</label>
                                <input onFocus={() => this.focusColor("emaill")} onBlur={() => this.focusOut('emaill')} onChange={(e) => this.gettingValues(e, "username")} name="username" type="text" id="email" className="email" maxlength="256" />
                                <p className="helper helper1"></p>
                                <span className="indicator"></span>
                            </div>
                            <div id="passwordd" className="inputGroup inputGroup2">
                                <label for="password">Password</label>
                                <input onFocus={() => this.focusColor("passwordd")} onBlur={() => this.focusOut('passwordd')} onChange={(e) => this.gettingValues(e, "password")} name="password" type="password" id="password" className="password" />
                            </div>
                            <p className="error text-center" id="loginError">{this.state.err && this.state.err.toLowerCase() === "enter valid credentials" ? "Invalid email or password" : ""}</p>

                            <input type="hidden" value="0" name="quiz" id="quiz" />
                            <div className="row mt-3">
                                <div className="col-md-12" style={{ textAlign: "right" }}>
                                    {window.screen.width < 600 ?
                                        <a href="/resetPassword">Forgot Password?</a>
                                        :
                                        <NavLink to="/resetPassword">Forgot Password?</NavLink>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-login" onClick={(ev) => this.login(ev)}>Login</button>
                                </div>
                            </div>

                            <div className="socialLogins">
                                <FacebookLogin
                                    appId="845465089371224"
                                    // autoLoad={true}
                                    callback={this.responseFacebook}
                                    cssClass="my-facebook-button-class"
                                    icon={<i class="fab fa-facebook"></i>}
                                    onClick={() => this.setState({ facebok: true })}
                                    textButton=""
                                />
                                <GoogleLogin
                                    className="googleButton"
                                    clientId="249280993801-t44rf582uhai4kbi84u29ekog1kkkfdi.apps.googleusercontent.com"
                                    buttonText={<img src={googleIcon} />}
                                    icon={false}
                                    onSuccess={this.responseGoogle}
                                    onFailure={this.responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />
                                <button className="btn btn-login signupp" onClick={(ev) => window.location = "sign-up"}>{!getCookie('quiz') ? "Start Quiz" : "Sign up"}</button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 text-center">
                                    <p className="login-link">By continuing, you agree that you have read <br /> and accept our <a className="login-link" target="_blank" href="/terms-and-conditions">T&Cs</a> and  <a className="login-link" target="_blank" href="/privacy">Privacy Policy</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            addTodoWithDispatch
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CopyComponent))