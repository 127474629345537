import React from 'react'
import FirstSection from './Components/FirstSection'
import SecondSection from './Components/SecondSection';
import ThirdSection from './Components/ThirdSection';
import FourthSection from './Components/FourthSection';
import MetaTags from 'react-meta-tags';
import ReactGA from 'react-ga'

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'auto' });
    ReactGA.pageview(window.location.pathname + window.location.search) 
  }

  render() {
    return (
      <div className="navvv">
    
        <FirstSection />
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <SecondSection />

        <ThirdSection />

        <FourthSection />

      </div>
    )
  }
}

export default About