import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dropArrow from '../../Images/droparrow.jpg'
import logo from '../../Images/Logo 2.png'
import logo2 from '../../Images/Logo 3.png'
import { NavLink, withRouter } from 'react-router-dom'
import Axios from 'axios'
import { fetchHaircareProducts, fetchBodycareProducts, fetchSkincareProducts, fetchSkincareProductsRecommended, getBodycareCategories, getHaircareCategories, getBrands, getCartOrders, getCategories, getCookie, getFilteredSkinCare, getFilteredSkinCareRecommended, getSearchResults, getProductSearhBar } from '../../modules/actions'
import { Drawer, Spin } from 'antd'
import { Collapse } from 'antd';
// import NOAvaliableImage from '../../'
import NOAvaliableImage from '../../Images/NoAvaliable.jpeg'
import { debounce } from 'lodash'
import ReactGA from 'react-ga'

function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

const { Panel } = Collapse;

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_authenticated: false,
            visible: false,
            placement: 'left',
            dbounce: 0,
            allBrands: [],
            allCategories: [],
            allProductsTitles: []
        }
        this.handleSearchDebounced = debounce(() => {
            let data = new FormData()
            data.append('query', this.state.searchVal)
            if (isEmptyOrSpaces(this.state.searchVal)) {
                this.setState({
                    loader: false
                })
            } else {
                Axios({
                    method: 'post',
                    url: process.env.REACT_APP_BACKEND_URL + '/api/search/bar/',
                    validateStatus: (status) => {
                        return true;
                    },
                    data,
                })
                    .then(response => {
                        let allProductsTitles = response.data.products
                        let allCategories = response.data.categories
                        let allBrands = response.data.brands

                        this.setState({
                            loader: false,
                            allProductsTitles,
                            allCategories,
                            allBrands
                        })

                        let dataList = document.getElementsByClassName('searchedProductNav')[0]
                        if (dataList) {
                            dataList.innerHTML = ''
                            if (this.state.searchVal) {
                                for (let i = 0; i < allProductsTitles.length; i++) {
                                    if (window.screen.availWidth < 768) {
                                        dataList.innerHTML += `
                                    <a id="a" style="text-decoration: none; color:black" href="/skin-care/${allProductsTitles[i].slug}">
                                    <div id="a" class='seachListItem'>
                                    <div id="a">
                                    <img id="a" src='${allProductsTitles[i].image !== null ? allProductsTitles[i].image : NOAvaliableImage}' >
                                            </div>
                                            <div id="a">
                                                ${allProductsTitles[i].name}
                                                <p id="a">${allProductsTitles[i].category} - ${allProductsTitles[i].brand}</p>
                                                </div>
                                        </div>
                                        </a>
                                    `
                                    } else {
                                        dataList.innerHTML += `
                                    <a id="a" style="text-decoration: none; color:black" href="/skin-care/${allProductsTitles[i].slug}">
                                        <div id="a" class='seachListItem'>
                                        <div id="a">
                                                <img id="a" src='${allProductsTitles[i].image !== null ? allProductsTitles[i].image : NOAvaliableImage}' >
                                                </div>
                                                <div id="a">
                                                ${allProductsTitles[i].name}
                                                <p id="a">${allProductsTitles[i].category} - ${allProductsTitles[i].brand}</p>
                                                </div>
                                                </div>
                                                </a>
                                                `
                                    }
                                }
                            }
                        }

                        let dataListBrands = document.getElementById('searchedBrandNav')
                        if (dataListBrands) {
                            dataListBrands.innerHTML = ''
                            for (let i = 0; i < allBrands.length; i++) {
                                dataListBrands.innerHTML += `
                                <a id="a" href="/brands/${allBrands[i].name}-${allBrands[i].id}">${allBrands[i].name}</a>
                                `
                            }
                        }

                        let dataListCategories = document.getElementById('searchedCategoriesNav')
                        if (dataListCategories) {
                            dataListCategories.innerHTML = ''
                            for (let i = 0; i < allCategories.length; i++) {
                                if (allCategories[i].type.toLowerCase() === "skin") {
                                    if (allCategories[i].name.match("&")) {
                                        dataListCategories.innerHTML += `
                                    <a id="a" href="/skin-care?category=${allCategories[i].name.split("&").join("-")}">${allCategories[i].name}</a>
                                    `
                                    } else {
                                        dataListCategories.innerHTML += `
                                        <a id="a" href="/skin-care?category=${allCategories[i].name}">${allCategories[i].name}</a>
                                        `
                                    }
                                }
                                if (allCategories[i].type.toLowerCase() === "body") {
                                    if (allCategories[i].name.match("&")) {
                                        dataListCategories.innerHTML += `
                                    <a id="a" href="/body-care?category=${allCategories[i].name.split("&").join("-")}">${allCategories[i].name}</a>
                                    `
                                    } else {
                                        dataListCategories.innerHTML += `
                                        <a id="a" href="/body-care?category=${allCategories[i].name}">${allCategories[i].name}</a>
                                        `
                                    }
                                }
                                if (allCategories[i].type.toLowerCase() === "hair") {
                                    if (allCategories[i].name.match("&")) {
                                        dataListCategories.innerHTML += `
                                    <a id="a" href="/hair-care?category=${allCategories[i].name.split("&").join("-")}">${allCategories[i].name}</a>
                                    `
                                    } else {
                                        dataListCategories.innerHTML += `
                                        <a id="a" href="/hair-care?category=${allCategories[i].name}">${allCategories[i].name}</a>
                                        `
                                    }
                                }
                            }
                        }
                    })
                    .catch(error => {

                    });
            }
        }, 1000);
    }

    componentDidMount() {
        // this.props.getCartOrders()
        this.props.getCategories()
        this.props.getBodycareCategories()
        this.props.getHaircareCategories()
        window.scrollTo({ top: 0, behavior: 'auto' });
        // this.search()
    }

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });

        let brands = document.querySelectorAll("#checkMob")
        let categories = document.querySelectorAll("#check2Mob")
        for (let i = 0; i < brands.length; i++) {
            brands[i].checked = false
        }
        for (let i = 0; i < categories.length; i++) {
            categories[i].checked = false
        }
    };

    dropdown = (id) => {
        // console.log(id)
        document.getElementById(id).style.zIndex = "1";
        document.getElementById(id).style.opacity = "1";
        if (id === "1") {
            document.getElementById("one").style.overflow = "initial";
        }
        if (id === "2") {
            document.getElementById("two").style.overflow = "initial";
        }
    }

    dropdownOut = (id) => {
        document.getElementById(id).style.zIndex = "-1";
        document.getElementById(id).style.opacity = "0";
        if (id === "1") {
            document.getElementById("one").style.overflow = "hidden";
        }
        if (id === "2") {
            document.getElementById("two").style.overflow = "hidden";
        }
    }

    navbarToggler = (name) => {
        document.getElementById(name).classList.toggle('show')
    }

    myFunction = () => {
        document.getElementById("myDropdown").classList.toggle("show");
    }

    openNav() {
        document.getElementById("mySidenav").classList += ' sidenavOpen'
        setTimeout(() => {
            document.getElementById('aa').style.opacity = 1
            document.getElementById('cartItemList').style.opacity = 1
        }, 300)
    }

    closeNav() {
        document.getElementById('aa').style.opacity = 0
        document.getElementById('cartItemList').style.opacity = 0
        setTimeout(() => {
            document.getElementById("mySidenav").classList.remove('sidenavOpen')
        }, 300)
    }

    dropdownToggle() {
        document.getElementById('first2').classList.toggle('first2Open')
    }

    gettingList(ev, name) {
        this.setState({
            loader: true
        })
        if (ev.keyCode === 13) {
            window.location.href = `/search?searchVal=${this.state.searchVal ? this.state.searchVal : ""}`
            this.search(ev)
        }
        if (!ev.target.value) {
            this.setState({
                loader: false
            })

            let dataList = document.getElementsByClassName('searchedProductNav')[0]
            if (dataList) {
                dataList.innerHTML = ''
            }
        }
        this.setState({
            [name]: ev.target.value,
            dropDown: true
        }, () => {
            if (document.getElementById('searchVal').value) {
                this.handleSearchDebounced()
            }
            // let data = new FormData()
            // data.append('query', this.state.searchVal)

            // Axios({
            //     method: 'post',
            //     url: process.env.REACT_APP_BACKEND_URL + '/api/products/search/',
            //     // headers: { Authorization: `Bearer ${code}` },
            //     validateStatus: (status) => {
            //         return true;
            //     },
            //     data: data,
            // })
            //     .then(response => {
            //         let data = response.data.products
            //         let allProductsTitles = response.data.products
            //         let dataList = document.getElementsByClassName('searchedProductNav')[0]
            //         dataList.innerHTML = ''

            //         for (let i = 0; i < allProductsTitles.length; i++) {
            //             dataList.innerHTML += `<a id="a" style="text-decoration: none; color:black" target="_blank" href="/skin-care/${allProductsTitles[i].slug}"><div id="a" class='seachListItem'>
            //             <div id="a">
            //             <img id="a" src='${allProductsTitles[i].image !== null ? allProductsTitles[i].image : NOAvaliableImage}' >
            //             </div>
            //                 <div id="a">
            //                 ${allProductsTitles[i].name}
            //                 <p id="a">${allProductsTitles[i].category} - ${allProductsTitles[i].brand}</p>
            //                 </div>
            //                 </div></a>`
            //         }
            //     })
            //     .catch(error => {

            //     });

        })
    }

    removeAlertShow = (id) => {
        // console.log(id)
        document.getElementById(id).classList.add("removeAlertShow")
    }
    removeAlertHide(id) {
        document.getElementById(id).classList.remove("removeAlertShow")
    }

    removeProduct = (id, i) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("product_id", id);
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/cart/product/remove/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                this.removeAlertHide(`removeAlert${i}`)
                let data = response.data.products
                this.props.getCartOrders(data);
                return data
            })
            .catch(error => {
                console.log(error)
            });
    }

    retakeTrue = () => {
        localStorage.setItem('retake', true)
    }

    setHead = (name) => {
        let arr1 = document.querySelectorAll('#checkWeb')
        let arr2 = document.querySelectorAll('#check2Web')

        console.log(arr1, arr2)

        for (let i = 0; i < arr1.length; i++) {
            arr1[i].checked = false
        }

        for (let i = 0; i < arr2.length; i++) {
            arr2[i].checked = false
        }

        let params = new URLSearchParams(window.location.search)
        params.delete("brands")
        params.delete("cat")
        if (window.screen.width < 600) {
            window.location.href = window.location.pathname + "?" + params
        } else {
            this.props.history.push(window.location.pathname + "?" + params)
        }

        if (name === "skin") {
            this.props.fetchSkincareProducts(1, 12, false)
            if (this.props.is_authenticated) {
                this.props.fetchSkincareProductsRecommended("skin")
            }
        }
        if (name === "body") {
            this.props.fetchBodycareProducts(1, 12, false)
            if (this.props.is_authenticated) {
                this.props.fetchSkincareProductsRecommended("body")
            }
        }
        if (name === "hair") {
            this.props.fetchHaircareProducts(1, 12, false)
            if (this.props.is_authenticated) {
                this.props.fetchSkincareProductsRecommended("hair")
            }
        }
    }

    addToCart = (id, out) => {
        if (!out) {
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("id", id);
            data.append("action", "add");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (response.data.code === "token_not_valid") {
                        let msg = "You have login first"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        let data = response.data.products
                        this.props.getCartOrders(data);
                        let msg = response.data.message
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        return data
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        } else {
            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>Out of stock</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
            }, 2000)
        }
    }

    removeFromCart = (id) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("id", id);
        data.append("action", "remove");
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                let data = response.data.products
                this.props.getCartOrders(data);
                return data
            })
            .catch(error => {
                console.log(error)
            });
    }

    logout = () => {
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        window.location.reload()
    }

    search = () => {
        let params = new URLSearchParams(window.location.search)
        let searchVal = params.get("searchVal")
        this.props.getSearchResults(searchVal ? searchVal : "", 1)
        if (document.getElementById("searchForPro")) {
            document.getElementById("searchForPro").blur()
        }
        setTimeout(() => {
            this.setState({
                // searchVal: 'nosearchnosearch'
                dropDown: false
            })
        }, 500)
    }

    cartTotal = (orders) => {
        let quantity = 0
        orders.map(a => quantity += Number(a.quantity))
        return quantity
    }


    blurr = (ev) => {
        // console.log(ev.target.id)
        // if (document.getElementsByClassName('searchedProductNav')[0]) {
        //     document.getElementsByClassName('searchedProductNav')[0].innerHTML = ``
        // }
    }

    quizClick = (ClickedButton) => {
        ReactGA.event({
            category: "Button Click",
            action: ClickedButton,
        });
    }

    SortArr = (oldArr, type) => {
        let arr = type === "skin" ? [
            "Cleansers",
            "Exfoliators",
            "Moisturisers",
            "Sun Protection",
            'Masks',
            "Eye Care",
            "Lip Care",
            "Toners",
            "Serums",
            "Face Oils",
            "Spot Treatments"
        ] : type === "hair" ?
            [
                "Shampoos",
                "Conditioners",
                "Hair Oil",
                "Hair Serums",
                "Hair Masks",
                "Dry Shampoos",
                "Hair Colors"
            ] : [
                "Shower gels and Body Wash",
                "Soaps ",
                "Bath Scrubs",
                "Moisturisers",
                "Massage Oils",
                "Bath Salts",
                "Body Butters",
            ]
        let newarr = []
        arr.map((a, i) => {
            let value = oldArr.filter((b, i) => a.toLowerCase() === b.toLowerCase())[0]
            if (value) {
                newarr.push(a)
            }
        })
        console.log({ newarr, oldArr, list: arr })
        return newarr
    }
    render() {

        document.addEventListener('click', (e) => {
            if (document.getElementsByClassName('searchList')[0] && document.getElementsByClassName('searchList')[0].innerHTML !== ``) {
                if (e.target.id !== "a") {
                    //     if (document.getElementsByClassName('searchList')[0]) {
                    //         document.getElementsByClassName('searchList')[0].innerHTML = ``
                    //     }
                    if (this.state.searchVal) {
                        this.setState({
                            searchVal: ""
                        })
                    }
                }
            }
        })

        window.onclick = function (event) {
            if (!event.target.matches('svg') && !event.target.matches('PATH')) {
                // console.log(event.target.nodeName)
                var dropdowns = document.getElementsByClassName("profileDrop");
                var i;
                for (i = 0; i < dropdowns.length; i++) {
                    // console.log("abc")
                    var openDropdown = dropdowns[i];
                    if (openDropdown.classList.contains('show')) {
                        openDropdown.classList.remove('show');
                    }
                }
            }
        }
        const { placement, visible } = this.state;
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light  sticky-top">
                    <div className="container">
                        <a className="navbar-brand" href="/home"><img width="65px" src={logo} alt="" /><img width="100px" src={logo2} alt="" /></a>
                        <button onClick={() => this.showDrawer()} className="navbar-toggler" type="button" data-target="#navbarText"
                            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="input-group mr-5 navSearchInput" style={{ zIndex: 2 }}>
                            {this.state.loader &&
                                <div className='loaderDiv'>
                                    <Spin />
                                </div>
                            }
                            <input id="searchForPro" onBlur={(e) => this.blurr(e)} autoComplete="off" id="searchInput" type="search" name="query" className="form-control"
                                placeholder="Search for Products, Brands etc. " id="searchVal" onKeyUp={(ev) => this.gettingList(ev, "searchVal")} />
                            {this.state.searchVal && this.state.dropDown && !this.state.loader &&
                                <div className='searchList' id="a">
                                    {this.state.allBrands.length ?
                                        <div id="a" className="searchedBrandNav">
                                            <p id="a"><b id="a">Brands: </b>
                                                <span id="searchedBrandNav">

                                                </span>
                                            </p>
                                            <hr />
                                        </div>
                                        : null}
                                    {this.state.allCategories.length ?
                                        <div id="a" className="searchedCategoriesNav">
                                            <p id="a"><b id="a">Categories: </b>
                                                <span id="searchedCategoriesNav">

                                                </span>
                                            </p>
                                            <hr />
                                        </div>
                                        : null}
                                    {this.state.allProductsTitles.length ?
                                        <div id="a" className="searchedProductNavMain">
                                            <b id="a">Products:</b>
                                            <div id="a" className="searchedProductNav">
                                            </div>
                                        </div>
                                        : null}

                                    {!this.state.allProductsTitles.length && !this.state.allCategories.length && !this.state.allBrands.length ?
                                        <div id="a" className="searchedProductNavMain">
                                            <b id="a">No results found</b>
                                        </div>
                                        : null}
                                </div>
                            }
                            <div className="input-group-append">
                                {window.screen.width < 600 ?
                                    <a href={`/search?searchVal=${this.state.searchVal}`}><button onClick={() => this.search()} className="btn" type="submit">Search</button></a>
                                    :
                                    <NavLink to={`/search?searchVal=${this.state.searchVal}`}><button onClick={() => this.search()} className="btn" type="submit">Search</button></NavLink>
                                }
                            </div>
                        </div>
                        <div className="antdDrawerWeb">
                            <ul className="navbar-nav ">
                                <li className="nav-item startQuiz">
                                    <a style={{ border: 'none' }} className="nav-link" href="/quiz">
                                        {this.props.is_authenticated ?
                                            <button onClick={() => this.retakeTrue()} className="quiz" style={{ color: 'white' }}>
                                                Retake Quiz
                                            </button>
                                            :
                                            <button onClick={() => this.quizClick("Started Quiz")} className="quiz" style={{ color: 'white' }}>
                                                Start Glam Quiz
                                            </button>
                                        }
                                    </a>
                                </li>
                            </ul>
                            <span className="navbar-text profileIcon">
                                {this.props.is_authenticated ?
                                    <>
                                        {window.screen.width < 600 ?
                                            <a href="/profile/whishlist">
                                                <i className="fa fa-heart"></i>
                                            </a>
                                            :
                                            <NavLink to="/profile/whishlist">
                                                <i className="fa fa-heart"></i>
                                            </NavLink>
                                        }
                                        <div id="profileDrop" className="dropdown2" onClick={this.myFunction}>
                                            <svg className="navSvg dropbtn2" width="2em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                <path className="dropbtn2" fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                            </svg>
                                            <div id="myDropdown" className="profileDrop">
                                                {window.screen.width < 600 ?
                                                    <a onClick={() => this.onClose()} href="/profile/summary">Profile</a>
                                                    :
                                                    <NavLink onClick={() => this.onClose()} to="/profile/summary">Profile</NavLink>
                                                }
                                                <a href="/home" onClick={this.logout}>Logout</a>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <a href="/login" className="dropdown2">
                                            <i className="fa fa-heart"></i>
                                        </a>
                                        <a href="/login" className="dropdown2">
                                            <svg className="navSvg dropbtn2" width="2em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                <path className="dropbtn2" fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                            </svg>
                                        </a>
                                    </>
                                }
                            </span>
                        </div>

                        <Drawer
                            title={
                                this.props.is_authenticated ?
                                    <div className="antDrawerHeader">
                                        <div>
                                            {this.props.is_authenticated ?
                                                window.screen.width < 600 ?
                                                    <a onClick={() => this.onClose()} style={{ color: "black" }} href="/profile/summary">
                                                        <svg className="navSvg dropbtn2" width="2em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                            <path className="dropbtn2" fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                                        </svg>
                                                        Profile
                                                    </a>
                                                    :
                                                    <NavLink onClick={() => this.onClose()} style={{ color: "black" }} to="/profile/summary">
                                                        <svg className="navSvg dropbtn2" width="2em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                            <path className="dropbtn2" fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                                        </svg>
                                                        Profile
                                                    </NavLink>
                                                :
                                                <a href="/login">
                                                    Profile
                                                    <svg className="navSvg dropbtn2" width="2em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                                        <path className="dropbtn2" fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                                    </svg>
                                                </a>
                                            }
                                        </div>
                                        <div onClick={this.logout} className="div">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-reply-all-fill" viewBox="0 0 16 16">
                                                <path d="M8.079 11.9l4.568-3.281a.719.719 0 0 0 0-1.238L8.079 4.1A.716.716 0 0 0 7 4.719V6c-1.5 0-6 0-7 8 2.5-4.5 7-4 7-4v1.281c0 .56.606.898 1.079.62z" />
                                                <path fill-rule="evenodd" d="M10.868 4.293a.5.5 0 0 1 .7-.106l3.993 2.94a1.147 1.147 0 0 1 0 1.946l-3.994 2.94a.5.5 0 0 1-.593-.805l4.012-2.954a.493.493 0 0 1 .042-.028.147.147 0 0 0 0-.252.496.496 0 0 1-.042-.028l-4.012-2.954a.5.5 0 0 1-.106-.699z" />
                                            </svg>
                                            Logout
                                        </div>
                                    </div>
                                    :
                                    <div className="antDrawerHeader">
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <a style={{ color: "black" }} href="/login">Login</a>
                                        </div>
                                        <div className="div">
                                            <a style={{ color: "black" }} href="/sign-up">Start quiz</a>
                                        </div>
                                    </div>
                            }
                            placement={placement}
                            closable={false}
                            onClose={this.onClose}
                            visible={visible}
                            key={placement}
                        >
                            <div style={{ display: "flex", justifyContent: "center", marginTop: navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(/iPhone/i) || (navigator.userAgent.match(/iPod/i)) ? 20 : 7, marginBottom: navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(/iPhone/i) || (navigator.userAgent.match(/iPod/i)) ? 0 : 7 }}>
                                <span>
                                    <a target="_blank" href="https://www.facebook.com/glamatebeauty" style={{ color: "#f47545" }}><i className="fab fa-facebook-f fa-fw fa-lg"></i></a>&nbsp; &nbsp;
                                    <a target="_blank" href="https://www.linkedin.com/company/glamate" style={{ color: "#f47545" }}><i className="fab fa-linkedin-in fa-fw fa-lg"></i></a>&nbsp; &nbsp;
                                    <a target="_blank" href="https://www.instagram.com/glamatebeauty/" style={{ color: "#f47545" }}><i className="fab fa-instagram fa-fw fa-lg"></i></a>
                                </span>
                            </div>
                            {/* <Collapse defaultActiveKey={['1', '2', '3']}> */}
                            <div className="navBrandsBlogs">
                                {window.screen.width < 600 ?
                                    <a onClick={this.onClose} href={`/brands`}>Brands</a>
                                    :
                                    <NavLink onClick={this.onClose} to={`/brands`}>Brands</NavLink>
                                }
                            </div>
                            <Collapse>
                                <Panel header="Face" key="1">
                                    {this.props.is_authenticated ?
                                        window.screen.width < 600 ?
                                            <a style={{ textAlign: "center" }} onClick={() => {
                                                this.props.fetchSkincareProductsRecommended("skin")
                                                this.onClose()
                                            }} className="dropdown-item" href={`/suggestions?Skin Care`}>Recommendations</a>
                                            :
                                            <NavLink style={{ textAlign: "center" }} onClick={() => {
                                                this.props.fetchSkincareProductsRecommended("skin")
                                                this.onClose()
                                            }} className="dropdown-item" to={`/suggestions?Skin Care`}>Recommendations</NavLink>
                                        :
                                        <a style={{ textAlign: "center" }} className="dropdown-item" href={`/login`}>Recommendations</a>
                                    }
                                    <div className="nestedCate">

                                        {this.props.categories && this.props.categories.map((a, i) => {
                                            return (
                                                <>
                                                    {window.screen.width < 600 ?
                                                        <a onClick={() => {
                                                            setTimeout(() => {
                                                                let params = new URLSearchParams(window.location.search)
                                                                let category = params.get("category")
                                                                let current = Number(params.get("page"))

                                                                // this.props.getBrands()
                                                                let to = 12
                                                                this.setState({
                                                                    to
                                                                })
                                                                if (category) {
                                                                    category = category.match("-") ? category.split("-").join("&") : category
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current,
                                                                        category
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'skin')
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'body')
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'hair')
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                    document.title = `All ${category}'s`
                                                                } else if (!category) {
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProductsRecommended("skin")
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchSkincareProductsRecommended("body")
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchSkincareProductsRecommended("hair")
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                }
                                                            }, 500)
                                                            this.onClose()
                                                        }} target={window.screen.availWidth < 768 ? false : "_blank"} href={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} className="dropdown-item" >{a}
                                                        </a>
                                                        :
                                                        <NavLink onClick={() => {
                                                            setTimeout(() => {
                                                                let params = new URLSearchParams(window.location.search)
                                                                let category = params.get("category")
                                                                let current = Number(params.get("page"))

                                                                // this.props.getBrands()
                                                                let to = 12
                                                                this.setState({
                                                                    to
                                                                })
                                                                if (category) {
                                                                    category = category.match("-") ? category.split("-").join("&") : category
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current,
                                                                        category
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'skin')
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'body')
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'hair')
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                    document.title = `All ${category}'s`
                                                                } else if (!category) {
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProductsRecommended("skin")
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchSkincareProductsRecommended("body")
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchSkincareProductsRecommended("hair")
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                }
                                                            }, 500)
                                                            this.onClose()
                                                        }} target={window.screen.availWidth < 768 ? false : "_blank"} to={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} className="dropdown-item" >{a}
                                                        </NavLink>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>
                                    {window.screen.width < 600 ?
                                        <a onClick={() => {
                                            this.onClose()
                                            this.setHead("skin")
                                        }} className="ViewAllMobLink" href="/skin-care">View all</a>
                                        :
                                        <NavLink onClick={() => {
                                            this.onClose()
                                            this.setHead("skin")
                                        }} className="ViewAllMobLink" to="/skin-care">View all</NavLink>
                                    }
                                </Panel>
                                <Panel header="Bath & Body" key="2">
                                    {this.props.is_authenticated ?
                                        window.screen.width < 600 ?
                                            <a style={{ textAlign: "center" }} onClick={() => {
                                                this.props.fetchSkincareProductsRecommended("body")
                                                this.onClose()
                                            }} className="dropdown-item" href={`/suggestions?Body Care`}>Recommendations</a>
                                            :
                                            <NavLink style={{ textAlign: "center" }} onClick={() => {
                                                this.props.fetchSkincareProductsRecommended("body")
                                                this.onClose()
                                            }} className="dropdown-item" to={`/suggestions?Body Care`}>Recommendations</NavLink>
                                        :
                                        <a style={{ textAlign: "center" }} className="dropdown-item" href={`/login`}>Recommendations</a>
                                    }
                                    <div className="nestedCate">
                                        {this.props.bodyCareCategories && this.props.bodyCareCategories.map((a, i) => {
                                            return (
                                                <>
                                                    {window.screen.width < 600 ?
                                                        <a onClick={() => {
                                                            setTimeout(() => {
                                                                let params = new URLSearchParams(window.location.search)
                                                                let category = params.get("category")
                                                                let current = Number(params.get("page"))

                                                                // this.props.getBrands()
                                                                let to = 12
                                                                this.setState({
                                                                    to
                                                                })
                                                                if (category) {
                                                                    category = category.match("-") ? category.split("-").join("&") : category
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current,
                                                                        category
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'skin')
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'body')
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'hair')
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                    document.title = `All ${category}'s`
                                                                } else if (!category) {
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProductsRecommended("skin")
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchSkincareProductsRecommended("body")
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchSkincareProductsRecommended("hair")
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                }
                                                            }, 500)
                                                            this.onClose()
                                                        }} target={window.screen.availWidth < 768 ? false : "_blank"} href={`/body-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} className="dropdown-item">{a.toLocaleLowerCase() === "Shower Gels & Body Wash".toLocaleLowerCase() ? "Body Wash" : a}
                                                        </a>
                                                        :
                                                        <NavLink onClick={() => {
                                                            setTimeout(() => {
                                                                let params = new URLSearchParams(window.location.search)
                                                                let category = params.get("category")
                                                                let current = Number(params.get("page"))

                                                                // this.props.getBrands()
                                                                let to = 12
                                                                this.setState({
                                                                    to
                                                                })
                                                                if (category) {
                                                                    category = category.match("-") ? category.split("-").join("&") : category
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current,
                                                                        category
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'skin')
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'body')
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'hair')
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                    document.title = `All ${category}'s`
                                                                } else if (!category) {
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProductsRecommended("skin")
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchSkincareProductsRecommended("body")
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchSkincareProductsRecommended("hair")
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                }
                                                            }, 500)
                                                            this.onClose()
                                                        }} target={window.screen.availWidth < 768 ? false : "_blank"} to={`/body-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} className="dropdown-item">{a.toLocaleLowerCase() === "Shower Gels & Body Wash".toLocaleLowerCase() ? "Body Wash" : a}
                                                        </NavLink>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>
                                    {window.screen.width < 600 ?
                                        <a onClick={() => {
                                            this.onClose()
                                            this.setHead("body")
                                        }} className="ViewAllMobLink" href="/body-care">View all</a>
                                        :
                                        <NavLink onClick={() => {
                                            this.onClose()
                                            this.setHead("body")
                                        }} className="ViewAllMobLink" to="/body-care">View all</NavLink>
                                    }
                                </Panel>
                                <Panel header="Hair" key="3">
                                    {this.props.is_authenticated ?
                                        window.screen.width < 600 ?
                                            <a style={{ textAlign: "center" }} className="dropdown-item" onClick={() => {
                                                this.props.fetchSkincareProductsRecommended("hair")
                                                this.onClose()
                                            }} href={`/suggestions?Hair Care`}>Recommendations</a>
                                            :
                                            <NavLink style={{ textAlign: "center" }} className="dropdown-item" onClick={() => {
                                                this.props.fetchSkincareProductsRecommended("hair")
                                                this.onClose()
                                            }} to={`/suggestions?Hair Care`}>Recommendations</NavLink>
                                        :
                                        <a style={{ textAlign: "center" }} className="dropdown-item" href={`/login`}>Recommendations</a>
                                    }
                                    <div className="nestedCate">
                                        {this.props.hairCareCategories && this.props.hairCareCategories.map((a, i) => {
                                            return (
                                                <>
                                                    <div key={i} className="dropdown-divider"></div>
                                                    {window.screen.width < 600 ?
                                                        <a onClick={() => {
                                                            setTimeout(() => {
                                                                let params = new URLSearchParams(window.location.search)
                                                                let category = params.get("category")
                                                                let current = Number(params.get("page"))

                                                                // this.props.getBrands()
                                                                let to = 12
                                                                this.setState({
                                                                    to
                                                                })
                                                                if (category) {
                                                                    category = category.match("-") ? category.split("-").join("&") : category
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current,
                                                                        category
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'skin')
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'body')
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'hair')
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                    document.title = `All ${category}'s`
                                                                } else if (!category) {
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProductsRecommended("skin")
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchSkincareProductsRecommended("body")
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchSkincareProductsRecommended("hair")
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                }
                                                            }, 500)
                                                            this.onClose()
                                                        }} target={window.screen.availWidth < 768 ? false : "_blank"} href={`/hair-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} className="dropdown-item">{a}</a>
                                                        :
                                                        <NavLink onClick={() => {
                                                            setTimeout(() => {
                                                                let params = new URLSearchParams(window.location.search)
                                                                let category = params.get("category")
                                                                let current = Number(params.get("page"))

                                                                // this.props.getBrands()
                                                                let to = 12
                                                                this.setState({
                                                                    to
                                                                })
                                                                if (category) {
                                                                    category = category.match("-") ? category.split("-").join("&") : category
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current,
                                                                        category
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'skin')
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'body')
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12, category, false, "did")
                                                                        this.props.getFilteredSkinCareRecommended(category, 'hair')
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                    document.title = `All ${category}'s`
                                                                } else if (!category) {
                                                                    let page = window.location.pathname.slice(1).split("-").join(" ")
                                                                    this.setState({
                                                                        page,
                                                                        current
                                                                    })
                                                                    if (page.toLowerCase() === "skin care") {
                                                                        this.props.fetchSkincareProductsRecommended("skin")
                                                                        this.props.fetchSkincareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "skin-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "body care") {
                                                                        this.props.fetchSkincareProductsRecommended("body")
                                                                        this.props.fetchBodycareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "body-care"
                                                                        })
                                                                    }
                                                                    if (page.toLocaleLowerCase() === "hair care") {
                                                                        this.props.fetchSkincareProductsRecommended("hair")
                                                                        this.props.fetchHaircareProducts(current ? current : 1, 12)
                                                                        this.setState({
                                                                            mainPage: "hair-care"
                                                                        })
                                                                    }
                                                                }
                                                            }, 500)
                                                            this.onClose()
                                                        }} target={window.screen.availWidth < 768 ? false : "_blank"} to={`/hair-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} className="dropdown-item">{a}</NavLink>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>
                                    {window.screen.width < 600 ?
                                        <a onClick={() => {
                                            this.onClose()
                                            this.setHead("hair")
                                        }} className="ViewAllMobLink" href="/hair-care">View all</a>
                                        :
                                        <NavLink onClick={() => {
                                            this.onClose()
                                            this.setHead("hair")
                                        }} className="ViewAllMobLink" to="/hair-care">View all</NavLink>
                                    }
                                </Panel>
                            </Collapse>
                            <div className="navBrandsBlogs">
                                {window.screen.width < 600 ?
                                    <a onClick={this.onClose} href={`/blogs`}>Glam Politics</a>
                                    :
                                    <NavLink onClick={this.onClose} to={`/blogs`}>Glam Politics</NavLink>
                                }
                            </div>
                            <div className="navBrandsBlogs">
                                {window.screen.width < 600 ?
                                    <a onClick={this.onClose} href={`/coupons`}>Coupons</a>
                                    :
                                    <NavLink onClick={this.onClose} to={`/coupons`}>Coupons</NavLink>
                                }
                            </div>
                            <div className="navBrandsBlogs">
                                {window.screen.width < 600 ?
                                    <a onClick={this.onClose} href={`/about`}>About Us</a>
                                    :
                                    <NavLink onClick={this.onClose} to={`/about`}>About Us</NavLink>
                                }
                            </div>
                            <a style={{ border: 'none' }} className="nav-link" href="/quiz">
                                {this.props.is_authenticated ?
                                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                        {window.screen.width < 600 ?
                                            <a onClick={this.onClose} href="/profile/whishlist" style={{ color: "black" }}>
                                                <i className="fa fa-heart"></i>
                                            </a>
                                            :
                                            <NavLink onClick={this.onClose} to="/profile/whishlist" style={{ color: "black" }}>
                                                <i className="fa fa-heart"></i>
                                            </NavLink>
                                        }
                                        <span style={{ color: "black" }}>|</span>
                                        <button onClick={() => this.retakeTrue()} className="quiz" style={{ color: 'white' }}>
                                            Retake Quiz
                                        </button>
                                    </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                        {window.screen.width < 600 ?
                                            <a onClick={this.onClose} href="/login" style={{ color: "black" }}>
                                                <i className="fa fa-heart"></i>
                                            </a>
                                            :
                                            <NavLink onClick={this.onClose} to="/login" style={{ color: "black" }}>
                                                <i className="fa fa-heart"></i>
                                            </NavLink>
                                        }
                                        <span style={{ color: "black" }}>|</span>
                                        <button onClick={() => this.quizClick("Started Quiz")} className="quiz" style={{ color: 'white' }}>
                                            Start Glam Quiz
                                        </button>
                                    </div>
                                }
                            </a>

                        </Drawer>


                        {/* <div className="collapse navbar-collapse" id="navbarText"> */}


                        {/* <ul className="navbar-nav ">
                                <li className="nav-item startQuiz">
                                    <a style={{ border: 'none' }} className="nav-link" href="/quiz">
                                        {this.props.is_authenticated ?
                                            <button onClick={() => this.retakeTrue()} className="quiz" style={{ color: 'white' }}>
                                                Retake Quiz
                                        </button>
                                            :
                                            <button className="quiz" style={{ color: 'white' }}>
                                                Start Quiz
                                        </button>
                                        }
                                    </a>
                                </li>
                                <span className="resSpanWeb">
                                    {
                                        //     <li className="nav-item active">
                                        //     <a className="nav-link" href="/home">Home <span className="sr-only">(current)</span></a>
                                        // </li>
                                    }

                                    <li id="two" className="nav-item dropdown" onMouseOver={() => this.dropdown(`2`, 'mob')} onMouseOut={() => this.dropdownOut(`2`, 'mob')}>
                                        <NavLink onClick={this.setHead} className="nav-link dropdown-toggle webSkinCare" to="/skin-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Skin Care &nbsp; <img src={dropArrow} />
                                        </NavLink>
                                        <a onClick={this.dropdownToggle} className="nav-link dropdown-toggle mobSkinCare" href="#!"
                                        >
                                            Skin Care &nbsp; <img src={dropArrow} />
                                        </a>

                                        <div id="first2" className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {this.props.is_authenticated ?
                                                <NavLink onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" to={`/suggestions`}>Recommendations</NavLink>
                                                :
                                                <a className="dropdown-item" href={`/login`}>Recommendations</a>
                                            }
                                            {this.props.categories && this.props.categories.map((a, i) => {
                                                return (
                                                    <>
                                                        <div key={i} className="dropdown-divider"></div>
                                                        <NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/skin-care?${a.toLowerCase()}`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" to={`/skin-care?${a.toLowerCase()}`}>{a}</NavLink>
                                                    </>
                                                )
                                            })}
                                        </div>

                                        <div id="2" className="droppdownn2">
                                            {this.props.is_authenticated ?
                                                <div id="first" className="first">
                                                    <div><NavLink to="/suggestions">Recommendations</NavLink></div>
                                                    {this.props.categories && this.props.categories.map((a, i) => {
                                                        return (
                                                            <div key={i}><NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/skin-care?${a.toLowerCase()}`}>{a}</NavLink></div>
                                                        )
                                                    })}
                                                </div>
                                                :
                                                <div id="first" className="first">
                                                    <div><a href="/login">Recommendations</a></div>
                                                    {this.props.categories && this.props.categories.map((a, i) => {
                                                        return (
                                                            <div key={i}><NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/skin-care?${a.toLowerCase()}`}>{a}</NavLink></div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </li>
                                    {
                                        // <li className="nav-item">
                                        //     <NavLink className="nav-link" to="/about">About Us</NavLink>
                                        // </li>
                                    }

                                    <li id="two" className="nav-item dropdown" onMouseOver={() => this.dropdown(`2`, 'mob')} onMouseOut={() => this.dropdownOut(`2`, 'mob')}>
                                        <NavLink onClick={this.setHead} className="nav-link dropdown-toggle webSkinCare" to="/body-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Body Care &nbsp;
                                </NavLink>
                                        <a onClick={this.dropdownToggle} className="nav-link dropdown-toggle mobSkinCare" href="#!"
                                        >
                                            Body Care &nbsp; <img src={dropArrow} />
                                        </a>

                                        <div id="first2" className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {this.props.is_authenticated ?
                                                <NavLink onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" to={`/suggestions`}>Recommendations</NavLink>
                                                :
                                                <a className="dropdown-item" href={`/login`}>Recommendations</a>
                                            }
                                            {this.props.bodyCareCategories && this.SortArr(this.props.bodyCareCategories,"body").map((a, i) => {
                                                return (
                                                    <>
                                                        <div key={i} className="dropdown-divider"></div>
                                                        <NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/body-care?${a.toLowerCase()}`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" to={`/skin-care?${a.toLowerCase()}`}>{a}</NavLink>
                                                    </>
                                                )
                                            })}
                                        </div>

                                        <div id="2" className="droppdownn2">
                                            {this.props.is_authenticated ?
                                                <div id="first" className="first">
                                                    <div><NavLink to="/suggestions">Recommendations</NavLink></div>
                                                    {this.props.bodyCareCategories && this.SortArr(this.props.bodyCareCategories,"body").map((a, i) => {
                                                        return (
                                                            <div key={i}><NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/body-care?${a.toLowerCase()}`}>{a}</NavLink></div>
                                                        )
                                                    })}
                                                </div>
                                                :
                                                <div id="first" className="first">
                                                    <div><a href="/login">Recommendations</a></div>
                                                    {this.props.bodyCareCategories && this.SortArr(this.props.bodyCareCategories,"body").map((a, i) => {
                                                        return (
                                                            <div key={i}><NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/body-care?${a.toLowerCase()}`}>{a}</NavLink></div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </li>

                                    <li id="two" className="nav-item dropdown" onMouseOver={() => this.dropdown(`2`, 'mob')} onMouseOut={() => this.dropdownOut(`2`, 'mob')}>
                                        <NavLink onClick={this.setHead} className="nav-link dropdown-toggle webSkinCare" to="/hair-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Hair Care &nbsp;
                                </NavLink>
                                        <a onClick={this.dropdownToggle} className="nav-link dropdown-toggle mobSkinCare" href="#!"
                                        >
                                            Hair Care &nbsp; <img src={dropArrow} />
                                        </a>

                                        <div id="first2" className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {this.props.is_authenticated ?
                                                <NavLink onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" to={`/suggestions`}>Recommendations</NavLink>
                                                :
                                                <a className="dropdown-item" href={`/login`}>Recommendations</a>
                                            }
                                            {this.props.hairCareCategories && this.SortArr(this.props.hairCareCategories,"hair").map((a, i) => {
                                                return (
                                                    <>
                                                        <div key={i} className="dropdown-divider"></div>
                                                        <NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/hair-care?${a.toLowerCase()}`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" to={`/skin-care?${a.toLowerCase()}`}>{a}</NavLink>
                                                    </>
                                                )
                                            })}
                                        </div>

                                        <div id="2" className="droppdownn2">
                                            {this.props.is_authenticated ?
                                                <div id="first" className="first">
                                                    <div><NavLink to="/suggestions">Recommendations</NavLink></div>
                                                    {this.props.hairCareCategories && this.SortArr(this.props.hairCareCategories,"hair").map((a, i) => {
                                                        return (
                                                            <div key={i}><NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/hair-care?${a.toLowerCase()}`}>{a}</NavLink></div>
                                                        )
                                                    })}
                                                </div>
                                                :
                                                <div id="first" className="first">
                                                    <div><a href="/login">Recommendations</a></div>
                                                    {this.props.hairCareCategories && this.SortArr(this.props.hairCareCategories,"hair").map((a, i) => {
                                                        return (
                                                            <div key={i}><NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/hair-care?${a.toLowerCase()}`}>{a}</NavLink></div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </li>

                                </span>
                            </ul> */}
                        {/* <span className="navbar-text">
                                {
                                    //     <NavLink to={`${window.location.pathname}/${window.location.search}`}>
                                    // <div className="cartIconDiv">
                                    //     {this.props.is_authenticated ?
                                    //         <div className="cartNumber">{this.cartTotal(this.props.cartOrders)}</div>
                                    //         : null}

                                    //     <svg onClick={this.openNav} className="navSvg" width="1em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                    //         <path fillRule="evenodd" d="M10.243 1.071a.5.5 0 0 1 .686.172l3 5a.5.5 0 1 1-.858.514l-3-5a.5.5 0 0 1 .172-.686zm-4.486 0a.5.5 0 0 0-.686.172l-3 5a.5.5 0 1 0 .858.514l3-5a.5.5 0 0 0-.172-.686z"></path>
                                    //         <path fillRule="evenodd" d="M1 7v1h14V7H1zM.5 6a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5H.5z"></path>
                                    //         <path fillRule="evenodd" d="M14 9H2v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V9zM2 8a1 1 0 0 0-1 1v5a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9a1 1 0 0 0-1-1H2z"></path>
                                    //         <path fillRule="evenodd" d="M4 10a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 1 1-1 0v-3a.5.5 0 0 1 .5-.5z"></path>
                                    //     </svg>
                                    // </div> 
                                }
                                {
                                    // </NavLink>
                                }
                                {this.props.is_authenticated ?
                                    <div id="profileDrop" className="dropdown2" onClick={this.myFunction}>
                                        <svg className="navSvg dropbtn2" width="2em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                            <path className="dropbtn2" fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                        </svg>
                                        <div id="myDropdown" className="profileDrop">
                                            <NavLink to="/profile">Profile</NavLink>
                                            <a href="/home" onClick={this.logout}>Logout</a>
                                        </div>
                                    </div>
                                    :
                                    <a href="/login" className="dropdown2">
                                        <svg className="navSvg dropbtn2" width="2em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                                            <path className="dropbtn2" fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
                                        </svg>
                                    </a>
                                }


                                <NavLink to="#!" className="ipadview" onMouseOver={() => this.dropSearch('search')} onMouseOut={() => this.dropSearchOut('search')}>
                                    <i className="mobSView fas fa-search"></i>
                                </NavLink>
                                <div id="search" className="searchDrop">
                                    <input type="text" className="nav-search form-control" placeholder="Search for products, Brand etc"
                                        onMouseOver={() => this.dropSearch('search')} onMouseOut={() => this.dropSearchOut('search')} />

                                </div>

                            </span> */}
                        {/* </div> */}
                    </div>
                </nav>

                <div className="topBar">
                    <div className="topBar-con container">

                        <div className="row" style={{ zIndex: -1, opacity: 0 }}>
                            <p> <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-telephone-fill" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z" />
                            </svg>+1(917)4709709</p>
                            <p> <NavLink to="#!"><svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-envelope-fill"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                            </svg> mail@domain.com </NavLink> </p>
                        </div>

                        <span className="resSpanMob">
                            <ul className="navbar-nav ">
                                {/* <li className="nav-item active">
                                    <a className="nav-link" href="/home">Home <span className="sr-only">(current)</span></a>
                                </li> */}

                                <li className="nav-item" id="aboutusnav">
                                    {window.screen.width < 600 ?
                                        <a className="nav-link" href="/brands">Brands</a>
                                        :
                                        <NavLink className="nav-link" to="/brands">Brands</NavLink>
                                    }
                                </li>

                                <li id="one" className="nav-item dropdown" onMouseOver={() => this.dropdown(`1`, 'mob')} onMouseOut={() => this.dropdownOut(`1`, 'mob')}>
                                    {window.screen.width < 600 ?
                                        <a onClick={() => this.setHead("skin")} className="nav-link dropdown-toggle webSkinCare" href="/skin-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Face&nbsp;
                                        </a>
                                        :
                                        <NavLink onClick={() => this.setHead("skin")} className="nav-link dropdown-toggle webSkinCare" to="/skin-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Face&nbsp;
                                        </NavLink>
                                    }
                                    <a onClick={this.dropdownToggle} className="nav-link dropdown-toggle mobSkinCare" href="#!"
                                    >
                                        Face&nbsp; <img src={dropArrow} />
                                    </a>

                                    <div id="first2" className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {this.props.is_authenticated ?
                                            window.screen.width < 600 ?
                                                <a onClick={() => {
                                                    this.navbarToggler('navbarText')
                                                    this.onClose()
                                                }} className="dropdown-item" href={`/suggestions?Skin Care`}>Recommendations</a>
                                                :
                                                <NavLink onClick={() => {
                                                    this.navbarToggler('navbarText')
                                                    this.onClose()
                                                }} className="dropdown-item" to={`/suggestions?Skin Care`}>Recommendations</NavLink>
                                            :
                                            <a className="dropdown-item" href={`/login`}>Recommendations</a>
                                        }
                                        {this.props.categories && this.props.categories.map((a, i) => {
                                            return (
                                                <>
                                                    <div key={i} className="dropdown-divider"></div>
                                                    {window.screen.width < 600 ?
                                                        <a target={window.screen.availWidth < 768 ? false : "_blank"} href={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item">{a}</a>
                                                        :
                                                        <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item">{a}</NavLink>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>

                                    <div id="1" className="droppdownn2" style={{
                                        width: this.props.categories && this.props.categories.length > 9 ? "515px" : "410px"
                                    }}>
                                        {this.props.is_authenticated ?
                                            <div id="first" className="first" style={{
                                                flexDirection: "column",
                                                flexWrap: "wrap",
                                                maxHeight: "125px",

                                            }}>
                                                <div>
                                                    {window.screen.width < 600 ?
                                                        <a onClick={() => {
                                                            this.props.fetchSkincareProductsRecommended("skin")
                                                            this.onClose()
                                                        }} href="/suggestions?Skin Care">Recommendations</a>
                                                        :
                                                        <NavLink onClick={() => {
                                                            this.props.fetchSkincareProductsRecommended("skin")
                                                            this.onClose()
                                                        }} to="/suggestions?Skin Care">Recommendations</NavLink>
                                                    }
                                                </div>
                                                {this.props.categories && this.props.categories.map((a, i) => {
                                                    return (
                                                        <div key={i}><NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</NavLink></div>
                                                    )
                                                })}
                                            </div>
                                            :
                                            <div id="first" className="first"
                                                style={{
                                                    flexDirection: "column",
                                                    flexWrap: "wrap",
                                                    maxHeight: "125px",

                                                }}
                                            >
                                                <div><a href="/login">Recommendations</a></div>
                                                {this.props.categories && this.props.categories.map((a, i) => {
                                                    return (
                                                        <div key={i}>
                                                            {window.screen.width < 600 ?
                                                                <a target={window.screen.availWidth < 768 ? false : "_blank"} href={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</a>
                                                                :
                                                                <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</NavLink>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </li>


                                {/* bodycare */}
                                <li id="two" className="nav-item dropdown" onMouseOver={() => this.dropdown(`2`, 'mob')} onMouseOut={() => this.dropdownOut(`2`, 'mob')}>
                                    {window.screen.width < 600 ?
                                        <a onClick={() => this.setHead("body")} className="nav-link dropdown-toggle webSkinCare" href="/body-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Bath & Body&nbsp;
                                        </a>
                                        :
                                        <NavLink onClick={() => this.setHead("body")} className="nav-link dropdown-toggle webSkinCare" to="/body-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Bath & Body&nbsp;
                                        </NavLink>
                                    }
                                    <a onClick={this.dropdownToggle} className="nav-link dropdown-toggle mobSkinCare" href="#!"
                                    >
                                        Bath & Body&nbsp; <img src={dropArrow} />
                                    </a>

                                    <div id="first2" className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {this.props.is_authenticated ?
                                            window.screen.width < 600 ?
                                                <a onClick={() => {
                                                    this.navbarToggler('navbarText')
                                                    this.onClose()
                                                }} className="dropdown-item" href={`/suggestions?Body Care`}>Recommendations</a>
                                                :
                                                <NavLink onClick={() => {
                                                    this.navbarToggler('navbarText')
                                                    this.onClose()
                                                }} className="dropdown-item" to={`/suggestions?Body Care`}>Recommendations</NavLink>
                                            :
                                            <a className="dropdown-item" href={`/login`}>Recommendations</a>
                                        }
                                        {this.props.bodyCareCategories && this.props.bodyCareCategories.map((a, i) => {
                                            return (
                                                <>
                                                    <div key={i} className="dropdown-divider"></div>
                                                    {window.screen.width < 600 ?
                                                        <a target={window.screen.availWidth < 768 ? false : "_blank"} to={`/body-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" href={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</a>
                                                        :
                                                        <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/body-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" to={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</NavLink>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>

                                    <div id="2" className="droppdownn2" style={{
                                        width: this.props.bodyCareCategories && this.props.bodyCareCategories.length > 9 ? "515px" : "410px"
                                    }}>
                                        {this.props.is_authenticated ?
                                            <div id="first" className="first" style={{
                                                flexDirection: "column",
                                                flexWrap: "wrap",
                                                maxHeight: "125px",

                                            }}>
                                                <div>
                                                    {window.screen.width < 600 ?
                                                        <a onClick={() => {
                                                            this.props.fetchSkincareProductsRecommended("body")
                                                            this.onClose()
                                                        }} href="/suggestions?Body Care">Recommendations</a>
                                                        :
                                                        <NavLink onClick={() => {
                                                            this.props.fetchSkincareProductsRecommended("body")
                                                            this.onClose()
                                                        }} to="/suggestions?Body Care">Recommendations</NavLink>
                                                    }
                                                </div>
                                                {this.props.bodyCareCategories && this.props.bodyCareCategories.map((a, i) => {
                                                    return (
                                                        <div key={i}>
                                                            {window.screen.width < 600 ?
                                                                <a target={window.screen.availWidth < 768 ? false : "_blank"} href={`/body-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</a>
                                                                :
                                                                <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/body-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</NavLink>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            :
                                            <div id="first" className="first" style={{
                                                flexDirection: "column",
                                                flexWrap: "wrap",
                                                maxHeight: "125px",

                                            }}>
                                                <div><a href="/login">Recommendations</a></div>
                                                {this.props.bodyCareCategories && this.props.bodyCareCategories.map((a, i) => {
                                                    return (
                                                        <div key={i}>
                                                            {window.screen.width < 600 ?
                                                                <a target={window.screen.availWidth < 768 ? false : "_blank"} href={`/body-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</a>
                                                                :
                                                                <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/body-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</NavLink>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </li>

                                {/* haircare */}
                                <li id="three" className="nav-item dropdown" onMouseOver={() => this.dropdown(`3`, 'mob')} onMouseOut={() => this.dropdownOut(`3`, 'mob')}>
                                    {window.screen.width < 600 ?
                                        <a onClick={() => this.setHead("hair")} className="nav-link dropdown-toggle webSkinCare" href="/hair-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Hair&nbsp;</a>
                                        :
                                        <NavLink onClick={() => this.setHead("hair")} className="nav-link dropdown-toggle webSkinCare" to="/hair-care" id="navbarDropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">
                                            Hair&nbsp;</NavLink>
                                    }
                                    <a onClick={this.dropdownToggle} className="nav-link dropdown-toggle mobSkinCare" href="#!"
                                    >
                                        Hair&nbsp; <img src={dropArrow} />
                                    </a>

                                    <div id="first2" className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        {this.props.is_authenticated ?
                                            window.screen.width < 600 ?
                                                <a onClick={() => {
                                                    this.navbarToggler('navbarText')
                                                    this.onClose()
                                                }} className="dropdown-item" href={`/suggestions?Hair Care`}>Recommendations</a>
                                                :
                                                <NavLink onClick={() => {
                                                    this.navbarToggler('navbarText')
                                                    this.onClose()
                                                }} className="dropdown-item" to={`/suggestions?Hair Care`}>Recommendations</NavLink>
                                            :
                                            <a className="dropdown-item" href={`/login`}>Recommendations</a>
                                        }
                                        {this.props.hairCareCategories && this.props.hairCareCategories.map((a, i) => {
                                            return (
                                                <>
                                                    <div key={i} className="dropdown-divider"></div>
                                                    {window.screen.width < 600 ?
                                                        <a target={window.screen.availWidth < 768 ? false : "_blank"} to={`/hair-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" href={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</a>
                                                        :
                                                        <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/hair-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`} onClick={() => this.navbarToggler('navbarText')} className="dropdown-item" to={`/skin-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</NavLink>
                                                    }
                                                </>
                                            )
                                        })}
                                    </div>

                                    <div id="3" className="droppdownn2" style={{
                                        width: this.props.hairCareCategories && this.props.hairCareCategories.length > 9 ? "515px" : "410px"
                                    }}>
                                        {this.props.is_authenticated ?
                                            <div id="first" className="first" style={{
                                                flexDirection: "column",
                                                flexWrap: "wrap",
                                                maxHeight: "125px",

                                            }}>
                                                <div>
                                                    {window.screen.width < 600 ?
                                                        <a onClick={() => {
                                                            this.props.fetchSkincareProductsRecommended("hair")
                                                            this.onClose()
                                                        }} href="/suggestions?Hair Care">Recommendations</a>
                                                        :
                                                        <NavLink onClick={() => {
                                                            this.props.fetchSkincareProductsRecommended("hair")
                                                            this.onClose()
                                                        }} to="/suggestions?Hair Care">Recommendations</NavLink>
                                                    }
                                                </div>
                                                {this.props.hairCareCategories && this.props.hairCareCategories.map((a, i) => {
                                                    return (
                                                        <div key={i}>
                                                            {window.screen.width < 600 ?
                                                                <a target={window.screen.availWidth < 768 ? false : "_blank"} href={`/hair-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</a>
                                                                :
                                                                <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/hair-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</NavLink>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            :
                                            <div id="first" className="first" style={{
                                                flexDirection: "column",
                                                flexWrap: "wrap",
                                                maxHeight: "125px",

                                            }}>
                                                <div><a href="/login">Recommendations</a></div>
                                                {this.props.hairCareCategories && this.props.hairCareCategories.map((a, i) => {
                                                    return (
                                                        <div key={i}>
                                                            {window.screen.width < 600 ?
                                                                <a target={window.screen.availWidth < 768 ? false : "_blank"} href={`/hair-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</a>
                                                                :
                                                                <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/hair-care?category=${a.match("&") ? a.split("&").join("-") : a}&&page=1`}>{a}</NavLink>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </li>
                                <li className="nav-item" id="aboutusnav">
                                    {window.screen.width < 600 ?
                                        <a className="nav-link" href="/about">About Us</a>
                                        :
                                        <NavLink className="nav-link" to="/about">About Us</NavLink>
                                    }
                                </li>
                                <li className="nav-item" id="aboutusnav">
                                    <NavLink className="nav-link" to="/coupons">Coupons</NavLink>
                                </li>
                                <li className="nav-item" id="aboutusnav">
                                    <a className="nav-link" href="/blogs">Glam Politics</a>
                                </li>
                            </ul>
                        </span>

                        <div>
                            <span>
                                <a target="_blank" href="https://www.facebook.com/glamatebeauty"><i className="fab fa-facebook-f"></i></a>
                                <a target="_blank" href="https://www.linkedin.com/company/glamate"><i className="fab fa-linkedin-in"></i></a>
                                <a target="_blank" href="https://www.instagram.com/glamatebeauty/"><i className="fab fa-instagram"></i></a>
                            </span>
                        </div>

                    </div>
                </div>

                <div id="mySidenav" className="sidenav">
                    <a id="aa" to="#!" className="closebtn" onClick={this.closeNav}>
                        <img src={logo} alt="" />
                        <span>Cart</span>
                        &times;
                    </a>
                    <div id="cartItemList" className="cartItemList">

                        {this.props.cartOrders.map((prod, i) => {
                            return (
                                <div key={i} id={`cartMain${prod.product_details[0] && prod.product_details[0].id}`} className="cartMain">
                                    <div className="cartImageDiv">
                                        <div onClick={() => this.removeAlertShow(`removeAlert${i}`)} className="cartRemove">
                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
                                            </svg>
                                        </div>
                                        <div id={`removeAlert${i}`} className="removeAlert">
                                            <div className="removeAlertMain">
                                                <button onClick={() => this.removeAlertHide(`removeAlert${i}`)}>Cancel</button>
                                                <button onClick={() => this.removeProduct(prod.product_details[0] && prod.product_details[0].id, i)}>Remove</button>
                                            </div>
                                        </div>

                                        {prod.product_details[0] && prod.product_details[0].stock_quantity === 0 &&
                                            <div id={`removeAlert${i}`} className="outOfStockAlert">
                                                <div className="removeAlertMain">
                                                    <h1>Out Of Stock</h1>
                                                    <div onClick={() => this.removeAlertShow(`removeAlert${i}`)} className="cartRemove">
                                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <img src={`${prod.product_details[0] && prod.product_details[0].image}`} />
                                    </div>
                                    <div className="cartContent">
                                        <h3>{prod.product_details[0] && prod.product_details[0].name}</h3>
                                        <p><b><span style={{ display: 'inline-block', marginTop: '10px', color: '#f47545' }}>Quantity</span> <br />
                                            <span onClick={() => this.removeFromCart(prod.product_details[0] && prod.product_details[0].id)} className="plusMinus" data-action="remove">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-dash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                                </svg>
                                            </span>
                                            <b id={`itemQuantity${i}`}>{prod.quantity}x</b>
                                            <span onClick={() => prod.stock_quantity !== 0 ? this.addToCart(prod.product_details[0] && prod.product_details[0].id) : this.addToCart(prod.product_details[0] && prod.product_details[0].id, "out")} className="plusMinus" data-action="add">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                </svg>
                                            </span>
                                        </b><span id={`itemTotal${i}`} style={{ color: '#f47545' }}><i className="fas fa-rupee-sign"></i>{prod.total_price}</span></p>
                                    </div>
                                </div>
                            )
                        })}
                        <div className="cartButtonDiv">
                            {window.screen.width < 600 ?
                                <a onClick={this.closeNav} href="/checkout">Checkout</a>
                                :
                                <NavLink onClick={this.closeNav} to="/checkout">Checkout</NavLink>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    skinCareProducts: store.skinCareProducts,
    cartOrders: store.cartOrders,
    is_authenticated: store.is_authenticated,
    categories: store.categories,
    bodyCareCategories: store.bodyCareCategories,
    hairCareCategories: store.hairCareCategories,
    user: store.user
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCartOrders,
            getCategories,
            getFilteredSkinCare,
            getFilteredSkinCareRecommended,
            getBrands,
            fetchSkincareProducts,
            fetchSkincareProductsRecommended,
            getSearchResults,
            fetchBodycareProducts,
            getBodycareCategories,
            getHaircareCategories,
            fetchHaircareProducts,
            // getProductSearhBar
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Navbar))
