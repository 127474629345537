import { Spin } from 'antd';
import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import logo from '../../Images/Logo 2.png'
import logo2 from '../../Images/Logo 3.png'

class RedirectPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        if (!localStorage.getItem('redirect')) {
            window.close()
        } else {
            setTimeout(() => {
                window.location = `${window.location.search.slice(1)}`
                // window.open(window.location.search.slice(1))
                // window.close()
                localStorage.removeItem("redirect")
            }, 2000)

            // setTimeout(() => {
            //     if (window.location.pathname === '/redirect') {
            //         window.close()
            //     }
            // }, 5000)
        }
    }

    render() {
        document.title = "Redirecting.."
        return (
            <div className="redirectPage">
                <img className="redirectPageImage1" src={logo} />
                <img className="redirectPageImage2" src={logo2} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <h1>Redirecting....</h1> <Spin style={{ color: "red" }} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedirectPage)