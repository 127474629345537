import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBlogSingle, getTopBlogs, quickViewModel } from '../../modules/actions';
import ReactMarkdown from 'react-markdown';
import { NavLink } from 'react-router-dom';
import QuickView from '../quickViewModel/quickView';
import moment from 'moment'
import Axios from 'axios';
import Modal from 'antd/lib/modal/Modal';
import ReactGA from 'react-ga'


function LinkRenderer(props) {
    return <a href={props.href} target="_blank">{props.children}</a>
}

class BlogDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relatedPosts: [
                {
                    img: "https://www.cosmetify.com/media/images/best_full_cove.2e16d0ba.fill-164x105-c100.jpegquality-65.png",
                    title: "The Best Full Coverage Foundation for a Perfect Complexion"
                },
                {
                    img: "https://www.cosmetify.com/media/images/decleor-4.2e16d0ba.fill-164x105-c100.jpegquality-65.png",
                    title: "The 10 Best Decléor Products According to Our Beauty Editors"
                },
                {
                    img: "https://www.cosmetify.com/media/images/brushes-5.2e16d0ba.fill-164x105-c100.jpegquality-65.png",
                    title: "The 10 Best Facial Cleansing Brushes of 2021"
                },
                {
                    img: "https://www.cosmetify.com/media/images/brushes-1.2e16d0ba.fill-164x105-c100.jpegquality-65.png",
                    title: "The Best Makeup Brushes and Brush Sets"
                },
                {
                    img: "https://www.cosmetify.com/media/images/tik_tok_beauty.2e16d0ba.fill-164x105-c100.jpegquality-65.png",
                    title: "The Tik Tok Approved Beauty Products You Need for 2021"
                },
            ],
        }
    }

    componentDidMount() {
        window.scrollTo(500, 0);
        let blogId = window.location.pathname.split("/")[2]
        this.props.getBlogSingle(blogId)
        this.props.getTopBlogs()
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    quickView = (obj) => {
        if (obj) {
            let data = new FormData()
            data.append('slug', obj.slug)
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + `/api/products/details/`,
                validateStatus: (status) => {
                    return true;
                },
                data,
            }).then((response) => {
                let product = response.data.product
                if (product) {
                    this.props.quickViewModel(product)
                }
            })
        } else {
            this.setState({
                notExist: true
            })
        }
    }

    render() {
        let blog = this.props.blog

        let months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        return (
            blog ?
                <div className="blogDetailsMain">
                    <div className="container">
                        <div className="tree">
                            {window.screen.width < 600 ?
                                <a href={`/${window.location.pathname.split("/")[1]}`}>{window.location.pathname.split("/")[1]} </a>
                                :
                                <NavLink to={`/${window.location.pathname.split("/")[1]}`}>{window.location.pathname.split("/")[1]} </NavLink>
                            }
                             / {blog.title}</div>
                        <h6 className="title">{blog.title}</h6>
                        <h5>{blog.sub_heading}</h5>
                        <p className="by">By {blog.author} - {months[new Date(blog.created_at).getMonth()]} {new Date(blog.created_at).getDate()}, {new Date(blog.created_at).getFullYear()}</p>
                    </div>
                    <div className="blogPosting container">
                        <div id="blog-p" className="blog col-md-8 col-sm-12">
                            <img src={blog.image} className="mainImage" />
                            <p className="blogDes">
                                <ReactMarkdown
                                    source={blog.content}
                                    renderers={{ link: LinkRenderer }}
                                ></ReactMarkdown>
                            </p>
                            <div className="blogProducts">
                                {blog.blog_products && blog.blog_products.map((a, i) => {
                                    return (
                                        // i % 2 == 0 && window.screen.width > 600 ?
                                        //     <>
                                        //         <p className="blogDes">
                                        //             <ReactMarkdown>{a.content}</ReactMarkdown>
                                        //         </p>
                                        //         <div key={i} className="blogProduct">
                                        //             <div className="blogProductImage col-md-6 col-sm-12">
                                        //                 <a href={`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`} target="_blank">
                                        //                     <img src={a.product_image} />
                                        //                 </a>
                                        //             </div>
                                        //             <div className="blogProductContent col-md-6 col-sm-12">
                                        //                 <h6><a href={`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`} target="_blank">{a.name}</a></h6>
                                        //                 <p>
                                        //                     <ReactMarkdown>{a.description}</ReactMarkdown>
                                        //                 </p>
                                        //                 {window.screen.width > 600
                                        //                     ?
                                        //                     <button onClick={() => this.quickView(a.product_details && a.product_details[0])}>Quick View</button>
                                        //                     :
                                        //                     <div style={{ width: "100%", textAlign: "center" }}>
                                        //                         <button onClick={() => {
                                        //                             // this.props.history.push(`${window.location.pathname}${a.product_details && a.product_details[0].slug}`)
                                        //                             window.open(`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`)
                                        //                         }}>Quick View</button>
                                        //                     </div>
                                        //                 }
                                        //             </div>
                                        //         </div>
                                        //     </>
                                        //     :
                                        //     <>
                                        //         <p className="blogDes">
                                        //             <ReactMarkdown>{a.content}</ReactMarkdown>
                                        //         </p>
                                        //         <div key={i} className="blogProduct aliggRight">
                                        //             <div className="blogProductContent col-md-6 col-sm-12">
                                        //                 <h6><a href={`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`} target="_blank">{a.name}</a></h6>
                                        //                 <p>
                                        //                     <ReactMarkdown>{a.description}</ReactMarkdown>
                                        //                 </p>
                                        //                 {window.screen.width > 600
                                        //                     ?
                                        //                     <button onClick={() => this.quickView(a.product_details && a.product_details[0])}>Quick View</button>
                                        //                     :
                                        //                     <div style={{ width: "100%", textAlign: "center" }}>
                                        //                         <button onClick={() => {
                                        //                             // this.props.history.push(`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`)
                                        //                             window.open(`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`)
                                        //                         }}>Quick View</button>
                                        //                     </div>
                                        //                 }
                                        //             </div>
                                        //             <div className="blogProductImage col-md-6 col-sm-12">
                                        //                 <a href={`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`} target="_blank">
                                        //                     <img src={a.product_image} />
                                        //                 </a>
                                        //             </div>
                                        //         </div>
                                        //     </>
                                        a.type === "product" ?
                                            a.position.toLowerCase() === "left" ?
                                                <div key={i} className="blogProduct">
                                                    <div className="blogProductImage col-md-6 col-sm-12">
                                                        {!a.product_details ?
                                                            <a>
                                                                <img src={a.product_image} />
                                                            </a>
                                                            :
                                                            <a href={`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`} target="_blank">
                                                                <img src={a.product_image} />
                                                            </a>
                                                        }
                                                    </div>
                                                    <div className="blogProductContent col-md-6 col-sm-12">
                                                        <h6>
                                                            {!a.product_details ?
                                                                <a href="#">{a.name}</a>
                                                                :
                                                                <a href={`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`} target="_blank">{a.name}</a>
                                                            }
                                                        </h6>
                                                        <p>
                                                            <ReactMarkdown>{a.description}</ReactMarkdown>
                                                        </p>
                                                        {window.screen.width > 600
                                                            ?
                                                            <button onClick={() => this.quickView(a.product_details && a.product_details[0])}>Quick View</button>
                                                            :
                                                            <div style={{ width: "100%", textAlign: "center" }}>
                                                                <button onClick={() => {
                                                                    // this.props.history.push(`${window.location.pathname}${a.product_details && a.product_details[0].slug}`)
                                                                    window.open(`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`)
                                                                }}>Quick View</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div key={i} className="blogProduct aliggRight">
                                                    <div className="blogProductContent col-md-6 col-sm-12">
                                                        <h6>
                                                            {!a.product_details ?
                                                                <a>{a.name}</a>
                                                                :
                                                                <a href={`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`} target="_blank">{a.name}</a>
                                                            }
                                                        </h6>
                                                        <p>
                                                            <ReactMarkdown>{a.description}</ReactMarkdown>
                                                        </p>
                                                        {window.screen.width > 600
                                                            ?
                                                            <button onClick={() => this.quickView(a.product_details && a.product_details[0])}>Quick View</button>
                                                            :
                                                            <div style={{ width: "100%", textAlign: "center" }}>
                                                                <button onClick={() => {
                                                                    // this.props.history.push(`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`)
                                                                    window.open(`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`)
                                                                }}>Quick View</button>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="blogProductImage col-md-6 col-sm-12">
                                                        {!a.product_details ?
                                                            <a>
                                                                <img src={a.product_image} />
                                                            </a>
                                                            :
                                                            <a href={`${window.location.pathname}/${a.product_details && a.product_details[0].slug}`} target="_blank">
                                                                <img src={a.product_image} />
                                                            </a>
                                                        }
                                                    </div>
                                                </div>
                                            :
                                            <p className="blogDes">
                                                <ReactMarkdown>{a.content}</ReactMarkdown>
                                            </p>
                                    )
                                })}
                            </div>
                            {/* <div className="subDes">
                                <p>
                                    Get your fill of all the best collagen skin products and update your skin care collection pronto. For more hot tips and collagen for skin take a look at everything you need to know about vegan collagen and the best supplements for your skin.
                            </p>
                            </div>

                            <div className="authorDiv">
                                <div className="authorProfile">
                                    <div className="profileImage" style={{ backgroundImage: "url(https://www.cosmetify.com/images/profiles/rebecca-jenkinson.jpg)" }} />
                                    <div className="profileContent">
                                        <p>Written by</p>
                                        <h6>Rebecca Jenkinson</h6>
                                    </div>
                                </div>
                                <div className="authorDescription">
                                    <p>
                                        Rebecca is a content writer with over three years’ experience and a keen interest in cosmetics. Cosmetify allows her to combine her blogging skills with her passion for beauty.
                                </p>
                                </div>
                            </div> */}

                            {/* <div className="socialButtonDiv">
                                <button className="facebook">
                                    <i class="fab fa-facebook-f"></i> Share
                            </button>
                                <button className="twitter">
                                    <i class="fab fa-twitter"></i> Tweet
                            </button>
                                <button className="pinterest">
                                    <i class="fab fa-pinterest-p"></i> Pin
                            </button>
                            </div> */}
                        </div>
                        <div className="blogsRelated col-md-4 col-sm-12">
                            <h6>Top Posts</h6>
                            <div className="relatedMain">
                                {this.props.topBlogs.map((a, i) => {
                                    return (
                                        <a key={i} className="relatedBlogMain" href={`/blogs/${a.slug}`} target="_blank">
                                            <div className="relatedBlog">
                                                <div className="relatedImage col-md-4" style={{ backgroundImage: `url(${a.image})` }}></div>
                                                <div className="relatedContent col-md-8">
                                                    <a href={`/blogs/${a.slug}`} target="_blank">
                                                        <p>{a.title}</p>
                                                    </a>
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })}
                            </div>
                            {/* <h6 className="beside">Keep In Touch</h6>
                            <div className="socioIcons">
                                <i class="far fa-envelope"></i>
                                <i class="fab fa-instagram"></i>
                                <i class="fab fa-facebook-f"></i>
                                <i class="fab fa-youtube"></i>
                                <i class="fab fa-pinterest-p"></i>
                                <i class="fab fa-tiktok"></i>
                                <i class="fab fa-twitter"></i>
                            </div> */}
                        </div>
                    </div>
                    {this.props.quickView &&
                        <QuickView />
                    }

                    <Modal footer={false} visible={this.state.notExist} onCancel={() => this.setState({ notExist: false })} centered closeIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                    </svg>}>
                        <h5 style={{ textAlign: "center", fontWeight: "bold", marginTop: 30, marginBottom: 30, }}>This product no longer exist in GlaMate</h5>
                    </Modal>
                </div>
                :
                <div></div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    blog: store.blog,
    quickView: store.quickView,
    topBlogs: store.topBlogs
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBlogSingle: (id) => getBlogSingle(id),
            quickViewModel,
            getTopBlogs
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlogDetails)