import React from 'react'

// import icon1 from '../../../Images/icon1.jpg'
// import icon2 from '../../../Images/icon2.jpg'
// import icon3 from '../../../Images/icon3.jpg'
// import icon4 from '../../../Images/icon4.jpg'

import how1 from '../../../Images/how1.png'
import stepHover1 from '../../../Images/stepHover1.png'
import how2 from '../../../Images/how2.png'
import stepHover2 from '../../../Images/stepHover2.png'
import how3 from '../../../Images/how3.png'
import stepHover3 from '../../../Images/stepHover3.png'
import how4 from '../../../Images/how4.png'
import stepHover4 from '../../../Images/stepHover4.png'

import OnScrollAnimation from 'react-animate-on-scroll'
// import { Carousel } from 'antd';

class SeconSection extends React.Component {
    constructor(props) {
        super(props);
    }

    stepsHover = (val) => {
        if (val !== "1") {
            document.getElementById(`line${val}`).style.width = "100%"
        }
        document.getElementById(`radius${val}`).style.backgroundColor = "#f47545"
        document.getElementById(`radius${val}`).style.color = "white"
        document.getElementById(`radius${val}`).style.boxShadow = "0px 6px 20px 1px #ff9c78"
        document.getElementById(`workDiv${val}`).style.boxShadow = "#0000001c 5px 5px 32px 5px"
        document.getElementById(`workDiv${val}`).style.background = "#f47545"
        document.getElementById(`workDiv${val}`).style.color = "white"
        document.getElementById(`withoutHover${val}`).style.display = "none"
        document.getElementById(`hover${val}`).style.display = "block"
    }

    mouseOutStep = (val) => {
        if (val !== "1") {
            document.getElementById(`line${val}`).style.width = "0%"
        }
        document.getElementById(`radius${val}`).style.backgroundColor = "white"
        document.getElementById(`radius${val}`).style.color = "#f47545"
        document.getElementById(`radius${val}`).style.boxShadow = "none"
        document.getElementById(`workDiv${val}`).style.background = "white"
        document.getElementById(`workDiv${val}`).style.color = "black"
        document.getElementById(`workDiv${val}`).style.boxShadow = "none"
        document.getElementById(`withoutHover${val}`).style.display = "block"
        document.getElementById(`hover${val}`).style.display = "none"
    }

    componentDidMount(){
        setTimeout(()=>{
            if(document.getElementById("carouselExampleSlidesOnlyNext")){
                document.getElementById("carouselExampleSlidesOnlyNext").click()
            }
        },2000)
    }

    render() {
        return (
            <>
                {/* <div className="dil-serv container">
                    <div className="Delivery">
                        <div className="deliDiv">
                            <div>
                                <img src={icon1} />
                            </div>
                            <div>
                                <h6>Free Shipping <br /> <span>for all order over $99</span> </h6>
                            </div>
                        </div>
                        <div className="deliDiv">
                            <div>
                                <img src={icon2} />
                            </div>
                            <div>
                                <h6>Easy Payment <br /> <span>Different Payment Method</span> </h6>
                            </div>
                        </div>
                        <div className="deliDiv">
                            <div>
                                <img src={icon3} />
                            </div>
                            <div>
                                <h6>Secure Payment <br /> <span>100% Secure Payment</span> </h6>
                            </div>
                        </div>
                        <div className="deliDiv lastdiv">
                            <div>
                                <img src={icon4} />
                            </div>
                            <div>
                                <h6>24/7 Support <br /> <span>Dedicated Support</span> </h6>
                            </div>
                        </div>

                    </div>

                </div> */}
                <OnScrollAnimation animateIn="fadeIn" animateOnce >
                    <div className="work-con container">
                        <h2>How It Works</h2>
                        <div className="steps">
                            <div onMouseOut={() => this.mouseOutStep('1')} onMouseOver={() => this.stepsHover('1')} id="radius1"
                                className="radius animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>1</div>

                            <div className="line animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                                <div id="line2"></div>
                            </div>

                            <div onMouseOut={() => this.mouseOutStep('2')} onMouseOver={() => this.stepsHover('2')} id="radius2"
                                className="radius animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>2</div>

                            <div className="line animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.7s', animationName: 'fadeInUp' }}>
                                <div id="line3"></div>
                            </div>

                            <div onMouseOut={() => this.mouseOutStep('3')} onMouseOver={() => this.stepsHover('3')} id="radius3"
                                className="radius animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.7s', animationName: 'fadeInUp' }}>3</div>

                            <div className="line animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.9s', animationName: 'fadeInUp' }}>
                                <div id="line4"></div>
                            </div>

                            <div onMouseOut={() => this.mouseOutStep('4')} onMouseOver={() => this.stepsHover('4')} id="radius4"
                                className="radius animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.9s', animationName: 'fadeInUp' }}>4</div>
                        </div>
                        <div className="work work1">
                            <div id="workDiv1" onMouseOut={() => this.mouseOutStep('1')} onMouseOver={() => this.stepsHover('1')}
                                className="animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInUp' }}>
                                <img id="withoutHover1" src={how1} alt="" />
                                <img id="hover1" src={stepHover2} alt="" />

                                <div className="work-text">
                                    <p>Take a simple & fun quiz and we will create your beauty profile.</p>

                                </div>
                            </div>
                            <div id="workDiv2" onMouseOut={() => this.mouseOutStep('2')} onMouseOver={() => this.stepsHover('2')}
                                className="animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                                <img id="withoutHover2" src={how2} alt="" />
                                <img id="hover2" src={stepHover3} alt="" />

                                <div className="work-text">
                                    <p>View recommended products based on your profile or browse freely.</p>

                                </div>
                            </div>
                            <div id="workDiv3" onMouseOut={() => this.mouseOutStep('3')} onMouseOver={() => this.stepsHover('3')}
                                className="animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.7s', animationName: 'fadeInUp' }}>
                                <img id="withoutHover3" src={how3} alt="" />
                                <img id="hover3" src={stepHover1} alt="" />


                                <div className="work-text">
                                    <p>Compare prices and shop from the retailer you prefer.</p>

                                </div>
                            </div>
                            <div id="workDiv4" onMouseOut={() => this.mouseOutStep('4')} onMouseOver={() => this.stepsHover('4')}
                                className="animated wow fadeInUp"
                                style={{ visibility: 'visible', animationDelay: '0.9s', animationName: 'fadeInUp' }}>
                                <img id="withoutHover4" src={how4} alt="" />
                                <img id="hover4" src={stepHover4} alt="" />

                                <div className="work-text">
                                    <p>Save money and time by comparing prices for 1000's of products.</p>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="work2">
                        <div id="carouselExampleSlidesOnly" className="carousel slide" data-interval="3000" data-ride="carousel">
                            <div class="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="Work2Div">
                                        <img id="withoutHover1" src={how1} alt="" />

                                        <div className="work-text">
                                            <p>Take a simple & fun quiz and we will create your beauty profile.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="Work2Div">
                                        <img id="withoutHover2" src={how2} alt="" />

                                        <div className="work-text">
                                            <p>View recommended products based on your profile or browse freely.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="Work2Div">
                                        <img id="withoutHover3" src={how3} alt="" />

                                        <div className="work-text">
                                            <p>Compare prices and shop from the retailer you prefer.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="Work2Div">
                                        <img id="withoutHover4" src={how4} alt="" />

                                        <div className="work-text">
                                            <p>Save money and time by comparing prices for 1000's of products.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="controlProduct">
                                <a className=" btn " href="#carouselExampleSlidesOnly" data-slide="prev"><i className="fas fa-chevron-left"></i></a>
                                <a id="carouselExampleSlidesOnlyNext" className=" btn" href="#carouselExampleSlidesOnly" data-slide="next"><i className="fas fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>

                </OnScrollAnimation>
            </>
        )
    }
}

export default SeconSection