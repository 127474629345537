import React from 'react'
import './SkinCare.scss'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Axios from 'axios'
import { fetchBodycareProducts, fetchHaircareProducts, fetchSkincareProducts, fetchSkincareProductsRecommended, getBrands, getCartOrders, getCookie, getFilteredBodycare, getFilteredHaircare, getFilteredSkinCare, getFilteredSkinCareRecommended, getFilterProducts, getWishlist, isLoadingTrue, quickViewModel } from '../../modules/actions';
import Slider from 'react-slick';
import { Input, Pagination, Select } from 'antd';
import NOAvaliableImage from '../../Images/NoAvaliable.jpeg'
import QuickView from '../quickViewModel/quickView'
import Skeleton from '@material-ui/lab/Skeleton';
import Sliderr from '@material-ui/core/Slider';
import MetaTags from 'react-meta-tags';
import ReactGA from 'react-ga'

class SkinCare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_authenticated: true,
            firstName: 'ali',
            filterProducts: [],
            from: 0,
            to: 9,
            current: 1,
            page: "",
            pricesArr: [0, 4000],
            update: true,
            filteredBrands: []
        }
    }

    componentDidUpdate() {
        let params = new URLSearchParams(window.location.search)
        let page = params.get("page")
        let category = params.get("category")
        if (category) {
            category = category.match("-") ? category.split("-").join("&") : category
            if (this.props.is_authenticated) {
                if (this.props.user.firstname) {
                    document.getElementById('recHead').innerHTML = `${this.props.user.firstname ? this.props.user.firstname : "Loading.."}'s Recommended ${category}`
                }
            }
            document.getElementById('mainHead').innerHTML = `All ${category}'s`
        } else {
            if (this.props.is_authenticated) {
                if (this.props.user.firstname) {
                    document.getElementById('recHead').innerHTML = `${this.props.user.firstname ? this.props.user.firstname : "Loading.."}'s Recommended ${this.props.name}'s`
                }
            }
            if (this.state.update) {
                this.setState({
                    category: "",
                    update: false
                })
            }
            document.getElementById('mainHead').innerHTML = `All ${this.props.name} products`
        }

        if (this.state.initialRoute !== window.location.href) {
            this.setState({
                current: Number(page),
                initialRoute: window.location.href
            })
        }
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    componentDidMount() {
        // setTimeout(() => {

        //     let brandss = document.querySelectorAll('#checkMob')
        //     console.log(brandss)
        //     for (let i = 0; i < brandss.length; i++) {
        //         console.log(brandss[i].checked)
        //         brandss[i].checked = true
        //     }
        // }, 2000)

        this.setState({
            initialRoute: window.location.href
        })

        this.props.history.listen(location => {
            if (this.props.history.action === "POP") {
                setTimeout(() => {
                    if (this.props.is_authenticated) {
                        window.scrollTo({ top: 500, behavior: 'auto' });
                    } else {
                        window.scrollTo({ top: 0, behavior: 'auto' });
                    }
                }, 500)
                setTimeout(() => {
                    if (document.getElementById("carousel-example2Next")) {
                        document.getElementById("carousel-example2Next").click()
                    }
                }, 2000)
                let params = new URLSearchParams(window.location.search)
                let category = params.get("category")
                let current = Number(params.get("page"))
                let pricesArr = params.get("priceArr") ? params.get("priceArr").split(",") : [0, 4000]

                pricesArr[0] = Number(pricesArr[0])
                pricesArr[1] = Number(pricesArr[1])

                this.setState({
                    pricesArr,
                })


                // this.props.getBrands()
                let a = window.screen.availWidth
                let to = 12
                this.setState({
                    to
                })
                if (category) {
                    category = category.match("-") ? category.split("-").join("&") : category
                    let page = window.location.pathname.slice(1).split("-").join(" ")
                    this.setState({
                        page,
                        current,
                        category
                    })
                    if (page.toLowerCase() === "skin care") {
                        this.props.fetchSkincareProducts(current ? current : 1, 12, category, false, "did")
                        this.props.getFilteredSkinCareRecommended(category, 'skin')
                        this.setState({
                            mainPage: "skin-care"
                        })
                    }
                    if (page.toLocaleLowerCase() === "body care") {
                        this.props.fetchBodycareProducts(current ? current : 1, 12, category, false, "did")
                        this.props.getFilteredSkinCareRecommended(category, 'body')
                        this.setState({
                            mainPage: "body-care"
                        })
                    }
                    if (page.toLocaleLowerCase() === "hair care") {
                        this.props.fetchHaircareProducts(current ? current : 1, 12, category, false, "did")
                        this.props.getFilteredSkinCareRecommended(category, 'hair')
                        this.setState({
                            mainPage: "hair-care"
                        })
                    }
                    document.title = `All ${category}'s`
                } else if (!category) {
                    let page = window.location.pathname.slice(1).split("-").join(" ")
                    this.setState({
                        page,
                        current
                    })
                    if (page.toLowerCase() === "skin care") {
                        this.props.fetchSkincareProductsRecommended("skin")
                        this.props.fetchSkincareProducts(current ? current : 1, 12)
                        this.setState({
                            mainPage: "skin-care"
                        })
                    }
                    if (page.toLocaleLowerCase() === "body care") {
                        this.props.fetchSkincareProductsRecommended("body")
                        this.props.fetchBodycareProducts(current ? current : 1, 12)
                        this.setState({
                            mainPage: "body-care"
                        })
                    }
                    if (page.toLocaleLowerCase() === "hair care") {
                        this.props.fetchSkincareProductsRecommended("hair")
                        this.props.fetchHaircareProducts(current ? current : 1, 12)
                        this.setState({
                            mainPage: "hair-care"
                        })
                    }
                    if (document.getElementById('mainHead')) {
                        document.getElementById('mainHead').innerHTML = `All ${this.props.name} Products`
                    }
                    document.title = `All ${this.props.name} Products`
                }
                // window.scrollTo({ top: 0, behavior: 'auto' });
            } else {
                setTimeout(() => {
                    if (this.props.is_authenticated) {
                        window.scrollTo({ top: 500, behavior: 'auto' });
                    } else {
                        window.scrollTo({ top: 0, behavior: 'auto' });
                    }
                }, 0)
            }
        });

        setTimeout(() => {
            if (document.getElementById("carousel-example2Next")) {
                document.getElementById("carousel-example2Next").click()
            }
        }, 2000)
        let params = new URLSearchParams(window.location.search)
        let category = params.get("category")
        let current = Number(params.get("page"))
        let pricesArr = params.get("priceArr") ? params.get("priceArr").split(",") : [0, 4000]

        pricesArr[0] = Number(pricesArr[0])
        pricesArr[1] = Number(pricesArr[1])

        this.setState({
            pricesArr,
        })


        // this.props.getBrands()
        let a = window.screen.availWidth
        let to = 12
        this.setState({
            to
        })
        if (category) {
            category = category.match("-") ? category.split("-").join("&") : category
            let page = window.location.pathname.slice(1).split("-").join(" ")
            this.setState({
                page,
                current,
                category
            })
            if (page.toLowerCase() === "skin care") {
                this.props.fetchSkincareProducts(current ? current : 1, 12, category, false, "did")
                this.props.getFilteredSkinCareRecommended(category, 'skin')
                this.setState({
                    mainPage: "skin-care"
                })
            }
            if (page.toLocaleLowerCase() === "body care") {
                this.props.fetchBodycareProducts(current ? current : 1, 12, category, false, "did")
                this.props.getFilteredSkinCareRecommended(category, 'body')
                this.setState({
                    mainPage: "body-care"
                })
            }
            if (page.toLocaleLowerCase() === "hair care") {
                this.props.fetchHaircareProducts(current ? current : 1, 12, category, false, "did")
                this.props.getFilteredSkinCareRecommended(category, 'hair')
                this.setState({
                    mainPage: "hair-care"
                })
            }
            document.title = `All ${category}'s`
        } else if (!category) {
            let page = window.location.pathname.slice(1).split("-").join(" ")
            this.setState({
                page,
                current
            })
            if (page.toLowerCase() === "skin care") {
                this.props.fetchSkincareProductsRecommended("skin")
                this.props.fetchSkincareProducts(current ? current : 1, 12)
                this.setState({
                    mainPage: "skin-care"
                })
            }
            if (page.toLocaleLowerCase() === "body care") {
                this.props.fetchSkincareProductsRecommended("body")
                this.props.fetchBodycareProducts(current ? current : 1, 12)
                this.setState({
                    mainPage: "body-care"
                })
            }
            if (page.toLocaleLowerCase() === "hair care") {
                this.props.fetchSkincareProductsRecommended("hair")
                this.props.fetchHaircareProducts(current ? current : 1, 12)
                this.setState({
                    mainPage: "hair-care"
                })
            }
            document.getElementById('mainHead').innerHTML = `All ${this.props.name} Products`
            document.title = `All ${this.props.name} Products`
        }
        window.scrollTo({ top: 0, behavior: 'auto' });
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    byBrands = (products, id, callback, mob) => {
        let brandChecked = []
        let categoryChecked = []
        let priceChechked = []

        let brands = ""
        if (mob) {
            brands = document.querySelectorAll('#checkMob')
        } else {
            brands = document.querySelectorAll('#checkWeb')
        }
        let categories = ""
        if (mob) {
            categories = document.querySelectorAll('#check2Mob')
        } else {
            categories = document.querySelectorAll('#check2Web')
        }
        let prices = document.querySelectorAll('#check3')

        for (let i = 0; i < brands.length; i++) {
            if (brands[i].checked) {
                brandChecked.push(brands[i].value)
            }
        }
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].checked) {
                categoryChecked.push(categories[i].value)
            }
        }
        if (id) {
            for (let i = 0; i < prices.length; i++) {
                if (id !== i) {
                    prices[i].checked = false
                }
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].checked) {
                priceChechked.push(prices[i].value)
            }
        }

        let params = new URLSearchParams(window.location.search)
        let current = Number(params.get("page"))
        let category = params.get("category")
        let pricess = params.get('priceArr') ? params.get('priceArr').split(',') : ""
        let from = pricess[0] && this.state.setPrice ? Number(pricess[0]) : ""
        let to = pricess[1] && this.state.setPrice ? Number(pricess[1]) : ""

        let sortBy = params.get("sortBy")

        if (!callback && !mob) {
            if (category) {
                category = category.match("-") ? category.split("-").join("&") : category
                if (this.props.name.toLowerCase() === "skin care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/skin-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${`page=1&&`}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`
                    } else {
                        this.props.history.push(`/skin-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${`page=1&&`}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`)
                    }
                }
                if (this.props.name.toLowerCase() === "body care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/body-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${`page=1&&`}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`
                    } else {
                        this.props.history.push(`/body-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${`page=1&&`}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`)
                    }
                }
                if (this.props.name.toLowerCase() === "hair care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/hair-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${`page=1&&`}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`
                    } else {
                        this.props.history.push(`/hair-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${`page=1&&`}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`)
                    }
                }
            } else {
                if (this.props.name.toLowerCase() === "skin care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/skin-care?${`page=1&&`}${categoryChecked.length ? `cat=${categoryChecked.join(",")}&&` : ""}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`}`
                    } else {
                        this.props.history.push(`/skin-care?${`page=1&&`}${categoryChecked.length ? `cat=${categoryChecked.join(",")}&&` : ""}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`)
                    }
                }
                if (this.props.name.toLowerCase() === "body care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/body-care?${`page=1&&`}${categoryChecked.length ? `cat=${categoryChecked.join(",")}&&` : ""}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`
                    } else {
                        this.props.history.push(`/body-care?${`page=1&&`}${categoryChecked.length ? `cat=${categoryChecked.join(",")}&&` : ""}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`)
                    }
                }
                if (this.props.name.toLowerCase() === "hair care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/hair-care?${`page=1&&`}${categoryChecked.length ? `cat=${categoryChecked.join(",")}&&` : ""}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`}`
                    } else {
                        this.props.history.push(`/hair-care?${`page=1&&`}${categoryChecked.length ? `cat=${categoryChecked.join(",")}&&` : ""}${brandChecked.length ? `brands=${brandChecked.join(",")}&&` : ""}${pricess === null || pricess === false || !prices.length ? "" : `priceArr=${pricess}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}${pricess === null || pricess === false || pricess === "" ? "" : `priceArr=${pricess ? pricess : ""}&&`}`)
                    }
                }
            }
        }
        this.setState({
            current: 1
        }, () => {
            if (mob) {
                this.priceFilter()
            }
        })
        if (!callback && !mob) {
            this.props.getFilterProducts(1, 12, category ? category.match("-") ? category.split("-").join("&") : category : categoryChecked.join(","), brandChecked.join(","), this.state.mainPage, from, to)
        } else {
            return {
                current: 1, productPerPage: 12, categories: category ? category.match("-") ? category.split("-").join("&") : category : categoryChecked.join(","), brands: brandChecked.join(","), main: this.state.mainPage, from, to
            }
        }
    }

    addToCart = (id, out) => {
        if (!out) {
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("id", id);
            data.append("action", "add");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (response.data.code === "token_not_valid") {
                        let msg = "You have login first"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        let data = response.data.products
                        // this.props.getCartOrders(data);
                        let msg = response.data.message
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        return data
                    }
                })
                .catch(error => {
                    // console.log(error)
                });
        } else {
            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>Out of stock</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
            }, 2000)
        }
    }

    addToWhishlist = (id) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("product_id", id);
        // data.append("action", "add");
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/wishlist/update/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.code === "token_not_valid") {
                    // let msg = "You have login first"
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    // setTimeout(() => {
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    window.location.href = `/login`
                } else {
                    let data = response.data.products
                    // let msg = response.data.message
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    this.props.getWishlist()
                    // setTimeout(() => {
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    return data
                }
            })
            .catch(error => {
                // console.log(error)
            });
    }

    serRec = (a) => {
        if (a.score) {
            localStorage.setItem(`score`, a.score)
        }
    }

    quickView = (obj) => {
        this.props.quickViewModel(obj)
    }

    checkWhishlist = (a, abc) => {
        let whishlistProducts = this.props.whishlistProducts
        if (whishlistProducts) {
            let check = whishlistProducts.filter(b => b.id === a.id)
            if (check.length) {
                return (
                    <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-heart-fill" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                    </svg>
                )
            } else {
                return (
                    <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                    </svg>
                )
            }
        } else {
            return (
                <svg id="noOpen" width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart" fill={abc ? "black" : "white"} xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                </svg>
            )
        }
    }

    discoutGet = (a, b) => {
        let price = a
        let discounted_price = b
        let discounted_given = Number(price) - Number(discounted_price)
        return `${Number(Math.ceil((discounted_given / price) * 100).toFixed(1)).toFixed(0)}% Off`

    }

    paginate = (ev) => {
        let filters = this.byBrands("", "", true)
        // console.log(filters)
        if (this.props.is_authenticated) {
            window.scrollTo({ top: 500, behavior: 'auto' });
        } else {
            window.scrollTo({ top: 0, behavior: 'auto' });
        }
        let a = 12
        let from = (ev * a) - a
        let to = ev * a
        this.setState({
            from,
            to,
            current: ev
        })
        let page = window.location.pathname.slice(1).split("-").join(" ")
        let params = new URLSearchParams(window.location.search)
        let category = params.get("category")
        if (category) {
            if (page.toLowerCase() === "skin care") {
                this.props.fetchSkincareProducts(ev, 12, category, filters)
                if (window.screen.width < 600) {
                    window.location.href = `/skin-care?${category === null || category === false ? "" : `category=${category ? category : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`
                } else {
                    this.props.history.push(`/skin-care?${category === null || category === false ? "" : `category=${category ? category : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`)
                }
            }
            if (page.toLocaleLowerCase() === "body care") {
                this.props.fetchBodycareProducts(ev, 12, category, filters)
                if (window.screen.width < 600) {
                    window.location.href = `/body-care?${category === null || category === false ? "" : `category=${category ? category : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`
                } else {
                    this.props.history.push(`/body-care?${category === null || category === false ? "" : `category=${category ? category : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`)
                }
            }
            if (page.toLocaleLowerCase() === "hair care") {
                this.props.fetchHaircareProducts(ev, 12, category, filters)
                if (window.screen.width < 600) {
                    window.location.href = `/hair-care?${category === null || category === false ? "" : `category=${category ? category : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`
                } else {
                    this.props.history.push(`/hair-care?${category === null || category === false ? "" : `category=${category ? category : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`)
                }
            }
        } else {
            if (page.toLowerCase() === "skin care") {
                this.props.fetchSkincareProducts(ev, 12, false, filters)
                if (window.screen.width < 600) {
                    window.location.href = `/skin-care?${params.get("cat") === null || params.get("cat") === false ? "" : `cat=${params.get("cat") ? params.get("cat") : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`
                } else {
                    this.props.history.push(`/skin-care?${params.get("cat") === null || params.get("cat") === false ? "" : `cat=${params.get("cat") ? params.get("cat") : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`)
                }
            }
            if (page.toLocaleLowerCase() === "body care") {
                this.props.fetchBodycareProducts(ev, 12, false, filters)
                this.props.history.push(`/body-care?${params.get("cat") === null || params.get("cat") === false ? "" : `cat=${params.get("cat") ? params.get("cat") : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`)
            }
            if (page.toLocaleLowerCase() === "hair care") {
                this.props.fetchHaircareProducts(ev, 12, false, filters)
                if (window.screen.width < 600) {
                    window.location.href = `/hair-care?${params.get("cat") === null || params.get("cat") === false ? "" : `cat=${params.get("cat") ? params.get("cat") : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`
                } else {
                    this.props.history.push(`/hair-care?${params.get("cat") === null || params.get("cat") === false ? "" : `cat=${params.get("cat") ? params.get("cat") : ""}&&`}${params.get("brands") === null || params.get("brands") === false ? "" : `brands=${params.get("brands") ? params.get("brands") : ""}&&`}${params.get("priceArr") === null || params.get("priceArr") === false ? "" : `priceArr=${params.get("priceArr") ? params.get("priceArr") : ""}&&`}${ev && `page=${ev}&&`}${params.get("sortBy") === null || params.get("sortBy") === false ? "" : `sortBy=${params.get("sortBy") ? params.get("sortBy") : ""}&&`}`)
                }
            }
        }

    }

    renderArrows = () => {
        return (
            <div style={{ zIndex: 1 }} className="slider-arrow">
                <i onClick={() => this.slider.slickPrev()} className="fas fa-chevron-left" aria-hidden="true"></i>
                <i onClick={() => this.slider.slickNext()} className="fas fa-chevron-right" aria-hidden="true"></i>
            </div>
        );
    };

    priceFilter = (ev) => {
        let brandChecked = []
        let categoryChecked = []

        let brands = ""
        if (window.screen.width < 450) {
            brands = document.querySelectorAll('#checkMob')
        } else {
            brands = document.querySelectorAll('#checkWeb')
        }
        let categories = ""
        if (window.screen.width < 450) {
            categories = document.querySelectorAll('#check2Mob')
        } else {
            categories = document.querySelectorAll('#check2Web')
        }
        let prices = document.querySelectorAll('#check3')

        for (let i = 0; i < brands.length; i++) {
            if (brands[i].checked) {
                brandChecked.push(brands[i].value)
            }
        }
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].checked) {
                categoryChecked.push(categories[i].value)
            }
        }


        let params = new URLSearchParams(window.location.search)
        let current = Number(params.get("page"))
        let category = params.get("category")
        let sortBy = params.get("sortBy")

        this.setState({
            current: 1
        })
        if (category) {
            category = category.match("-") ? category.split("-").join("&") : category
            if (ev) {
                if (this.props.name.toLowerCase() === "skin care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/skin-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`
                    } else {
                        this.props.history.push(`/skin-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`)
                    }
                    this.props.fetchSkincareProducts(1, 12, category, true, "mob")
                }
                if (this.props.name.toLowerCase() === "body care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/body-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`
                    } else {
                        this.props.history.push(`/body-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`)
                    }
                    this.props.fetchBodycareProducts(1, 12, category, true, "mob")
                }
                if (this.props.name.toLowerCase() === "hair care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/hair-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`
                    } else {
                        this.props.history.push(`/hair-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`)
                    }
                    this.props.fetchHaircareProducts(1, 12, category, true, "mob")
                }
            } else {
                if (this.props.name.toLowerCase() === "skin care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/skin-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`
                    } else {
                        this.props.history.push(`/skin-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`)
                    }
                    this.props.fetchSkincareProducts(1, 12, category, true, "mob")
                }
                if (this.props.name.toLowerCase() === "body care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/body-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`
                    } else {
                        this.props.history.push(`/body-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`)
                    }
                    this.props.fetchBodycareProducts(1, 12, category, true, "mob")
                }
                if (this.props.name.toLowerCase() === "hair care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/hair-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`
                    } else {
                        this.props.history.push(`/hair-care?${category === null || category === false ? "" : `category=${category.match("&") ? category.split("&").join("-") : category}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`)
                    }
                    this.props.fetchHaircareProducts(1, 12, category, true, "mob")
                }
            }
        } else {
            if (ev) {
                if (this.props.name.toLowerCase() === "skin care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/skin-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`
                    } else {
                        this.props.history.push(`/skin-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`)
                    }
                    this.props.fetchSkincareProducts(1, 12, category, true, "mob")
                }
                if (this.props.name.toLowerCase() === "body care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/body-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`
                    } else {
                        this.props.history.push(`/body-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`)
                    }
                    this.props.fetchBodycareProducts(1, 12, category, true, "mob")
                }
                if (this.props.name.toLowerCase() === "hair care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/hair-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`
                    } else {
                        this.props.history.push(`/hair-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}sortBy=${ev}`)
                    }
                    this.props.fetchHaircareProducts(1, 12, category, true, "mob")
                }
            } else {
                if (this.props.name.toLowerCase() === "skin care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/skin-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`
                    } else {
                        this.props.history.push(`/skin-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`)
                    }
                    this.props.fetchSkincareProducts(1, 12, category, true, "mob")
                }
                if (this.props.name.toLowerCase() === "body care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/body-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`
                    } else {
                        this.props.history.push(`/body-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`)
                    }
                    this.props.fetchBodycareProducts(1, 12, category, true, "mob")
                }
                if (this.props.name.toLowerCase() === "hair care") {
                    if (window.screen.width < 600) {
                        window.location.href = `/hair-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`
                    } else {
                        this.props.history.push(`/hair-care?${!categoryChecked.length ? "" : `cat=${categoryChecked && categoryChecked.length ? categoryChecked.join(",") : ""}&&`}${!brandChecked.length ? "" : `brands=${!brandChecked.length ? "" : brandChecked.length ? brandChecked.join(',') : ""}&&`}${!this.state.pricesArr.length ? "" : `priceArr=${this.state.setPrice ? this.state.pricesArr.join(",") : ""}&&`}${sortBy === null || sortBy === false ? "" : `sortBy=${sortBy ? sortBy : ""}&&`}`)
                    }
                    this.props.fetchHaircareProducts(1, 12, category, true, "mob")
                }
            }
        }
    }

    changeRoute = (a, ev, score) => {
        if (ev.target.id !== "noOpen" && ev.target.nodeName !== "path") {
            if (score) {
                if (window.screen.availWidth < 768) {
                    window.location.href = `/${a.type}/${a.slug}?score=${a.score}`
                } else {
                    window.open(`/${a.type}/${a.slug}?score=${a.score}`)
                }
            } else {
                if (window.screen.availWidth < 768) {
                    window.location.href = `/${this.state.mainPage}/${a.slug}`
                } else {
                    window.open(`/${this.state.mainPage}/${a.slug}`, '_blank');
                }
            }
        }
    }

    filterBrands = (ev, brands) => {
        let val = ev.target.value
        this.setState({
            filteredBrandsValue: val
        })
        let filteredBrands = brands.filter((a) => {
            return a.name.toLowerCase().match(val.toLowerCase())
        })
        this.setState({
            filteredBrands,
        })
    }

    checkingChecked = (val, name) => {
        var re = new RegExp(val, "g");
        let params = new URLSearchParams(window.location.search)
        if (name === "brands") {
            let brands = params.get("brands")
            if (brands) {
                let brandsArr = brands.split(",")
                return brandsArr.filter((a) => a == val).length
            }
        }
        if (name === "category") {
            let cat = params.get("cat")
            if (cat) {
                let catArr = cat.split(",")
                return catArr.filter((a) => a == val).length
            }
        }
    }

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false
        };
        // console.log(this.props.numberOfProducts)
        let products = this.state.page.toLocaleLowerCase() === "skin care" ? this.props.skinCareProducts : this.state.page.toLocaleLowerCase() === "body care" ? this.props.bodyCareProducts : this.state.page.toLocaleLowerCase() === "hair care" ? this.props.hairCareProducts : []
        let categories = this.props.categoriesWithCount
        let params = new URLSearchParams(window.location.search)
        return (

            <div className="skinCaree">
                {/* {this.props.isLoading && !this.props.recommendedProducts.length &&
                    <div className="loaderr loaderSkin" id="loader">
                        <div className="loading">
                            <div className="finger finger-1" id="loaderIn">
                                <div className="finger-item">
                                    <span></span><i></i>
                                </div>
                            </div>
                            <div className="finger finger-2">
                                <div className="finger-item">
                                    <span></span><i></i>
                                </div>
                            </div>
                            <div className="finger finger-3">
                                <div className="finger-item">
                                    <span></span><i></i>
                                </div>
                            </div>
                            <div className="finger finger-4">
                                <div className="finger-item">
                                    <span></span><i></i>
                                </div>
                            </div>
                            <div className="last-finger">
                                <div className="last-finger-item"><i></i></div>
                            </div>
                        </div>
                    </div>} */}
                {this.props.quickView &&
                    <QuickView />
                }


                <div className="page container">
                    <div className="alert alert-success alert-dismissable" style={{ display: 'none' }}>
                        <strong className="alert-text"></strong>
                    </div>


                    {/* authenticated part */}
                    {/* authenticated part */}
                    {/* authenticated part */}

                    {this.props.is_authenticated &&
                        <div className="shop-con">
                            <h2 style={{ textTransform: "capitalize" }} id="recHead"></h2>
                            {/* <h6 style={{ textTransform: 'capitalize' }}>{this.props.user && this.props.user.firstname}'s Recommendation</h6> */}
                            <div className="rec-div" id="webRec">
                                {/* <Slider {...settings}> */}
                                {this.props.recommendedProducts && this.props.recommendedProducts.length > 3 &&
                                    this.renderArrows()
                                }
                                {this.props.recommendedProducts && this.props.recommendedProducts.length ? this.props.recommendedProducts.length > 3 ?
                                    <Slider style={{ zIndex: 2 }} ref={c => (this.slider = c)} {...settings}>
                                        {this.props.recommendedProducts && this.props.recommendedProducts.map((a, i) => {
                                            // console.log(a.name.length)
                                            return (
                                                <div key={i} className="product">
                                                    <div onClick={(ev) => this.changeRoute(a, ev, "abc")} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                                        <div className="row">
                                                            <div className="col-md-12 text-right">
                                                                <button style={{ fontSize: 14 }} className="btn btn-match"> {a.score}% Match</button>
                                                            </div>
                                                        </div>
                                                        <div id="hoverDivR62" className="hoverDiv" style={{ opacity: 0 }}>
                                                            {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} className="hoverI update-cart" data-action="add" data-productid={a.id}>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                                </svg>
                                                            </div> */}
                                                            <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                {this.checkWhishlist(a)}
                                                            </div>
                                                        </div>
                                                        {a.stock_quantity === 0 &&
                                                            <div className="outOfStockDiv">Out of stock</div>
                                                        }
                                                    </div>
                                                    <div onClick={(ev) => this.changeRoute(a, ev, "abc")} className="product-txt">
                                                        <h6><i>{a.brand} - </i>
                                                            {a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}
                                                            {/* <NavLink target={window.screen.availWidth < 768 ? false : false} to="#!">{a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}</NavLink> */}
                                                        </h6>
                                                        {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                                        <p className="price web">
                                                            {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                <>
                                                                    <span className="lightGray">
                                                                        MRP:&nbsp;&nbsp;
                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                    <span style={{ float: 'right' }}>
                                                                        <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                        {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                    </span>
                                                                    <span className=""> |</span>
                                                                    <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                </>
                                                                :
                                                                <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                            }
                                                        </p>
                                                        <div className="mob-btn">
                                                            {/* <div className="mob-cart"> */}
                                                            {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                    <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                                </svg>
                                                                <div>
                                                                    <p> ADD TO CART </p>
                                                                </div>
                                                            </button> */}
                                                            {/* </div> */}
                                                            <p className="price">
                                                                {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                    <>
                                                                        <span className="lightGray">
                                                                            <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                        <span style={{ float: 'right' }}>
                                                                            <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                            {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                        </span>
                                                                        <span className=""> |</span>
                                                                        <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                    </>
                                                                    :
                                                                    <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                                }
                                                            </p>
                                                            {/* <div className="mob-wish">
                                                                <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                    {this.checkWhishlist(a, "a")}
                                                                    <div id="noOpen">
                                                                        <p id="noOpen"> WISHLIST </p>
                                                                    </div>
                                                                </button>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    {/* <hr /> */}
                                                    <div className="button-div">
                                                        <button onClick={() => this.quickView(a)}>
                                                            Quick View
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </Slider>
                                    :
                                    this.props.recommendedProducts && this.props.recommendedProducts.length ? this.props.recommendedProducts.map((a, i) => {
                                        // console.log(a.name.length)
                                        return (
                                            <div key={i} className="product">
                                                <div onClick={(ev) => this.changeRoute(a, ev, "abc")} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                                    <div className="row">
                                                        <div className="col-md-12 text-right">
                                                            <button style={{ fontSize: 13 }} className="btn btn-match"> {a.score}% Match</button>
                                                        </div>
                                                    </div>
                                                    <div id="hoverDivR62" className="hoverDiv" style={{ opacity: 0 }}>
                                                        {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} className="hoverI update-cart" data-action="add" data-productid={a.id}>
                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                            </svg>
                                                        </div> */}
                                                        <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                            {this.checkWhishlist(a)}
                                                        </div>
                                                    </div>
                                                    {a.stock_quantity === 0 &&
                                                        <div className="outOfStockDiv">Out of stock</div>
                                                    }
                                                </div>
                                                <div onClick={(ev) => this.changeRoute(a, ev, "abc")} className="product-txt">
                                                    <h6><i>{a.brand} - </i>
                                                        {a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}
                                                        {/* <NavLink target={window.screen.availWidth < 768 ? false : false} to="#!">{a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}</NavLink> */}
                                                    </h6>
                                                    {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                                    <p className="price web">
                                                        {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                            <>
                                                                <span className="lightGray">
                                                                    MRP:&nbsp;&nbsp;
                                                                    <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                <span style={{ float: 'right' }}>
                                                                    <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                    {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                </span>
                                                                <span className=""> |</span>
                                                                <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                            </>
                                                            :
                                                            <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                        }
                                                    </p>
                                                    <div className="mob-btn">
                                                        {/* <div className="mob-cart"> */}
                                                        {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                            </svg>
                                                            <div>
                                                                <p> ADD TO CART </p>
                                                            </div>
                                                        </button> */}
                                                        {/* </div> */}
                                                        <p className="price">
                                                            {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                <>
                                                                    <span className="lightGray">

                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                    <span style={{ float: 'right' }}>
                                                                        <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                        {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                    </span>
                                                                    <span className=""> |</span>
                                                                    <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                </>
                                                                :
                                                                <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                            }
                                                        </p>
                                                        {/* <div className="mob-wish">
                                                            <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                {this.checkWhishlist(a, "a")}
                                                                <div id="noOpen">
                                                                    <p id="noOpen"> WISHLIST </p>
                                                                </div>
                                                            </button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                {/* <hr /> */}
                                                <div className="button-div">
                                                    <button onClick={() => this.quickView(a)}>
                                                        Quick View
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                        :
                                        [1, 2, 1].map((a, i) => {
                                            return (
                                                <div key={i} className="product">
                                                    <div style={{ width: "100%", height: 364, alignItems: "center", display: 'flex', justifyContent: "flex-start", flexDirection: "column" }}>
                                                        <Skeleton animation="wave" variant="rect" width={window.innerWidth > 600 ? 230 : "100%"} height="60%" />
                                                        <Skeleton animation="wave" variant="text" width="90%" style={{ marginTop: 10 }} height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="rect" width="50%" height={35} style={{ marginTop: 10, borderRadius: 50 }} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    :
                                    this.props.isLoading ?
                                        [1, 2, 1].map((a, i) => {
                                            return (
                                                <div key={i} className="product">
                                                    <div style={{ width: "100%", height: 364, alignItems: "center", display: 'flex', justifyContent: "flex-start", flexDirection: "column" }}>
                                                        <Skeleton animation="wave" variant="rect" width={window.innerWidth > 600 ? 230 : "100%"} height="60%" />
                                                        <Skeleton animation="wave" variant="text" width="90%" style={{ marginTop: 10 }} height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="rect" width="50%" height={35} style={{ marginTop: 10, borderRadius: 50 }} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <h3 style={{ display: "flex", alignItems: "center", height: "50%" }}>No recommendations available</h3>
                                }
                            </div>


                            <div id="carousel-example2" className="carousel slide hidden-xs div2" data-ride="carousel" data-type="multi"
                                style={{ position: 'inherit' }}>

                                <div className="carousel-inner" id="mobRec">
                                    {this.props.recommendedProducts && this.props.recommendedProducts.length ?
                                        <div className="controlProduct">
                                            <a className=" btn " href="#carousel-example2" data-slide="prev"><i className="fas fa-chevron-left" aria-hidden="true"></i></a>
                                            <a id="carousel-example2Next" className=" btn" href="#carousel-example2" data-slide="next"><i className="fas fa-chevron-right" aria-hidden="true"></i></a>
                                        </div>
                                        : null}
                                    {this.props.recommendedProducts && this.props.recommendedProducts.length ? this.props.recommendedProducts.map((a, i) => {
                                        return (
                                            <div key={i} className={`carousel-item ${i === 0 ? 'active' : ""}`}>
                                                <div className="product-div-2">
                                                    <div className="product">
                                                        <div onClick={(ev) => this.changeRoute(a, ev, "abc")} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                                            <div className="row">
                                                                <div className="col-md-12 text-right">
                                                                    <button style={{ fontSize: 14 }} className="btn btn-match"> {a.score}% Match</button>
                                                                </div>
                                                            </div>
                                                            <div id="hoverDivR62" className="hoverDiv" style={{ opacity: 0 }}>
                                                                {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} className="hoverI update-cart" data-action="add" data-productid={a.id}>
                                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                                    </svg>
                                                                </div> */}
                                                                <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                    {this.checkWhishlist(a)}
                                                                </div>
                                                            </div>
                                                            {a.stock_quantity === 0 &&
                                                                <div className="outOfStockDiv">Out of stock</div>
                                                            }
                                                        </div>
                                                        <div onClick={(ev) => this.changeRoute(a, ev, "abc")} className="product-txt">
                                                            <h6><i>{a.brand} - </i>
                                                                {a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}
                                                                {/* <NavLink target={window.screen.availWidth < 768 ? false : false} to="#!">{a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}</NavLink> */}
                                                            </h6>
                                                            {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                                            <p className="price web">
                                                                {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                    <>
                                                                        <span className="lightGray">
                                                                            MRP:&nbsp;&nbsp;
                                                                            <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                        <span style={{ float: 'right' }}>
                                                                            <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                            {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                        </span>
                                                                        <span className=""> |</span>
                                                                        <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                    </>
                                                                    :
                                                                    <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                                }
                                                            </p>
                                                            <div className="mob-btn">
                                                                {/* <div className="mob-cart"> */}
                                                                {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                                    </svg>
                                                                    <div>
                                                                        <p> ADD TO CART </p>
                                                                    </div>
                                                                </button> */}
                                                                {/* </div> */}
                                                                <p className="price">
                                                                    {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                        <>
                                                                            <span className="lightGray">

                                                                                <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                            <span style={{ float: 'right' }}>
                                                                                <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                                {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                            </span>
                                                                            <span className=""> |</span>
                                                                            <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                        </>
                                                                        :
                                                                        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                                    }
                                                                </p>
                                                                {/* <div className="mob-wish">
                                                                    <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                        {this.checkWhishlist(a, "a")}
                                                                        <div id="noOpen">
                                                                            <p id="noOpen"> WISHLIST </p>
                                                                        </div>
                                                                    </button>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        {/* <hr /> */}
                                                        <div className="button-div">
                                                            <button onClick={() => this.quickView(a)}>
                                                                Quick View
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) :
                                        this.props.isLoading ? [1, 2, 1, 1, 1].map((a, i) => {
                                            return (
                                                <div key={i} className={`carousel-item ${i === 0 ? 'active' : ""}`}>
                                                    <div className="product-div-2">
                                                        <div className="product">
                                                            <div style={{ width: "100%", height: "100%", alignItems: "center", display: 'flex', justifyContent: "flex-start", flexDirection: "column" }}>
                                                                <Skeleton animation="wave" variant="rect" width={window.innerWidth > 600 ? 230 : "100%"} height="60%" />
                                                                <Skeleton animation="wave" variant="text" width="90%" style={{ marginTop: 10 }} height={25} />
                                                                <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                                <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                                <Skeleton animation="wave" variant="rect" width="50%" height={35} style={{ marginTop: 10, borderRadius: 50 }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                            :
                                            <h3 style={{ display: "flex", alignItems: "center", height: "50%", justifyContent: "center", marginTop: "20px" }}>No recommendations available</h3>
                                    }
                                </div>
                            </div>

                        </div>
                    }




                    <div className="allProducts">
                        <h2 style={{ textTransform: "capitalize" }} id='mainHead'></h2>
                        <div className="cat-pro">

                            <div className="categories ">
                                {/* <button className="btn applyFilter" style={{ backgroundColor: '#cfcece', borderRadius: '20px', color: 'white' }}
                                    onClick={() => this.byBrands(products)}>Apply Filter</button> */}
                                <div>
                                    <h6 className="mobileHIde bBranddsss">By Brands<span onClick={() => {
                                        let params = new URLSearchParams(window.location.search)
                                        params.delete("brands")
                                        this.props.history.push(window.location.pathname + "?" + params)
                                        let arr = document.querySelectorAll('#checkWeb')
                                        for (let i = 0; i < arr.length; i++) {
                                            arr[i].checked = false
                                        }
                                        this.byBrands()
                                    }} style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}>Clear</span> </h6>
                                    <div className="byBrand mobileHIde">
                                        <div id="brand-div">
                                            <div className="brandSearchInput">
                                                <input placeholder="Search by brands" type="text" onChange={(ev) => this.filterBrands(ev, this.props.brands2)} />
                                            </div>
                                            {this.props.brands2 && !this.state.filteredBrands.length && !this.state.filteredBrandsValue ?
                                                this.props.brands2.map((a, i) => {
                                                    return (
                                                        a.number_of_products ?
                                                            <label key={i} className="check">
                                                                {a.name}&nbsp;&nbsp;<span style={{ color: "#cfcece" }}>({a.number_of_products})</span>
                                                                <input onChange={() => this.byBrands(products)} id="checkWeb" type="checkbox" className="brand-filter" value={a.id} checked={this.checkingChecked(a.id, "brands")} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            : null
                                                    )
                                                })
                                                :
                                                this.state.filteredBrands.length ?
                                                    this.state.filteredBrands.map((a, i) => {
                                                        return (
                                                            a.number_of_products ?
                                                                <label key={i} className="check">
                                                                    {a.name}&nbsp;&nbsp;<span style={{ color: "#cfcece" }}>({a.number_of_products})</span>
                                                                    <input onChange={() => this.byBrands(products)} id="checkWeb" type="checkbox" className="brand-filter" value={a.id} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                : null
                                                        )
                                                    })
                                                    :
                                                    <p style={{ marginLeft: 10, fontWeight: "bold", fontSize: 12 }}>No result found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {categories && !this.state.category &&
                                    <div>
                                        <h6 className="mobileHIde">By Category<span onClick={() => {
                                            let params = new URLSearchParams(window.location.search)
                                            params.delete("cat")
                                            this.props.history.push(window.location.pathname + "?" + params)
                                            let arr = document.querySelectorAll('#check2Web')
                                            for (let i = 0; i < arr.length; i++) {
                                                arr[i].checked = false
                                            }
                                            this.byBrands()
                                        }} style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}>Clear</span> </h6>
                                        <div className="byCategories mobileHIde">
                                            <div id="category-div">
                                                {categories &&
                                                    categories.map((a, i) => {
                                                        return (
                                                            a.count ?
                                                                <label key={i} className="check">
                                                                    {a.category}&nbsp;&nbsp;<span style={{ color: "#cfcece" }}>({a.count})</span>
                                                                    <input onChange={() => this.byBrands(products)} id="check2Web" type="checkbox" className="brand-filter" value={a.category} checked={this.checkingChecked(a.category, "category")} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                : null
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div>
                                    <h6 className="mobileHIde">By Price <span onClick={() => {
                                        let params = new URLSearchParams(window.location.search)
                                        params.delete("priceArr")
                                        params.delete("sortBy")
                                        this.props.history.push(window.location.pathname + "?" + params)
                                        this.setState({
                                            pricesArr: [0, 4000]
                                        })
                                        this.byBrands()
                                    }} style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}>Clear</span> </h6>
                                    <div className="byCategories mobileHIde">
                                        <div id="category-div" className="rangeMain">
                                            {/* {["0 - 499", "500 - 999", "1000 - 1999", "2000 - 3999", "4000 & Above"].map((a, i) => {
                                                return (
                                                    <label key={i} className="check">
                                                        {a}
                                                        <input onChange={() => this.byBrands(products, i)} id="check3" type="checkbox" className="brand-filter" value={a} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                )
                                            })
                                            } */}
                                            <div className="filteredProducts"><span className="s">filters</span> <span>({this.props.numberOfProducts})</span></div>
                                            <Sliderr
                                                min={0}
                                                max={4000}
                                                step={100}
                                                // defaultValue={[0, 600]}
                                                value={this.state.pricesArr}
                                                onChange={(ev, newVal) => {
                                                    this.setState({
                                                        pricesArr: newVal,
                                                        setPrice: true
                                                    })
                                                }}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                            />
                                            <div className="rangeInputs">
                                                <Input
                                                    onChange={(ev) => {
                                                        let pricesArr = [...this.state.pricesArr]
                                                        pricesArr[0] = ev.target.value
                                                        this.setState({
                                                            pricesArr: ev.target.value <= pricesArr[1] ? pricesArr : this.state.pricesArr,
                                                        })
                                                    }}
                                                    type="number"
                                                    placeholder="min"
                                                    value={this.state.pricesArr[0]}
                                                />
                                                -
                                                <Input
                                                    onChange={(ev) => {
                                                        let pricesArr = [...this.state.pricesArr]
                                                        pricesArr[1] = ev.target.value
                                                        this.setState({
                                                            pricesArr: ev.target.value >= pricesArr[0] ? pricesArr : this.state.pricesArr,
                                                        })
                                                    }}
                                                    type="number"
                                                    placeholder="max"
                                                    value={this.state.pricesArr[1]}
                                                />
                                                <button onClick={() => this.priceFilter()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                        <path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div id="category-div" className="highToLow">
                                            <div className="filteredProducts"><span className="s">Sort by:</span></div>
                                            <Select defaultValue={"Default"} onChange={(ev) => this.priceFilter(ev)} className="sortFilter">
                                                <Select.Option value="Default">Popular</Select.Option>
                                                <Select.Option value="LTH">Lowest to Highest</Select.Option>
                                                <Select.Option value="HTL">Highest to Lowest</Select.Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="webhide btn " data-toggle="modal" data-target="#exampleModalLong">
                                    <p> FILTER</p>
                                </button>


                                <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog"
                                    aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLongTitle">FILTER</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="byBrand ">
                                                    <h6>By Brands<span data-dismiss="modal" onClick={() => {
                                                        let params = new URLSearchParams(window.location.search)
                                                        params.delete("brands")
                                                        this.props.history.push(window.location.pathname + "?" + params)
                                                        let arr = document.querySelectorAll('#checkMob')
                                                        for (let i = 0; i < arr.length; i++) {
                                                            arr[i].checked = false
                                                        }
                                                        this.byBrands(products, false, false, "mob")
                                                    }} style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}>Clear</span> </h6>
                                                    <div id="brand-div-mob">
                                                        <div className="brandSearchInput">
                                                            <input placeholder="Search by brands" type="text" onChange={(ev) => this.filterBrands(ev, this.props.brands2)} />
                                                        </div>
                                                        {this.props.brands2 && !this.state.filteredBrands.length && !this.state.filteredBrandsValue ?
                                                            this.props.brands2.map((a, i) => {
                                                                return (
                                                                    a.number_of_products ?
                                                                        <label key={i} className="check">
                                                                            {a.name}&nbsp;&nbsp;<span style={{ color: "#cfcece" }}>({a.number_of_products})</span>
                                                                            <input id="checkMob" type="checkbox" className="brand-filter" value={a.id} checked={this.checkingChecked(a.id, "brands")} />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        : null
                                                                )
                                                            })
                                                            :
                                                            this.state.filteredBrands.length ?
                                                                this.state.filteredBrands.map((a, i) => {
                                                                    return (
                                                                        a.number_of_products ?
                                                                            <label key={i} className="check">
                                                                                {a.name}&nbsp;&nbsp;<span style={{ color: "#cfcece" }}>({a.number_of_products})</span>
                                                                                <input id="checkMob" type="checkbox" className="brand-filter" value={a.id} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            : null
                                                                    )
                                                                })
                                                                :
                                                                <p style={{ marginLeft: 10, fontWeight: "bold", fontSize: 12 }}>No result found</p>
                                                        }
                                                    </div>
                                                </div>
                                                {!this.state.category &&
                                                    <div className="byCategories">
                                                        <h6 className="">By Category<span data-dismiss="modal" onClick={() => {
                                                            let params = new URLSearchParams(window.location.search)
                                                            params.delete("cat")
                                                            this.props.history.push(window.location.pathname + "?" + params)
                                                            let arr = document.querySelectorAll('#check2Mob')
                                                            for (let i = 0; i < arr.length; i++) {
                                                                arr[i].checked = false
                                                            }
                                                            this.byBrands()
                                                        }} style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}>Clear</span> </h6>
                                                        <div id="category-div-mob">
                                                            {
                                                                categories &&
                                                                categories.map((a, i) => {
                                                                    return (
                                                                        a.count ?
                                                                            <label key={i} className="check">
                                                                                {a.category}&nbsp;&nbsp;<span style={{ color: "#cfcece" }}>({a.count})</span>
                                                                                <input id="check2Mob" type="checkbox" className="brand-filter" value={a.category} checked={this.checkingChecked(a.category, "category")} />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            : null
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className="byCategories" className="rangeMain">
                                                    <h6 className="">By Price <span data-dismiss="modal" onClick={() => {
                                                        let params = new URLSearchParams(window.location.search)
                                                        params.delete("priceArr")
                                                        params.delete("sortBy")
                                                        this.props.history.push(window.location.pathname + "?" + params)
                                                        this.setState({
                                                            pricesArr: [0, 4000]
                                                        })
                                                        this.byBrands()
                                                    }} style={{ color: "red", fontSize: 12, float: "right", cursor: "pointer" }}>Clear</span> </h6>
                                                    <div id="category-div">
                                                        {/* {["0 - 499", "500 - 999", "1000 - 1999", "2000 - 3999", "4000 & Above"].map((a, i) => {
                                                            return (
                                                                <label key={i} className="check">
                                                                    {a}
                                                                    <input id="check3" type="checkbox" className="brand-filter" value={a} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            )
                                                        })
                                                        } */}
                                                        <div className="filteredProducts"><span className="s">filters</span> <span>({this.props.numberOfProducts})</span></div>
                                                        <Sliderr
                                                            min={0}
                                                            max={4000}
                                                            step={100}
                                                            // defaultValue={[0, 600]}
                                                            value={this.state.pricesArr}
                                                            onChange={(ev, newVal) => {
                                                                this.setState({
                                                                    pricesArr: newVal,
                                                                    setPrice: true
                                                                })
                                                            }}
                                                            valueLabelDisplay="auto"
                                                            aria-labelledby="range-slider"
                                                        />
                                                        <div className="rangeInputs">
                                                            <Input
                                                                onChange={(ev) => {
                                                                    let pricesArr = [...this.state.pricesArr]
                                                                    pricesArr[0] = ev.target.value
                                                                    this.setState({
                                                                        pricesArr: ev.target.value <= pricesArr[1] ? pricesArr : this.state.pricesArr,
                                                                    })
                                                                }}
                                                                type="number"
                                                                placeholder="min"
                                                                value={this.state.pricesArr[0]}
                                                            />
                                                            -
                                                            <Input
                                                                onChange={(ev) => {
                                                                    let pricesArr = [...this.state.pricesArr]
                                                                    pricesArr[1] = ev.target.value
                                                                    this.setState({
                                                                        pricesArr: ev.target.value >= pricesArr[0] ? pricesArr : this.state.pricesArr,
                                                                    })
                                                                }}
                                                                type="number"
                                                                placeholder="max"
                                                                value={this.state.pricesArr[1]}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="category-div" className="highToLow">
                                                        <div className="filteredProducts"><span className="s">Sort by:</span></div>
                                                        <Select defaultValue={"Default"} onChange={(ev) => this.priceFilter(ev)} className="sortFilter">
                                                            <Select.Option value="Default">Popular</Select.Option>
                                                            <Select.Option value="LTH">Lowest to Highest</Select.Option>
                                                            <Select.Option value="HTL">Highest to Lowest</Select.Option>
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => {
                                                    this.byBrands(products, false, false, "mob")
                                                }}>Apply Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shopProducts" id="pagi">
                                <div className="webpro" id="desktop-view-products">
                                    {this.props.productLoading &&

                                        [1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((a, i) => {
                                            return (
                                                <div key={i} className="product">
                                                    <div style={{ width: "100%", height: "100%", alignItems: "center", display: 'flex', justifyContent: "flex-start", flexDirection: "column" }}>
                                                        <Skeleton animation="wave" variant="rect" width={window.innerWidth > 600 ? 230 : "100%"} height="60%" />
                                                        <Skeleton animation="wave" variant="text" width="90%" style={{ marginTop: 10 }} height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="rect" width="50%" height={35} style={{ marginTop: 10, borderRadius: 50 }} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    {!this.props.productLoading && products && products.map((a, i) => {
                                        return (
                                            // i >= this.state.from && i < this.state.to &&
                                            <div key={i} className="product">
                                                {!this.props.productLoading ?
                                                    <>
                                                        <div onClick={(ev) => this.changeRoute(a, ev)} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                                            <div id="hoverDivR62" className="hoverDiv" style={{ opacity: 0 }}>
                                                                {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} className="hoverI update-cart" data-action="add" data-productid={a.id}>
                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                            </svg>
                                                        </div> */}
                                                                <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                    {this.checkWhishlist(a)}
                                                                </div>
                                                            </div>
                                                            {a.stock_quantity === 0 &&
                                                                <div className="outOfStockDiv">Out of stock</div>
                                                            }
                                                        </div>
                                                        <div onClick={(ev) => this.changeRoute(a, ev)} className="product-txt">
                                                            <h6><i>{a.brand} - </i>
                                                                {a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}
                                                                {/* <NavLink target={window.screen.availWidth < 768 ? false : false} to="#!">{a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}</NavLink> */}
                                                            </h6>
                                                            {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                                            <p className="price web">
                                                                {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                    <>
                                                                        <span className="lightGray">
                                                                            MRP:&nbsp;&nbsp;
                                                                            <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                        <span style={{ float: 'right' }}>
                                                                            <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                            {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                        </span>
                                                                        <span className=""> |</span>
                                                                        <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                    </>
                                                                    :
                                                                    <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                                }
                                                            </p>
                                                            <div className="mob-btn">
                                                                {/* <div className="mob-cart"> */}
                                                                {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                                            </svg>
                                                            <div>
                                                                <p> ADD TO CART </p>
                                                            </div>
                                                        </button> */}
                                                                {/* </div> */}
                                                                <p className="price">
                                                                    {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                                                        <>
                                                                            <span className="lightGray">

                                                                                <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                                                            <span style={{ float: 'right' }}>
                                                                                <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                                {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                            </span>
                                                                            <span className=""> |</span>
                                                                            <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                        </>
                                                                        :
                                                                        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                                                    }
                                                                </p>
                                                                {/* <div className="mob-wish">
                                                                    <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                                                        {this.checkWhishlist(a, "a")}
                                                                        <div id="noOpen">
                                                                            <p id="noOpen"> WISHLIST </p>
                                                                        </div>
                                                                    </button>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        {/* <hr /> */}
                                                        <div className="button-div">
                                                            <button onClick={() => this.quickView(a)}>
                                                                Quick View
                                                            </button>
                                                        </div>
                                                    </>
                                                    :
                                                    <div style={{ width: "100%", height: "100%", alignItems: "center", display: 'flex', justifyContent: "flex-start", flexDirection: "column" }}>
                                                        <Skeleton animation="wave" variant="rect" width={window.innerWidth > 600 ? 230 : "100%"} height="60%" />
                                                        <Skeleton animation="wave" variant="text" width="90%" style={{ marginTop: 10 }} height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="text" width="90%" height={25} />
                                                        <Skeleton animation="wave" variant="rect" width="50%" height={35} style={{ marginTop: 10, borderRadius: 50 }} />
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                        // :
                                        // this.state.filterProducts.map((a, i) => {
                                        //     // console.log(a.name.length)
                                        //     return (
                                        //         i >= this.state.from && i < this.state.to &&
                                        //         <div key={i} className="product">
                                        //             <div onClick={(ev) => this.changeRoute(a, ev)} className="product-img" style={a.image === null ? { backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${NOAvaliableImage})` } : { backgroundImage: `url(${a.image})` }}>
                                        //                 <div id="hoverDivR62" className="hoverDiv" style={{ opacity: 0 }}>
                                        //                     {/* <div onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} className="hoverI update-cart" data-action="add" data-productid={a.id}>
                                        //                         <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        //                             <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                        //                         </svg>
                                        //                     </div> */}
                                        //                     <div id="noOpen" className="hoverI" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                        //                         {this.checkWhishlist(a)}
                                        //                     </div>
                                        //                 </div>
                                        //                 {a.stock_quantity === 0 &&
                                        //                     <div className="outOfStockDiv">Out of stock</div>
                                        //                 }
                                        //             </div>
                                        //             <div onClick={(ev) => this.changeRoute(a, ev)} className="product-txt">
                                        //                 <h6><i>{a.brand} - </i> <NavLink target={window.screen.availWidth < 768 ? false :"_blank"} to={`/${this.state.mainPage}/${a.slug}`}>{a.name.length + a.brand.length > 36 ? `${a.name.slice(0, (36 - a.brand.length))}...` : a.name}</NavLink></h6>
                                        //                 {/* <p><i>{a.category} - {a.brand}</i></p> */}
                                        //                 <p className="price web">
                                        //                     {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                        //                         <>
                                        //                             <span className="lightGray">
                                        //                                 MRP:&nbsp;&nbsp;
                                        //                                                 <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                        //                             <span style={{ float: 'right' }}>
                                        //                                 <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                        //                                 {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                        //                                 <span className="lightGray two"> |
                                        //                                                 </span>
                                        //                             </span>
                                        //                             <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                        //                         </>
                                        //                         :
                                        //                         <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                        //                     }
                                        //                 </p>
                                        //                 <div className="mob-btn">
                                        //                     {/* <div className="mob-cart"> */}
                                        //                     {/* <button onClick={() => a.stock_quantity !== 0 ? this.addToCart(a.id) : this.addToCart(a.id, "out")} data-action="add" data-productid={a.id}>
                                        //                         <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-cart3" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        //                             <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                                        //                         </svg>
                                        //                         <div>
                                        //                             <p> ADD TO CART </p>
                                        //                         </div>
                                        //                     </button> */}
                                        //                     {/* </div> */}
                                        //                     <p className="price">
                                        //                         {a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < a.price ?
                                        //                             <>
                                        //                                 <span className="lightGray">
                                        //                                     MRP:&nbsp;&nbsp;
                                        //                                                 <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span></span>
                                        //                                 <span style={{ float: 'right' }}>
                                        //                                     <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                        //                                     {a.product_affiliates && a.product_affiliates.length && Number(a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                        //                                     <span className="lightGray two"> |
                                        //                                                 </span>
                                        //                                 </span>
                                        //                                 <span className="pinkk">{this.discoutGet(a.price, a.product_affiliates && a.product_affiliates.length && a.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                        //                             </>
                                        //                             :
                                        //                             <span style={{ float: 'right' }}>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{a.price}</span>
                                        //                         }
                                        //                     </p>
                                        //                     <div className="mob-wish">
                                        //                         <button id="noOpen" onClick={() => this.addToWhishlist(a.id)} data-productid={a.id}>
                                        //                             {this.checkWhishlist(a, "a")}
                                        //                             <div id="noOpen">
                                        //                                 <p id="noOpen"> WISHLIST </p>
                                        //                             </div>
                                        //                         </button>
                                        //                     </div>
                                        //                 </div>
                                        //             </div>
                                        //             {/* <hr /> */}
                                        //             <div className="button-div">
                                        //                 <button onClick={() => this.quickView(a)}>
                                        //                     Quick View
                                        //                 </button>
                                        //             </div>
                                        //         </div>
                                        //     )
                                        // })
                                    }
                                    {products && !products.length &&
                                        <h3 style={{ display: "flex", alignItems: "center", height: "50%" }}>No products available for filter criteria</h3>
                                    }
                                </div>
                                {!this.state.filter &&
                                    // <Pagination href="#pagi" responsive onChange={(ev) => this.paginate(ev)} showQuickJumper pageSize={12} current={this.state.current} defaultCurrent={1} total={this.state.filterProducts && this.state.filterProducts.length ? this.state.filterProducts.length : products && products.length ? products.length : 0} />
                                    <Pagination showQuickJumper={false} href="#pagi" responsive onChange={(ev) => this.paginate(ev)} showQuickJumper pageSize={18} current={this.state.current} total={this.props.numberOfProducts} />
                                }
                                <div className="mobviewpro" id="mobile-view-products">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = ({ store }) => ({
    brands: store.brands,
    brands2: store.brands2,
    is_authenticated: store.is_authenticated,
    user: store.user,
    recommendedProducts: store.recommendedProducts,
    skinCareProducts: store.skinCareProducts,
    numberOfProducts: store.numberOfProducts,
    items: store.items,
    whishlistProducts: store.whishlistProducts,
    categories: store.categories,
    bodyCareProducts: store.bodyCareProducts,
    bodyCareCategories: store.bodyCareCategories,
    hairCareProducts: store.hairCareProducts,
    hairCareCategories: store.hairCareCategories,
    quickView: store.quickView,
    isLoading: store.isLoading,
    productLoading: store.productLoading,
    categoriesWithCount: store.categoriesWithCount
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchSkincareProducts,
            fetchSkincareProductsRecommended,
            getBrands,
            getFilteredSkinCare,
            getFilteredSkinCareRecommended,
            getCartOrders,
            quickViewModel,
            getWishlist,
            fetchBodycareProducts,
            getFilteredBodycare,
            getFilteredHaircare,
            fetchHaircareProducts,
            getFilterProducts,
            isLoadingTrue
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SkinCare))