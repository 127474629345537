import Axios from 'axios'
import store from './store';


// product loader true

function productLoader() {
    return dispatch => {
        dispatch(productLoading());
    };
}
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const productLoading = (products) => {
    return {
        type: PRODUCT_LOADING,
        payload: {}
    }
}

// get skincare products all
export function fetchSkincareProducts(page, productPerPage, category, filter, mob) {
    return dispatch => {
        dispatch(productLoader())
        let params = new URLSearchParams(window.location.search)
        console.log(params)
        let categoryy = category ? category : params.get("cat")
        let brands = params.get("brands")
        let prices = params.get("priceArr") ? params.get("priceArr").split(',') : ""
        let data = new FormData()
        data.append('page', page)
        data.append("product_per_page", productPerPage)
        if (categoryy) {
            data.append('category', categoryy)
        }
        if (brands) {
            data.append('brands', brands)
        }
        if (prices) {
            data.append('price_min', prices.length && prices[0])
            data.append('price_max', prices.length && prices[1])
        }

        let sortBy = params.get("sortBy")
        if (sortBy === "LTH") {
            data.append("ascending", true)
        }
        if (sortBy === "HTL") {
            data.append("descending", true)
        }

        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/skin/all/',
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                let data = response.data.products.skin_products
                let numberOfProducts = response.data.products.number_of_products
                let brands = []
                data.filter(a => {
                    if (!brands.includes(a.brand)) {
                        brands.push(a.brand)
                    }
                })

                let brandsWithCount = response.data.products.brands_with_count.sort((a, b) => b.number_of_products - a.number_of_products)
                dispatch(gettingBrands(brandsWithCount));

                let catWithCount = response.data.products.categories_with_count.sort((a, b) => b.number_of_products - a.number_of_products)
                dispatch(gettingCategoriesWithCount(catWithCount));

                dispatch(fetchSkincareProductsSuccess(data, numberOfProducts, brands));


                let params = new URLSearchParams(window.location.search)
                let category = params.get("category")
                let current = Number(params.get("page"))

                let brandss = ""
                if (window.screen.width < 768) {
                    brandss = document.querySelectorAll('#checkMob')
                } else {
                    brandss = document.querySelectorAll('#checkWeb')
                }
                let categories = ""
                if (window.screen.width < 768) {
                    categories = document.querySelectorAll('#check2Mob')
                } else {
                    categories = document.querySelectorAll('#check2Web')
                }
                let prices = document.querySelectorAll('#check3')
                // setTimeout(() => {
                //     for (let i = 0; i < brandss.length; i++) {
                //         if (params.get('brands')) {
                //             if (params.get('brands').split(',').join(" ").match(brandss[i].value)) {
                //                 brandss[i].checked = true
                //             } else {
                //                 brandss[i].checked = false
                //             }
                //         } else {
                //             brandss[i].checked = false
                //         }
                //     }

                //     for (let i = 0; i < categories.length; i++) {
                //         if (params.get('cat')) {
                //             if (params.get('cat').split(',').join(" ").match(categories[i].value)) {
                //                 categories[i].checked = true
                //             } else {
                //                 categories[i].checked = false
                //             }
                //         } else {
                //             categories[i].checked = false
                //         }
                //     }
                // }, 0)

                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FETCH_SKINCARE_PRODUCTS_SUCCESS = 'FETCH_SKINCARE_PRODUCTS_SUCCESS';
export const fetchSkincareProductsSuccess = (products, numberOfProducts, brands) => ({
    type: FETCH_SKINCARE_PRODUCTS_SUCCESS,
    payload: { products, numberOfProducts, brands }
});


export const GET_CATEGORIES_WITH_COUNT = 'GET_CATEGORIES_WITH_COUNT';
export const gettingCategoriesWithCount = data => ({
    type: GET_CATEGORIES_WITH_COUNT,
    payload: { categoriesWithCount: data }
});

// get bodycare products all
export function fetchBodycareProducts(page, productPerPage, category, filter, mob) {
    return dispatch => {
        dispatch(productLoader())
        let params = new URLSearchParams(window.location.search)
        let categoryy = category ? category : params.get("cat")
        if (categoryy && categoryy.match("-")) {
            categoryy = categoryy.split("-").join("&")
        }
        let brands = params.get("brands")
        let prices = params.get("priceArr") ? params.get("priceArr").split(',') : ""
        let data = new FormData()
        data.append('page', page)
        data.append("product_per_page", productPerPage)
        if (categoryy) {
            data.append('category', categoryy)
        }
        if (brands) {
            data.append('brands', brands)
        }
        if (prices) {
            data.append('price_min', prices.length && prices[0])
            data.append('price_max', prices.length && prices[1])
        }
        let sortBy = params.get("sortBy")
        if (sortBy === "LTH") {
            data.append("ascending", true)
        }
        if (sortBy === "HTL") {
            data.append("descending", true)
        }
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/body/all/',
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                let data = response.data.products.body_products
                let numberOfProducts = response.data.products.number_of_products
                let brands = []
                data.filter(a => {
                    if (!brands.includes(a.brand)) {
                        brands.push(a.brand)
                    }
                })

                let brandsWithCount = response.data.products.brands_with_count.sort((a, b) => b.number_of_products - a.number_of_products)
                dispatch(gettingBrands(brandsWithCount));

                let catWithCount = response.data.products.categories_with_count.sort((a, b) => b.number_of_products - a.number_of_products)
                dispatch(gettingCategoriesWithCount(catWithCount));

                dispatch(fetchBodycareProductsSuccess(data, numberOfProducts, brands));

                let params = new URLSearchParams(window.location.search)
                let category = params.get("category")
                let current = Number(params.get("page"))

                let brandss = ""
                if (window.screen.width < 768) {
                    brandss = document.querySelectorAll('#checkMob')
                } else {
                    brandss = document.querySelectorAll('#checkWeb')
                }
                let categories = ""
                if (window.screen.width < 768) {
                    categories = document.querySelectorAll('#check2Mob')
                } else {
                    categories = document.querySelectorAll('#check2Web')
                }
                let prices = document.querySelectorAll('#check3')
                // setTimeout(() => {
                //     console.log(brands)

                //     for (let i = 0; i < brandss.length; i++) {
                //         if (params.get('brands')) {
                //             if (params.get('brands').split(',').join(" ").match(brandss[i].value)) {
                //                 brandss[i].checked = true
                //             } else {
                //                 brandss[i].checked = false
                //             }
                //         } else {
                //             brandss[i].checked = false
                //         }
                //     }

                //     for (let i = 0; i < categories.length; i++) {
                //         if (params.get('cat')) {
                //             if (params.get('cat').split(',').join(" ").match(categories[i].value)) {
                //                 categories[i].checked = true
                //             } else {
                //                 categories[i].checked = false
                //             }
                //         } else {
                //             categories[i].checked = false
                //         }
                //     }
                // }, 0)

                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FETCH_BODYCARE_PRODUCTS_SUCCESS = 'FETCH_BODYCARE_PRODUCTS_SUCCESS';
export const fetchBodycareProductsSuccess = (products, numberOfProducts, brands) => ({
    type: FETCH_BODYCARE_PRODUCTS_SUCCESS,
    payload: { products, numberOfProducts, brands }
});

// get haircare products all
export function fetchHaircareProducts(page, productPerPage, category, filter, mob) {
    return dispatch => {
        dispatch(productLoader())
        let params = new URLSearchParams(window.location.search)
        console.log(params)
        let categoryy = category ? category : params.get("cat")
        let brands = params.get("brands")
        let prices = params.get("priceArr") ? params.get("priceArr").split(',') : ""
        let data = new FormData()
        data.append('page', page)
        data.append("product_per_page", productPerPage)
        if (categoryy) {
            data.append('category', categoryy)
        }
        if (brands) {
            data.append('brands', brands)
        }
        if (prices) {
            data.append('price_min', prices.length && prices[0])
            data.append('price_max', prices.length && prices[1])
        }
        let sortBy = params.get("sortBy")
        if (sortBy === "LTH") {
            data.append("ascending", true)
        }
        if (sortBy === "HTL") {
            data.append("descending", true)
        }
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/hair/all/',
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                let data = response.data.products.hair_products
                let numberOfProducts = response.data.products.number_of_products
                let brands = []
                data.filter(a => {
                    if (!brands.includes(a.brand)) {
                        brands.push(a.brand)
                    }
                })

                let brandsWithCount = response.data.products.brands_with_count.sort((a, b) => b.number_of_products - a.number_of_products)
                dispatch(gettingBrands(brandsWithCount));

                let catWithCount = response.data.products.categories_with_count.sort((a, b) => b.number_of_products - a.number_of_products)
                dispatch(gettingCategoriesWithCount(catWithCount));

                dispatch(fetchHaircareProductsSuccess(data, numberOfProducts, brands));

                let params = new URLSearchParams(window.location.search)
                let category = params.get("category")
                let current = Number(params.get("page"))

                let brandss = ""
                if (window.screen.width < 768) {
                    brandss = document.querySelectorAll('#checkMob')
                } else {
                    brandss = document.querySelectorAll('#checkWeb')
                }
                let categories = ""
                if (window.screen.width < 768) {
                    categories = document.querySelectorAll('#check2Mob')
                } else {
                    categories = document.querySelectorAll('#check2Web')
                }
                let prices = document.querySelectorAll('#check3')
                // setTimeout(() => {
                //     console.log(brands)

                //     for (let i = 0; i < brandss.length; i++) {
                //         if (params.get('brands')) {
                //             if (params.get('brands').split(',').join(" ").match(brandss[i].value)) {
                //                 brandss[i].checked = true
                //             } else {
                //                 brandss[i].checked = false
                //             }
                //         } else {
                //             brandss[i].checked = false
                //         }
                //     }

                //     for (let i = 0; i < categories.length; i++) {
                //         if (params.get('cat')) {
                //             if (params.get('cat').split(',').join(" ").match(categories[i].value)) {
                //                 categories[i].checked = true
                //             } else {
                //                 categories[i].checked = false
                //             }
                //         } else {
                //             categories[i].checked = false
                //         }
                //     }
                // }, 0)

                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FETCH_HAIRCARE_PRODUCTS_SUCCESS = 'FETCH_HAIRCARE_PRODUCTS_SUCCESS';
export const fetchHaircareProductsSuccess = (products, numberOfProducts, brands) => ({
    type: FETCH_HAIRCARE_PRODUCTS_SUCCESS,
    payload: { products, numberOfProducts, brands }
});

// is loading

export function isLoadingTrue() {
    return dispatch => {
        dispatch(isLoading());
    };
}
export const IS_LOADING = 'IS_LOADING';
export const isLoading = products => ({
    type: IS_LOADING,
});

//get recommended products
export function fetchSkincareProductsRecommended(page) {
    // let code = getCookie("authToken")
    // let route = ""
    // // console.log(page)
    // // if (page.toLowerCase() === "skin care") {
    // //     route = "skin"
    // // }
    // // else if (page.toLowerCase() === "body care") {
    // //     route = "body"
    // // }
    // // else if (page.toLowerCase() === "hair care") {
    // //     route = "hair"
    // // }
    // return dispatch => {
    //     return Axios({
    //         method: 'post',
    //         url: process.env.REACT_APP_BACKEND_URL + `/api/products/recommended/${page}/`,
    //         headers: { Authorization: `Bearer ${code}` },
    //         validateStatus: (status) => {
    //             return true;
    //         },
    //         data: '',
    //     })
    //         .then(response => {
    //             let data = response.data.products
    //             console.log(response.data)
    //             dispatch(fetchSkincareProductsSuccessRecommended(data));
    //             return data
    //         })
    //         .catch(error => {
    //             console.log(error)
    //         });
    // };
    // console.log(page)
    let code = getCookie("authToken")
    return dispatch => {
        dispatch(isLoadingTrue())
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/products/recommended/${page}/`,
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data.products
                dispatch(fetchSkincareProductsSuccessRecommended(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FETCH_SKINCARE_PRODUCTS_SUCCESS_RECOMMENDED = 'FETCH_SKINCARE_PRODUCTS_SUCCESS_RECOMMENDED';
export const fetchSkincareProductsSuccessRecommended = products => ({
    type: FETCH_SKINCARE_PRODUCTS_SUCCESS_RECOMMENDED,
    payload: { products }
});

//get recommended bodycare products
export function fetchBodycareProductsRecommended() {
    let code = getCookie("authToken")
    // isLoadingTrue()
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/recommended/body/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                // console.log(response.data)
                // let data = response.data.products
                // dispatch(fetchSkincareProductsSuccessRecommended(data));
                // return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
// export const FETCH_SKINCARE_PRODUCTS_SUCCESS_RECOMMENDED = 'FETCH_SKINCARE_PRODUCTS_SUCCESS_RECOMMENDED';
// export const fetchSkincareProductsSuccessRecommended = products => ({
//     type: FETCH_SKINCARE_PRODUCTS_SUCCESS_RECOMMENDED,
//     payload: { products }
// });


//get brands
export function getBrands(name) {
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/brands/get/v2/',
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data.brands
                dispatch(gettingBrands(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_BRANDS = 'GET_BRANDS';
export const gettingBrands = brands => ({
    type: GET_BRANDS,
    payload: { brands }
});


//get cart orders
export function getCartOrders() {
    let code = getCookie("authToken")
    return dispatch => {
        // return Axios({
        //     method: 'post',
        //     url: process.env.REACT_APP_BACKEND_URL + '/api/cart/get/',
        //     headers: { Authorization: `Bearer ${code}` },
        //     validateStatus: (status) => {
        //         return true;
        //     },
        //     data: '',
        // })
        //     .then(response => {
        //         let data = response.data.current_order
        //         let shippingPrice = response.data.shipping_price
        //         dispatch(gettingCartOrder(data, shippingPrice));
        //         return data
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     });
    };
}
export const GET_CART_ORDERS = 'GET_CART_ORDERS';
export const gettingCartOrder = (data, shippingPrice) => ({
    type: GET_CART_ORDERS,
    payload: { orders: data, shippingPrice: shippingPrice }
});


//get Categories
export function getCategories() {
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/skin/categories/',
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = Object.values(response.data.categories)
                // console.log(data, "skincategory")
                dispatch(gettingCtegories(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const gettingCtegories = data => ({
    type: GET_CATEGORIES,
    payload: { categories: data }
});


//get bodycare Categories
export function getBodycareCategories() {
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/body/categories/',
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = Object.values(response.data.categories)
                dispatch(gettingBodycareCtegories(data));
                // console.log(data, "bodycategory")
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_BODYCARE_CATEGORIES = 'GET_BODYCARE_CATEGORIES';
export const gettingBodycareCtegories = data => ({
    type: GET_BODYCARE_CATEGORIES,
    payload: { bodyCareCategories: data }
});


//get haircare Categories
export function getHaircareCategories() {
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/hair/categories/',
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = Object.values(response.data.categories)
                dispatch(gettingHaircareCtegories(data));
                // console.log(data, "haircategory")
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_HAIRCARE_CATEGORIES = 'GET_HAIRCARE_CATEGORIES';
export const gettingHaircareCtegories = data => ({
    type: GET_HAIRCARE_CATEGORIES,
    payload: { hairCareCategories: data }
});


//filter product
export function getFilteredSkinCare(category) {
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/skin/all/',
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data.products.skin_products.filter(a => a.category.toUpperCase() === category.toUpperCase())
                let brands = []
                data.filter(a => {
                    if (!brands.includes(a.brand)) {
                        brands.push(a.brand)
                    }
                })
                let obj = {
                    data,
                    brands
                }
                dispatch(gettingFilteredSkinCare(obj));
                return obj
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FILTER_SKINCARE_PRODUCTS = 'FILTER_SKINCARE_PRODUCTS';
export const gettingFilteredSkinCare = products => ({
    type: FILTER_SKINCARE_PRODUCTS,
    payload: { items: products.data, brands: products.brands }
});

//filter product
export function getFilteredBodycare(category) {
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/body/all/',
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data.products.body_products.filter(a => a.category.toUpperCase() === category.toUpperCase())
                let brands = []
                data.filter(a => {
                    if (!brands.includes(a.brand)) {
                        brands.push(a.brand)
                    }
                })
                let obj = {
                    data,
                    brands
                }
                dispatch(gettingFilteredBodycare(obj));
                return obj
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FILTER_BODYCARE_PRODUCTS = 'FILTER_BODYCARE_PRODUCTS';
export const gettingFilteredBodycare = products => ({
    type: FILTER_BODYCARE_PRODUCTS,
    payload: { items: products.data, brands: products.brands }
});

//filter product
export function getFilteredHaircare(category) {
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/hair/all/',
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data.products.hair_products.filter(a => a.category.toUpperCase() === category.toUpperCase())
                let brands = []
                data.filter(a => {
                    if (!brands.includes(a.brand)) {
                        brands.push(a.brand)
                    }
                })
                let obj = {
                    data,
                    brands
                }
                dispatch(gettingFilteredHaircare(obj));
                return obj
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FILTER_HAIRCARE_PRODUCTS = 'FILTER_HAIRCARE_PRODUCTS';
export const gettingFilteredHaircare = products => ({
    type: FILTER_HAIRCARE_PRODUCTS,
    payload: { items: products.data, brands: products.brands }
});


//filter recommended product
export function getFilteredSkinCareRecommended(category, name) {
    let code = getCookie("authToken")
    return dispatch => {
        dispatch(isLoadingTrue())
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/products/recommended/${name}/`,
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data.products.filter(a => a.category.toUpperCase() === category.toUpperCase())
                dispatch(gettingFilteredSkinCareRecommended(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FILTER_SKINCARE_PRODUCTS_RECOMMENDED = 'FILTER_SKINCARE_PRODUCTS_RECOMMENDED';
export const gettingFilteredSkinCareRecommended = products => ({
    type: FILTER_SKINCARE_PRODUCTS_RECOMMENDED,
    payload: { items: products }
});


//getting profile info
export function getProfileData(category) {
    let code = getCookie("authToken")
    return dispatch => {
        // dispatch(isLoadingTrue())
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/userdetails/get/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data
                dispatch(gettingProfileData(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const gettingProfileData = data => ({
    type: GET_PROFILE_DATA,
    payload: { user: data }
});


//getting My orders
export function getMyOrders(category) {
    let code = getCookie("authToken")
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/orders/get/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data.orders
                dispatch(gettinMyOrders(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_MY_ORDERS = 'GET_MY_ORDERS';
export const gettinMyOrders = data => ({
    type: GET_MY_ORDERS,
    payload: { orders: data }
});


// get whishlist
export function getWishlist(category) {
    let code = getCookie("authToken")
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/wishlist/get/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data.products
                dispatch(gettingWhishlist(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_WHISHLIST = 'GET_WHISHLIST';
export const gettingWhishlist = data => ({
    type: GET_WHISHLIST,
    payload: { whishlistProducts: data }
});


function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
}

// get search results
export function getSearchResults(query, page) {
    // let code = getCookie("authToken")
    let data = new FormData()
    data.append('query', query ? query : "")
    data.append("products_per_page", 12)
    data.append("page", page)
    return dispatch => {
        dispatch(isLoadingTrue())
        dispatch(productLoader())
        if (isEmptyOrSpaces(query)) {
            dispatch(gettingSeachResults([], "", 0));
        } else {
            return Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/products/search/',
                // headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    let data = response.data.products
                    let numberOfProducts = response.data.number_of_products
                    dispatch(gettingSeachResults(data, query ? query : "", numberOfProducts));
                    return data
                })
                .catch(error => {
                    // console.log(error)
                });
        }
    };
}
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const gettingSeachResults = (data, query, numberOfProducts) => ({
    type: GET_SEARCH_RESULTS,
    payload: { searchProducts: data, query: query, numberOfProducts }
});


export function quickViewModel(obj) {
    return dispatch => {
        document.getElementsByTagName("body")[0].style.overflow = "hidden"
        dispatch(setQuickViewObj(obj));
    }
}
export const QUICK_VIEW = 'QUICK_VIEW';
export const setQuickViewObj = data => ({
    type: QUICK_VIEW,
    payload: { quickViewObj: data }
});

export function quickViewModelFalse(obj) {
    return dispatch => {
        dispatch(setQuickViewObjFalse(obj));
    }
}
export const QUICK_VIEW_FALSE = 'QUICK_VIEW_FALSE';
export const setQuickViewObjFalse = data => ({
    type: QUICK_VIEW_FALSE,
    payload: { quickViewObj: false }
});


export function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

//get arrived products
export function fetchArrivedProduct(type) {
    console.log("{type}")
    let code = getCookie("authToken")
    return dispatch => {
        dispatch(isLoadingTrue())
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/products/arrived/`,
            // headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: '',
        })
            .then(response => {
                let data = response.data
                dispatch(fetchArrivedProductsSucces(data));
                // console.log(data)
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const FETCH_ARRIVED_PRODUCTS = 'FETCH_ARRIVED_PRODUCTS';
export const fetchArrivedProductsSucces = arrivedProducts => ({
    type: FETCH_ARRIVED_PRODUCTS,
    payload: { arrivedProducts }
});

//get brands
export function getBrandsTypeUser() {
    return dispatch => {
        return Axios({
            method: 'GET',
            url: process.env.REACT_APP_BACKEND_URL + '/api/admin/brand-type-user/',
            validateStatus: (status) => {
                return true;
            },
        })
            .then(response => {
                let data = response.data
                dispatch(gettingBrandsTypeUser(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_BRANDS_TYPE_USER = 'GET_BRANDS_TYPE_USER';
export const gettingBrandsTypeUser = brandsTypeUser => ({
    type: GET_BRANDS_TYPE_USER,
    payload: { brandsTypeUser }
});



// get skincare products all
export function getFilterProducts(page, productPerPage, category, brands, sub, from, to) {
    return dispatch => {
        dispatch(productLoader())
        let route = ""
        if (sub === "skin-care") {
            route = "skin"
        }
        if (sub === "body-care") {
            route = "body"
        }
        if (sub === "hair-care") {
            route = "hair"
        }
        let data = new FormData()

        let params = new URLSearchParams(window.location.search)
        let categoryy = category ? category : params.get("cat")
        let brands = params.get("brands")
        let prices = params.get("priceArr") ? params.get("priceArr").split(',') : ""
        let sortBy = params.get("sortBy")
        if (sortBy === "LTH") {
            data.append("ascending", true)
        }
        if (sortBy === "HTL") {
            data.append("descending", true)
        }
        data.append('page', page)
        data.append("product_per_page", productPerPage)
        if (categoryy) {
            data.append('category', categoryy)
        }
        if (brands) {
            data.append('brands', brands)
        }
        if (prices) {
            data.append('price_min', prices.length && prices[0])
            data.append('price_max', prices.length && prices[1])
        }

        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/products/${route}/all/`,
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                let data = []
                if (sub === "skin-care") {
                    data = response.data.products.skin_products
                }
                if (sub === "body-care") {
                    data = response.data.products.body_products
                }
                if (sub === "hair-care") {
                    console.log("abcc")
                    data = response.data.products.hair_products
                }
                let numberOfProducts = response.data.products.number_of_products
                let brands = []
                data.filter(a => {
                    if (!brands.includes(a.brand)) {
                        brands.push(a.brand)
                    }
                })

                if (sub === "skin-care") {
                    dispatch(fetchSkincareProductsSuccess(data, numberOfProducts));
                }
                if (sub === "body-care") {
                    dispatch(fetchBodycareProductsSuccess(data, numberOfProducts));
                }
                if (sub === "hair-care") {
                    console.log("abcc")
                    dispatch(fetchHaircareProductsSuccess(data, numberOfProducts));
                }
                let brandsWithCount = response.data.products.brands_with_count.sort((a, b) => b.number_of_products - a.number_of_products)
                dispatch(gettingBrands(brandsWithCount));
                let catWithCount = response.data.products.categories_with_count.sort((a, b) => b.number_of_products - a.number_of_products)
                dispatch(gettingCategoriesWithCount(catWithCount));
                // console.log(response.data)
                return data
            })
            .catch(error => {
                console.log(error)
            });
    };
}

//get blogTags
export function getBlogTags() {
    return dispatch => {
        return Axios({
            method: 'GET',
            url: process.env.REACT_APP_BACKEND_URL + '/api/admin/v2/blog-tag/',
            validateStatus: (status) => {
                return true;
            },
        })
            .then(response => {
                let data = response.data.results
                dispatch(gettingBlogTags(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_BLOG_TAGS = 'GET_BLOG_TAGS';
export const gettingBlogTags = blogTags => ({
    type: GET_BLOG_TAGS,
    payload: { blogTags }
});

//get blogs
export function getBlogs(id) {
    return dispatch => {
        let params = new URLSearchParams(window.location.search)
        let tag = id
        let page = params.get("page") ? params.get("page") : 1
        return Axios({
            method: 'GET',
            url: process.env.REACT_APP_BACKEND_URL + `/api/admin/v2/blog-post/?ordering=-updated_at&&blog_tag=${tag ? tag : ""}&&page_size=14&&page=${page}`,
            validateStatus: (status) => {
                return true;
            },
        })
            .then(response => {
                let data = response.data.results
                let count = response.data.count
                dispatch(gettingBlogs(data, count));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_BLOGS = 'GET_BLOGS';
export const gettingBlogs = (blogs, count) => ({
    type: GET_BLOGS,
    payload: { blogs, count }
});

//get top blogs
export function getTopBlogs() {
    return dispatch => {
        let params = new URLSearchParams(window.location.search)
        let tag = params.get("tag")
        return Axios({
            method: 'GET',
            url: process.env.REACT_APP_BACKEND_URL + `/api/admin/v2/blog-post/?ordering=-views&&page_size=5`,
            validateStatus: (status) => {
                return true;
            },
        })
            .then(response => {
                let data = response.data.results
                dispatch(gettingTopBlogs(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}
export const GET_TOP_BLOGS = 'GET_TOP_BLOGS';
export const gettingTopBlogs = topBlogs => ({
    type: GET_TOP_BLOGS,
    payload: { topBlogs }
});

//get filtered blogs
export function getFilteredBlogs() {
    return dispatch => {
        let params = new URLSearchParams(window.location.search)
        let tag = params.get("tag")
        return Axios({
            method: 'GET',
            url: process.env.REACT_APP_BACKEND_URL + `/api/admin/v2/blog-post/?ordering=-views&&blog_tag=${tag}`,
            validateStatus: (status) => {
                return true;
            },
        })
            .then(response => {
                let data = response.data.results
                dispatch(gettingBlogs(data));
                return data
            })
            .catch(error => {
                // console.log(error)
            });
    };
}

//get blogSingle
export function getBlogSingle(id) {
    return dispatch => {
        if (id) {
            return Axios({
                method: 'GET',
                url: process.env.REACT_APP_BACKEND_URL + `/api/admin/v2/blog-post/${id}/`,
                validateStatus: (status) => {
                    return true;
                },
            })
                .then(response => {
                    let data = response.data
                    dispatch(gettingBlogSingle(data));
                    return data
                })
                .catch(error => {
                    console.log(error)
                });
        } else {
            dispatch(gettingBlogSingle(false));
        }
    };
}
export const GET_BLOG_SINGLE = 'GET_BLOG_SINGLE';
export const gettingBlogSingle = blog => ({
    type: GET_BLOG_SINGLE,
    payload: { blog }
});


//get brand products
export function getBrandProducts(id) {
    return dispatch => {
        dispatch(productLoader())
        let params = new URLSearchParams(window.location.search)
        console.log(params.get("page"))
        let prices = params.get("prices") ? params.get("prices").split(',') : ""
        let sortBy = params.get("sort")
        let cat = params.get("cat")
        let data = new FormData()
        data.append("brands", id)
        data.append('page', params.get("page") ? params.get("page") : 1)
        data.append("product_per_page", 12)

        if (prices) {
            data.append('price_min', prices.length && prices[0])
            data.append('price_max', prices.length && prices[1])
        }
        if (sortBy === "LTH") {
            data.append("ascending", true)
        }
        if (sortBy === "HTL") {
            data.append("descending", true)
        }
        if (cat) {
            data.append("category", cat)
        }

        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/products/product/all/`,
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                let data = response.data.products.skin_products
                let numberOfProducts = response.data.products.number_of_products
                let catWithCount = response.data.products.categories_with_count.sort((a, b) => b.count - a.count)
                console.log(catWithCount)
                dispatch(gettingCategoriesWithCount(catWithCount));
                dispatch(gettingBrandProducts(data, numberOfProducts));
            })
            .catch(error => {
                console.log(error)
            });
    }
};

export const GET_BRAND_PRODUCTS = 'GET_BRAND_PRODUCTS';
export const gettingBrandProducts = (products, numberOfProducts) => ({
    type: GET_BRAND_PRODUCTS,
    payload: { products, numberOfProducts }
});

//get all categories
export function getAllCategories() {
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/product/categories/`,
            validateStatus: (status) => {
                return true;
            },
        })
            .then(response => {
                let data = Object.values(response.data.categories)
                dispatch(gettingAllCategories(data));
                return data
            })
            .catch(error => {
                console.log(error)
            });
    };
}
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const gettingAllCategories = allCategories => ({
    type: GET_ALL_CATEGORIES,
    payload: { allCategories }
});


//get all productBarSearch
export function getProductSearhBar(val, page, productPerPage) {
    return dispatch => {
        let data = new FormData()
        data.append("query", val)
        data.append("products_per_page", productPerPage)
        data.append("page", page)
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/search/bar/`,
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            });
    };
}

//get coupons
export function getCoupons() {
    return dispatch => {
        // let code = getCookie("authToken")
        return Axios({
            method: 'get',
            url: process.env.REACT_APP_BACKEND_URL + `/api/admin/static-coupon/`,
            validateStatus: (status) => {
                return true;
            },
            // headers: { Authorization: `Bearer ${code}` },
        })
            .then(response => {
                let data = response.data
                dispatch(gettingCoupons(data));
                // return data
            })
            .catch(error => {
                console.log(error)
            });
    };
}
export const GET_COUPONS = 'GET_COUPONS';
export const gettingCoupons = coupons => ({
    type: GET_COUPONS,
    payload: { coupons }
});


//Send Mails
export function sendMails(dataa) {
    let data = new FormData()
    for(let key in dataa){
        data.append(key, dataa[key])
    }
    return dispatch => {
        return Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + `/api/email/send/`,
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                
            })
            .catch(error => {
                console.log(error)
            });
    };
}