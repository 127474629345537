import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgressBar from '../home/Components/ProgressBar';
import './quickView.scss'
import Axios from 'axios'
import { getCartOrders, getCookie, getWishlist, quickViewModelFalse } from '../../modules/actions';
import 'animate.css'
import RedirectPage from '../ProductDetails/RedirectPage';
import { NavLink, withRouter } from 'react-router-dom';

let percentage = 0

function percentageCalculate(percentagee) {
    setInterval(() => {
        if (percentage < percentagee) {
            // console.log(percentage)
            percentage = percentage + 1
            if (document.getElementById('percentText')) {
                document.getElementById('percentText').innerHTML = `${percentage}%`
            }
        }
    }, 10);
}


class QuickViewModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            percentage: 0
        }
    }

    animateProgress(scores) {
        // console.log(scores)
        let scoresArr = []
        for (let key in scores) {
            let obj = {
                lable: key,
                score: scores[key]
            }
            scoresArr.push(obj)
        }
        this.setState({
            scores: scoresArr
        }, () => {
            setTimeout(() => {
                this.state.scores.map((a, i) => {
                    document.getElementById(`progress${i}`).style.width = `${a.score}%`
                })
            }, 500)
        })
    }

    componentDidMount() {
        let obj = this.props.quickViewObj
        let slug = obj.slug
        let score = obj.score
        this.setState({
            product: obj,
        }, () => {
            if (score) {
                this.setState({
                    percentage: 0
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            score,
                            aff: true
                        }, () => {
                            // this.animateProgress(this.state.product.scores)
                            this.setMatch(score)
                        })
                    }, 500)
                })
            } else {
                this.setState({
                    aff: true
                })
            }
        })
    }

    setMatch = (a) => {
        let percentagee = a

        setTimeout(() => {
            percentageCalculate(percentagee)
            this.setState({
                percentage: percentagee
            })
        });
    }

    addToCart = (id, out) => {
        if (!out) {
            let code = getCookie("authToken")
            const data = new FormData();
            data.append("id", id);
            data.append("action", "add");
            Axios({
                method: 'post',
                url: process.env.REACT_APP_BACKEND_URL + '/api/cart/update/',
                headers: { Authorization: `Bearer ${code}` },
                validateStatus: (status) => {
                    return true;
                },
                data: data,
            })
                .then(response => {
                    if (response.data.code === "token_not_valid") {
                        let msg = "You have login first"
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        window.location.href = '/login'
                    } else {
                        let data = response.data.products
                        // this.props.getCartOrders(data);
                        let msg = response.data.message
                        document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                        document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                        setTimeout(() => {
                            document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                        }, 2000)
                        return data
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        } else {
            document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>Out of stock</strong>`
            document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
            setTimeout(() => {
                document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
            }, 2000)
        }
    }

    addToWhishlist = (id) => {
        let code = getCookie("authToken")
        const data = new FormData();
        data.append("product_id", id);
        // data.append("action", "add");
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/wishlist/update/',
            headers: { Authorization: `Bearer ${code}` },
            validateStatus: (status) => {
                return true;
            },
            data: data,
        })
            .then(response => {
                if (response.data.code === "token_not_valid") {
                    // let msg = "You have login first"
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    window.location.href = '/login'
                } else {
                    let data = response.data.products
                    // let msg = response.data.message
                    // document.getElementsByClassName('notificationAlert')[0].innerHTML = `<strong>${msg}</strong>`
                    // document.getElementsByClassName('notificationAlert')[0].style.display = 'block'
                    this.props.getWishlist()
                    // setTimeout(() => {
                    //     document.getElementsByClassName('notificationAlert')[0].style.display = 'none'
                    // }, 2000)
                    return data
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    quickViewModelFalse = () => {
        document.getElementsByTagName("body")[0].style.overflow = "auto"
        this.props.quickViewModelFalse()
        this.setState({
            score: false,
            percentage: 0
        })
    }

    checkWhishlist = (a, abc) => {
        let whishlistProducts = this.props.whishlistProducts
        if (whishlistProducts) {
            let check = whishlistProducts.filter(b => b.id === a.id)
            if (check.length) {
                return (
                    <svg onClick={() => this.addToWhishlist(this.state.product.id)} className="bi bi-heart-fill detailWhishlistIcon" width="1em" height="1em" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
                    </svg>
                )
            } else {
                return (
                    <svg onClick={() => this.addToWhishlist(this.state.product.id)} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart detailWhishlistIcon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                    </svg>
                )
            }
        } else {
            return (
                <svg onClick={() => this.addToWhishlist(this.state.product.id)} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-heart detailWhishlistIcon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                </svg>
            )
        }
    }

    redirecTo = (link, obj) => {
        localStorage.setItem('redirect', true)

        // this.setState({
        //     redirectPage: true
        // })
        let data = new FormData()
        data.append('link_id', obj.id)
        console.log(obj)
        Axios({
            method: 'post',
            url: process.env.REACT_APP_BACKEND_URL + '/api/products/link/click/',
            validateStatus: (status) => {
                return true;
            },
            data,
        })
            .then(response => {
                // console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            });

        window.open(process.env.REACT_APP_FRONTEND_URL + `/redirect?${link}`)
        // window.open(`http://localhost:3000/redirect?${link}`)
    }

    discoutGet = (a, b) => {
        let price = a
        let discounted_price = b
        let discounted_given = Number(price) - Number(discounted_price)
        return `${Number(Math.ceil((discounted_given / price) * 100).toFixed(1)).toFixed(0)}% Off`
    }

    render() {
        document.getElementById("quickViewModel") && document.getElementById("quickViewModel").addEventListener('click', (ev) => {
            if (ev.target.id === "quickViewModel") {
                this.quickViewModelFalse()
            }
        })
        return (
            <div id="quickViewModel" className="quickViewModel">
                {this.state.redirectPage && <RedirectPage />}
                {this.state.product && this.props.quickView &&
                    <div className="profilee">
                        <div onClick={this.quickViewModelFalse} className="crossIcon">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                            </svg>
                        </div>
                        <div className="checkout-page container">
                            <div className="alert alert-success alert-dismissable" style={{ display: 'none' }}>
                                <strong className="alert-text"></strong>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card  animate__animated animate__rotateInDownLeft">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="preview col-md-4">
                                                    <div className="preview-pic tab-content">
                                                        <div className="tab-pane active" id="pic-00"><img src={this.state.product.image} /></div>
                                                        {this.state.product && this.state.product.secondary_images.map((a, i) => {
                                                            return (
                                                                <div key={i} className="tab-pane" id={`pic-0${i + 1}`}><img src={a} /></div>
                                                            )
                                                        })}
                                                    </div>
                                                    <ul className="preview-thumbnail nav nav-tabs">
                                                        <li className="active">
                                                            <a data-target="#pic-00" data-toggle="tab"><img src={this.state.product.image} /></a>
                                                        </li>
                                                        {this.state.product && this.state.product.secondary_images.map((a, i) => {
                                                            return (
                                                                <li key={i}>
                                                                    <a data-target={`#pic-0${i + 1}`} data-toggle="tab"><img src={a} /></a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>

                                                </div>

                                                <div className="col-md-8 seconddd" id="topp">
                                                    <div className="row b-top">
                                                        <div className="col-md-6 max-width">
                                                            {this.state.score ?
                                                                <h4 className="product-title quickTitle">
                                                                    {window.screen.width < 600 ?
                                                                        <a target={window.screen.availWidth < 768 ? false : "_blank"} href={`/${this.state.product.type}/${this.state.product.slug}?score=${this.state.product.score}`}>{this.state.product.name}</a>
                                                                        :
                                                                        <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/${this.state.product.type}/${this.state.product.slug}?score=${this.state.product.score}`}>{this.state.product.name}</NavLink>
                                                                    }
                                                                </h4>
                                                                :
                                                                <h4 className="product-title quickTitle">
                                                                    {window.screen.width < 600 ?
                                                                        <a target={window.screen.availWidth < 768 ? false : "_blank"} href={`/skin-care/${this.state.product.slug}`}>{this.state.product.name}</a>
                                                                        :
                                                                        <NavLink target={window.screen.availWidth < 768 ? false : "_blank"} to={`/skin-care/${this.state.product.slug}`}>{this.state.product.name}</NavLink>
                                                                    }
                                                                </h4>
                                                            }
                                                            <p>{this.state.product.category} by {this.state.product.brand}</p>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h5 className="fs mt-2 price">
                                                                        {/* <span className="colord"><i className="fas fa-rupee-sign"></i> {this.state.product.price}.00</span> */}
                                                                        {/* {this.state.product.discounted_price && this.state.product.discounted_price !== this.state.product.price ?
                                                                    <>
                                                                        {this.state.product.discounted_price && <span className="lightGray"><span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign three" aria-hidden="true"></i>{this.state.product.price}.00 </span></span>}
                                                                        <span className="colord">&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i>{this.state.product.discounted_price}.00 {this.state.product.off && <span className="lightGray two">|</span>}</span>
                                                                        {this.state.product.discounted_price && <span className="pinkk">{this.discoutGet(this.state.product.price, this.state.product.discounted_price)}</span>}
                                                                    </>
                                                                    :
                                                                    <span className="colord"><i className="fas fa-rupee-sign" aria-hidden="true"></i>{this.state.product.price}.00</span>
                                                                } */}
                                                                        {/* <span className="colord"><i className="fas fa-rupee-sign" aria-hidden="true"></i>{this.state.product.product_affiliates.length && this.state.product.product_affiliates.sort((a, b) => Number(a.calculated_price) - Number(b.calculated_price))[0].calculated_price}.00</span> */}
                                                                        {this.state.product.product_affiliates && this.state.product.product_affiliates.length && this.state.product.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price < this.state.product.price ?
                                                                            <>
                                                                                <span className="lightGray">
                                                                                    MRP:&nbsp;&nbsp;
                                                                                        <span style={{ textDecoration: "line-through" }}><i className="fas fa-rupee-sign" aria-hidden="true"></i>{this.state.product.price}</span></span>
                                                                                <span>
                                                                                    <i className="fas fa-rupee-sign" aria-hidden="true"></i>
                                                                                    {this.state.product.product_affiliates && this.state.product.product_affiliates.length && Number(this.state.product.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price).toFixed(0)}
                                                                                    <span className="lightGray two"> |
                                                                                        </span>
                                                                                </span>
                                                                                <span className="pinkk">{this.discoutGet(this.state.product.price, this.state.product.product_affiliates && this.state.product.product_affiliates.length && this.state.product.product_affiliates.sort((a, bb) => Number(a.calculated_price) - Number(bb.calculated_price))[0].calculated_price)}</span>
                                                                            </>
                                                                            :
                                                                            <span>MRP:&nbsp;&nbsp;<i className="fas fa-rupee-sign" aria-hidden="true"></i> {this.state.product.price}</span>
                                                                        }
                                                                    </h5>
                                                                    {/* <b>inclusive of all taxes</b> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 max-width text-right mb-mt-3">
                                                            {this.checkWhishlist(this.state.product, "a")}

                                                            {/* <button onClick={() => this.state.product.stock_quantity !== 0 ? this.addToCart(this.state.product.id) : this.addToCart(this.state.product.id, "out")} data-productid="{{product.id}}"
                                                                data-action="add" className="btn btn-cart update-cart">Add to cart</button> */}
                                                        </div>
                                                    </div>
                                                    <div className="row" style={!this.state.score && this.props.is_authenticated ? { justifyContent: "flex-end" } : !this.props.is_authenticated ? { justifyContent: "space-between", alignItems: "center" } : {}}>
                                                        {!this.props.is_authenticated &&
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <p className="QuizBtnText">Take our quiz to find your product match</p>
                                                                <button onClick={() => window.location.href = 'quiz'} className="quickViewQuizButton">Start Glam Quiz </button>
                                                            </div>}
                                                        {this.state.score &&
                                                            <div className="match col-md-6">
                                                                <div className="upperProgress">
                                                                    <CircularProgressBar percentage={this.state.percentage} />
                                                                    <div className="progressText">
                                                                        <b id="percentText">{percentage}%</b>
                                                                        <small>matching score</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.aff &&
                                                            <div className="afiliateMarketList quickViewAff col-md-6">
                                                                {/* <table>
                                                                    <tbody>
                                                                        {this.state.product.product_affiliates.length ?
                                                                            this.state.product.product_affiliates.sort((a, b) => Number(a.calculated_price) - Number(b.calculated_price)).map((a, i) => {
                                                                                return (
                                                                                    <tr key={i}>
                                                                                        <th>{a.vendor_name}</th>
                                                                                        <td className="pricee"><i className="fas fa-rupee-sign" aria-hidden="true"></i> {a.calculated_price}</td>
                                                                                        <td>
                                                                                            <button onClick={() => this.redirecTo(a.affiliate_link, a)}>BUY NOW</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            : null}
                                                                    </tbody>
                                                                </table> */}
                                                                {this.state.product.product_affiliates && this.state.product.product_affiliates.length ?
                                                                    this.state.product.product_affiliates.sort((a, b) => Number(a.calculated_price) - Number(b.calculated_price)).map((a, i) => {
                                                                        return (
                                                                            <div key={i} className="offerLinks">
                                                                                <b>{a.vendor_name}</b>
                                                                                <p className="pricee"><i className="fas fa-rupee-sign" aria-hidden="true"></i> {Number(a.calculated_price).toFixed(0)}</p>
                                                                                <button onClick={() => this.redirecTo(a.affiliate_link, a)}>BUY NOW</button>
                                                                                {a.offer_code &&
                                                                                    <div className="offerCode">Use <span>{a.offer_code}</span> to get this price</div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : null}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    quickViewObj: store.quickViewObj,
    whishlistProducts: store.whishlistProducts,
    is_authenticated: store.is_authenticated,
    quickView: store.quickView
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getCartOrders,
            quickViewModelFalse,
            getWishlist
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(QuickViewModel))