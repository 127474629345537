import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMyOrders } from '../../../modules/actions';

class MyOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.getMyOrders()
    }

    quantityTotal = (a) => {
        let total = 0
        a.map((b) => {
            total += Number(b.quantity)
        })
        return total
    }

    orderDetails = (name,i) => {
        let hr = document.getElementById(`hr${i}`)
        let detailCard = document.getElementById(`detailCard${i}`)
        let button = document.getElementById(`orderDetailsBut${i}`)
        let button2 = document.getElementById(`orderDetailsButClose${i}`)
        if (!name) {
            hr.style.display = "block"
            detailCard.style.display = "block"
            button.style.display = "none"
            button2.style.display = "block"
        } else {
            hr.style.display = "none"
            detailCard.style.display = "none"
            button2.style.display = "none"
            button.style.display = "block"
        }
    }

    render() {
        return (
            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="review">
                <div className="row mb-mobile">
                    <div className="col-md-12 text-center">
                        <h2 style={{ fontSize: '50px !important' }}>My Orders</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {this.props.orders && this.props.orders.map((order, i) => {
                            console.log(order)
                            return (
                                <div className="card card-profile orderMainFlex">
                                    <div className="orderMain">
                                        <h6>Order Id</h6>
                                        <p>{order.id}</p>
                                        <h5>{this.quantityTotal(order.orderitems)} Item(s) {order.status}</h5>
                                        <p>{order.status === "Pending" ? `Order Booked On ${new Date(order.date_ordered).toDateString()}` : `Order Delivered On ${new Date(order.date_ordered).toDateString()}`}</p>
                                        <div className="orderSmallImages">
                                            {order.orderitems.map((a) => {
                                                return (
                                                    <div className="singleImageDiv">
                                                        <p>x{a.quantity}</p>
                                                        <img src={a.product_details[0].image}
                                                            className="img img-responsive img-orders" />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div>
                                        <button onClick={() => this.orderDetails(false, i)} id={`orderDetailsBut${i}`} className="orderDetailsBut">Order Details</button>
                                        <button onClick={() => this.orderDetails("hide", i)} id={`orderDetailsButClose${i}`} className="orderDetailsButClose">Hide Details</button>
                                    </div>
                                    <hr id={`hr${i}`} style={{ width: '100%' }} />
                                    <div className="col-md-12">
                                        <div id={`detailCard${i}`} className="card">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12">
                                                    <ul id="progressbar" className="text-center">
                                                        <li className="active step0"></li>
                                                        <li className="active step0"></li>
                                                        <li className="active step0"></li>
                                                        <li className="step0"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row justify-content-between top">
                                                <div className="row d-flex icon-content"> <img className="icon" src="https://i.imgur.com/9nnc9Et.png" />
                                                    <div className="d-flex flex-column">
                                                        <p className="font-weight-bold">Order<br />Processed</p>
                                                    </div>
                                                </div>
                                                <div className="row d-flex icon-content"> <img className="icon" src="https://i.imgur.com/u1AzR7w.png" />
                                                    <div className="d-flex flex-column">
                                                        <p className="font-weight-bold">Order<br />Shipped</p>
                                                    </div>
                                                </div>
                                                <div className="row d-flex icon-content"> <img className="icon" src="https://i.imgur.com/TkPm63y.png" />
                                                    <div className="d-flex flex-column">
                                                        <p className="font-weight-bold">Order<br />En Route</p>
                                                    </div>
                                                </div>
                                                <div className="row d-flex icon-content"> <img className="icon" src="https://i.imgur.com/HdsziHP.png" />
                                                    <div className="d-flex flex-column">
                                                        <p className="font-weight-bold">Order<br />Arrived</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 ">
                                                <div className="table-responsive">
                                                    <table className="table table-borderless tb-my mobileTable">
                                                        <thead>
                                                            <tr>
                                                                <th>IMAGE</th>
                                                                <th>Product Name</th>
                                                                <th>QUNATITY</th>
                                                                <th className="">PRICE</th>
                                                                <th className="">DATE ADDED</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody id="tbodyOfOrdersShiped">
                                                        </tbody>
                                                        <tbody>
                                                            <>
                                                                {order.orderitems.map((item, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td className="text-left">
                                                                                <img src={item.product_details[0].image}
                                                                                    className="img img-responsive img-orders" />
                                                                            </td>
                                                                            <td className="text-left">
                                                                                {item.product_details[0].name}
                                                                            </td>
                                                                            <td>
                                                                                {item.quantity}
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="price-text">{Number(item.total_price) * Number(item.quantity)}</p>
                                                                            </td>
                                                                            <td>
                                                                                {item.date_added}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                                <tr>
                                                                    <td colspan="2"><b>Shipped Date: </b>{order.date_ordered}
                                                                    </td>
                                                                    <td colspan="2"><b>Total:</b> ${order.total_price}</td>
                                                                </tr>
                                                            </>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        {/* <div className="card-body padd-0">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="table-responsive">
                                            <table className="table table-borderless tb-my mobileTable">
                                                <thead>
                                                    <tr>
                                                        <th>IMAGE</th>
                                                        <th>Product Name</th>
                                                        <th>QUNATITY</th>
                                                        <th className="">PRICE</th>
                                                        <th className="">DATE ADDED</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="tbodyOfOrdersShiped">
                                                </tbody>
                                                <tbody>
                                                    {this.props.orders && this.props.orders.map((order,i) => {
                                                        return (
                                                            <>
                                                                {order.orderitems.map((item,i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td className="text-left">
                                                                                <img src={item.product_details[0].image}
                                                                                    className="img img-responsive img-orders" />
                                                                            </td>
                                                                            <td className="text-left">
                                                                                {item.product_details[0].name}
                                                                            </td>
                                                                            <td>
                                                                                {item.quantity}
                                                                            </td>
                                                                            <td className="">
                                                                                <p className="price-text">{Number(item.total_price) * Number(item.quantity)}</p>
                                                                            </td>
                                                                            <td>
                                                                                {item.date_added}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                                <tr>
                                                                    <td colspan="2"><b>Shipped Date: </b>{order.date_ordered}
                                                                    </td>
                                                                    <td colspan="2"><b>Total:</b> ${order.total_price}</td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    orders: store.orders
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getMyOrders
        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyOrders)