import React from 'react'
import test1 from '../../../Images/test1.jpg'
import test2 from '../../../Images/test2.jpg'
import test3 from '../../../Images/test3.jpg'
import test4 from '../../../Images/test4.jpg'
import test5 from '../../../Images/test5.jpg'
import test6 from '../../../Images/test6.jpg'

class NinthSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div>
                <div className="banner2 container-fluid">
                    <div id="carouselExampleIndicators" className="carousel slide  banner2-text carousel-style-change"
                        data-ride="carousel">
                        <div className="carousel-indicators image_with_slider team">
                            <div data-target="#carouselExampleIndicators" data-slide-to="0" className="team-img light active">
                                <img src={test1} alt="" />
                            </div>
                            <div data-target="#carouselExampleIndicators" data-slide-to="1" className="team-img light">
                                <img src={test2} alt="" />
                            </div>
                            <div data-target="#carouselExampleIndicators" data-slide-to="2" className="team-img light">
                                <img src={test3} alt="" />
                            </div>
                            <div data-target="#carouselExampleIndicators" data-slide-to="3" className="team-img light">
                                <img src={test4} alt="" />
                            </div>
                            <div data-target="#carouselExampleIndicators" data-slide-to="4" className="team-img light">
                                <img src={test5} alt="" />
                            </div>
                            <div data-target="#carouselExampleIndicators" data-slide-to="5" className="team-img light">
                                <img src={test6} alt="" />
                            </div>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">

                                <h1>”</h1>

                                <p id="first1">I must say the website is very well designed. Instead of searching for a product in all the  websites we can just land here and compare the prices. Recommendations are also very useful as it specifically suggests the products that best suits our skin type.<br />
                                    <span></span> <span className="olie">Suruchi</span> &nbsp; <span></span>
                                </p>
                            </div>
                            <div className="carousel-item ">

                                <h1>”</h1>

                                <p id="first1"> I can tell you that GlaMate is the best place to solve all your beauty related problems. The attractive thing is it's recommendations wherein it shows all the concerned products with price comparisons. I have come across the blogs in the site which helped to know pretty new things.<br />
                                    <span></span> <span className="olie">Mahitha Rangarajula</span> &nbsp; <span></span>
                                </p>
                            </div>
                            <div className="carousel-item ">

                                <h1>”</h1>

                                <p id="first1">I spent days investing in the best products until I found GlaMate. The most lovable part of the website is the quiz- it asked for all the concerns of my skin and resulted in the best skin products for my skin type in minimal to high price ranges. Thanks to the all the team of GlaMate, you made my skin flawless.<br />
                                    <span></span> <span className="olie">Mounika</span> &nbsp; <span></span>
                                </p>
                            </div>
                            <div className="carousel-item ">

                                <h1>”</h1>

                                <p id="first1">To start, the design of the website is well organized. As we know online shopping makes our life's easier, GlaMate has made it much easier to buy products of different brands at one place without worrying about the price ranges.It's really a “one stop beauty shopping solution”,where we can compare prices and get recommendations.<br />

                                    <span></span> <span className="olie">Aishwarya Veldanda</span> &nbsp; <span></span>
                                 </p>
                            </div>
                            <div className="carousel-item ">

                                <h1>”</h1>

                                <p id="first1">This is an amazing website to compare and get what's best for you. I've come across various blogs on this website and gained information on some products and I'm in love with it. Shopping has never been so easier.<br />

                                    <span></span> <span className="olie">Kanchi</span> &nbsp; <span></span>
                                 </p>
                            </div>
                            <div className="carousel-item ">

                                <h1>”</h1>

                                <p id="first1">My god, this site is my saviour every time. I have been using this site since 10 days and it changed the way I shop. I can easily compare the prices of the same product in different sites and choose the cheapest and save some good money. They even keep updating the prices quickly. Also, love the quiz - gives me the exact solution to my skincare. <br />

                                    <span></span> <span className="olie">Gayatri</span> &nbsp; <span></span>
                                 </p>
                            </div>

                        </div>
                        <a className="carousel-control-prev top-16" href="#carouselExampleIndicators" role="button" data-slide="prev">

                            <i className="fa fa-chevron-left " aria-hidden="true"></i>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next top-16" href="#carouselExampleIndicators" role="button" data-slide="next">

                            <i className="fa fa-chevron-right " aria-hidden="true"></i>

                            <span className="sr-only">Next</span>
                        </a>
                        <div className="team1">
                            <div className="team-img big">
                                <img src={test2} alt="" />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default NinthSection