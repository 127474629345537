import React from 'react'

class ThirdSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="container curate-con mt-5">
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h2 className="mb-text">Our Process</h2>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <ul className="nav nav-tabs about-tabs" id="myTab" role="tablist" style={{borderBottom: 'none !important'}}>
                                    <li className="nav-item col-md-4 pad-0">
                                        <a className="aboutTab nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">PERSONALIZE <span className="process-no">01</span></a>
                                    </li>
                                    <li className="nav-item col-md-4 pad-0">
                                        <a className="aboutTab nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">ANALYZE AND RECOMEND  <span className="process-no">02</span></a>
                                    </li>
                                    <li className="nav-item col-md-4 pad-0">
                                        <a className="aboutTab nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">COMPARE AND BUY <span className="process-no">03</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card shadow-custom">
                                    <div className="card-body padd-0">
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                It’s easy to find a thousand beauty products at the drop of a hat on the internet, but how do you land on the right product without expert advice? We believe every individual has discrete beauty needs who seek unique solutions too. At GlaMate we’ve paired technology and expert wisdom to create a one-stop solution to customer’s beauty challenges. We want to introduce you to beauty products that cater to your unique skin type, hair type, lifestyle, and several other factors, saving you the hassle of rigorous browsing and analyzing. So, take our glam quiz now to get your beauty recommendations.
                                            </div>
                                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                 Our glam quiz is meticulously tailored to understand your own beauty requirements. Paired with the wisdom of expert dermatologists, estheticians, and data scientists, our algorithm analyses your concerns and provides you with the most suitable, unbiased recommendations. Each recommendation comes with a matching score for you to compare and pick the product which aligns best with your needs. 
                                            </div>
                                            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                                                Another unique feature of our platform is price comparison. Prices from different retailers are displayed for you to bag the best deal on every product available in our inventory. We want to save you the trouble of visiting multiple sites to avail the best price and present everything on one table. Our sole motive is to save your time, money, and tabs and deliver a smooth shopping experience you always yearn for.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ThirdSection