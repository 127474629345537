import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Wallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        console.log(this.props)
        return (
            <div className="tab-pane fade" id="wallet" role="tabpanel" aria-labelledby="wallet">
                <div className="walletTop">
                    <i class="fas fa-wallet"></i>
                    <div className="walletBalance">
                        <div className="amt"><i className="fas fa-rupee-sign"></i> {this.props.user && this.props.user.wallet}.00</div>
                        <div className="txt">Your wallet balance</div>
                    </div>
                    <div className="ques">
                        Want to earn some Glamat Money ?
                    </div>
                </div>
                <div className="walletSecond">
                    <div className="secondFirst">
                        <h4>REFER & EARN</h4>
                        <h6>Hey !</h6>
                        <b>Have you sharedyour beauty secret yet! Introduce Glamate.com to your bestiesand get rewarded</b>
                        <h6 style={{ color: "gray", marginTop: 20 }}>SHARE YOUR INVITE CODE</h6>
                        <h3>{this.props.user && this.props.user.referral_code}</h3>
                        <div className="SocialIcons">
                            <i class="fab fa-facebook-square"></i>
                            <i class="fab fa-twitter-square"></i>
                            <i class="fab fa-google-plus-square"></i>
                        </div>
                    </div>

                    <div className="secondSecond">

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({
    user: store.user,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Wallet)