import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SuccessPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }

    render() {
        document.title = `Success`
        return (
            <div className="successDiv">
                <div className="success">
                    Successfully paid <img src="https://icon-library.com/images/successful-icon/successful-icon-9.jpg" alt="" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ store }) => ({

})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {

        },
        dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SuccessPage)